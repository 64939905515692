import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'
import {
  ICreateCommentPushAgreeForm,
  IDeleteCommentPushAgreeForm,
  IFindCommentPushAgreeForm,
  ISendCommentPushForm,
  ISetTokenForm,
} from '@/interfaces/push'
import qs from 'qs'

export async function setToken(setTokenForm: ISetTokenForm) {
  await StrapiAPI.post('/tokens', setTokenForm)
}

export async function findCommentPushAgree(form: IFindCommentPushAgreeForm) {
  const query = qs.stringify({
    _where: [{ type_eq: form.type }, { type_id_eq: form.type_id }, { user_id_eq: form.user_id }],
  })
  const response = await StrapiAPI.get(`/comment-push-agrees?${query}`)
  return response.data && response.data[0]
}

export async function createCommentPushAgree(form: ICreateCommentPushAgreeForm) {
  const response = await StrapiJwtAPI(form.jwt).post('/comment-push-agrees', form)
  return response.data
}

export async function deleteCommentPushAgree(form: IDeleteCommentPushAgreeForm) {
  const response = await StrapiJwtAPI(form.jwt).delete(`/comment-push-agrees/${form.id}`)
  return response.data
}

export async function sendCommentPush(form: ISendCommentPushForm) {
  await StrapiJwtAPI(form.jwt).post('/apps/push/comment', form)
}
