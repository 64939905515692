import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'

export default function useLocation() {
  const navigation = useNavigation<any>()

  const getScreenName = (category: number) => {
    switch (category) {
      case 1:
      case 2:
        return TOP_TAB.JOB
      case 3:
      case 4:
        return TOP_TAB.PROPERTY
      case 5:
      case 6:
        return TOP_TAB.SHOPPING
      case 7:
      case 8:
        return TOP_TAB.SERVICE
      case 9:
      case 10:
        return TOP_TAB.EDUCATION
      case 11:
      case 12:
        return TOP_TAB.TRIP
      case 13:
      case 14:
        return TOP_TAB.MEDICAL
      case 15:
      case 16:
        return TOP_TAB.LAW
      case 17:
      case 18:
        return TOP_TAB.CLUB
      case 19:
      case 20:
        return TOP_TAB.ADDRESS
    }
  }

  const handleBoardListLocation = (category: number) => {
    navigation.navigate(ROOT_STACK.TOP_TAB, {
      screen: getScreenName(category),
      params: { isScrollToList: true },
    })
  }

  const handleLoginPageLocation = () => {
    navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
  }

  return {
    handleBoardListLocation,
    handleLoginPageLocation,
  }
}
