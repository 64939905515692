import React from 'react'
import { Box } from 'native-base'

import AddressPremium from '@/screens/boards/components/AddressPremium'
import AddressNormal from '@/screens/boards/components/AddressNormal'
// import AddressDetailHeader from '@/screens/boards/components/AddressDetailHeader'
import { IAddressBook, IAddressGroup } from '@/interfaces/address'

interface Props {
  addressBook: IAddressBook
  addressGroupList: IAddressGroup[]
}

export default function AddressDetail(props: Props) {
  const { addressBook, addressGroupList } = props

  return (
    <Box bgColor='#f4f4f4' pb='11px' w='full'>
      <Box bgColor='#F9F9F9' w='full' mx='auto'>
        {/*<AddressDetailHeader title={addressBook.title} />*/}
        <AddressPremium addressList={addressBook.address_lists} />
        {addressGroupList.map((item) => {
          return item.address_lists && item.address_lists.length > 0 ? (
            <AddressNormal addressGroup={item} key={item.id} />
          ) : null
        })}
      </Box>
    </Box>
  )
}
