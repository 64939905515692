export enum ROOT_STACK {
  BACK = 'BACK',
  TOP_TAB = 'TOP_TAB',
  MY_PAGE_TAB = 'MY_PAGE_TAB',
  SEARCH_STACK = 'SEARCH_STACK',
  AUTH_STACK = 'AUTH_STACK',
  NOT_FOUND = '*',
  SEND_MESSAGE = 'SEND_MESSAGE',
  USER_PAGE_TAB = 'USER_PAGE_TAB',
  NEWS_PAPER = 'NEWS_PAPER',
  NEWS_PAPER_DETAIL = 'NEWS_PAPER_DETAIL',
  NEWS_DETAIL = 'NEWS_DETAIL',
  KYODO_DETAIL = 'KYODO_DETAIL',
  BANNER_DETAIL = 'BANNER_DETAIL',
  BANNER_COMPANY_DETAIL = 'BANNER_COMPANY_DETAIL',
  ADDRESS_DETAIL = 'ADDRESS_DETAIL',
  BOARD_DETAIL = 'BOARD_DETAIL',
  BOARD_CREATE = 'BOARD_CREATE',
  BOARD_UPDATE = 'BOARD_UPDATE',
  BANNER_BOARD_DETAIL = 'BANNER_BOARD_DETAIL',
  BANNER_BOARD_CREATE = 'BANNER_BOARD_CREATE',
  BANNER_BOARD_UPDATE = 'BANNER_BOARD_UPDATE',
  COMPANY_INTRO = 'COMPANY_INTRO',
  INQUIRY = 'INQUIRY',
  POLICY_PRIVACY = 'POLICY_PRIVACY',
  POLICY_SERVICE = 'POLICY_SERVICE',
  MEMBERSHIP_WITHDRAWAL = 'MEMBERSHIP_WITHDRAWAL',
  FACEBOOK_LOGIN = 'auth/facebook-login',
  MODIFY_PROFILE = 'MODIFY_PROFILE',
}

export enum USER_PAGE_TAB {
  USER_PAGE_BOARD = 'USER_PAGE_BOARD',
  USER_PAGE_COMMENT = 'USER_PAGE_COMMENT',
  USER_PAGE_GOOD = 'USER_PAGE_GOOD',
}

export enum TOP_TAB {
  MAIN = 'MAIN',
  NEWS = 'NEWS',
  JOB = 'JOB',
  PROPERTY = 'PROPERTY',
  SHOPPING = 'SHOPPING',
  SERVICE = 'SERVICE',
  EDUCATION = 'EDUCATION',
  TRIP = 'TRIP',
  MEDICAL = 'MEDICAL',
  LAW = 'LAW',
  CLUB = 'CLUB',
  ADDRESS = 'ADDRESS',
}

export enum SEARCH_STACK {
  SEARCH_HISTORY = 'SEARCH_HISTORY',
  SEARCH_PREVIEW = 'SEARCH_PREVIEW',
  SEARCH_NEWS = 'SEARCH_NEWS',
  SEARCH_BANNER = 'SEARCH_BANNER',
  SEARCH_BOARD = 'SEARCH_BOARD',
}

export enum AUTH_STACK {
  AUTH_SIGN_IN = 'AUTH_SIGN_IN',
  AUTH_FORGOT_ID_COMPLETE = 'AUTH_FORGOT_ID_COMPLETE',
  AUTH_FORGOT_PW = 'AUTH_FORGOT_PW',
  AUTH_FORGOT_PW_COMPLETE = 'AUTH_FORGOT_PW_COMPLETE',
  AUTH_REGISTER = 'AUTH_REGISTER',
  AUTH_AUTHENTICATION = 'AUTH_AUTHENTICATION',
  AUTH_PROFILE = 'AUTH_PROFILE',
  AUTH_REGISTER_COMPLETE = 'AUTH_REGISTER_COMPLETE',
  AUTH_RESET_PW = 'AUTH_RESET_PW',
  AUTH_RESET_PW_COMPLETE = 'AUTH_RESET_PW_COMPLETE',
  AUTH_MODIFY_PROFILE = 'AUTH_MODIFY_PROFILE',
  AUTH_AGREE_TO_TERMS = 'AUTH_AGREE_TO_TERMS',
}

export enum MY_PAGE_TAB {
  MY_PAGE_BOARD = 'MY_PAGE_BOARD',
  MY_PAGE_COMMENT = 'MY_PAGE_COMMENT',
  MY_PAGE_GOOD = 'MY_PAGE_GOOD',
  MY_PAGE_MESSAGE = 'MY_PAGE_MESSAGE',
  MY_PAGE_SCRAP = 'MY_PAGE_SCRAP',
  MY_PAGE_SUBSCRIPTION = 'MY_PAGE_SUBSCRIPTION',
}

export enum MODAL_TYPE {
  NONE = 'NONE',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
  SELECT_CATEGORY = 'SELECT_CATEGORY',
  COMPLAIN = 'COMPLAIN',
  SHARE = 'SHARE',
  ALERT_SELF_COMPLAIN = 'ALERT_SELF_COMPLAIN',
  ALERT_NO_CONTENT = 'ALERT_NO_CONTENT',
}

export enum REGISTER_STATUS {
  REGISTER = 'REGISTER',
  AUTHENTICATION = 'AUTHENTICATION',
  PROFILE = 'PROFILE',
}

export enum SOCIAL {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  KAKAO = 'KAKAO',
  NAVER = 'NAVER',
  APPLE = 'APPLE',
}
