import { Box, HStack, Text } from 'native-base'
import useFormatter from '@/hooks/useFormatter'
import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import React, { useState } from 'react'
import {
  createCommentPushAgree,
  deleteCommentPushAgree,
  findCommentPushAgree,
} from '@/services/push'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'

interface Props {
  commentCount: number
  reLoadHandler: () => void
  type: string
  typeId: number
}

export default function CommentBoardHeader(props: Props) {
  const { commentCount, reLoadHandler, type, typeId } = props
  const { numberCommaFormatter } = useFormatter()
  const [, setIsAgree] = useState(false)
  const [agreeId, setAgreeId] = useState(0)
  const [auth] = useAtom(authAtom)

  const handleAgree = (value: boolean) => {
    if (!auth.logged || !auth.user || auth.user.id === 0) return

    setIsAgree(value)

    if (value) {
      void createCommentPushAgree({
        jwt: auth.jwt,
        user_id: auth.user ? auth.user.id : 0,
        type: type.toLowerCase(),
        type_id: typeId,
      }).then((data) => {
        if (data) {
          setAgreeId(data.id)
        }
      })
    } else {
      if (agreeId !== 0) {
        void deleteCommentPushAgree({
          jwt: auth.jwt,
          id: agreeId,
        }).then(() => {
          setAgreeId(0)
        })
      }
    }
  }

  React.useEffect(() => {
    if (auth && auth.user) {
      findCommentPushAgree({
        user_id: auth.user ? auth.user.id : 0,
        type: type.toLowerCase(),
        type_id: typeId,
      }).then((data) => {
        if (data) {
          setAgreeId(data.id)
          setIsAgree(true)
        }
      })
    }
  }, [auth, auth.user, type, typeId])

  return (
    <HStack justifyContent='space-between' mb='16px' ml='4px'>
      <HStack alignItems='center'>
        <Text fontSize='16px' mr='8px' numberOfLines={1} w='auto'>
          댓글
        </Text>
        <Text fontSize='15px' color='#555555' fontWeight={700} mr='8px'>
          {numberCommaFormatter(commentCount)}
        </Text>
        <CButton onPress={reLoadHandler}>
          <Box
            w='23px'
            h='23px'
            borderWidth='1px'
            borderColor='#E0E0E0'
            borderRadius='12px'
            justifyContent='center'
            alignItems='center'
            pt='6px'
            mb='2px'
          >
            <FastImage
              source={require('@/assets/images/reLoad.png')}
              style={{
                width: 15,
                height: 15,
                marginBottom: 6,
              }}
              resizeMode='contain'
            />
          </Box>
        </CButton>
      </HStack>
    </HStack>
  )
}
