import { Linking } from 'react-native'
import { Box, Center, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { IAddress } from '@/interfaces/address'
import { RootStackParamList } from '@/interfaces/navigation'
import React from 'react'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

interface Props {
  address: IAddress
}

export default function AddressPremiumItem(props: Props) {
  const { address } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const [isPressed, setPressed] = React.useState(false)

  return (
    <Box
      mx={5}
      my={2}
      rounded={8}
      bgColor='white'
      borderWidth='1px'
      borderColor='#cdcdcd'
      px='16px'
      w='auto'
      h='auto'
      flex={1}
    >
      <FastImage
        source={{ uri: address.thumbnail?.url || address.url }}
        style={{
          width: 'auto',
          height: 59,
          borderRadius: 5,
          marginLeft: '20%',
          marginRight: '20%',
          marginTop: 8,
          marginBottom: 8,
        }}
        resizeMode='contain'
      />
      <Text textAlign='left' mt='10px' mb='10px' color='#222222' fontWeight={700} fontSize='24px'>
        {address.company}
      </Text>
      <Box flexDirection='row' mb='15px'>
        <Box>
          <Box w={screenWidth - 70} h='auto' pb='9px' mb='auto' pt='2px'>
            <Text
              fontSize='15px'
              lineHeight='16px'
              lineBreakMode='tail'
              numberOfLines={3}
              width='full'
              minH='48px'
            >
              {address.description}
            </Text>
            {address.address == null || address.address == '' ? null : (
              <Text fontSize='14px' color='#7C7C7C' lineBreakMode='tail' numberOfLines={2} mb='2px'>
                {address.address}
              </Text>
            )}
          </Box>
          <Box mt='8px' flexDirection='row' justifyContent='space-between' mb='9px' h='20px'>
            {address.contact_info ? (
              <CButton onPress={() => Linking.openURL(`tel:${address.contact_info}`)}>
                <HStack alignContent='center'>
                  <FastImage
                    source={require('@/assets/images/address-phone.png')}
                    style={{
                      width: 16,
                      height: 16,
                      marginRight: 8,
                      marginTop: 3,
                    }}
                    resizeMode='contain'
                  />
                  <Text fontSize='16px' fontWeight={700} my='auto'>
                    {address.contact_info}
                  </Text>
                </HStack>
              </CButton>
            ) : (
              <Box></Box>
            )}
            {address.site_url && address.site_url != '' ? (
              <CButton
                // bgColor={`${isPressed ? '#F4F4F4' : 'white'}`}
                pr='6px'
                onPressOut={() => setPressed(false)}
                onPressIn={() => setPressed(true)}
                onPress={() => {
                  if (address.site_url) {
                    void Linking.openURL(address.site_url)
                  }
                }}
              >
                <Center w='100px' h='24px' mt='2px' bgColor='#989898' borderRadius='5px'>
                  <Text mt='1px' color='white' fontSize='13px' fontWeight={600}>
                    사이트 바로가기
                  </Text>
                </Center>
              </CButton>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
