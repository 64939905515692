import React from 'react'
import { RefreshControl } from 'react-native'
import { Box, ScrollView, View } from 'native-base'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import {
  myPageBoardSubListAtom,
  myPageBoardSubTotalCountAtom,
  myPageMemberSubListAtom,
  myPageMemberSubTotalCountAtom,
} from '@/atom/initAtom'
import { findAllBoardSubByMeAuth, findAllMemberSubByMeAuth } from '@/services/userContents'
import MoreTab from '@/components/MoreTab'
import { LIMIT } from '@/constants/limit'
import MyPageSubscriptionTab from './components/MyPageSubscriptionTab'
import MyPageMemberSubItem from './components/MyPageMemberSubItem'
import MyPageBannerMemberSubItem from './components/MyPageBannerMemberSubItem'
import { MY_PAGE_TAB } from '@/constants/label'
import { MyPageTabParamList } from '@/interfaces/navigation'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import MyPageBannerBoardSubItem from './components/MyPageBannerBoardSubItem'
import MyPageBoardBlank from './components/MyPageBoardBlank'

type Props = NativeStackScreenProps<MyPageTabParamList, MY_PAGE_TAB.MY_PAGE_SUBSCRIPTION>

export default function MyPageSubscriptionScreen({ navigation }: Props) {
  const [auth] = useAtom(authAtom)
  const [MembertotalCount, setMemberTotalCount] = useAtom(myPageMemberSubTotalCountAtom)
  const [BoardtotalCount, setBoardTotalCount] = useAtom(myPageBoardSubTotalCountAtom)
  const [memberList, setMemberList] = useAtom(myPageMemberSubListAtom)
  const [boardList, setBoardList] = useAtom(myPageBoardSubListAtom)
  const [boardLimit, setBoardLimit] = React.useState(LIMIT.MY_PAGE_LIST)
  const [memberLimit, setMemberLimit] = React.useState(LIMIT.MY_PAGE_LIST)
  const [refreshing, setRefreshing] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [selectedTabId, setSelectedTabId] = React.useState('MEMBER_BOARD')

  const isMore = () => {
    const totalCount = selectedTabId === 'MEMBER_BOARD' ? MembertotalCount : BoardtotalCount
    const contentsLength = selectedTabId === 'MEMBER_BOARD' ? memberList.length : boardList.length
    return (totalCount ? totalCount : 0) > contentsLength
  }

  const handleSelectedTabId = (value: string) => {
    setSelectedTabId(value)
  }

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    if (selectedTabId === 'MEMBER_BOARD') {
      await findAllMemberSubByMeAuth({ jwt: auth.jwt, limit: LIMIT.MY_PAGE_LIST }).then((data) => {
        setMemberList(data.contents)
        setMemberTotalCount(data.totalCount)
      })
    } else {
      await findAllBoardSubByMeAuth({ jwt: auth.jwt, limit: LIMIT.MY_PAGE_LIST }).then((data) => {
        setBoardList(data.contents)
        setBoardTotalCount(data.totalCount)
      })
    }
    await setTimeout(() => {
      setRefreshing(false)
    }, 500)
  }, [auth, setMemberList, setMemberTotalCount, setBoardList, setBoardTotalCount])

  const onMore = React.useCallback(async () => {
    setLoading(true)
    if (selectedTabId === 'MEMBER_BOARD') {
      const newLimit = memberLimit + LIMIT.MY_PAGE_LIST
      await findAllMemberSubByMeAuth({ jwt: auth.jwt, limit: newLimit }).then((data) => {
        setMemberList(data.contents)
        setMemberTotalCount(data.totalCount)
        setMemberLimit(newLimit)
      })
    } else {
      const newLimit = boardLimit + LIMIT.MY_PAGE_LIST
      await findAllBoardSubByMeAuth({ jwt: auth.jwt, limit: newLimit }).then((data) => {
        setBoardList(data.contents)
        setBoardTotalCount(data.totalCount)
        setBoardLimit(newLimit)
      })
    }
    setLoading(false)
  }, [
    auth,
    setMemberLimit,
    setBoardLimit,
    setMemberList,
    setMemberTotalCount,
    setBoardList,
    setBoardTotalCount,
  ])

  return (
    <React.Fragment>
      <View flex={1} backgroundColor='#ffffff'>
        <MyPageSubscriptionTab
          selectedTabId={selectedTabId}
          handleSelectedTabId={handleSelectedTabId}
        />
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={['#ff6f1e']}
              tintColor='#ff6f1e'
            />
          }
          contentContainerStyle={{ flexGrow: 1, backgroundColor: '#ffffff' }}
          showsVerticalScrollIndicator={false}
          // scrollEventThrottle={16}
          // onScroll={scrollHandler}
        >
          {/* 회원 구독 */}
          {selectedTabId === 'MEMBER_BOARD' && memberList.length ? (
            memberList.map((item, index) => {
              if (item.type != 'board') {
                return (
                  <Box key={item.type + item.id}>
                    <MyPageBannerMemberSubItem item={item} />
                  </Box>
                )
              } else {
                return (
                  <Box key={item.type + item.id}>
                    <MyPageMemberSubItem item={item} />
                  </Box>
                )
              }
            })
          ) : selectedTabId === 'MEMBER_BOARD' && !memberList.length ? (
            <MyPageBoardBlank title='구독이 없습니다.' screen={'MyPageSubscriptionScreen'} />
          ) : null}
          {/* 게시판 구독 */}
          {selectedTabId === 'ADVER_BOARD' && boardList.length ? (
            boardList.map((item, index) => {
              return (
                <Box key={item.type + item.id}>
                  <MyPageBannerBoardSubItem item={item} />
                </Box>
              )
            })
          ) : selectedTabId === 'ADVER_BOARD' && !boardList.length ? (
            <MyPageBoardBlank title='구독이 없습니다.' screen={'MyPageSubscriptionScreen'} />
          ) : null}
          {isMore() ? (
            <MoreTab
              moreButtonText='더보기'
              moreButtonOnPress={onMore}
              useSideButton={false}
              loading={isLoading}
            />
          ) : null}
        </ScrollView>
      </View>
    </React.Fragment>
  )
}
