import FastImage from '@/components/FastImage'
import { ScrollView } from 'react-native'
import { Box } from 'native-base'
import Footer from '@/components/Footer'
import React from 'react'

export default function CompanyIntroScreen() {
  return (
    <ScrollView
      contentContainerStyle={{ backgroundColor: '#f4f4f4' }}
      showsVerticalScrollIndicator={false}
    >
      <Box bgColor='#ffffff' pb='50px'>
        <FastImage
          source={require('@/assets/images/company-intro.png')}
          style={{
            height: 2243,
          }}
        />
      </Box>
      <Footer />
    </ScrollView>
  )
}
