import { Box, Text } from 'native-base'

interface Props {
  title: string
}

export default function AuthSubTitle(props: Props) {
  const { title } = props
  return (
    <Box borderBottomWidth={1} borderBottomColor='#e8e8e8' mb={15}>
      <Text fontSize={20} textAlign='center' fontWeight='800' color='#222222' pb={11}>
        {title}
      </Text>
    </Box>
  )
}
