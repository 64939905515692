import React from 'react'
import { Box, Center, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { removeReCommentByReCommentId } from '@/services/comment'
import {
  createComplainHistories,
  findOneComplainHistories,
  updateComplainHistories,
} from '@/services/articleElements'

import useFormatter from '@/hooks/useFormatter'
import CButton from '@/components/CButton'
import CreateModal from '@/components/CreateModal'
import UpdateReCommentBox from '@/components/UpdateReCommentBox'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { IReComment } from '@/interfaces/comment'
import { RootStackParamList } from '@/interfaces/navigation'
import FastImage from '@/components/FastImage'
import { Autolink } from 'react-native-autolink'
import ModalConfirmRound from '@/components/ModalConfirmRound'

interface Props {
  reComment: IReComment
  handleHashUser: (hashUser: number, hashUserNickName: string) => void
  hashUser: number
  hashUserNickName: string
  reLoadCommentList: () => void
  handleOpenCreateCommentBox: (value: boolean) => void
  articleWriter: number
}

export default function ReCommentItem(props: Props) {
  const {
    reComment,
    handleHashUser,
    hashUser,
    hashUserNickName,
    reLoadCommentList,
    articleWriter,
  } = props
  const [auth] = useAtom(authAtom)
  const [openUpdateReComment, setOpenUpdateReComment] = React.useState(false)
  const { datetimeFormatter } = useFormatter()
  const [isOpenComplain, setIsOpenComplain] = React.useState(false)
  const [isOpenAlertSelfComplain, setIsOpenAlertSelfComplain] = React.useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const handleOpenConfirm = (value: boolean) => {
    setIsOpenConfirm(value)
  }

  const openUpdateReCommentHandle = (val: boolean) => {
    setOpenUpdateReComment(val)
  }

  const removeReComment = async () => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (!reComment.id) return

    await removeReCommentByReCommentId({ id: reComment.id, jwt: auth.jwt }).then(() => {
      openUpdateReCommentHandle(false)
      handleOpenConfirm(false)
      return reLoadCommentList()
    })
  }

  const saveComplain = async (value: number) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    await findOneComplainHistories({
      type: 're-comment',
      type_id: reComment.id,
      reported_user: auth.user ? auth.user.id : 0,
    }).then((data) => {
      if (data) {
        // update
        const update = async () => {
          await updateComplainHistories({
            jwt: auth.jwt,
            id: data.id,
            complain_type: value,
          })
        }
        void update()
      } else {
        // create
        const create = async () => {
          await createComplainHistories({
            jwt: auth.jwt,
            type: 're-comment',
            type_id: reComment.id,
            complain_type: value,
            reported_user: auth.user ? auth.user.id : 0,
            writer: reComment.writer,
            contents: reComment.contents,
          })
        }
        void create()
      }
    })
  }

  const handleOpenComplain = (value: boolean) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (auth.user && auth.user.id === reComment.writer) {
      setIsOpenAlertSelfComplain(true)
      return
    }

    setIsOpenComplain(value)
  }

  return (
    <Box
      bgColor='#f8f8f8'
      px='17px'
      pt='15px'
      pb='18px'
      borderBottomWidth='1px'
      borderBottomColor='#e5e5e5'
    >
      {isOpenAlertSelfComplain && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_SELF_COMPLAIN}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => {
            setIsOpenAlertSelfComplain(false)
          }}
        />
      )}
      {isOpenComplain && (
        <CreateModal
          modalType={MODAL_TYPE.COMPLAIN}
          onAccept={() => {
            return
          }}
          saveComplain={saveComplain}
          handleCloseModal={() => {
            setIsOpenComplain(false)
          }}
          articleElements={{ type: 're-comment', typeId: reComment.id }}
        />
      )}
      {isOpenConfirm && (
        <ModalConfirmRound
          isOpen={isOpenConfirm}
          onAccept={() => removeReComment()}
          onClose={() => handleOpenConfirm(false)}
          contentsMessage={'삭제하시겠습니까?'}
          acceptButtonText='예'
          cancelButtonText='아니오'
        />
      )}
      <HStack mb='6px' alignItems='center'>
        <Box w='23px'>
          <FastImage
            source={require('@/assets/images/re-comment-arrow.png')}
            style={{
              width: 15,
              height: 13,
            }}
            resizeMode='contain'
          />
        </Box>
        <CButton onPress={() => handleHashUser(reComment.writer, reComment.nick_name)}>
          <Text mt='3px' mr='8px' fontSize='16px' fontWeight='bold' my='auto'>
            {reComment.nick_name}
          </Text>
        </CButton>
        {articleWriter === reComment.writer ? (
          <Center bgColor='#ff6f1e' w='44px' h='20px' borderRadius='5px' my='auto' mr='8px'>
            <Text mt='3px' color='white' fontSize='13px' fontWeight={700} textAlign='center'>
              작성자
            </Text>
          </Center>
        ) : null}
        <Text fontSize='14px' color='#6C6C6C' my='auto'>
          {datetimeFormatter(reComment.created_at)}
        </Text>
      </HStack>
      <Box pb='20px' pl='23px'>
        {openUpdateReComment ? (
          <UpdateReCommentBox
            reComment={reComment}
            hashUser={hashUser}
            hashUserNickName={hashUserNickName}
            handleHashUser={handleHashUser}
            reLoadCommentList={reLoadCommentList}
            openCreateReCommentHandle={openUpdateReCommentHandle}
          />
        ) : (
          <Text>
            {reComment.hash_user_nick_name && !reComment.is_block && (
              <Text fontSize='16px' color='#ff6f1e'>
                {`#${reComment.hash_user_nick_name}  `}
              </Text>
            )}
            <Text
              fontSize='16px'
              color={reComment.is_block ? '#6C6C6C' : 'black'}
              selectable={true}
            >
              <Autolink text={reComment.contents} email={false} />
            </Text>
          </Text>
        )}
      </Box>
      <Center flexDirection='row'>
        {auth.user && auth.user.id === reComment.writer ? (
          <Box flexDirection='row' w='full' justifyContent='space-between'>
            <Box flexDirection='row'>
              <CButton onPress={() => openUpdateReCommentHandle(true)}>
                <Center
                  w='48px'
                  h='28px'
                  bgColor='white'
                  borderColor='#8F8F8F'
                  borderWidth='1px'
                  borderRadius='5px'
                  mr='6px'
                  ml='20px'
                >
                  <Text fontSize='15px' color='#222222' textAlign='center'>
                    수정
                  </Text>
                </Center>
              </CButton>
              <CButton onPress={() => handleOpenConfirm(true)}>
                <Center
                  w='48px'
                  h='28px'
                  bgColor='white'
                  borderColor='#8F8F8F'
                  borderWidth='1px'
                  borderRadius='5px'
                  mr='6px'
                >
                  <Text fontSize='15px' color='#222222' textAlign='center'>
                    삭제
                  </Text>
                </Center>
              </CButton>
            </Box>
            <CButton mr='6px' onPress={() => handleOpenComplain(true)}>
              <Text fontSize='14px' color='#6C6C6C'>
                신고
              </Text>
            </CButton>
          </Box>
        ) : (
          <CButton mr='6px' ml='auto' onPress={() => handleOpenComplain(true)}>
            <Text fontSize='14px' color='#6C6C6C'>
              신고
            </Text>
          </CButton>
        )}
      </Center>
    </Box>
  )
}
