import { TouchableOpacity } from 'react-native'
import { Box, HStack, Pressable, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { authAtom } from '@/atom/authAtom'

import FastImage from '@/components/FastImage'
import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  mb?: string
  scrollToTop?: () => void
}

export default function Footer({ mb, scrollToTop }: Props) {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [screenWidth] = useAtom(screenWidthAtom)
  const [auth] = useAtom(authAtom)

  return (
    <VStack mt='19px' mb={mb ? mb : '70px'} alignItems='center' bg='F9F9F9'>
      <TouchableOpacity
        onPress={() => {
          if (scrollToTop) {
            scrollToTop()
          } else {
            navigation.navigate(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.MAIN })
          }
        }}
      >
        <Box>
          <FastImage
            source={require('@/assets/images/logo.png')}
            style={{
              width: 80,
              height: 54,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            resizeMode='contain'
          />
        </Box>
      </TouchableOpacity>
      <VStack
        borderBottomWidth='1px'
        borderBottomColor='#d8d8d8'
        py='20px'
        w={screenWidth - 36}
        px='18px'
      >
        <HStack justifyContent='center'>
          <Pressable
            onPress={() => {
              navigation.push(ROOT_STACK.COMPANY_INTRO)
            }}
          >
            <Text fontWeight='bold' color='#8c8c8c'>
              회사소개
            </Text>
          </Pressable>
          <Text mx='5px' color='#d9d9d9'>
            |
          </Text>
          <Pressable
            onPress={() => {
              navigation.push(ROOT_STACK.INQUIRY)
            }}
          >
            <Text fontWeight='bold' color='#8c8c8c'>
              제휴 및 광고문의
            </Text>
          </Pressable>
          <Text mx='5px' color='#d9d9d9'>
            |
          </Text>
          <Pressable
            onPress={() => {
              navigation.push(ROOT_STACK.POLICY_PRIVACY)
            }}
          >
            <Text fontWeight='bold' color='#8c8c8c'>
              개인정보취급방침
            </Text>
          </Pressable>
        </HStack>
        <HStack justifyContent='center'>
          <Pressable
            onPress={() => {
              navigation.push(ROOT_STACK.POLICY_SERVICE)
            }}
          >
            <Text fontWeight='bold' color='#8c8c8c'>
              이용약관
            </Text>
          </Pressable>
          <Text mx='5px' color='#d9d9d9'>
            |
          </Text>
          <Pressable
            onPress={() => {
              if (!auth.logged) {
                navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
                return
              }
              navigation.push(ROOT_STACK.MEMBERSHIP_WITHDRAWAL)
            }}
          >
            <Text fontWeight='bold' color='#8c8c8c'>
              회원탈퇴
            </Text>
          </Pressable>
        </HStack>
      </VStack>
      <HStack mt='18px' w='260px' alignItems='center'>
        <Box mr='7px'>
          <Text color='#747474' textAlign='right'>
            Tel
          </Text>
          <Text fontSize='14px' fontWeight={400} color='#747474' textAlign='right'>
            E-mail
          </Text>
        </Box>
        <Box>
          <HStack alignItems='center'>
            <Text fontWeight='bold' color='#8c8c8c'>
              03-6205-5138 /
            </Text>
            <FastImage
              source={require('@/assets/images/line-icon.png')}
              style={{
                width: 13,
                height: 13,
                marginLeft: 4,
                marginRight: 2,
              }}
              resizeMode='contain'
            />
            <FastImage
              source={require('@/assets/images/kakao-icon.png')}
              style={{
                width: 13,
                height: 13,
                marginLeft: 2,
                marginRight: 4,
              }}
              resizeMode='contain'
            />
            <Text fontWeight='bold' color='#8c8c8c'>
              hanto8102
            </Text>
          </HStack>
          <Text fontWeight='bold' color='#8c8c8c'>
            hanteo2050@gmail.com
          </Text>
        </Box>
      </HStack>
      <Box alignItems='center' mt='10px'>
        <Text fontSize='14px' fontWeight={400} color='#747474'>
          〒169-0072 東京都新宿区大久保1-3-14 WBC 新宿 401号
        </Text>
        <Text fontSize='14px' fontWeight={400} color='#747474'>
          Copyrightⓒ 2022 Hachitoni Co., Ltd. All Rights Reserved.
        </Text>
      </Box>
    </VStack>
  )
}
