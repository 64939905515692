import { Box, Center, Modal, Text, VStack } from 'native-base'
import CButton from '@/components/CButton'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function ModalPolicyAgreement(props: Props) {
  const { isOpen, onClose } = props
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Content w={screenWidth - 60} minH='507px'>
        <VStack
          mx='auto'
          h='507px'
          alignItems='center'
          justifyItems='center'
          justifyContent='center'
          bgColor='white'
          borderRadius='8px'
          py='4px'
          px='26px'
        >
          <Text fontSize='23px' fontWeight='bold' textAlign='center' mb='16px'>
            개인정보 수집 및 이용 동의
          </Text>
          <Box h='70%'>
            <KeyboardAwareScrollView
              enableOnAndroid={true}
              enableAutomaticScroll={true}
              enableResetScrollToCoords={true}
              scrollEnabled={true}
              keyboardShouldPersistTaps={'handled'}
              style={{ backgroundColor: '#FFFFFF' }}
            >
              <p className='text-[#000000] text-[16px] font-normal'>
                한터(HANTEO2050.COM)에서는 모든 회원 여러분께서 안심하고 한터(HANTEO2050.COM)를
                이용할 수 있도록, 상업 및 정치적 목적을 위한 개인정보 악용과 개인 사생활의 침해를
                막기 위해 최선의 노력을 다하고 있습니다. 또한 무엇보다도 소중한 회원 여러분의
                개인정보 지킴이로서의 역할을 성실히 수행하고 있습니다.
                <br />
                <br />
                한터(HANTEO2050.COM)의 개인정보취급방침은 정부의 관련 법률 및 지침의 변경이나
                한터(HANTEO2050.COM)의 내부방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른
                개인정보취급방침의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 아울러
                개인정보취급방침을 개정하는 경우 그 변경사항에 대하여 즉시 홈페이지를 통하여
                게시하여 개정된 사항을 이용자들이 쉽게 알아볼 수 있도록 하고 있습니다. 회원분께서는
                사이트 방문시 수시로 확인하여 주시기 바랍니다.
                <br />
                <br />
                한터(HANTEO2050.COM)는 회원 여러분의 개인정보를 보호하기 위해 최선을 다하고
                있습니다.
                <br />
                한터(HANTEO2050.COM)는 회원님의 허락 없이 개인정보를 제3자에게 제공하지 않습니다.
                <br />
                <br />
                보다 자세한 사항은 다음과 같습니다.
                <br />
                <br />
                1. 개인정보 수집에 대한 동의
                <br />
                2. 개인정보의 수집항목
                <br />
                3. 개인정보의 수집 및 이용목적
                <br />
                4. 개인정보 공유 및 제공
                <br />
                5. 개인정보의 보유 및 이용기간
                <br />
                6. 개인정보의 파기절차 및 방법
                <br />
                7. 수집한 개인정보의 위탁, 목적 외 사용 및 제3자에 대한 정보 공유
                <br />
                8. 이용자 및 법정대리인의 권리와 그 행사방법
                <br />
                9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
                <br />
                10. 개인정보의 기술적/관리적 보호 대책
                <br />
                11. 이용자 관리에 대한 조치 및 준수사항
                <br />
                12. 광고성 정보 전송
                <br />
                13. 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처
                <br />
                14. 고지의 의무
                <br />
                <br />
                <br />
                <br />
                <span className='font-bold'>1. 개인정보 수집에 대한 동의</span>
                <br />
                한터(HANTEO2050.COM)는 회원가입 절차 중 이용약관에 대해「동의」란에 체크하시면
                개인정보 수집 및 이용에 대해 동의한 것으로 봅니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>2. 개인정보의 수집항목</span>
                <br />
                가. 수집하는 개인정보의 항목
                <br />
                <br />
                회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 개인정보를
                수집하고 있습니다.
                <br />
                희망ID, 비밀번호, 성별, 국적, 우편번호, 생년월일, 이메일
                <br />
                <br />
                또한 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수
                있습니다.
                <br />
                <br />
                - 이용자의 브라우저 종류 및 OS, 검색어, 서비스 이용 기록
                <br />
                - IP Address, 방문 일시, 서비스 이용기록, 불량 이용 기록
                <br />
                - 주소, 결제기록, 쿠키
                <br />
                <br />
                나. 개인정보 수집방법
                <br />
                <br />
                - 홈페이지 (회원가입)
                <br />
                - 전화/팩스를 통한 회원가입
                <br />
                - 제휴사로부터의 제공
                <br />
                <br />
                <br />
                <span className='font-bold'>3. 개인정보의 수집 및 이용목적</span>
                <br />
                한터(HANTEO2050.COM) 가입시 기입한 개인정보는 가로수닷컴 서비스 제공시 기초 자료가
                되며, 회원 개개인에게 알맞는 유용한 서비스 제공을 위해 사용하게 됩니다.
                <br />
                <br />
                가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
                <br />
                컨텐츠 제공, 본인인증, 구매 및 요금 결제
                <br />
                <br />
                나. 회원관리
                <br />
                회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원의 부정
                이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 추후 법정 대리인
                본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
                <br />
                <br />
                다. 신규 서비스 개발 및 마케팅
                <br />
                신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재,
                서비스의 유효성 확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에
                대한 통계
                <br />
                <br />
                <br />
                <span className='font-bold'>4. 개인정보 공유 및 제공</span>
                <br />
                한터(HANTEO2050.COM)는 회원님으로부터 수집한 개인정보를 회원님의 사전 동의 없이는
                절대로 제3자와 함께 공유하거나 공개하지 않습니다.
                <br />
                다만, 한터(HANTEO2050.COM) 서비스 내용 중 회원님께서 희망하는 서비스가 제휴, 협력
                혹은 기타 계약(이하 협력)에 의해, 추가 가입이 필요한 경우에는 이를 미리 회원
                여러분께 알려드리며, 이에 대한 회원님의 동의가 있거나 법에 의해 요구되는 경우에 한해
                예외로 합니다.
                <br />
                이 경우, 회원님의 개인정보 중 일부분, 혹은 전체가 제3자에게 제공됨을 미리
                알려드리며, 추가 가입 약관, 혹은 기타 형태의 내용 화면에서 &apos;동의&apos; 혹은
                &apos;추가가입&apos; 등의 버튼을 눌러 회원님의 동의가 확인될 경우에만 회원님의
                정보가 보다 편리한 서비스 활용을 위하여 협력사에 제공되며, 아래의 경우에는 예외로
                합니다.
                <br />
                <br />
                - 회원님께서 사전에 공개에 동의한 경우
                <br />
                - 서비스 제공에 따른 요금정산을 위하여 필요한 경우
                <br />
                - 한터(HANTEO2050.COM) 서비스 이용약관이나 기타 회원 서비스 등 이용약관 또는 정책을
                위반한 경우
                <br />
                - 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우
                <br />
                - 서비스 이용에 따른 문의사항(민원)의 처리를 위하여 해당 문의사항의 처리에 필요한
                개인정보를 제공하는 경우
                <br />
                - 이벤트 당첨으로 인한 경품 배송의 경우
                <br />
                <br />
                어떠한 경우에도 회원님의 동의가 없는 경우에는 추가적인 정보를 임의로 수집하거나
                공유하지 않습니다.
                <br />
                <br />
                다만, 회원님들이 직접 등록하신 매물정보가 제휴사에 제공되는 경우가 있습니다. 이는
                회원님들의 매물광고효과를 높이기 위해 매물정보를 제공할 뿐이며, 제휴사에게는
                매물홍보목적외에는 사용되지 않습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>5. 개인정보의 보유 및 이용기간</span>
                <br />
                고객님의 개인정보는 고객님께 서비스를 제공하는 기간동안에 한하여 보유 및 이용됩니다.
                <br />
                다만, 불량 회원의 부정한 이용의 재발을 방지하고, 소비자의 불만 및 분쟁해결 등을
                목적으로 이용계약 해지일로부터 1개월간 해당 회원의 ID만을 보유할 수 있습니다. 또한,
                관계법령의 규정에 의하여 보존할 필요성이 있는 경우에는 관계법령에 따라 보존합니다.
                <br />
                <br />
                단, 다음과 같은 경우 일정기간 회원정보를 보관하며 보관된 개인정보는 보관 목적으로만
                이용합니다.
                <br />
                - 개별적으로 회원의 동의 또는 요청에 의한 경우 : 1년 (전자상거래 등에서의
                소비자보호에 관한 법률)
                <br />
                - 보유기간을 회원에게 미리 고지하거나 명시한 경우(단, 지정한 보유기간동안에만 보관)
                <br />
                - 이용약관 및 관계법령을 위반한 불법 사용자에 대한 사법기관 수사협조 등을 위해
                회원탈퇴 후 개인정보 : 1년
                <br />
                - 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한
                법률)
                <br />
                - 대금결제 및 재화등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한
                법률)
                <br />
                - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에
                관한 법률)
                <br />
                <br />
                <br />
                <span className='font-bold'>6. 개인정보의 파기절차 및 방법</span>
                <br />
                이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
                지체없이 파기합니다.
                <br />
                파기절차 및 방법은 다음과 같습니다.
                <br />
                <br />
                가. 파기절차
                <br />
                고객님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
                옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 개인정보보호
                사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
                <br />
                <br />
                나. 파기방법
                <br />
                - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                삭제합니다.
                <br />
                - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>
                  7. 수집한 개인정보의 위탁, 목적 외 사용 및 제3자에 대한 정보 공유
                </span>
                <br />
                고객의 개인정보를 「개인정보의 수집목적 및 이용목적」에서 고지한 범위 내에서
                사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다.
                <br />
                <br />
                가. 제휴관계
                <br />
                보다 나은 서비스 제공을 위하여 회원님의 개인정보를 제휴사에게 제공하거나 또는
                제휴사와 공유할 수 있습니다.
                <br />
                개인정보를 제공하거나 공유할 경우에는 사전에 회원님께 제휴사가 누구인지, 제공 또는
                공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지
                그리고 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 전자우편 및 서면을 통해
                고지하여 동의를 구하는 절차를 거치게 되며, 회원님께서 동의하지 않는 경우에는
                제휴사에게 제공하거나 제휴사와 공유하지 않습니다.
                <br />
                <br />
                나. 위탁 처리
                <br />
                고객님의 동의 없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가
                생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전
                동의를 받도록 하겠습니다.
                <br />
                <br />
                다음은 예외로 합니다.
                <br />
                관계법령에 의하여 수사상의 목적으로 관계기관으로부터의 요구가 있을 경우
                <br />
                - 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주,
                협력사나 연구단체 등에 제공하는 경우
                <br />
                - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
                <br />
                <br />
                그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공한
                경우에는 이를 당사자에게 고지하는 것을 원칙으로 운영하고 있습니다. 법률상의 근거에
                의해 부득이하게 고지를 하지 못할 수도 있습니다.
                <br />
                본래의 수집목적 및 이용목적에 반하여 무분별하게 정보가 제공되지 않도록 최대한
                노력하겠습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>8. 이용자 및 법정대리인의 권리와 그 행사방법</span>
                <br />
                이용자들은 개인정보 조회, 수정을 위해서는 &apos;회원정보수정&apos;을,
                가입해지(동의철회)를 위해서는 &quot;회원탈퇴&quot;를 클릭하여 본인 확인 절차를
                거치신 후 직접 열람, 정정 또는 탈퇴가 가능하며, 탈퇴 처리된 아이디의 재사용은
                불가합니다.
                <br />
                <br />
                또한, 비밀번호를 잊어버린 경우에는 &quot;ID/PW 찾기&quot;를 클릭하여 본인 확인에
                필요한 사항을 입력하시면, 본인여부 확인 후 E-mail을 통하여 비밀번호를 알려 드립니다.
                <br />
                <br />
                이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
                개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한
                경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
                <br />
                이용자 요청에 의해 해지 또는 삭제된 개인정보는 “수집하는 개인정보의 보유 및
                이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
                처리하고 있습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>
                  9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
                </span>
                <br />
                한터(HANTEO2050.COM)는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의
                정보를 저장하고 수시로 불러오는 &apos;쿠키(cookie)&apos;를 사용합니다.
                <br />
                쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는
                소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                <br />
                <br />
                가. 쿠키 등 사용 목적
                <br />
                - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석
                <br />
                - 이용자의 취향과 관심분야를 파악 및 자취 추적
                <br />
                - 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스
                제공
                <br />
                - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹
                브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 , 쿠키가 저장될 때마다
                확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
                <br />
                <br />
                나. 쿠키 설정 거부 방법
                <br />
                쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을
                선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든
                쿠키의 저장을 거부할 수 있습니다.
                <br />
                설정방법 예 (인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 &gt; 인터넷 옵션
                &gt; 개인정보
                <br />
                단, 이용자께서 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에
                어려움이 있을 수 있습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>10. 개인정보의 기술적/관리적 보호 대책</span>
                <br />
                한터(HANTEO2050.COM)는 회원님들의 개인정보를 취급함에 있어 개인정보가 분실, 도난,
                누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
                대책을 강구하고 있습니다.
                <br />
                <br />
                가. 기술적 대책
                <br />
                - 회원님의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나
                파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고
                있습니다.
                <br />
                - 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고
                있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우
                백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                <br />
                - 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는
                보안장치(SSL 또는 SET) 를 채택하고 있습니다.
                <br />
                - 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을
                이용하여 보안에 만전을 기하고 있습니다.
                <br />
                <br />
                나. 관리적 대책
                <br />
                은 개인정보관련 취급 직원을 관련 담당자에 한정시키고 있으며,
                <br />
                이를 관리하기 위해 별도의 아이피, 아이디만을 접근이 가능하도록 하고 있으며,
                정기적으로 갱신하고 있습니다.
                <br />
                담당자에 대한 수시 교육을 통하여 개인정보 취급방침의 준수를 항상 강조하고 있습니다.
                <br />
                <br />
                개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시
                수정하고 바로 잡을 수 있도록 노력하고 있습니다.
                <br />
                <br />
                단, 회원님의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한
                문제에 대해 회사는 일체의 책임을 지지 않습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>11. 이용자 관리에 대한 조치 및 준수사항</span>
                <br />
                인터넷 결제시스템의 안전성을 확보하고 이용자에게 원활한 정보를 제공하기 위하여
                다음에 해당하는 행위를 한 이용자에 대하여 서비스를 제한합니다.
                <br />
                <br />
                - 부당한 방법으로 정보통신망에 의하여 처리, 보관, 전송되는 타인의 정보를 훼손 하거나
                타인의 비밀을 침해, 도용 또는 누설하는 행위
                <br />
                - 트로이목마 , 컴퓨터 바이러스 등 악성 프로그램의 유포행위
                <br />
                - 음란 , 폭력물 등의 불건전한 자료의 게재 , 유포행위
                <br />
                - 기타 관련법률에서 금지하는 행위
                <br />
                <br />
                이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지
                않을 의무도 가지고 있습니다.
                <br />
                비밀번호를 포함한 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의
                개인정보를 훼손하지 않도록 유의해 주십시오.
                <br />
                만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할
                시에는『정보통신망이용촉진 및 정보보호등에관한법률』등에 의해 처벌받을 수 있습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>12. 광고성 정보 전송</span>
                <br />
                회원님의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.
                <br />
                <br />
                가. 뉴스레터 등 동의한 전자우편
                <br />
                회원님이 뉴스레터 등 전자우편 전송에 대한 동의를 한 경우 전자우편의 제목란 및
                본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록 조치합니다.
                <br />
                <br />
                - 전자우편의 제목란에 (광고)라는 문구를 제목란에 표시하지 않을 수 있으며, 전자우편
                본문란의 주요 내용을 표시합니다.
                <br />
                - 전자우편의 본문란에 회원님이 수신거부의 의사표시를 할 수 있는 전송자의 명칭,
                전자우편주소, 전화번호 및 주소를 명시합니다.
                <br />
                회원님이 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각 각
                명시합니다.
                <br />
                <br />
                나. 온라인 마케팅을 위한 광고성 정보
                <br />
                상품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 전자우편 등으로 전송하는 경우
                전자우편의 제목란 및 본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록
                조치합니다.
                <br />
                <br />
                - 전자우편의 제목란에 (광고)라는 문구를 제목란의 한글로 표시하고 이어서 전자우편
                본문란의 주요 내용을 표시합니다.
                <br />
                - 전자우편의 본문란에 회원님이 수신거부의 의사표시를 할 수 있는 전송자의 명칭,
                전자우편주소, 전화번호 및 주소를 명시합니다.
                <br />
                회원님이 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각 각
                명시합니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>
                  13. 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처
                </span>
                <br />
                회원님의 개인정보는 보다 편리하고, 유익한 정보를 전달하기 위한 기본자료로서만
                충실하게 활용할 것을 약속드립니다.
                <br />
                또한 회원님의 허락없이 활용되는 일이 없도록 최선의 노력을 다하고 있습니다.
                <br />
                <br />
                <br />
                <span className='font-bold'>14. 고지의 의무</span>
                <br />
                현 개인정보취급방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터
                홈페이지의 &apos;공지사항&apos;을 통해 고지됩니다.
                <br />
                <br />
                - 공고일자 : 2022년 9월 1일
                <br />
                - 시행일자 : 2022년 9월 16일
                <br />
                <br />
                * 본 개인정보취급방침은 적용일자 이전 가입자 또한 본 취급방침의 적용을 받습니다.
                <br />
              </p>
            </KeyboardAwareScrollView>
          </Box>
          <CButton onPress={() => onClose()}>
            <Center mt='16px' w='80px' h='35px' backgroundColor='#FF6F1E' borderRadius='3px'>
              <Text fontSize='15px' fontWeight={600} color='#ffffff'>
                닫기
              </Text>
            </Center>
          </CButton>
        </VStack>
      </Modal.Content>
    </Modal>
  )
}
