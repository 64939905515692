import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { RootStackParamList } from '@/interfaces/navigation'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { useNavigationState } from '@react-navigation/native'
import { CATEGORY_LIST } from '@/constants/site'
import FabButton from '@/screens/auth/components/FabButton'

export default function FabBottomMain({ tabRef, scrollToTop }: any) {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)
  const routes = useNavigationState((state) => state.routes)
  const currentRoute = routes[routes.length - 1].name
  const openPage = () => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
    } else {
      // const navigati = useNavigation();
      // const currentTabIndex = useNavigationState(state => state.index);
      // const currentTabName = navigation.getState().routeNames[0];
      // console.log(currentTabIndex)
      // console.log(currentTabName)
      // const focusedTab = tabRef.current?.getFocusedTab() || 'MAIN';
      // const foundCategory = CATEGORY_LIST.find(item => item.name === focusedTab);
      // if(foundCategory){
      //   navigation.push(ROOT_STACK.BOARD_CREATE,{categoryId: foundCategory.category})
      // }else{
      navigation.push(ROOT_STACK.BOARD_CREATE)
      // }
    }
  }

  return currentRoute === 'TOP_TAB' ? <FabButton onPress={openPage} /> : null
}
