import BoardListBannerItem from '@/screens/boards/components/BoardListBannerItem'
import { Center } from 'native-base'
import React from 'react'
import { IBanner, NoneBanner } from '@/interfaces/banner'
import { LIMIT } from '@/constants/limit'
import BoardListBannerNoneItem from '@/screens/boards/components/BoardListBannerNoneItem'

interface Props {
  bannerList: IBanner[]
}

export default function BoardListBanner(props: Props) {
  const { bannerList } = props

  const handleNoneBannerList = () => {
    const bannerListLength = bannerList ? bannerList.length : 0
    const noneBannerLength = (LIMIT.BOARD_BANNER_LIST - bannerListLength) % 2
    const activeBannerList: NoneBanner[] = []
    if (noneBannerLength) {
      for (let i = 0; i < noneBannerLength; i++) {
        activeBannerList.push({ id: Math.random() })
      }
      return activeBannerList
    } else {
      return []
    }
  }

  return (
    <Center py='8px' px='8px' flexDirection='row' flexWrap='wrap' bg='#EBEBEB'>
      {bannerList &&
        bannerList.map((item: IBanner) => {
          return <BoardListBannerItem item={item} key={item.id} />
        })}
      {handleNoneBannerList() &&
        handleNoneBannerList().map((item) => {
          return <BoardListBannerNoneItem key={item.id} />
        })}
    </Center>
  )
}
