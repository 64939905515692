import React from 'react'
import { Box, Center, Text } from 'native-base'

import CButton from '@/components/CButton'

interface Props {
  selectedTabId: string
  handleSelectedTabId: (value: string) => void
}

export default function BannerCreateTab(props: Props) {
  const { selectedTabId, handleSelectedTabId } = props

  return (
    <Box flexDirection='row' alignItems='center' justifyContent='center' mt='16px'>
      <CButton width='32%' onPress={() => handleSelectedTabId('공지')} mr='3px'>
        <Center
          h='40px'
          bgColor={selectedTabId === '공지' ? '#FFF1EA' : '#FFFFFF'}
          borderColor={selectedTabId === '공지' ? '#FF6F1E' : '#8F8F8F'}
          borderWidth='1px'
          borderRadius='44px'
          mr='8px'
        >
          <Text
            color={selectedTabId === '공지' ? '#FF6F1E' : '#000000'}
            fontWeight='bold'
            fontSize='16px'
          >
            공지
          </Text>
        </Center>
      </CButton>
      <CButton width='32%' onPress={() => handleSelectedTabId('필독')} mr='3px'>
        <Center
          h='40px'
          bgColor={selectedTabId === '필독' ? '#FFF1EA' : '#FFFFFF'}
          borderColor={selectedTabId === '필독' ? '#FF6F1E' : '#8F8F8F'}
          borderWidth='1px'
          borderRadius='44px'
          mr='8px'
        >
          <Text
            color={selectedTabId === '필독' ? '#FF6F1E' : '#000000'}
            fontWeight='bold'
            fontSize='16px'
          >
            필독
          </Text>
        </Center>
      </CButton>
      <CButton width='32%' onPress={() => handleSelectedTabId('일반 게시물')}>
        <Center
          h='40px'
          bgColor={selectedTabId === '일반 게시물' ? '#FFF1EA' : '#FFFFFF'}
          borderColor={selectedTabId === '일반 게시물' ? '#FF6F1E' : '#8F8F8F'}
          borderWidth='1px'
          borderRadius='44px'
        >
          <Text
            color={selectedTabId === '일반 게시물' ? '#FF6F1E' : '#000000'}
            fontWeight='bold'
            fontSize='16px'
          >
            일반
          </Text>
        </Center>
      </CButton>
    </Box>
  )
}
