import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IBanner } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'
import { TouchableHighlight } from 'react-native-gesture-handler'
import MyPageBoardTitle from '@/screens/my-page/components/MyPageBoardTitle'
import MyPageBoardContents from '@/screens/my-page/components/MyPageBoardContents'
import { Box, HStack, VStack } from 'native-base'

interface Props {
  item: IBoard
  banner: IBanner
  setDataSourceCords: (value: number) => void
}

export default function BannerBoardList(props: Props) {
  const { item, banner, setDataSourceCords } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <VStack>
      <Box
        onLayout={(e) => {
          setDataSourceCords(e.nativeEvent.layout.y)
        }}
      />
      <TouchableHighlight
        key={item.id}
        onPress={() =>
          navigation.push(ROOT_STACK.BANNER_BOARD_DETAIL, {
            id: item.id,
            category: item.category,
            banner: banner,
          })
        }
        underlayColor='#e9e9e9'
      >
        <VStack py='16px' px='16px' borderColor='#F2F2F2' borderBottomWidth='8px'>
          <HStack pt='5px' space='12px'>
            <VStack flex={1} alignContent='center' mr='4px'>
              <MyPageBoardTitle board={item} />
              <MyPageBoardContents board={item} screen='ArticleItem' />
            </VStack>
          </HStack>
        </VStack>
      </TouchableHighlight>
    </VStack>
  )
}
