import React from 'react'
import { Center, HStack, Text } from 'native-base'
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { ICreateBoardForm } from '@/interfaces/board'

interface Props {
  handleOpenConfirm: (value: boolean) => void
  handleSubmit: (
    onValid: SubmitHandler<ICreateBoardForm>,
    onInvalid?: SubmitErrorHandler<ICreateBoardForm>,
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>
  onSubmit: (form: ICreateBoardForm) => Promise<void>
  isModify: boolean
  navigation: any
}

export default function CreateBoardHeader(props: Props) {
  const { handleOpenConfirm, handleSubmit, onSubmit, isModify, navigation } = props

  const handleBack = () => {
    if (isModify) handleOpenConfirm(true)
    else navigation.goBack()
  }

  return (
    <HStack
      mt='10px'
      mb='5px'
      pb='15px'
      px='5px'
      bg='#ffffff'
      justifyContent='space-between'
      alignItems='center'
    >
      <CButton onPress={() => handleBack()}>
        <FastImage
          source={require('@/assets/images/black-back-icon.png')}
          style={{
            width: 24,
            height: 24,
          }}
          resizeMode='contain'
        />
      </CButton>
      <CButton onPress={handleSubmit(onSubmit)}>
        <Center mt='5px' bgColor='#ff6f1e' w='70px' h='33px' borderRadius='17px'>
          <Text mt='3px' color='white' alignItems='center' fontSize='16px' fontWeight={600}>
            등록
          </Text>
        </Center>
      </CButton>
    </HStack>
  )
}
