import { Box, Text } from 'native-base'
import CButton from '@/components/CButton'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { noReadMessageCountAtom } from '@/atom/navigationAtom'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import { AUTH_STACK, MY_PAGE_TAB, ROOT_STACK } from '@/constants/label'

interface Props {
  navigation: any
}

export default function MypageButton({ navigation }: Props) {
  const [auth, setAuth] = useAtom(authAtom)
  const [noReadMessageCount] = useAtom(noReadMessageCountAtom)

  return auth.logged ? (
    noReadMessageCount > 0 ? (
      <CButton
        mt='3px'
        position='relative'
        my='auto'
        onPress={() => {
          navigation.navigate(ROOT_STACK.MY_PAGE_TAB, { screen: MY_PAGE_TAB.MY_PAGE_BOARD })
        }}
      >
        <DynamicSvgIcon width='26px' height='29px' src={require('@/assets/svg/main-person.svg')} />
        <div className='notranslate'>
          <Box
            position='absolute'
            top='-5px'
            right='-8px'
            zIndex={20}
            borderRadius={8}
            backgroundColor={noReadMessageCount > 0 ? '#ff6f1e' : '#DADADA'}
            w='16px'
            h='16px'
            alignItems='center'
          >
            <Text
              pt='1px'
              pl='1px'
              fontSize='10px'
              fontWeight='bold'
              color={noReadMessageCount > 0 ? '#ffffff' : '#000000'}
            >
              {noReadMessageCount > 99 ? '99+' : noReadMessageCount}
            </Text>
          </Box>
        </div>
      </CButton>
    ) : (
      <CButton
        mt='3px'
        my='auto'
        onPress={() => {
          navigation.navigate(ROOT_STACK.MY_PAGE_TAB, { screen: MY_PAGE_TAB.MY_PAGE_BOARD })
        }}
      >
        <DynamicSvgIcon width='26px' height='29px' src={require('@/assets/svg/main-person.svg')} />
      </CButton>
    )
  ) : (
    <CButton
      mt='3px'
      my='auto'
      onPress={() => {
        navigation.navigate(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      }}
    >
      <DynamicSvgIcon width='26px' height='29px' src={require('@/assets/svg/main-person.svg')} />
    </CButton>
  )
}
