import { StyleSheet } from 'react-native'

const TooltipButtonStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  button: {
    padding: 10,
    backgroundColor: '#007BFF',
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
  tooltipContainer: {
    alignItems: 'center',
  },
  tooltipBubble: {
    backgroundColor: '#333',
    borderRadius: 5,
    padding: 10,
    maxWidth: 250,
  },
  tooltipText: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold',
  },
  tooltipArrow: {
    width: 0,
    height: 0,
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#333',
    marginTop: 0,
  },
})

export default TooltipButtonStyles
