import { Box, Center, Spinner, Text } from 'native-base'
import CButton from '@/components/CButton'

interface Props {
  buttonTitle: string
  fill?: boolean
  onPress?: () => void
  loading?: boolean
}

export default function SiteButton(props: Props) {
  const { buttonTitle, fill, onPress, loading } = props
  return (
    <Box w='214px' alignSelf='center' mt='8px' mb='20px'>
      <CButton onPress={onPress}>
        <Center
          h='48px'
          bgColor={fill ? '#F4F4F4' : '#ffffff'}
          borderColor='#8F8F8F'
          borderWidth='1px'
          borderRadius='24px'
          mb='10px'
        >
          {loading ? (
            <Spinner color={fill ? '#282828' : '#282828'} />
          ) : (
            <Text fontSize='20px' color={fill ? '#282828' : '#282828'} fontWeight={600}>
              {buttonTitle}
            </Text>
          )}
        </Center>
      </CButton>
    </Box>
  )
}
