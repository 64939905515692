import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { RootStackParamList } from '@/interfaces/navigation'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { useNavigationState } from '@react-navigation/native'
import FabButton from '@/screens/auth/components/FabButton'

export default function FabBottomBannerBoard({ banner }: any) {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)
  const routes = useNavigationState((state) => state.routes)
  const currentRoute = routes[routes.length - 1].name
  const openPage = () => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
    } else {
      if (banner) {
        navigation.push(ROOT_STACK.BANNER_BOARD_CREATE, { banner: banner })
      }
    }
  }

  return currentRoute === 'BANNER_BOARD_DETAIL' ? <FabButton onPress={openPage} /> : null
}
