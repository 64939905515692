import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'
import {
  IForgot,
  IForgotId,
  ILogin,
  IRegister,
  IReset,
  ISocialAccess,
  IUpdateAttendance,
  IUser,
} from '@/interfaces/auth'
import { Env } from '@/constants/env'

export async function loginAuth(data: ILogin) {
  const response = await StrapiAPI.post('/auth/local', data)
  return response.data
}

export async function getEmailInfo(email: string) {
  const response = await StrapiAPI.get('users?email=' + email)
  return response.data
}

export async function getTokenInfo(token: string) {
  const response = await StrapiAPI.get('users?username=' + token)
  return response.data
}

//kakao naver apple
export async function confirmedSocial(jwt: string, id: string) {
  const data = { confirmed: true }
  const response = await StrapiJwtAPI(jwt).put('/users/' + id, data)
  return response.data
}

export async function socialLoginAuth(data: ISocialAccess) {
  const googleUrl = `/auth/google/callback?access_token=${data.token}`
  const facebookUrl = `/auth/facebook/callback?access_token=${data.token}`
  const url = data.provider === 'google' ? googleUrl : facebookUrl
  const response = await StrapiAPI.get(url)
  return response.data
}

export async function loginAdminAuth() {
  const data = {
    identifier: Env.STRAPI_API_ADMIN_IDENTIFIER,
    password: Env.STRAPI_API_ADMIN_PASSWORD,
  }
  const response = await StrapiAPI.post('/auth/local', data)
  return response.data
}

export async function forgotIdAuth(data: IForgotId) {
  const response = await StrapiJwtAPI(data.jwt).get(`/users?email=${data.email}`)
  return response.data[0]
}

export async function forgotPwAuth(data: IForgot) {
  await StrapiAPI.post('/auth/forgot-password', data)
}

export async function resetPwAuth(data: IReset) {
  await StrapiAPI.post('/auth/reset-password', data)
}

export async function registerAuth(data: IRegister) {
  const response = await StrapiAPI.post('/auth/local/register', data)
  return response.data
}

export async function confirmCheckByEmailAuth(email: string) {
  try {
    const response = await StrapiAPI.get(`/apps/auth/confirm-check?email=${email}`)
    return response.data
  } catch (error) {
    return null
  }
}

export async function confirmCheckByIdentifierAuth(identifier: string) {
  const response = await StrapiAPI.get(`/apps/auth/confirm-check?identifier=${identifier}`)
  return response.data
}

export async function reSendConfirmEmailAuth(email: string) {
  await StrapiAPI.post('/auth/send-email-confirmation', { email })
}

export async function updateProfileAuth(data: IUser) {
  const response = await StrapiAPI.post('/apps/auth/update-profile', data)
  return response.data
}

export async function updateMeAttendance(data: IUpdateAttendance) {
  const response = await StrapiJwtAPI(data.jwt).put('/users/me', data)
  return response.data
}

export async function updateVisitorCount(id: number) {
  return StrapiAPI.put(`/article-elements/user/${id}`)
}

export async function removeUser(data: { jwt: string; id: number }) {
  return StrapiJwtAPI(data.jwt).delete(`/users/${data.id}`)
}

export async function findAllWithdrawal() {
  return StrapiAPI.get('/withdrawal-types')
}

export async function createWithdrawalHistory(data: {
  jwt: string
  username: string
  withdrawal_type: number
}) {
  const { jwt, username, withdrawal_type } = data
  return StrapiJwtAPI(jwt).post('/withdrawal-histories', { username, withdrawal_type })
}

export async function SubCount(jwt: string) {
  const response = await StrapiJwtAPI(jwt).get('/article-elements/me/SubCount')
  return response.data
}

export async function ReadSub(data: {
  jwt: string
  board_type: string
  user_num: number
  board_num: number
}) {
  const readData = {
    boardType: data.board_type,
    userId: data.user_num,
    boardNum: data.board_num,
  }
  const response = await StrapiJwtAPI(data.jwt).post('/article-elements/me/ReadSub', { readData })
  return response.data
}
