import { Box, Center, HStack, Text, VStack } from 'native-base'
import { ICuration, IYonhapNews } from '@/interfaces/news'
import MainYonhapNewsItem from './MainYonhapNewsItem'
// import MainKyodoNewsItem from './MainKyodoNewsItem'

interface Props {
  mainYonhapNewsList: IYonhapNews[]
  // mainKyodoNewsList: ICuration[]
}

export default function MainNewsList(props: Props) {
  const { mainYonhapNewsList } = props

  return (
    <VStack pt='20px' bg='#ffffff'>
      <MainYonhapNewsItem YonhapNews={mainYonhapNewsList} />
      {/* <MainKyodoNewsItem kyodoNews={mainKyodoNewsList} /> */}
    </VStack>
  )
}
