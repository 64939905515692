import React from 'react'
import { Box, HStack, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'
import { IBoard } from '@/interfaces/board'
import MoreTabBannerBoard from '@/components/MoreTabBannerBoard'
import { authAtom } from '@/atom/authAtom'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import BannerBoardList from './BannerBoardList'

interface Props {
  list: any[] | IBoard[]
  banner: IBanner
  onMore: () => void
  sum: number
  boarderLimit: number
}

export default function BannerDetailBottom(props: Props) {
  const { list, banner, onMore, sum, boarderLimit } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)
  const [dataSourceCords, setDataSourceCords] = React.useState(0)

  return (
    <Box>
      {list.length == 0 ? (
        <Box ml='16px' mt='16px' mr='8px'>
          <Text fontWeight={700} fontSize={20} pb='8px'>
            업체 전용 게시판입니다.
          </Text>
          <Text fontWeight={400} fontSize={15} mb='8px'>
            문의하신 내용에 대해서 업체가 확인 후 답변을 드립니다.
          </Text>
        </Box>
      ) : (
        <Box>
          {list.map((item) => {
            return (
              <Box key={item.id}>
                <BannerBoardList
                  item={item}
                  banner={banner}
                  setDataSourceCords={setDataSourceCords}
                />
              </Box>
            )
          })}
          {sum <= boarderLimit ? null : (
            <MoreTabBannerBoard
              moreButtonText='더보기'
              moreButtonOnPress={onMore}
              mainButton={false}
              useSideButton={false}
              sideButtonText='글쓰기'
              loading={false}
              wid={120}
              sideButtonOnPress={() => {
                if (!auth.logged) {
                  navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
                  return
                }
                navigation.push(ROOT_STACK.BANNER_BOARD_CREATE, { banner: banner })
              }}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
