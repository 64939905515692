import { Box, Center, Modal, Text, VStack } from 'native-base'
import CButton from '@/components/CButton'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function ModalServiceAgreement(props: Props) {
  const { isOpen, onClose } = props
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Content w={screenWidth - 60} minH='507px'>
        <VStack
          mx='auto'
          h='507px'
          alignItems='center'
          justifyItems='center'
          justifyContent='center'
          bgColor='white'
          borderRadius='8px'
          py='4px'
          px='26px'
        >
          <Text fontSize='23px' fontWeight='bold' textAlign='center' mb='16px'>
            서비스 이용약관 동의
          </Text>
          <Box h='70%'>
            <KeyboardAwareScrollView
              enableOnAndroid={true}
              enableAutomaticScroll={true}
              enableResetScrollToCoords={true}
              scrollEnabled={true}
              keyboardShouldPersistTaps={'handled'}
              style={{ backgroundColor: '#FFFFFF' }}
            >
              <p className='text-[#000000] text-[16px] font-normal'>
                <h3>제1조 (목적)</h3>
                <p>
                  본 이용약관(이하 “약관”)은 한터(HANTEO2050.COM)(이하 “회사”)에서 운영하는
                  한터(HANTEO2050.COM)(이하 “서비스”)를 이용함에 있어 회사와 회원의 권리, 의무 및
                  기타 필요한 사항을 규정함을 목적으로 합니다.
                </p>
                <h3>제2조 (용어의 정의)</h3>
                <p>본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.</p>
                <br />
                <p>
                  1. 회원 : 회사에 개인정보를 제공하여 회원가입을 한 자로서, 회사와 서비스
                  이용계약을 체결하고 회원 아이디(ID)를 부여 받은 자를 말합니다.
                  <br />
                  2. 회원 아이디(이하 “회원ID”) : 회원의 식별과 회원의 서비스 이용을 위하여 회원이
                  선정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다. <br />
                  3. 비밀번호 : 회원이 부여 받은 ID와 일치된 회원임을 확인하고, 회원의 개인정보를
                  보호 하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다. <br />
                  4. 가입해지 : 회사 또는 회원이 이용계약을 해지하는 것을 말합니다.
                  <br />
                  5. 유료서비스 : 회사가 유료로 제공하는 각종 인터넷 컨텐츠 서비스를 말합니다.
                  <br />
                </p>
                <h3>제3조 (약관의 명시와 개정)</h3>
                <p>
                  1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회사의 초기 서비스화면에
                  게시합니다.
                  <br /> 2. 회사는 약관의규제에관한법률, 전자거래기본법, 전자서명법,
                  정보통신망이용촉진등에관한법률, 방문판매 등에관한법률, 소비자보호법 등 관련법을
                  위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
                  <br /> 3. 회사는 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 새로운 약관을
                  회사의 초기 서비스화면에 공지합니다.
                  <br /> 4. 회원은 개정된 약관에 대해 거부할 권리가 있습니다. 회원은 약관의 변경에
                  대하여 주의 의무를 다하여야 하며, 개정된 약관에 동의하지 않을 경우 회원가입을
                  해지할 수 있으며, 개정된 약관의 효력발생 일 이후에도 서비스를 계속 이용할 경우
                  약관의 변경사항에 동의하는 것으로 간주합니다.
                </p>
                <h3>제4조 (약관외 준칙)</h3>
                <p>
                  1. 본 약관은 회사가 제공하는 서비스에 관하여 별도의 정책 및 운영규칙과 함께
                  적용됩니다.
                </p>
                <br />
                <br />
                [제 2 장 이용계약의 체결]
                <br />
                <h3>제5조 (이용신청)</h3>
                <p>
                  1. 회원으로 가입하여 서비스를 이용하고자 하는 자는 서비스 신청시 본 약관을 읽고
                  “위의 이용약관에 동의합니다”의 대화창에 표시를 한 후 가입하기 단추를 선택하고,
                  회사가 요청하는 소정의 신청양식에 요구된 항목을 기재하여 이용신청을 합니다.
                  <br />
                  2. 회원은 반드시 회원본인의 실명인증을 하여야 합니다. 실명으로 가입하지 않은
                  회원은 법적인 보호를 받을 수 없으며, 서비스 이용제한을 받게 되며 관련법에 따라
                  처벌을 받을 수도 있습니다.
                  <br />
                  3. 온라인 이용신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며
                  실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용제한을
                  받을 수 있습니다.
                  <br />
                  4. 회사는 회원의 실명확인을 위하여 전문기관을 통하여 실명인증을 할 수 있습니다.
                  <br />
                </p>
                <h3>제6조 이용신청의 승낙과 제한</h3>
                <p>
                  1. 회사는 제5조의 내용에 따르는 이용신청에 대하여 특별한 하자가 없는 한 접수한
                  순서대로 승낙함으로써 이용계약은 성립합니다.
                  <br />
                  2. 회사는 다음 각 호의 내용에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고,
                  그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                  <br />
                  (1) 서비스 관련설비에 여유가 없는 경우
                  <br />
                  (2) 기술상 지장이 있는 경우
                  <br />
                  (3) 기타 회사의 사정상 필요하다고 인정되는 경우
                  <br />
                  3. 회사는 다음 각 호의 내용에 해당하는 경우 이용신청에 대하여는 이를 승낙하지 아니
                  할 수도 있습니다.
                  <br />
                  (1) 실명이 아니거나 타인의 명의를 이용하여 신청한 경우
                  <br />
                  (2) 이용 신청서의 내용을 허위로 기재하여 신청한 경우
                  <br />
                  (3) 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우
                  <br />
                  (4) 부정한 용도로 서비스를 이용하고자 하는 경우
                  <br />
                  (5) 기타 회사가 정한 이용신청 요건이 미비 된 경우
                  <br />
                </p>
                <h3>제7조 개인정보보호 정책</h3>
                <p>
                  회사는 관계법령이 정하는 바에 따라 회원가입정보를 포함한 회원의 개인정보를
                  보호하기 위하여 노력을 합니다. 회원의 개인정보보호에 관하여 관계법령 및 회사가
                  정하는 개인정보보호정책에 정한 바에 따릅니다.
                </p>
                <h3>제9조 회원ID 부여 및 변경 등</h3>
                <p>
                  1. 회사는 회원에 대하여 본 약관에 정하는 바에 따라 회원ID를 부여합니다.
                  <br />
                  2. 회원은 서비스 내의 나의 정보 또는 기타 해당 페이지로 링크된 메뉴를 통하여
                  자신의 개인정보를 관리할 수 있는 페이지를 열람할 수 있으며, 해당 페이지에서
                  언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
                  <br />
                  <p>
                    다만, 서비스 관리를 위하여 반드시 필요한 실명, 주민등록번호, 성별 등은 수정할 수
                    없습니다.
                  </p>
                  <br />
                  3. 회원ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는
                  경우에는 해당ID를 해지하신 후 새로 가입하셔야 합니다.
                  <br />
                  <br />
                  <br />
                  [제 3 장 계약 당사자의 의무]
                </p>
                <h3>제10조 회사의 의무</h3>
                <p>
                  1. 회사는 관련법과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본
                  약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위하여 최선을
                  다하여야 합니다.
                  <br />
                  2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보보호를 위한
                  보안시스템을 구축하며, 개인정보보호정책을 준수합니다.
                  <br />
                  3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관계절차 및
                  내용 등에 있어 회원에게 편의를 제공하도록 노력합니다.
                  <br />
                </p>
                <h3>제11조 회원ID 및 비밀번호에 대한 관리</h3>
                <p>
                  1. 회사가 관계법령 및 개인정보보호정책에 의하여 그 책임을 지는 경우를 제외하고,
                  회원ID와 비밀번호의 관리책임은 회원에게 있습니다.
                  <br />
                  2. 회원은 자신의 회원ID와 비밀번호를 도용 당하거나 제3자가 사용하고 있음을 인지한
                  경우에는 바로 회사에 통보하고 회사의 안내에 따라야 합니다.
                  <br />
                </p>
                <h3>제12조 회원의 의무</h3>
                <p>
                  1. 회원은 본 약관에 규정하는 사항과 서비스 이용안내 또는 공지사항 등 회사가 공지
                  또는 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는
                  안됩니다.
                  <br />
                  2. 회사가 관계법령 및 개인정보보호정책에 의하여 그 책임을 지는 경우를 제외하고
                  제11조의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게
                  있습니다.
                  <br />
                  3. 회원은 회사의 사전승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 회원간
                  또는 회원과 제3자간에 서비스를 매개로 한 물품거래 및 서비스의 이용과 관련하여
                  기대하는 이익 등에 관하여 발생하는 손해에 대하여 회사는 책임을 지지 않습니다. 이와
                  같은 영업 활동으로 회사가 손해를 입은 경우 회원은 회사에 대하여 손해배상의 의무를
                  집니다.
                  <br />
                  4. 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를
                  타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
                  <br />
                  5. 회원은 서비스 이용과 관련하여 제24조 제2항의 각 호에 해당하는 행위를 하여서는
                  안됩니다.
                  <br />
                  <br />
                  <br />
                  <br />
                  [제 4 장 서비스 이용]
                </p>
                <h3>제13조 서비스의 제공 및 변경</h3>
                <p>
                  1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                  <br />
                  (1) 부동산 서비스
                  <br />
                  (2) 자동차 서비스
                  <br />
                  (3) 취업 서비스
                  <br />
                  (4) 기타 회사가 자체 개발하거나 다른 회사와의 협력계약 등을 통해 회원들에게 제공할
                  일체의 서비스
                  <br />
                  2. 회사에서 제공하는 서비스는 기본적으로 무료입니다.
                  <br />
                  다만, 회사가 정한 유료서비스에 대하여는 그러하지 아니합니다. 유료서비스의 이용에
                  대한 사항은 회사가 별도로 정한 정책 또는 운영규칙에 따릅니다.
                  <br />
                  3. 회사는 제13조 제1항에서 정한 서비스를 변경하여 제공할 수 있으며, 그 변경될
                  서비스의 내용 및 제공일자를 제15조에 정한 방법으로 회원에게 통지합니다.
                  <br />
                </p>
                <h3>제14조 유료서비스 이용</h3>
                <p>
                  1. 회사가 회원에게 제공하는 서비스는 기본적으로 무료입니다.
                  <br />
                  단, 회사가 유료로 책정한 부가서비스나 제3자와의 업무제휴를 통해 제공하는 별도의
                  유료정보에 대해서는 회원이 해당 보에 명시된 요금을 지불하여야 하며 이는 회원의
                  동의를 전제로 합니다. 2. 회사의 유료서비스이용요금은 별도로 공지합니다.
                  <br />
                  3. 유료 부가서비스를 이용한 회원이 이 약관에서 정한 사유가 발생하여 회원가입을
                  취소하고 환불을 요구하거나, 회사의 귀책사유로 서비스를 지속할 수 없을 경우 회사는
                  잔여 이용요금을 용자가 지정하는 구좌로 환불하여야 합니다.
                  <br />
                </p>
                <h3>제15조 정보의 제공 및 통지</h3>
                <p>
                  1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항 혹은
                  전자우편 등의 방법으로 회원에게 제공할 수 있습니다.
                  <br />
                  2. 회사는 불특정다수 회원에 대한 통지를 하는 경우 7일 이상 서비스 게시판에
                  게시함으로써 개별 통지에 갈음할 수 있습니다.
                  <br />
                </p>
                <h3>제16조 게시물의 관리 및 저작권</h3>
                <p>
                  1. 회사는 회원의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을
                  다하여 보호합니다. 다만, 다음 각 호에 해당하는 경우 사전통지 없이 해당 게시물을
                  삭제할 수 있고, 해당 회원의 자격을 제한, 정지 또는 상실시킬 수 있습니다.
                  <br />
                  (1) 타인을 비방할 목적으로 허위사실을 유포, 타인의 명예를 훼손하는 내용인 경우
                  <br />
                  (2) 공서양속에 위반되는 내용인 경우
                  <br />
                  (3) 범죄적 행위에 결부된다고 인정되는 경우
                  <br />
                  (4) 회사의 저작권 혹은 제3자의 저작권 등 기타 지적재산권을 침해하는 내용인 경우
                  <br />
                  (5) 회원이 음란물을 게재하거나 음란사이트를 링크한 경우
                  <br />
                  (6) 게시판의 성격에 부합하지 않는 게시물의 경우
                  <br />
                  (7) 스팸성 게시물인 경우
                  <br />
                  (8) 정상적인 서비스 운영을 방해하는 경우
                  <br />
                  (9) 기타 관계법령에 위반된다고 판단하는 경우
                  <br />
                  <br />
                  2. 서비스 내에 게시한 게시물의 저작권은 게시한 회원에게 있습니다. 또한 회사는
                  게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만, 비영리 목적인
                  경우 그러하지 아니하며, 또한 회사는 서비스 내에서의 게재권을 가집니다.
                </p>
                <h3>제17조 광고게재 및 광고주와의 거래</h3>
                <p>
                  회사는 본 서비스 상에 게재된 광고주의 판촉활동 및 광고주와 회원간의 교신 또는
                  거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.
                </p>
                <h3>제18조 서비스 이용시간</h3>
                <p>
                  1. 서비스 이용시간은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일
                  24시간을 원칙으로 합니다. 다만, 정기점검 등의 필요로 회사가 정한 날이나 시간에
                  서비스를 일시 중단할 수 있습니다.
                  <br />
                  2. 회사는 서비스를 일정 범위로 분할하여 각 범위별로 이용가능시간을 별도로 정할 수
                  있습니다. 이러한 경우에는 그 내용을 사전에 공지합니다.
                  <br />
                </p>
                <h3>제19조 서비스 이용요금</h3>
                <p>
                  1. 이용요금은 회원에게 제공되는 서비스 중 유료서비스를 이용하기 위해 지불하는
                  요금으로 회원의 종류에 따라 차등하게 적용할 수 있으며, 서비스에 준하여 산정된
                  금액은 회원에게 사전에 고지합니다. 변경 시에는 변경 7일 전에 고지합니다.
                  <br />
                  2. 회사는 이용요금과 관련하여 오류가 있는 경우에 이용자의 요청, 또는 회사의 사전
                  통지에 의하여 다음에 해당하는 조치를 취합니다. (1) 과다 납입한 요금에 대하여는 그
                  금액을 반환합니다. 다만, 회원이 동의할 경우 다음달에 청구할 요금에서 해당
                  금액만큼을 감하여 청구합니다.
                  <br />
                  (2) 요금을 반환 받아야 할 회원이 요금 체납이 있는 경우에는 반환해야 할 요금에서
                  이를 우선 공제하고 반환합니다.
                </p>
                <h3>제20조 서비스 이용 책임</h3>
                <p>
                  회원은 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로 허용한 경우를
                  제외하고는 서비스를 이용하여 상품을 판매하는 영업활동을 할 수 없으며 특히 해킹,
                  돈벌이 광고, 음란 사이트 등을 통한 상업행위, 용S/W 불법배포 등을 할 수 없습니다.
                  <br />
                  이를 어기고 발생한 사안에 해서는 회사가 책임지지 않습니다.
                  <br />
                  또한 회사가 관여되지 않은 개인-개인, 개인-기업, 기업-기업 등의 사이에서 발생한
                  상호거래나 계약에 의해 발생한 결과 및 손실, 관계기관에 의한 구속 등 법적 조치에
                  관해서는 회사가 책임을 지지 않습니다.
                  <br /> 단, 회사의 과실로 인한 정보가 유출되는 경우에는 회사가 손해에 대한 책임을
                  지도록 합니다.
                  <br />
                </p>
                <h3>제21조 서비스 제공의 중지 등</h3>
                <p>
                  1. 회사는 다음에 해당하는 경우 서비스 제공을 중지할 수 있습니다. (1)
                  정보통신설비의 보수점검?교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는
                  부득이하게 서비스의 제공을 일시적으로 중단할 수 있습니다.
                  <br />
                  (2) 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우.
                  <br />
                  (3) 기타 불가항력적 사유가 있는 경우.
                  <br />
                  2. 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로
                  정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나
                  중지할 수 있습니다.
                  <br />
                  3. 회사는 제1항 및 제2항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는
                  그 사유 및 제한기간 등을 공지하거나 전자우편 기타의 방법으로 지체 없이 회원에게
                  알려야 합니다.
                  <br />
                  <br />
                  <br />
                  [제 5 장 계약해지 및 이용제한]
                </p>
                <h3>제24조 계약해지 및 이용제한</h3>
                <p>
                  1. 회원이 이용 계약을 해지하고자 하는 경우에는 온라인 상에서 즉시 회원탈퇴를 실행
                  하시거나 회원탈퇴 문의 메일을 온라인을 통해 보내주시면 됩니다. 문의 메일을 통해
                  회원탈퇴를 진행하실 경우에는 최대 24시간의 처리 기간을 갖고 있습니다.
                  <br />
                  <br />
                  2. 회사는 회원이 다음에 해당하는 행위를 하였을 경우 사전통지 후 이용계약을
                  해지하거나 또는 서비스 이용 기간과 범위를 정하여 이용을 제한할 수 있습니다.
                  <br />
                  (1) 타인의 개인정보, ID 및 비밀번호를 도용한 경우.
                  <br />
                  (2) 가입한 이름이 실명이 아닌 경우.
                  <br />
                  (3) 같은 사용자가 다른 ID로 이중등록을 한 경우.
                  <br />
                  (4) 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우.
                  <br />
                  (5) 회사, 다른 회원 또는 제 3자의 지적재산권을 침해하는 경우.
                  <br />
                  (6) 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여
                  선거관리위원회의 유권해석을 받은 경우.
                  <br />
                  <br />
                  3. 회사는 회원이 다음에 해당하는 행위를 하였을 경우 사전 동의 없이 이용계약을
                  해지하거나 또는 서비스 이용 중지, 채용공고, 게시물 삭제 조치를 취할 수 있습니다.
                  <br />
                  (1) 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때.
                  <br />
                  (2) 회원이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때.
                  <br />
                  (3) 동일 회원ID로 마감일이 지나지 않은 사실상의 동일 내용 채용공고를 중복 등록했을
                  때.
                  <br />
                  (4) 허위 채용공고를 등록했을 때.
                  <br />
                  (5) 지사, 지점, 영업소 등의 경우 구체적인 지사, 지점, 영업소 명칭을 사용하지 않고
                  기업명을 등록했거나 지사, 지점, 영업소의 사업자등록번호가 별도 있음에도 불구하고
                  본사의 사업자등록번호로 기업회원 가입을 했을 때.
                  <br />
                  (6) 사업자등록증에 나타난 정보와 기업회원 가입시의 정보가 일치하지 않을 때.
                  <br />
                  (7) 모집요강에 적합한 분류를 지정하지 않았을 때. 특히, 교육기관이나 이벤트업체
                  또는 영업대행 기관의 경우 영업, 마케팅, 텔레마케팅, 상담 인력을 모집하고자 할 경우
                  반드시 텔레마케팅 또는 고객상담, 교육/강사/학원 등 직접 관련되는 해당 업종, 직종
                  분류만 지정해야 한다.
                  <br />
                  (8) 재택 쇼핑몰 운영(홍보)에 관한 내용을 등록했을 때.
                  <br />
                  (9) 기타 본 서비스 명예를 훼손하였을 때.
                  <br />
                  (10) 임금체불 사실이 접수되었거나 근로감독원에 진정서가 접수되어 있는 기업.
                  <br />
                  (11) 취업시 수수료를 지불해야 하거나 교육비 명목으로 개인에게 비용을 요구했을 때.
                  <br />
                  (12) 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
                  유통시키거나 상업적으로 이용하는 경우.
                  <br />
                  (13) 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란 사이트 링크하는
                  경우.
                  <br />
                  (14) 본 약관을 포함하여 회사가 정한 기타 이용 조건에 위반한 경우.
                  <br />
                  (15) 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우(예. 욕설, 음란물
                  배포 등).
                  <br />
                  (16) 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는
                  실행하는 경우.
                  <br />
                  (17) 서비스 운영을 고의로 방해한 경우.
                  <br />
                  (18) 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를
                  전송하는 경우 (예. 게시판 도배 등).
                  <br />
                  (19) 정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스 프로그램
                  등을 유포하는 경우(예. 해킹 등).
                  <br />
                  4. 회사는 회원정보 보호를 위해 인재 DB의 이용을 다음과 같이 제한할 수 있습니다.
                  (1) 보험회사, 카드사, 텔레마케팅을 통한 영업회사, 학원 등 인재 DB를 악용할
                  가능성이 있는 기업체라고 판단된 경우, 인재DB 제공 서비스를 이용할 수 없으며 서비스
                  이용을 막는다.
                  <br /> (2) 인재 DB의 활용은 채용목적에만 국한하여야 합니다.
                  <br /> (3) 타 기업의 사업자등록번호나 상호를 도용하거나, 인재DB 검색 권한을 임의로
                  양도, 대여할 수 없습니다.
                  <br /> (4) 위 사항들을 위반한 경우에는 모든 서비스가 자동으로 중지되고 사용요금은
                  환불조치 되지 않으며, 회사는 손해배상 청구 및 법적고발 조치를 취할 수 있습니다.
                  <br />
                  5. 회원이 사망한 때는 회원 자격을 상실합니다.
                  <br />
                  <br />
                  <br />
                  [제 6 장 손해배상 및 기타 사항]
                </p>
                <h3>제25조 손해배상 및 면책</h3>
                <p>
                  1. 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는
                  회사의 서비스 제공에 관한 책임이 면제됩니다.
                  <br />
                  2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
                  <br />
                  3. 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여
                  얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
                  <br />
                  4. 회사는 회원이 서비스에 게재한 정보, 자료 등의 사실 유무에 관하여는 책임을 지지
                  않습니다.
                  <br />
                  5. 회사는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의 고의,
                  과실에 의한 손해에 대하여 책임을 지지 않습니다.
                  <br />
                </p>
                <h3>제26조 이용자 보호</h3>
                <p>
                  1. 회사는 이용자의 권익을 보호하기 위해, 상호명, 대표자 성명, 영업소 소재지와
                  전화번호, 팩스번호, E-mail, 사업자등록번호 등 회사의 신원정보를 홈페이지를 통해
                  표시하고 있습니다.
                  <br />
                  2. 회사는 서비스 이용약관을 공시하여 이용자의 피해를 최소화하기 위해 노력합니다.
                  <br />
                  3. 회사는 다음과 같은 거래관련 정보 관리 절차를 갖고 있습니다.
                  <br />
                  (1) 회사가 제공하는 서비스의 명칭, 종류, 내용 및 가격 등을 표시합니다.
                  <br />
                  (2) 회사에서 구입하신 상품은 회사의 고객지원센터로 문의 주시면 안내 받으실 수
                  있으며, 구입하신 상품의 A/S에 관한 보증기간은 통상 서비스 마감일로부터 1개월
                  이내입니다.
                  <br />
                  (3) 소비자 피해 보상 및 불만이 있을 경우, 고객지원센터를 통해 처리 받으실 수
                  있습니다.
                  <br />
                  4. 회원은 다음과 같은 절차를 통하여 회사의 서비스를 구매하고, 구매사항을 교환 및
                  환불 받으실 수 있습니다. (1) 구매를 희망하는 회사의 서비스에 대한 대금지급 방법은
                  계좌이체, 신용카드결제, 온라인 무통장 입금, 휴대폰결제 있습니다.
                  <br />
                  대금지급 완료시기는 현금의 경우 무통장 입금 확인 후, 카드결제는 자동승인
                  인정합니다.
                  <br />
                  (2) 회사는 결제/입금이 완료된 회원이 서비스 제공 전에 환불을 요청하는 경우에는
                  무조건 환불 또는 동일금액의 타상품으로 대체 처리합니다.
                  <br />
                  (3) 이미 서비스를 이용한 기업에게는 환불을 하지 않는 것을 원칙으로 하나, 회사의
                  시스템상의 오류나 에러로 인한 환불 요청에는 전액 환불 처리합니다.
                  <br />
                  (4) 품절 등 불가피한 사유로 상품을 서비스할 수 없을 때, 3일 이내 대금 환불 조치를
                  취하고 있습니다.
                  <br />
                  (5) 회사의 고객보호규정에서 규정한 인재정보 사용제한 업체, 혹은 정당한 사유로
                  서비스 이용을 제한하는 기업, 중요한 사실을 조작 및 누락하여 서비스를 이용하는
                  경우, 명의를 도용하여 이용하는 경우, 기타 법규 및 공공정서에 어긋나는 행위를
                  통해서 서비스를 이용하다가 적발되는 경우는 거래취소 및 환불처리가 불가능합니다.
                  단, 서비스 불량일 경우 제외이니 고객만족센터로 연락 주시기 바랍니다.
                  <br />
                  5. 회사는 거래의 신뢰성을 확보하기 위해 다음과 같은 노력을 기울이고 있습니다. (1)
                  이용자는 웹 상에서 주문 내역을 확인하고 취소, 변경할 수 있습니다.
                  <br />
                  (2) 회사는 주문 내역 수신 확인 메시지를 이용자에게 즉시 통보합니다.
                  <br />
                  (3) 이용자의 구매 의사 변화 시,
                  &quot;전자상거래등에서의소비자보호에관한법률&quot;에 의거하여 아래와 같은 경우,
                  회사는 일정한 기한 내에 불이익 없이 이용자가 청약 철회를 할 수 있도록 합니다.
                  <br />
                  <br />
                  * 계약에 관한 서면을 교부 받은 날로부터 7일
                  <br />
                  * 서면을 교부 받은 때보다 재화 등의 공급이 늦어진 경우에는 재화 등을 공급받거나
                  공급이 개시된 날로부터 7일
                  <br />
                  * 계약에 관한 서면을 교부 받지 못했거나, 교부 받은 서면에 주소가 기재되지 않았을
                  경우, 주소 변경 등으로 청약 철회 등을 할 수 없었을 경우에는 주소를 안 날로 또는 알
                  수 있었던 날부터 7일
                  <br />
                  6. 회사에서는 아래 예외 사항의 경우를 제외하고, 회사를 통하여 이용자가 피해를
                  보았을 때는 보상 및 불만 처리 절차를 갖고 있습니다. 처리 방법은 고객지원센터로,
                  전화, 이메일, 팩스 등을 통해 접수하시면 됩니다. 접수된 민원은 접수일로부터 만 1일
                  이내 고객이 받을 수 있는 수단을 통해 답변을 드리고 있습니다. 단, 휴일은 다음날
                  근무일 내에 처리됩니다.
                  <br />
                  <br />
                  * 예외 사항
                  <br />
                  - 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우
                  <br />
                  - 회원의 귀책사유로 인한 서비스 이용의 장애
                  <br />
                  - 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은
                  자료로 인한 손해
                  <br />
                  - 회원이 서비스에 게재한 정보, 자료 등의 사실 유무에 관한 사항
                  <br />
                  - 가입자의 고의, 과실에 의한 손해
                  <br />
                  - 회사를 배제한 이용자간 상호거래나 관계에 의해 발생한 결과 및 손실
                  <br />
                  7. 만 20세 미만의 이용자의 경우 거래 발생 시 법률상의 법정 대리인의 동의가
                  필요합니다.
                  <br />
                  법정 대리인의 동의 없이 거래가 이루어진 경우, 회원의 요청에 따라 회사는 거래 취소
                  및 환불요청을 수용합니다. 8. 회사는 해당 담당직원에게 이용자 보호 지침 공지 및
                  교육을 실시하고 있습니다.
                  <br />
                  9. 회사에서 구입하신 상품에 대한 애프터 서비스는 안산Kcr 고객지원센터
                  031-410-9000로 문의 주시면 친절히 안내해 드립니다. 또한 구입하신 상품의 A/S에 관한
                  보증기간은 통상 서비스 마감일로부터 1개월 이내입니다.
                </p>
                <h3>제27조 분쟁 해결</h3>
                <p>
                  1. 회사는 이용자로부터 제기되는 불만사항 및 의견을 우선적으로 처리합니다. 다만,
                  신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보합니다.
                  <br />
                  2. 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한
                  모든 노력을 하여야 합니다.
                  <br />
                  3. 1항과 2항의 노력에도 불구하고, 분쟁에 대해 소송이 제기될 경우 소비자의 주소지
                  관할 법원에서 실시 또는 민사소송 절차에 따르도록 합니다.
                  <br />
                  <br />
                  2022 .09 .20
                </p>
              </p>
            </KeyboardAwareScrollView>
          </Box>
          <CButton onPress={() => onClose()}>
            <Center mt='16px' w='80px' h='35px' backgroundColor='#FF6F1E' borderRadius='3px'>
              <Text fontSize='15px' fontWeight={600} color='#ffffff'>
                닫기
              </Text>
            </Center>
          </CButton>
        </VStack>
      </Modal.Content>
    </Modal>
  )
}
