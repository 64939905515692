import { Box, HStack, Text } from 'native-base'

import CloseButton from '@/components/CloseButton'

interface Props {
  title: string
  navigation: any
}

export default function AuthStackHeader({ title, navigation }: Props) {
  return (
    <HStack
      height='70px'
      justifyContent='center'
      py='7px'
      borderBottomWidth={1}
      borderBottomColor='#e85502'
      bg='#ffffff'
    >
      <Box alignItems='center' justifyContent='center'>
        <Text fontFamily='body' fontStyle='normal' fontSize='24px' fontWeight='bold'>
          {title}
        </Text>
      </Box>
      <Box position='absolute' left='5%' top='36%'>
        <CloseButton navigation={navigation} />
      </Box>
    </HStack>
  )
}
