import React, { useMemo, useState } from 'react'
import { Platform, View } from 'react-native'
import { Box, HStack, Input, Text, VStack } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { authStatusAtom, handleUpdateProfile, profileAtom } from '@/atom/authAtom'
import { AuthStatusBar, LargeButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK, REGISTER_STATUS } from '@/constants/label'
import { AUTH_STATUS } from '@/constants/auth'
import { AuthStackParamList } from '@/interfaces/navigation'
import { IUser } from '@/interfaces/auth'
import { popupStateAtom } from '@/atom/initAtom'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ErrorMessageBox from './components/ErrorMessageBox'
import RadioGroup from 'react-native-radio-buttons-group'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_PROFILE>

export default function ProfileScreen({ navigation }: Props) {
  const [authStatus] = useAtom(authStatusAtom)
  const [profile] = useAtom(profileAtom)
  const [, updateProfile] = useAtom(handleUpdateProfile)
  const [popup] = useAtom(popupStateAtom)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isLoading, setLoading] = React.useState(false)
  const scrollRef = React.useRef<any>(null)
  const [dataSourceCords, setDataSourceCords] = React.useState<{ key: number; scrollY: number }[]>(
    [],
  )
  const nickNameRef = React.useRef<any>(null)

  const genderButtons = useMemo(
    () => [
      {
        id: '남성',
        label: '남성',
        value: '남성',
        size: 16,
      },
      {
        id: '여성',
        label: '여성',
        value: '여성',
        size: 16,
      },
    ],
    [],
  )

  const ageGroupButtons = useMemo(
    () => [
      {
        id: '10대 ~ 20대',
        label: '10대 ~ 20대',
        value: '10대 ~ 20대',
        size: 16,
      },
      {
        id: '30대 ~ 40대',
        label: '30대 ~ 40대',
        value: '30대 ~ 40대',
        size: 16,
        containerStyle: { marginTop: 14 },
      },
      {
        id: '50대 ~ 60대',
        label: '50대 ~ 60대',
        value: '50대 ~ 60대',
        size: 16,
        containerStyle: { marginTop: 14 },
      },
      {
        id: '70대 이상 ~',
        label: '70대 이상 ~  ',
        value: '70대 이상 ~',
        size: 16,
        containerStyle: { marginTop: 14 },
      },
    ],
    [],
  )

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<IUser>({
    defaultValues: {
      id: profile?.id,
      email: profile?.email,
      username: profile?.username,
      gender: '',
      nick_name: '',
      age_group: '',
    },
  })

  const findScrollYByKey = (key: number) => {
    if (key === 2) return 300

    const index = dataSourceCords.findIndex((item) => item.key === key)

    if (index > -1) {
      if (key === 0) return dataSourceCords[index].scrollY - 30
      else return dataSourceCords[index].scrollY
    } else {
      return 0
    }
  }

  const scrollToHandler = (scrollY: number) => {
    let y = errorMessage || formErrors.nick_name ? scrollY + 50 : scrollY

    y += popup.isOpen ? 50 : 0

    if (scrollRef.current) {
      scrollRef.current?.scrollTo({
        x: 0,
        y: y, //we get the offset value from array based on key
        animated: true,
      })
    }
  }

  const onSubmit = async (data: IUser) => {
    if (data.gender == '') {
      // data.gender = '선택안함'
      return setErrorMessage('성별을 입력해 주세요')
    }
    if (data.age_group == '') {
      // data.gender = undefined
      return setErrorMessage('연령대를 입력해 주세요')
    }

    setLoading(true)
    if (data.username?.includes('kakao')) {
      const updateData: IUser = {
        ...data,
        confirmed: true,
      }
      await updateProfile(updateData)
      return setLoading(false)
    } else if (data.username?.includes('naver')) {
      const updateData: IUser = {
        ...data,
        confirmed: true,
      }
      await updateProfile(updateData)
      return setLoading(false)
    } else {
      const updateData: IUser = {
        ...data,
      }
      await updateProfile(updateData)
      return setLoading(false)
    }
  }

  React.useEffect(() => {
    if (authStatus === AUTH_STATUS.SERVER_ERROR) {
      setErrorMessage('중복된 닉네임입니다.')
    } else if (authStatus === AUTH_STATUS.UPDATE_SUCCESS) {
      navigation.replace(AUTH_STACK.AUTH_REGISTER_COMPLETE)
    }
  }, [authStatus, navigation])

  return (
    <React.Fragment>
      <AuthStackHeader title='회원가입' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        keyboardShouldPersistTaps={'handled'}
      >
        <Box mt='30px' mx='10%'>
          <AuthStatusBar active={REGISTER_STATUS.PROFILE} />
          <Box py='10px'>
            {/* 닉네임 */}
            <VStack mt='10px' borderBottomWidth='1px' borderBottomColor='#EBEBEB'>
              <Text fontSize='15px' fontWeight='bold' color='#ff6f1e' mb='6px'>
                닉네임 (12자 이내)
              </Text>
              <Box
                key={2}
                onLayout={(e) => {
                  const layout = e.nativeEvent.layout
                  setDataSourceCords([...dataSourceCords, { key: 5, scrollY: layout.y }])
                }}
              >
                <Controller
                  control={control}
                  name='nick_name'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      ref={nickNameRef}
                      placeholder='닉네임을 입력해주세요.'
                      placeholderTextColor='#5B5B5B'
                      textAlign='left'
                      w='auto'
                      h='38px'
                      backgroundColor='#F4F4F4'
                      borderWidth={0}
                      mb='8px'
                      pl='10px'
                      fontSize='15px'
                      maxLength={12}
                      onChangeText={(value) => {
                        onChange(value)
                        formClearErrors()
                        setErrorMessage('')
                      }}
                      onBlur={() =>
                        scrollRef.current?.scrollTo({
                          x: 0,
                          y: 0,
                          animated: true,
                        })
                      }
                      value={value}
                      autoCapitalize='none'
                      autoCorrect={false}
                      onFocus={() => {
                        scrollToHandler(findScrollYByKey(2))
                      }}
                      onSubmitEditing={handleSubmit(onSubmit)}
                    />
                  )}
                />
              </Box>
              <Text fontSize='12px' fontWeight='400' color='#000000' mb='6px'>
                회원가입 후 마이페이지에서 변경 가능합니다.
              </Text>
            </VStack>
            {/* <VStack pt='16px' pb='8px' borderBottomWidth='1px' borderBottomColor='#EBEBEB'>
              <Box alignItems='flex-start' mr='5px'>
                <Text fontSize='16px' fontWeight='bold'>
                  선택 항목
                </Text>
              </Box>
            </VStack> */}
            {/* 성별 */}
            <HStack
              alignItems='center'
              borderBottomWidth='1px'
              borderBottomColor='#EBEBEB'
              py='12px'
            >
              <Box w='60px' alignItems='flex-start' mr='5px'>
                <Text fontSize='15px' color='#000000'>
                  성별
                </Text>
              </Box>
              <Controller
                control={control}
                name='gender'
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    radioButtons={genderButtons}
                    onPress={(value) => {
                      onChange(value)
                    }}
                    selectedId={value}
                    layout='row'
                  />
                )}
              />
            </HStack>
            {/* 연령대 */}
            <HStack
              // alignItems='center'
              borderBottomWidth='1px'
              borderBottomColor='#EBEBEB'
              py='12px'
            >
              <Box w='60px' alignItems='flex-start' mr='5px'>
                <Text fontSize='15px' color='#000000'>
                  연령대
                </Text>
              </Box>
              <Controller
                control={control}
                name='age_group'
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    radioButtons={ageGroupButtons}
                    onPress={(value) => {
                      onChange(value)
                    }}
                    selectedId={value}
                    layout='column'
                  />
                )}
              />
            </HStack>
            {formErrors.nick_name ? (
              <ErrorMessageBox
                marginTop='16px'
                marginBottom='6px'
                errorMessage='닉네임을 입력해주세요.'
              />
            ) : errorMessage ? (
              <ErrorMessageBox marginTop='16px' marginBottom='6px' errorMessage={errorMessage} />
            ) : null}
          </Box>
          <LargeButton
            buttonTitle='등록하기'
            loading={isLoading}
            onPress={handleSubmit(onSubmit)}
            fill
          />
        </Box>
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
