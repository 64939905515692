import {
  IAnotherUserLoad,
  ICreateMessageBoardForm,
  IKeepMessage,
  IReadMessage,
  IRemoveMessage,
  IUserContentsLoad,
} from '@/interfaces/userContents'
import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'

export async function findAllBoardByMeAuth(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(`/article-elements/me/boards?start=0&limit=${limit}`)
  return response.data
}

export async function findAllCommentByMeAuth(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(
    `/article-elements/me/comments?start=0&limit=${limit}`,
  )
  return response.data
}

export async function findAllGoodByMeAuth(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(`/article-elements/me/good?start=0&limit=${limit}`)
  return response.data
}

export async function findAllScrapByMeAuth(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(`/article-elements/me/scrap?start=0&limit=${limit}`)
  return response.data
}

export async function findAllMemberSubByMeAuth(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(
    `/article-elements/me/membersub?start=0&limit=${limit}`,
  )
  return response.data
}

export async function findAllMessageBoardByMe(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(`/message-boards/me/all?start=0&limit=${limit}`)
  return response.data
}

export async function createMessage(form: {
  jwt: string
  createMessageBoardForm: ICreateMessageBoardForm
}) {
  const { jwt, createMessageBoardForm } = form
  await StrapiJwtAPI(jwt).post('/message-boards', createMessageBoardForm)
}

export async function readMessage(data: IReadMessage) {
  const { jwt, userId, readId } = data
  await StrapiJwtAPI(jwt).put(`/message-boards/read-time/${readId}`, { userId })
}

export async function removeMessage(data: IRemoveMessage) {
  const { jwt, removeId, removeTab } = data
  let removeData = null
  switch (removeTab) {
    case 'FROM':
      removeData = {
        is_from_delete: true,
      }
      break
    case 'TO':
      removeData = {
        is_to_delete: true,
      }
      break
    case 'KEEP':
      removeData = {
        is_keep: false,
      }
      break
  }

  if (typeof removeId === 'number') {
    await StrapiJwtAPI(jwt).put(`/message-boards/${removeId}`, removeData)
  } else {
    for (let i = 0; i < removeId.length; i++) {
      await StrapiJwtAPI(jwt).put(`/message-boards/${removeId[i]}`, removeData)
    }
  }
}

export async function keepMessage(data: IKeepMessage) {
  const { jwt, keepId } = data

  if (typeof keepId === 'number') {
    await StrapiJwtAPI(jwt).put(`/message-boards/${keepId}`, { is_keep: true, is_to_delete: true })
  } else {
    for (let i = 0; i < keepId.length; i++) {
      await StrapiJwtAPI(jwt).put(`/message-boards/${keepId[i]}`, {
        is_keep: true,
        is_to_delete: true,
      })
    }
  }
}

export async function findNoReadMessageCountByUserId(data: { id: number; jwt: string }) {
  const { id, jwt } = data
  const response = await StrapiJwtAPI(jwt).get(`/message-boards/count?to_user=${id}`)
  return response.data.rows[0].count
}

export async function findAllBoardByAnotherUser(data: IAnotherUserLoad) {
  const { id, start, limit } = data
  const response = await StrapiAPI.get(
    `/article-elements/user/${id}/boards?start=${start}&limit=${limit}`,
  )
  return response.data
}

export async function findAllCommentByAnotherUser(data: IAnotherUserLoad) {
  const { id, start, limit } = data
  const response = await StrapiAPI.get(
    `/article-elements/user/${id}/comments?start=${start}&limit=${limit}`,
  )
  return response.data
}

export async function findAllGoodByAnotherUser(data: IAnotherUserLoad) {
  const { id, start, limit } = data
  const response = await StrapiAPI.get(
    `/article-elements/user/${id}/good?start=${start}&limit=${limit}`,
  )
  return response.data
}

export async function findAnotherUser(props: { jwt: string; id: number }) {
  const { jwt, id } = props
  const response = await StrapiJwtAPI(jwt).get(`/users/${id}`)
  return response.data
}

export async function updateVisitorAnotherUser(id: number) {
  await StrapiAPI.put(`/article-elements/user/${id}`)
}

export async function findAllBoardSubByMeAuth(data: IUserContentsLoad) {
  const { jwt, limit } = data
  const response = await StrapiJwtAPI(jwt).get(
    `/article-elements/me/boardsub?start=0&limit=${limit}`,
  )
  return response.data
}
