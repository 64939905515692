import { Box, Text } from 'native-base'

interface Props {
  title: string
}

export default function SelectBoardItem(props: Props) {
  const { title } = props

  return (
    <Box w='120px' h='40px' alignItems='center' my='7px' bgColor='#f4f4f4' borderRadius='20px'>
      <Text my='auto' fontSize='16px' fontWeight='bold'>
        {title}
      </Text>
    </Box>
  )
}
