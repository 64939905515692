import React, { useState } from 'react'
import { Box, Input, VStack, Text, View, Center, Icon, HStack } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import Animated from 'react-native-reanimated'
// import useScroll from '@/hooks/useScroll'
import { AuthHeader, LargeButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK } from '@/constants/label'
import { AuthStackParamList } from '@/interfaces/navigation'
import { IReset } from '@/interfaces/auth'
import { Pressable, TextInput } from 'react-native'
import { resetPwAuth } from '@/services/auth'
import ErrorMessageBox from './components/ErrorMessageBox'
import FastImage from '@/components/FastImage'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_RESET_PW>

export default function ResetPwScreen({ navigation, route }: Props) {
  const [isLoading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [dataSourceCords, setDataSourceCords] = React.useState<{ key: number; scrollY: number }[]>(
    [],
  )
  const scrollRef = React.useRef<any>(null)
  const passwordInputRef = React.useRef<TextInput>(null)
  const password2InputRef = React.useRef<TextInput>(null)

  // const { scrollHandler } = useScroll()
  //패스워드 보이기,숨기기
  const [showPswd, setShowPswd] = useState<boolean>(true)
  const toggleShowPswd = () => {
    setShowPswd(!showPswd)
  }

  const findScrollYByKey = (key: number) => {
    const index = dataSourceCords.findIndex((item) => item.key === key)
    return index > -1 ? dataSourceCords[index].scrollY : 0
  }

  const scrollToHandler = (scrollY: number) => {
    if (scrollRef.current) {
      scrollRef.current?.scrollTo({
        x: 0,
        y: scrollY + 130, //we get the offset value from array based on key
        animated: true,
      })
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<IReset>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
      code: route.params.code ? route.params.code : '',
    },
  })

  const onSubmit = async (data: IReset) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/

    if (data.password !== data.passwordConfirmation) {
      setErrorMessage('입력하신 패스워드와 패스워드 확인이 일치하지 않습니다.')
    } else if (!passwordRegex.test(data.password)) {
      setErrorMessage('패스워드는 영문자, 숫자, 특수문자를 포함한\n8자리 이상으로 입력해주세요.')
    } else {
      setLoading(true)
      await resetPwAuth(data)
        .then((response) => {
          setLoading(false)
          return navigation.push(AUTH_STACK.AUTH_RESET_PW_COMPLETE)
        })
        .catch(() => {
          setErrorMessage('서버에 문제가 발생하였습니다.')
          return setLoading(false)
        })
    }
  }

  return (
    <React.Fragment>
      <AuthStackHeader title='비밀번호 재설정' navigation={navigation} />
      <Animated.ScrollView
        contentContainerStyle={{ marginTop: 50, paddingHorizontal: 50 }}
        showsVerticalScrollIndicator={false}
        // scrollEventThrottle={16}
        // onScroll={scrollHandler}
      >
        <AuthHeader>
          <VStack>
            <Text fontSize='15px' color='#959595'>
              변경하실 비밀번호를
            </Text>
            <Text fontSize='15px' color='#959595'>
              입력해주세요.
            </Text>
          </VStack>
        </AuthHeader>
        <VStack
          key={2}
          onLayout={(e) => {
            const layout = e.nativeEvent.layout
            setDataSourceCords([...dataSourceCords, { key: 2, scrollY: layout.y }])
          }}
        >
          <Text fontSize='15px' fontWeight='bold' color='#ff6f1e' mb='5px'>
            비밀번호 재설정 (영문, 숫자, 기호 혼합)
          </Text>
          <View flexDirection='row' justifyContent='flex-end' flex={1} alignItems='center'>
            <Controller
              control={control}
              name='password'
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  flex={1}
                  ref={passwordInputRef}
                  placeholder='8자리 이상 입력해주세요.'
                  width='full'
                  height='40px'
                  borderColor='#F4F4F4'
                  backgroundColor='#F4F4F4'
                  borderRadius='3px'
                  placeholderTextColor='#5B5B5B'
                  mb='10px'
                  pl='10px'
                  fontSize='15px'
                  secureTextEntry={showPswd}
                  maxLength={32}
                  onChangeText={(value) => {
                    onChange(value)
                    formClearErrors()
                    setErrorMessage('')
                  }}
                  onBlur={onBlur}
                  value={value}
                  autoCapitalize='none'
                  autoComplete='password'
                  autoCorrect={false}
                  blurOnSubmit={false}
                  onSubmitEditing={() => {
                    password2InputRef.current?.focus()
                  }}
                  InputRightElement={
                    <Pressable onPress={() => setShowPswd(!showPswd)}>
                      <HStack mx='8px'>
                        <FastImage
                          source={
                            showPswd
                              ? require('@/assets/images/Eye-Open.png')
                              : require('@/assets/images/Eye-Closed.png')
                          }
                          style={{
                            width: 24,
                            height: 24,
                          }}
                          resizeMode='contain'
                        />
                      </HStack>
                    </Pressable>
                  }
                />
              )}
            />
          </View>
        </VStack>
        <VStack
          key={3}
          onLayout={(e) => {
            const layout = e.nativeEvent.layout
            setDataSourceCords([...dataSourceCords, { key: 3, scrollY: layout.y }])
          }}
        >
          <View flexDirection='row' justifyContent='flex-end' flex={1} alignItems='center'>
            <Controller
              control={control}
              name='passwordConfirmation'
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  flex={1}
                  ref={password2InputRef}
                  placeholder='한번 더 입력해주세요.'
                  width='full'
                  height='40px'
                  borderColor='#F4F4F4'
                  backgroundColor='#F4F4F4'
                  borderRadius='3px'
                  placeholderTextColor='#5B5B5B'
                  mb='16px'
                  pl='10px'
                  fontSize='15px'
                  secureTextEntry={showPswd}
                  maxLength={32}
                  onChangeText={(value) => {
                    onChange(value)
                    formClearErrors()
                    setErrorMessage('')
                  }}
                  onBlur={onBlur}
                  value={value}
                  autoCapitalize='none'
                  autoComplete='password'
                  autoCorrect={false}
                  InputRightElement={
                    <Pressable onPress={() => setShowPswd(!showPswd)}>
                      <HStack mx='8px'>
                        <FastImage
                          source={
                            showPswd
                              ? require('@/assets/images/Eye-Open.png')
                              : require('@/assets/images/Eye-Closed.png')
                          }
                          style={{
                            width: 24,
                            height: 24,
                          }}
                          resizeMode='contain'
                        />
                      </HStack>
                    </Pressable>
                  }
                />
              )}
            />
          </View>
        </VStack>
        {formErrors.password ? (
          <ErrorMessageBox errorMessage='패스워드를 입력해주세요.'></ErrorMessageBox>
        ) : formErrors.passwordConfirmation ? (
          <ErrorMessageBox errorMessage='패스워드 확인을 입력해주세요.'></ErrorMessageBox>
        ) : errorMessage ? (
          <ErrorMessageBox errorMessage={errorMessage}></ErrorMessageBox>
        ) : null}
        <Box mb='20px'>
          <LargeButton
            buttonTitle='비밀번호 재설정'
            fill
            onPress={handleSubmit(onSubmit)}
            loading={isLoading}
          />
        </Box>
      </Animated.ScrollView>
    </React.Fragment>
  )
}
