import React from 'react'
import { Box, Text } from 'native-base'
import CButton from '@/components/CButton'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { totalCountAtom } from '@/atom/navigationAtom'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import { AUTH_STACK, MY_PAGE_TAB, ROOT_STACK } from '@/constants/label'

interface Props {
  navigation: any
}

export default function SubscriptButton({ navigation }: Props) {
  const [auth, setAuth] = useAtom(authAtom)
  const [totalCount] = useAtom(totalCountAtom)

  return auth.logged ? (
    totalCount > 0 ? (
      <CButton
        mt='1px'
        position='relative'
        my='auto'
        onPress={() => {
          navigation.navigate(ROOT_STACK.MY_PAGE_TAB, { screen: MY_PAGE_TAB.MY_PAGE_SUBSCRIPTION })
        }}
      >
        <DynamicSvgIcon width='34px' height='34px' src={require('@/assets/svg/mypage-sub.svg')} />
        <div className='notranslate'>
          <Box
            position='absolute'
            top='0px'
            right='0px'
            zIndex={20}
            borderRadius={8}
            backgroundColor={totalCount > 0 ? '#ff6f1e' : '#DADADA'}
            w='16px'
            h='16px'
            alignItems='center'
          >
            <Text
              pt='1px'
              pl='1px'
              fontSize='10px'
              fontWeight='bold'
              color={totalCount > 0 ? '#ffffff' : '#000000'}
            >
              {totalCount > 99 ? '99+' : totalCount}
            </Text>
          </Box>
        </div>
      </CButton>
    ) : (
      <CButton
        my='auto'
        onPress={() => {
          navigation.navigate(ROOT_STACK.MY_PAGE_TAB, { screen: MY_PAGE_TAB.MY_PAGE_SUBSCRIPTION })
        }}
      >
        <DynamicSvgIcon width='34px' height='34px' src={require('@/assets/svg/mypage-sub.svg')} />
      </CButton>
    )
  ) : (
    <CButton
      my='auto'
      onPress={() => {
        navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      }}
    >
      <DynamicSvgIcon width='34px' height='34px' src={require('@/assets/svg/mypage-sub.svg')} />
    </CButton>
  )
}
