import { Center, HStack, Text } from 'native-base'

import CButton from '@/components/CButton'

interface Props {
  firstButtonText: string
  secondButtonText: string
  firstButtonFill?: boolean
  secondButtonFill?: boolean
  firstButtonOnPress?: () => void
  secondButtonOnPress?: () => void
}

export default function DualButton(props: Props) {
  const {
    firstButtonText,
    secondButtonText,
    firstButtonFill,
    secondButtonFill,
    firstButtonOnPress,
    secondButtonOnPress,
  } = props
  return (
    <HStack space='10px'>
      <CButton onPress={firstButtonOnPress}>
        <Center
          w='160px'
          h='48px'
          bgColor={firstButtonFill ? '#ff6f1e' : '#ffffff'}
          borderColor='#ff6f1e'
          borderWidth='1px'
          borderRadius='23px'
          mb='10px'
        >
          <Text fontSize='20px' color={firstButtonFill ? '#ffffff' : '#ff6f1e'} fontWeight={600}>
            {firstButtonText}
          </Text>
        </Center>
      </CButton>
      <CButton onPress={secondButtonOnPress}>
        <Center
          w='160px'
          h='48px'
          bgColor={secondButtonFill ? '#ff6f1e' : '#ffffff'}
          borderColor='#ff6f1e'
          borderWidth='1px'
          borderRadius='23px'
          mb='10px'
        >
          <Text fontSize='20px' color={secondButtonFill ? '#ffffff' : '#ff6f1e'} fontWeight={700}>
            {secondButtonText}
          </Text>
        </Center>
      </CButton>
    </HStack>
  )
}
