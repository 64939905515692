import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { AUTH_STACK } from '@/constants/label'
import { AuthStackParamList } from '@/interfaces/navigation'
import ResetPwCompleteScreen from '@/screens/auth/ResetPwCompleteScreen'
import RegisterScreen from '@/screens/auth/RegisterScreen'
import AuthenticationScreen from '@/screens/auth/AuthenticationScreen'
import ProfileScreen from '@/screens/auth/ProfileScreen'
import RegisterCompleteScreen from '@/screens/auth/RegisterCompleteScreen'
import ResetPwScreen from '@/screens/auth/ResetPwScreen'
import ForgotPwCompleteScreen from '@/screens/auth/ForgotIdCompleteScreen'
import ForgotPwScreen from '@/screens/auth/ForgotPwScreen'
import SignInScreen from '@/screens/auth/SignInScreen'
import AgreeToTerms from '@/screens/auth/AgreeToTerms'

const Stack = createNativeStackNavigator<AuthStackParamList>()

export default function AuthNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AUTH_STACK.AUTH_SIGN_IN}
      screenOptions={{ contentStyle: { backgroundColor: '#ffffff' } }}
    >
      <Stack.Screen
        name={AUTH_STACK.AUTH_SIGN_IN}
        component={SignInScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_FORGOT_PW}
        component={ForgotPwScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_FORGOT_PW_COMPLETE}
        component={ForgotPwCompleteScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_REGISTER}
        component={RegisterScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_AUTHENTICATION}
        component={AuthenticationScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_PROFILE}
        component={ProfileScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_REGISTER_COMPLETE}
        component={RegisterCompleteScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_RESET_PW}
        component={ResetPwScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_RESET_PW_COMPLETE}
        component={ResetPwCompleteScreen}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={AUTH_STACK.AUTH_AGREE_TO_TERMS}
        component={AgreeToTerms}
        options={{
          headerBackVisible: false,
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  )
}
