import React from 'react'
import { RefreshControl } from 'react-native'
import { Box, VStack } from 'native-base'
import { useNavigation, useScrollToTop } from '@react-navigation/native'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import {
  anotherUserGoodListAtom,
  anotherUserGoodTotalCountAtom,
  anotherUserIdAtom,
} from '@/atom/userPageAtom'
import { findAllGoodByAnotherUser } from '@/services/userContents'
import MoreTab from '@/components/MoreTab'
import { LIMIT } from '@/constants/limit'
import { TouchableHighlight } from 'react-native-gesture-handler'
import MyPageBoardHeader from '../my-page/components/MyPageBoardHeader'
import MyPageBoardTitle from '../my-page/components/MyPageBoardTitle'
import MyPageBoardContents from '../my-page/components/MyPageBoardContents'
import MyPageBoardBlank from '../my-page/components/MyPageBoardBlank'
import { CATEGORY_LABEL } from '@/constants/site'
import { findById } from '@/services/banner'
import { ROOT_STACK } from '@/constants/label'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'

export default function UserPageGoodScreen() {
  const [userId] = useAtom(anotherUserIdAtom)
  const [list, setList] = useAtom(anotherUserGoodListAtom)
  const [totalCount, setTotalCount] = useAtom(anotherUserGoodTotalCountAtom)
  const [limit, setLimit] = React.useState(LIMIT.USER_PAGE_LIST)
  const [refreshing, setRefreshing] = React.useState(false)
  const navigation_main = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  // const { scrollHandler } = useScroll()

  const onPageChange = async (board: any) => {
    if (typeof board === 'number') {
      return null
    }

    const board_category = CATEGORY_LABEL.find((item) => item.id === board.category)
    if (board.type == 'board' && board_category) {
      navigation_main.push(ROOT_STACK.BOARD_DETAIL, {
        id: board.id,
        category: board.category,
      })
    } else if (board.type == 'news') {
      navigation_main.push(ROOT_STACK.NEWS_DETAIL, { id: board.id })
    } else {
      const banner = await findById(board.category)
      navigation_main.push(ROOT_STACK.BANNER_BOARD_DETAIL, {
        id: board.id,
        category: board.category,
        banner: banner,
      })
    }
  }

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    await findAllGoodByAnotherUser({ id: userId, start: 0, limit }).then((data) => {
      setList(data.contents)
      setTotalCount(data.totalCount)
    })
    await setRefreshing(false)
  }, [userId, limit, setList, setTotalCount])

  const onMore = React.useCallback(async () => {
    const newLimit = limit + LIMIT.USER_PAGE_LIST
    await findAllGoodByAnotherUser({ id: userId, start: 0, limit }).then((data) => {
      setList(data.contents)
      setTotalCount(data.totalCount)
      setLimit(newLimit)
    })
  }, [userId, limit, setList, setTotalCount])

  return (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      contentContainerStyle={{ flexGrow: 1, paddingBottom: 70, backgroundColor: '#ffffff' }}
      showsVerticalScrollIndicator={false}
      // scrollEventThrottle={16}
      // onScroll={scrollHandler}
    >
      {list.length ? (
        list.map((item) => {
          return (
            <TouchableHighlight
              key={
                item.type == 'board'
                  ? 'board' + item.id
                  : item.type == 'advertisement'
                  ? 'advertisement' + item.id
                  : 'new' + item.id
              }
              onPress={() => onPageChange(item)}
              underlayColor='#e9e9e9'
            >
              <VStack py='16px' px='16px' borderColor='#F2F2F2' borderBottomWidth='8px'>
                <MyPageBoardHeader
                  board={item}
                  screen={'UserPageGoodScreen'}
                  onHandleFunc={onPageChange}
                />
                <MyPageBoardTitle board={item} />
                <MyPageBoardContents board={item} screen={'MyPageGoodScreen'} />
              </VStack>
            </TouchableHighlight>
          )
        })
      ) : (
        <MyPageBoardBlank title='좋아요한 글이 없습니다.' screen={'MyPageGoodScreen'} />
      )}
      {totalCount > list.length ? (
        <Box bg='#ffffff' pb='30px'>
          <MoreTab moreButtonText='더보기' moreButtonOnPress={onMore} useSideButton={false} />
        </Box>
      ) : null}
    </Animated.ScrollView>
  )
}
