import React from 'react'
import { Box, HStack, Spinner, Text } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'

import { useAtom } from 'jotai'
import { searchNewsListAtom, handleSearch, handleSearchResultClear } from '@/atom/searchAtom'

// import useScroll from '@/hooks/useScroll'
import { SearchHeader, SearchResultNewsItem } from '@/screens/search/components'
import MoreTab from '@/components/MoreTab'
import { LIMIT } from '@/constants/limit'
import { SEARCH_STACK } from '@/constants/label'
import { SearchStackParamList } from '@/interfaces/navigation'

type Props = NativeStackScreenProps<SearchStackParamList, SEARCH_STACK.SEARCH_NEWS>

export default function SearchNewsScreen({ route }: Props) {
  const { keyword } = route.params
  const [, search] = useAtom(handleSearch)
  const [, searchResultClear] = useAtom(handleSearchResultClear)
  const [searchNewsList] = useAtom(searchNewsListAtom)
  const [isLoading, setLoading] = React.useState(false)
  const [searchNewsLimit, setSearchNewsLimit] = React.useState(LIMIT.SEARCH_NEWS_LIST_LIMIT)

  // const { scrollHandler } = useScroll()

  const handleMore = React.useCallback(async () => {
    const newLimit = searchNewsLimit + LIMIT.SEARCH_NEWS_LIST_LIMIT
    await search({
      keyword,
      start: 0,
      limit: newLimit,
    })
    await setSearchNewsLimit(newLimit)
  }, [searchNewsLimit, keyword, search])

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await searchResultClear()
      await search({
        keyword,
        start: 0,
        limit: LIMIT.SEARCH_NEWS_LIST_LIMIT,
      })
      await setLoading(false)
    }
    void fetchData()
  }, [keyword, search, searchResultClear])

  return (
    <React.Fragment>
      <SearchHeader />
      {isLoading ? (
        <Box flex={1} justifyContent='center'>
          <Spinner color='#ff6f1e' />
        </Box>
      ) : !isLoading && searchNewsList ? (
        <Animated.FlatList
          data={searchNewsList.contents}
          listKey='searchNewsList'
          keyExtractor={(item) => item.id.toString()}
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 100, marginVertical: 15 }}
          renderItem={({ item }) => {
            return <SearchResultNewsItem item={item} />
          }}
          ListHeaderComponent={() => {
            return (
              <HStack px='15px' pb='15px'>
                <Text fontWeight='bold'>'{keyword}'</Text>
                <Text> 에 대한 뉴스 검색결과</Text>
              </HStack>
            )
          }}
          ListFooterComponent={() => {
            return searchNewsList.totalCount > searchNewsList.contents.length ? (
              <MoreTab
                moreButtonTextColor='뉴스'
                moreButtonText='더보기'
                moreButtonOnPress={handleMore}
                useSideButton={false}
              />
            ) : null
          }}
          showsVerticalScrollIndicator={false}
          // scrollEventThrottle={16}
          // onScroll={scrollHandler}
        />
      ) : null}
    </React.Fragment>
  )
}
