import axios from 'axios'
import { Env } from '@/constants/env'

export const StrapiAPI = axios.create({
  baseURL: Env.STRAPI_API_URL,
  headers: {
    'Contents-Type': 'application/json;charset=utf-8',
  },
})

export const StrapiJwtAPI = (jwt: string) =>
  axios.create({
    baseURL: Env.STRAPI_API_URL,
    headers: {
      'Contents-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${jwt}`,
    },
  })
