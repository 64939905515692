import { atom } from 'jotai'
import { Dimensions } from 'react-native'
import { authAtom } from '@/atom/authAtom'
import { findNoReadMessageCountByUserId } from '@/services/userContents'
import { SubCount } from '@/services/auth'

export const screenWidthAtom = atom<number>(Math.round(Dimensions.get('window').width))
export const screenHeightAtom = atom<number>(Math.round(Dimensions.get('window').height))

export const isOpenTopAuthMenuAtom = atom<boolean>(false)
export const showBarAtom = atom<boolean>(true)

///마이페이지 메세지 카운트
export const noReadMessageCountAtom = atom<number>(0)
export const reloadNoReadMessageCountAtom = atom(null, async (get, set) => {
  const auth = get(authAtom)
  if (auth && auth.user) {
    const response = await findNoReadMessageCountByUserId({ id: auth.user.id, jwt: auth.jwt })
    set(noReadMessageCountAtom, response)
  } else {
    set(noReadMessageCountAtom, 0)
  }
})

export const memberCountAtom = atom<number>(0)
export const boardCountAtom = atom<number>(0)
export const totalCountAtom = atom<number>(0)

export const reloadReadStateAtom = atom(null, async (get, set) => {
  const auth = get(authAtom)
  if (auth && auth.user) {
    const response = await SubCount(auth.jwt)
    set(memberCountAtom, response.memberCount)
    set(boardCountAtom, response.boardCount)
    set(totalCountAtom, Number(response.memberCount) + Number(response.boardCount))
  }
})

export const slideTouchFlagAtom = atom<boolean>(false)
/**************************/
