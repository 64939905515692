import React from 'react'
import { Box, HStack, Text } from 'native-base'
import useFormatter from '@/hooks/useFormatter'
import NotifyIcon from '@/assets/svgIcon/NotifyIcon'
import RequiredIcon from '@/assets/svgIcon/RequiredIcon'
import NewIcon from '@/assets/svgIcon/NewIcon'
import ExpireIcon from '@/assets/svgIcon/ExpireIcon'

interface Props {
  board: any
  screen?: string
}

const MyPageBoardTitle: React.FC<Props> = (props: Props) => {
  const { board, screen } = props
  const { compareIsBeforeDate, compareTodayDate, extractColorCode } = useFormatter()

  // 이미지가 표시되어야 하는지 여부 확인
  const showNewIcon = compareTodayDate(board.created_at) && !board.isblock
  const showNoticeIcon =
    board.writing_type && board.writing_type.trim() === '공지' && !board.isblock
  const showRequiredIcon =
    board.writing_type && board.writing_type.trim() === '필독' && !board.isblock
  const showExpiredIcon = compareIsBeforeDate(board.board_expired_date) && !board.isblock
  const extractedColor = extractColorCode(board.color_type)

  return (
    <Box>
      <HStack position='relative'>
        <Text
          numberOfLines={2}
          ellipsizeMode='tail'
          fontSize='18px'
          fontWeight='bold'
          lineHeight='22px'
          color={extractedColor}
        >
          {showExpiredIcon && (
            <Box mr='4px' top='3px'>
              <ExpireIcon width='35' height='20' fillColor='#000000' />
            </Box>
          )}
          {showNoticeIcon && (
            <Box mr='4px' top='3px'>
              <NotifyIcon width='35' height='20' fillColor={extractedColor} />
            </Box>
          )}
          {showRequiredIcon && (
            <Box mr='4px' top='3px'>
              <RequiredIcon width='35' height='20' fillColor={extractedColor} />
            </Box>
          )}
          {showNewIcon && (
            <Box mr='4px' top='2px'>
              <NewIcon width='16' height='16' fillColor='#FF6F1E' />
            </Box>
          )}
          {screen && screen == 'MyPageCommentScreen' ? board.contents : board.title}
        </Text>
      </HStack>
      {screen && screen == 'MyPageCommentScreen' ? (
        <Box mt='4px'>
          <Text fontSize='14px' numberOfLines={2} ellipsizeMode='tail'>
            {board.title}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}

export default MyPageBoardTitle
