import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function FabTextIcon() {
  return (
    <Svg width='36' height='12' viewBox='0 0 36 12' fill='none'>
      <Path
        d='M1.716 1.4H8.76V2.66H1.716V1.4ZM0.492 4.868H10.548V6.128H0.492V4.868ZM7.8 1.4H9.372V2.156C9.372 2.948 9.372 3.956 9.084 5.24L7.512 5.096C7.8 3.836 7.8 2.936 7.8 2.156V1.4ZM1.608 6.884H9.336V10.016H3.216V11.144H1.62V8.84H7.752V8.12H1.608V6.884ZM1.62 10.76H9.624V11.996H1.62V10.76ZM13.5351 1.748H14.7471V3.008C14.7471 4.916 14.0511 6.764 12.2751 7.532L11.4591 6.356C12.9591 5.696 13.5351 4.304 13.5351 3.008V1.748ZM13.9191 1.748H15.1311V3.008C15.1311 4.28 15.6711 5.732 17.2191 6.428L16.5351 7.7C14.5911 6.896 13.9191 4.892 13.9191 3.008V1.748ZM17.9511 1.748H19.1511V3.008C19.1511 4.916 18.4791 6.908 16.5351 7.7L15.8631 6.428C17.3991 5.744 17.9511 4.304 17.9511 3.008V1.748ZM18.3471 1.748H19.5591V3.008C19.5591 4.352 20.1231 5.708 21.6351 6.356L20.8071 7.532C19.0311 6.776 18.3471 4.964 18.3471 3.008V1.748ZM11.5191 9.404H21.5751V10.7H11.5191V9.404ZM30.2141 0.956H31.8221V12.068H30.2141V0.956ZM26.9381 2.108H28.5221C28.5221 5.504 27.3701 8.288 23.4221 10.196L22.5941 8.924C25.7861 7.388 26.9381 5.372 26.9381 2.396V2.108ZM23.1821 2.108H27.6941V3.368H23.1821V2.108Z'
        fill='white'
      />
    </Svg>
  )
}
