import { TouchableHighlight } from 'react-native'
import { Box, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import useUrl from '@/hooks/useUrl'
import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IYonhapNews } from '@/interfaces/news'

interface Props {
  item: IYonhapNews
}

export default function MainNewsImage(props: Props) {
  const { item } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { handleUrl } = useUrl()

  return (
    <TouchableHighlight
      onPress={() => {
        navigation.push(ROOT_STACK.NEWS_DETAIL, { id: item.id })
      }}
      underlayColor='#e9e9e9'
    >
      <Box w={(screenWidth - 45) / 2} alignItems='center'>
        <FastImage
          source={{
            uri: item.thumbnail
              ? handleUrl(item.thumbnail.url)
              : item.thumbnail_path
              ? handleUrl(item.thumbnail_path)
              : '',
          }}
          style={{
            width: (screenWidth - 45) / 2,
            height: 'auto',
            borderRadius: 5,
            aspectRatio: 33 / 26,
          }}
          resizeMode='cover'
        />
        <Text lineBreakMode='tail' numberOfLines={2} px='1px' mt='5px' fontSize={16}>
          {item.title}
        </Text>
      </Box>
    </TouchableHighlight>
  )
}
