import React from 'react'
import { TouchableHighlight } from 'react-native'
import { VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { authAtom } from '@/atom/authAtom'
import { useAtom } from 'jotai'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IUserContentsMemberSubscription } from '@/interfaces/userContents'
import { findById } from '@/services/banner'
import { IBanner } from '@/interfaces/banner'
import MySubMemberModal from '@/components/modal/MySubMemberModal'
import MyPageBoardHeader from './MyPageBoardHeader'
import MyPageBoardTitle from './MyPageBoardTitle'
import MyPageBoardContents from './MyPageBoardContents'

interface Props {
  item: IUserContentsMemberSubscription
}

export default function MyPageBannerMemberSubItem(props: Props) {
  const { item } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)
  const [banner, setBanner] = React.useState<IBanner | null>(null)
  const [isBoardOpen, setBoardOpen] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      await findById(item.category).then((data) => {
        setBanner(data)
      })
    }
    fetchData()
  }, [auth])

  const moveDetail = async (goComment: boolean) => {
    if (item) {
      if (item.type === 'board') {
        navigation.push(ROOT_STACK.BOARD_DETAIL, {
          id: item.id,
          category: item.category,
          goComment,
        })
      } else if (item.type === 'advertisement') {
        banner
          ? navigation.push(ROOT_STACK.BANNER_BOARD_DETAIL, {
              id: item.id,
              category: item.category,
              banner: banner,
            })
          : null
      }
    }
  }

  const openMenuModal = (val: string) => {
    setBoardOpen(true)
  }

  const closeMenuModal = () => {
    setBoardOpen(false)
  }

  return banner ? (
    <React.Fragment>
      {isBoardOpen && (
        <MySubMemberModal
          subId={item.id}
          writerId={item.writer}
          userNickName={item.nick_name}
          handleCloseModal={closeMenuModal}
        />
      )}
      <TouchableHighlight onPress={() => moveDetail(false)} underlayColor='#e9e9e9'>
        <VStack
          key={
            item.type == 'board'
              ? 'board' + item.id
              : item.type == 'advertisement'
              ? 'advertisement' + item.id
              : 'new' + item.id
          }
          py='16px'
          px='16px'
          borderColor='#F2F2F2'
          borderBottomWidth='8px'
        >
          <MyPageBoardHeader
            board={item}
            screen={'MyPageBannerBoardSubItem'}
            onHandleFunc={openMenuModal}
          />
          <MyPageBoardTitle board={item} />
          <MyPageBoardContents board={item} screen={'MyPageBannerBoardSubItem'} />
        </VStack>
      </TouchableHighlight>
    </React.Fragment>
  ) : null
}
