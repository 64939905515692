import React from 'react'
import { Box, Modal, Text } from 'native-base'
import { ROOT_STACK } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import CButton from '../CButton'
import { IBoard } from '@/interfaces/board'

interface Props {
  userId: number
  userNickName: string
  type: 'BOARD' | 'COMMENT'
  isBlock: boolean
  board: IBoard
  handleCloseModal: () => void
  openModal: (value: boolean) => void
}

export default function BoardMenuModal(props: Props) {
  const { userId, userNickName, isBlock, board, handleCloseModal, openModal } = props
  const [isMenuOpen, setIsMenuOpen] = React.useState(true)
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const closeUserMenu = () => {
    handleCloseModal()
  }

  const goToDeleteBoard = () => {
    closeUserMenu()
    openModal(true)
  }

  const goToUpdateBoard = () => {
    closeUserMenu()
    navigation.push(ROOT_STACK.BOARD_UPDATE, { board })
  }

  const styleObj = {
    backgroundColor: '#ECECEC',
    borderRadius: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 14,
  }

  return (
    <React.Fragment>
      <Modal isOpen={isMenuOpen} onClose={() => closeUserMenu()}>
        <Modal.Content w={screenWidth - 80} borderRadius='5px'>
          <Modal.Body borderBottomWidth={0} pb={2}>
            <Box>
              <CButton style={styleObj} onPress={goToUpdateBoard}>
                <Text
                  textAlign='center'
                  fontSize='16px'
                  fontWeight={700}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  수정하기
                </Text>
              </CButton>
              <CButton style={styleObj} onPress={goToDeleteBoard}>
                <Text
                  textAlign='center'
                  fontSize='16px'
                  fontWeight={700}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  삭제하기
                </Text>
              </CButton>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
