import React from 'react'
import { Box, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { createBlockUser, removeBlockUser } from '@/services/blockUser'
import CreateModal from '@/components/CreateModal'
import CButton from '@/components/CButton'
import { AUTH_STACK, MODAL_TYPE, MY_PAGE_TAB, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { screenWidthAtom } from '@/atom/navigationAtom'
import UserMenuModal from './modal/UserMenuModal'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import { getMemberAgree, sendMemberAgree, sendMemberDisagree } from '@/services/pushAgreeService'
import { ISendMemberPushForm } from '@/interfaces/push'

interface Props {
  userId: number
  userNickName: string
  isBlock: boolean
  type?: string
  blockCallback: () => void
  unblockCallback: () => void
}

export default function ModalUserMenu(props: Props) {
  const { userId, userNickName, isBlock, type, blockCallback, unblockCallback } = props
  const [auth] = useAtom(authAtom)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [modalType, setModalType] = React.useState(MODAL_TYPE.NONE)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [screenWidth] = useAtom(screenWidthAtom)
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  //나의 아이디인지 아닌지
  const [isMine, setMine] = React.useState(auth?.user?.id == userId ? true : false)
  //차단하기
  const [isMember, setMember] = React.useState(false)
  const [memberSub, setMemberSub] = React.useState<ISendMemberPushForm[] | null>(null)

  const openModal = (modalType: MODAL_TYPE) => {
    setIsMenuOpen(false)

    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    setModalType(modalType)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setModalType(MODAL_TYPE.NONE)
  }

  const closeMenuModal = () => {
    setIsOpenModal(false)
  }

  const doBlockUser = async () => {
    await createBlockUser({
      jwt: auth.jwt,
      userId: auth.user ? auth.user.id : 0,
      blockUserId: userId,
    })
    blockCallback()
    closeModal()
  }

  const doUnblockUser = async () => {
    await removeBlockUser({
      jwt: auth.jwt,
      userId: auth.user ? auth.user.id : 0,
      blockUserId: userId,
    })
    unblockCallback()
    closeModal()
  }

  const moveMyPage = () => {
    navigation.navigate(ROOT_STACK.MY_PAGE_TAB, {
      screen: MY_PAGE_TAB.MY_PAGE_BOARD,
    })
  }

  const memberAgree = async () => {
    if (auth.logged) {
      const ISendMemberPushForm: ISendMemberPushForm = {
        jwt: auth.jwt,
        user_id: auth.user ? auth.user.id : 0,
        member_id: userId,
      }
      const result = await sendMemberAgree(ISendMemberPushForm)
      setMemberSub([result])
      setMember(true)
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  const memberDisagree = async () => {
    if (auth.logged && memberSub) {
      if (memberSub[0].id) {
        const result = await sendMemberDisagree(auth.jwt, memberSub[0].id)
        if (result.id == memberSub[0].id) {
          setMemberSub(null)
          setMember(false)
        }
      }
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  React.useEffect(() => {
    const fetch = async () => {
      if (auth) {
        if (userId) {
          await Promise.all([
            await getMemberAgree(auth.jwt, auth.user ? auth.user.id : 0, userId),
          ]).then((values) => {
            setMember(values[0].length > 0 ? true : false)
            setMemberSub(values[0])
          })
        }
      }
    }
    void fetch()
  }, [])

  if (auth.user && userId === auth.user.id && isMenuOpen) {
    return (
      <CButton onPress={moveMyPage}>
        <Text
          w={screenWidth - 200}
          fontSize='16px'
          fontWeight={700}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {userNickName}
        </Text>
      </CButton>
    )
  }

  return (
    <React.Fragment>
      <HStack alignItems='center'>
        <CButton
          onPress={() => {
            if (!auth.logged) {
              navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
              return
            }
            if (!isMine) {
              setIsOpenModal(true)
            }
          }}
        >
          <Text
            mt='2px'
            mr='16px'
            fontSize='16px'
            fontWeight={700}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {userNickName}
          </Text>
        </CButton>
        {!isMine && !isBlock && !(type == 'COMMENT') && (
          <Box>
            {!isMember ? (
              <CButton
                onPress={() => {
                  memberAgree()
                }}
              >
                <HStack
                  px='16px'
                  h='24px'
                  alignItems='center'
                  bgColor='#FF6F1E33'
                  borderRadius='23px'
                >
                  <DynamicSvgIcon
                    width='12px'
                    height='12px'
                    src={require('@/assets/svg/bell-on.svg')}
                  />
                  <Text ml='4px' mt='2px' fontWeight='semibold' fontSize='13' color='#E85300'>
                    회원 구독
                  </Text>
                </HStack>
              </CButton>
            ) : (
              <CButton
                onPress={() => {
                  memberDisagree()
                }}
              >
                <HStack
                  px='16px'
                  h='24px'
                  alignItems='center'
                  bgColor='#F1F1F1'
                  borderRadius='23px'
                >
                  <DynamicSvgIcon
                    width='12px'
                    height='12px'
                    src={require('@/assets/svg/bell-off.svg')}
                  />
                  <Text ml='4px' mt='2px' fontWeight='semibold' fontSize='13' color='#000000'>
                    구독 취소
                  </Text>
                </HStack>
              </CButton>
            )}
          </Box>
        )}
        {isOpenModal && (
          <UserMenuModal
            userId={userId}
            userNickName={userNickName}
            isBlock={isBlock}
            isMember={isMember}
            handleCloseModal={closeMenuModal}
            openModal={openModal}
            memberAgree={memberAgree}
            memberDisagree={memberDisagree}
          />
        )}
        {isOpen && modalType === MODAL_TYPE.BLOCK_USER ? (
          <CreateModal
            modalType={MODAL_TYPE.BLOCK_USER}
            handleCloseModal={closeModal}
            onAccept={doBlockUser}
          />
        ) : null}
        {isOpen && modalType === MODAL_TYPE.UNBLOCK_USER ? (
          <CreateModal
            modalType={MODAL_TYPE.UNBLOCK_USER}
            handleCloseModal={closeModal}
            onAccept={doUnblockUser}
          />
        ) : null}
      </HStack>
    </React.Fragment>
  )
}
