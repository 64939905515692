import React, { useMemo, useState } from 'react'
import { Platform, TextStyle } from 'react-native'
import { Box, HStack, Text, VStack } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import * as WebBrowser from 'expo-web-browser'
import { useAtom } from 'jotai'
import { handleLogin } from '@/atom/authAtom'
import { LargeButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { AuthStackParamList } from '@/interfaces/navigation'
import { IRegister } from '@/interfaces/auth'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import RadioGroup from 'react-native-radio-buttons-group'
import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import ModalPolicyAgreement from '@/components/ModalPolicyAgreement'
import ModalServiceAgreement from '@/components/ModalServiceAgreement'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_AGREE_TO_TERMS>

WebBrowser.maybeCompleteAuthSession()

export default function AgreeToTerms({ navigation }: Props) {
  const [, login] = useAtom(handleLogin)
  const [isLoading, setLoading] = React.useState(false)
  const [isOpenPolicy, setOpenPolicy] = React.useState(false)
  const [isOpenService, setIsOpenService] = React.useState(false)

  const handleOpenPolicy = () => {
    isOpenPolicy ? setOpenPolicy(false) : setOpenPolicy(true)
  }
  const handleOpenService = () => {
    isOpenService ? setIsOpenService(false) : setIsOpenService(true)
  }

  const [terms, setTerms] = useState({
    terms: true,
    terms_of_service_agreement: true,
    privacy_policy_agreement: true,
    marketing_consent: true,
    email_consent: true,
  })

  const termsButtons = useMemo(
    () => [
      {
        id: '전체 동의하기',
        label: '전체 동의하기',
        value: '전체 동의하기',
        size: 18,
        labelStyle: {
          fontWeight: 'bold', // 적절한 값으로 수정
          fontSize: 20,
        } as TextStyle,
      },
    ],
    [],
  )

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<IRegister>({
    defaultValues: {
      terms_of_service_agreement: true,
      privacy_policy_agreement: true,
      marketing_consent: true,
      email_consent: true,
    },
  })

  const allTerms = () => {
    setTerms({
      terms: true,
      terms_of_service_agreement: true,
      privacy_policy_agreement: true,
      marketing_consent: true,
      email_consent: true,
    })
  }

  const marketingConsent = () => {
    if (terms.marketing_consent) {
      setTerms((prev) => ({
        ...prev,
        terms: false,
        marketing_consent: false,
      }))
    } else {
      if (terms.email_consent) {
        setTerms((prev) => ({
          ...prev,
          terms: true,
          marketing_consent: true,
        }))
      } else {
        setTerms((prev) => ({
          ...prev,
          marketing_consent: true,
        }))
      }
    }
  }

  const emailConsent = () => {
    if (terms.email_consent) {
      setTerms((prev) => ({
        ...prev,
        terms: false,
        email_consent: false,
      }))
    } else {
      if (terms.marketing_consent) {
        setTerms((prev) => ({
          ...prev,
          terms: true,
          email_consent: true,
        }))
      } else {
        setTerms((prev) => ({
          ...prev,
          email_consent: true,
        }))
      }
    }
  }

  const onSubmit = async (data: IRegister) => {
    navigation.push(AUTH_STACK.AUTH_REGISTER, {
      terms_of_service_agreement: terms.terms_of_service_agreement,
      privacy_policy_agreement: terms.privacy_policy_agreement,
      marketing_consent: terms.marketing_consent,
      email_consent: terms.email_consent,
    })
  }

  return (
    <React.Fragment>
      <ModalPolicyAgreement isOpen={isOpenPolicy} onClose={handleOpenPolicy} />
      <ModalServiceAgreement isOpen={isOpenService} onClose={handleOpenService} />
      <AuthStackHeader title='이용 약관 동의' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        keyboardShouldPersistTaps={'handled'}
      >
        <Box mb='20px' mx='10%'>
          <Box py='16px' mb='8px' borderBottomColor='#D9D9D9' borderBottomWidth={1}>
            <Controller
              control={control}
              name='terms_of_service_agreement'
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  radioButtons={termsButtons}
                  onPress={allTerms}
                  selectedId={terms.terms ? '전체 동의하기' : ''}
                  layout='row'
                />
              )}
            />
          </Box>
          <Box>
            <HStack mt='8px' pb='16px' mb='8px' borderBottomColor='#D9D9D9' borderBottomWidth={1}>
              <Box mr='4px' w='24px'>
                <FastImage
                  source={require('@/assets/images/check-orange.png')}
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  resizeMode='contain'
                />
              </Box>
              <VStack>
                <Text mt='2px' mb='8px' fontSize='md'>
                  [필수] 필수 제공 항목
                </Text>
                <Text color='#898A8D'>전체동의는 한터 서비스 동의를 포함하고 있</Text>
                <Text color='#898A8D'>습니다. 전체동의는 선택목적에 대한 동의를 </Text>
                <Text color='#898A8D'>포함하고 있으며, 선택목적에 대한 동의를 거</Text>
                <Text color='#898A8D'>부해도 서비스 이용이 가능합니다.</Text>
              </VStack>
            </HStack>
          </Box>
          <Box mt='8px' pb='16px' mb='16px' borderBottomColor='#D9D9D9' borderBottomWidth={1}>
            <Text fontSize='lg' fontWeight='bold'>
              한터 서비스 동의
            </Text>
            <VStack>
              <HStack mt='8px'>
                <Box mr='4px' w='24px'>
                  <FastImage
                    source={require('@/assets/images/check-orange.png')}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                    resizeMode='contain'
                  />
                </Box>
                <Box
                  w='270px' // 부모 컨테이너가 충분히 넓어야 함
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <Text mt='2px' mb='8px' fontSize='md'>
                    [필수] 서비스 이용약관 동의
                  </Text>
                  <CButton onPress={() => setIsOpenService(true)}>
                    <Text mt='2px' fontSize='sm' textDecorationLine='underline'>
                      보기
                    </Text>
                  </CButton>
                </Box>
              </HStack>
              <HStack mt='4px'>
                <Box mr='4px' w='24px'>
                  <FastImage
                    source={require('@/assets/images/check-orange.png')}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                    resizeMode='contain'
                  />
                </Box>
                <Box
                  w='270px' // 부모 컨테이너가 충분히 넓어야 함
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <Text mt='2px' mb='8px' fontSize='md'>
                    [필수] 개인정보 수집 및 이용 동의
                  </Text>
                  <CButton onPress={() => setOpenPolicy(true)}>
                    <Text mt='2px' fontSize='sm' textDecorationLine='underline'>
                      보기
                    </Text>
                  </CButton>
                </Box>
              </HStack>
              <CButton onPress={marketingConsent}>
                <HStack mt='4px'>
                  <Box mr='4px' w='24px'>
                    {terms.marketing_consent ? (
                      <FastImage
                        source={require('@/assets/images/check-orange.png')}
                        style={{
                          width: 24,
                          height: 24,
                        }}
                        resizeMode='contain'
                      />
                    ) : (
                      <FastImage
                        source={require('@/assets/images/check-gray.png')}
                        style={{
                          width: 24,
                          height: 24,
                        }}
                        resizeMode='contain'
                      />
                    )}
                  </Box>
                  <Text mt='2px' mb='8px' fontSize='md'>
                    [선택] 마케팅 정보 수신 동의
                  </Text>
                </HStack>
              </CButton>
              <CButton onPress={emailConsent}>
                <HStack mt='4px'>
                  <Box mr='4px' w='24px'>
                    {terms.email_consent ? (
                      <FastImage
                        source={require('@/assets/images/check-orange.png')}
                        style={{
                          width: 24,
                          height: 24,
                        }}
                        resizeMode='contain'
                      />
                    ) : (
                      <FastImage
                        source={require('@/assets/images/check-gray.png')}
                        style={{
                          width: 24,
                          height: 24,
                        }}
                        resizeMode='contain'
                      />
                    )}
                  </Box>
                  <Text mt='2px' mb='8px' fontSize='md'>
                    [선택] 이메일 수신
                  </Text>
                </HStack>
              </CButton>
            </VStack>
          </Box>
          <Box mt='8px'>
            <LargeButton
              buttonTitle='동의하기'
              loading={isLoading}
              onPress={handleSubmit(onSubmit)}
              fill
            />
          </Box>
        </Box>
        {/*</TouchableWithoutFeedback>*/}
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
