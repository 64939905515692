import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { popupStateAtom } from '@/atom/initAtom'
import TopTabNavigator from '@/navigation/TopTabNavigator'
import AuthNavigator from '@/navigation/AuthNavigator'
import SearchNavigator from '@/navigation/SearchNavigator'
import MyPageTabNavigator from '@/navigation/MyPageTabNavigator'
import UserPageTabNavigator from '@/navigation/UserPageNavigator'
import PopupBanner from '@/navigation/components/PopupBanner'
import NewsDetailScreen from '@/screens/detail/NewsDetailScreen'
import SendMessageScreen from '@/screens/send-message/SendMessageScreen'
import NewsPaperScreen from '@/screens/news-paper/NewsPaperScreen'
import NewsPaperDetailScreen from '@/screens/detail/NewsPaperDetailScreen'
import BannerDetailScreen from '@/screens/detail/BannerDetailScreen'
// import KyodoDetailScreen from '@/screens/detail/KyodoDetailScreen'
import BoardDetailScreen from '@/screens/detail/BoardDetailScreen'
import BannerCompanyDetailScreen from '@/screens/detail/BannerCompanyDetailScreen'
import AddressDetailScreen from '@/screens/boards/AddressDetailScreen'
import SimpleCreateBoardScreen from '@/screens/boards/SimpleCreateBoardScreen'
import SimpleUpdateBoardScreen from '@/screens/boards/SimpleUpdateBoardScreen'
import CompanyIntroScreen from '@/screens/footer/CompanyIntroScreen'
import InquiryScreen from '@/screens/footer/InquiryScreen'
import PolicyPrivacyScreen from '@/screens/footer/PolicyPrivacyScreen'
import PolicyServiceScreen from '@/screens/footer/PolicyServiceScreen'
import DetailStackHeader from '@/navigation/components/DetailStackHeader'
import ModalTopAuthMenu from '@/components/ModalTopAuthMenu'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import MembershipWithdrawalScreen from '@/screens/footer/MembershipWithdrawalScreen'
import FacebookScreen from '@/screens/auth/FacebookScreen'
import NewsStackHeader from './components/NewsStackHeader'
import BannerStackHeader from './components/BannerStackHeader'
import BannerCreateBoardScreen from '@/screens/boards/BannerCreateBoardScreen'
import BannerUpdateBoardScreen from '@/screens/boards/BannerUpdateBoardScreen'
import BannerBoardDetailScreen from '@/screens/detail/BannerBoardDetailScreen'
import ModifyProfileScreen from '@/screens/auth/ModifyProfileScreen'
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';

const Stack = createNativeStackNavigator<RootStackParamList>()

export default function RootNavigator() {
  const [auth] = useAtom(authAtom)
  const [popupState] = useAtom(popupStateAtom)

  React.useEffect(() => {
    const googleTranslateScript = document.createElement('script')
    googleTranslateScript.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    document.body.appendChild(googleTranslateScript)
    ;(window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: 'ko',
          includedLanguages: 'ko,ja,en,zh-CN,zh-TW,vi,tl,pt,ne,id',
          autoDisplay: true,
          layout: (window as any).google.translate.TranslateElement.InlineLayout.VERTICAL,
        },
        'google_translate_element',
      )
    }
  }, [])

  return (
    <React.Fragment>
      <div id='google_translate_element'></div>
      <ModalTopAuthMenu />
      <Stack.Navigator initialRouteName={ROOT_STACK.TOP_TAB}>
        <Stack.Screen
          name={ROOT_STACK.TOP_TAB}
          component={gestureHandlerRootHOC(TopTabNavigator)}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () =>
              popupState.isOpen && popupState.popup && popupState.popup.platform === 'mobile' ? (
                <PopupBanner />
              ) : null,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.SEND_MESSAGE}
          component={SendMessageScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name={ROOT_STACK.USER_PAGE_TAB}
          component={UserPageTabNavigator}
          options={{
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.NEWS_PAPER}
          component={NewsPaperScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <DetailStackHeader navigation={navigation} title='한터 신문' />,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.NEWS_PAPER_DETAIL}
          component={NewsPaperDetailScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.NEWS_DETAIL}
          component={gestureHandlerRootHOC(NewsDetailScreen)}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <NewsStackHeader navigation={navigation} title='뉴스' />,
          })}
        />
        {/* <Stack.Screen
          name={ROOT_STACK.KYODO_DETAIL}
          component={KyodoDetailScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        /> */}
        <Stack.Screen
          name={ROOT_STACK.BANNER_DETAIL}
          component={BannerDetailScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.BANNER_COMPANY_DETAIL}
          component={BannerCompanyDetailScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.ADDRESS_DETAIL}
          component={AddressDetailScreen}
          options={({ navigation, route }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => (
              <BannerStackHeader navigation={navigation} title={route.params.item.title} />
            ),
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.BOARD_DETAIL}
          component={BoardDetailScreen}
          options={({ navigation, route }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => null,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.BOARD_CREATE}
          component={SimpleCreateBoardScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.BOARD_UPDATE}
          component={SimpleUpdateBoardScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.BANNER_BOARD_CREATE}
          component={BannerCreateBoardScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.BANNER_BOARD_UPDATE}
          component={BannerUpdateBoardScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.BANNER_BOARD_DETAIL}
          component={BannerBoardDetailScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.SEARCH_STACK}
          component={SearchNavigator}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={ROOT_STACK.COMPANY_INTRO}
          component={CompanyIntroScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <DetailStackHeader navigation={navigation} title={'회사소개'} />,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.INQUIRY}
          component={InquiryScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <DetailStackHeader navigation={navigation} title={'제휴 및 광고문의'} />,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.POLICY_PRIVACY}
          component={PolicyPrivacyScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <DetailStackHeader navigation={navigation} title={'개인정보취급방침'} />,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.POLICY_SERVICE}
          component={PolicyServiceScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <DetailStackHeader navigation={navigation} title={'이용약관'} />,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.MEMBERSHIP_WITHDRAWAL}
          component={MembershipWithdrawalScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            header: () => <DetailStackHeader navigation={navigation} title={'회원탈퇴'} />,
          })}
        />
        <Stack.Screen
          name={ROOT_STACK.FACEBOOK_LOGIN}
          component={FacebookScreen}
          options={{
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          }}
        />
        {auth && auth.logged ? (
          <Stack.Screen
            name={ROOT_STACK.MY_PAGE_TAB}
            component={MyPageTabNavigator}
            options={{
              gestureEnabled: true,
              headerBackVisible: false,
              headerShown: false,
            }}
          />
        ) : (
          <Stack.Screen
            name={ROOT_STACK.AUTH_STACK}
            component={AuthNavigator}
            options={{
              gestureEnabled: true,
              headerBackVisible: false,
              headerShown: false,
            }}
          />
        )}
        <Stack.Screen
          name={ROOT_STACK.MODIFY_PROFILE}
          component={ModifyProfileScreen}
          options={({ navigation }) => ({
            gestureEnabled: true,
            headerBackVisible: false,
            headerShown: false,
          })}
        />
      </Stack.Navigator>
    </React.Fragment>
  )
}
