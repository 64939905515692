import React from 'react'
import { RefreshControl } from 'react-native'
import { Box, Spinner } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { 
  // kyodoNewsListAtom, 
  yonhapNewsListAtom
} from '@/atom/initAtom'
import {
  // findAllKyodoNews,
  findAllYonhapNews,
  findByIdNews,
  updateNewsViewCount,
} from '@/services/news'
import { findAllCommentByCommentLoad, findCommentCount } from '@/services/comment'
import { createArticleElements, findOne, updateArticleElements } from '@/services/articleElements'
import Comment from '@/components/Comment'
import Footer from '@/components/Footer'
import { LIMIT } from '@/constants/limit'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { ICuration, IYonhapNews } from '@/interfaces/news'
import { IAllComment, ICommentLoad } from '@/interfaces/comment'
import { IArticleElements, ICreateArticleElementsForm } from '@/interfaces/articleElements'
import NewsDetailYonhapNewsList from './components/NewsDetail/NewsDetailYonhapNewsList'
// import NewsDetailKyodoNewsList from './components/NewsDetail/NewsDetailKyodoNewsList'
import NewsDetailBody from './components/NewsDetail/NewsDetailBody'
import NewsDetailHeader from './components/NewsDetail/NewsDetailHeader'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.NEWS_DETAIL>

export default function NewsDetailScreen(props: Props) {
  const { id, goComment } = props.route.params
  const [auth] = useAtom(authAtom)
  const [initYonhapList] = useAtom(yonhapNewsListAtom)
  // const [initKyodoList] = useAtom(kyodoNewsListAtom)
  const [news, setNews] = React.useState<IYonhapNews | null>(null)
  const [commentList, setCommentList] = React.useState<IAllComment[]>([])
  const [commentCount, setCommentCount] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const [yonhapList, setYonhapList] = React.useState<IYonhapNews[]>([])
  const [kyodoList, setKyodoList] = React.useState<ICuration[]>([])
  const [yonhapLimit, setYonhapLimit] = React.useState(LIMIT.YONHAP_NEWS_LIMIT)
  // const [kyodoLimit, setKyodoLimit] = React.useState(LIMIT.KYODO_NEWS_LIMIT)
  const [refreshing, setRefreshing] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [goodCount, setGoodCount] = React.useState(0)
  const [hateCount, setHateCount] = React.useState(0)
  const [articleElement, setArticleElement] = React.useState<IArticleElements | null>(null)
  const [good, setGood] = React.useState(false)
  const [hate, setHate] = React.useState(false)
  const [scrap, setScrap] = React.useState(false)
  const [limit, setLimit] = React.useState(LIMIT.COMMENT_LIST_NEWS_LIMIT)
  const [dataSourceCords, setDataSourceCords] = React.useState(0)
  const [commentPageY, setCommentPageY] = React.useState(0)

  // const { scrollHandler } = useScroll()

  const commentScrollToHandler = (pageY: number) => {
    scrollToHandler(commentPageY + pageY - 160)
  }

  const scrollRef = React.useRef<any>(null)
  const scrollToHandler = (scrollY: number) => {
    if (scrollRef.current) {
      scrollRef.current?.scrollTo({
        x: 0,
        y: scrollY, //we get the offset value from array based on key
        animated: true,
      })
    }
  }

  const handleDataSourceCords = (scrollY: number) => {
    setDataSourceCords(scrollY)
  }

  const onMoreComment = React.useCallback(async () => {
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: limit,
      type: 'news',
      typeId: id,
    }
    await Promise.all([
      findAllCommentByCommentLoad(commentLoad),
      findCommentCount({
        type: 'news',
        typeId: id,
      }),
    ]).then((values) => {
      setCommentList(values[0].result)
      setCommentCount(values[0].commentCount)
      setTotalCount(values[1])
    })
    setLimit(limit + LIMIT.COMMENT_LIST_NEWS_LIMIT)
  }, [limit, auth.user, id])

  const reLoadCommentList = async () => {
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.COMMENT_LIST_NEWS_LIMIT,
      type: 'news',
      typeId: id,
    }
    await Promise.all([
      findAllCommentByCommentLoad(commentLoad),
      findCommentCount({
        type: 'news',
        typeId: id,
      }),
    ]).then((values) => {
      setCommentList(values[0].result)
      setCommentCount(values[0].commentCount)
      setTotalCount(values[1])
    })
  }

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.COMMENT_LIST_NEWS_LIMIT,
      type: 'news',
      typeId: id,
    }
    await Promise.all([
      findByIdNews(id),
      findAllYonhapNews(LIMIT.YONHAP_NEWS_LIMIT),
      // findAllKyodoNews(LIMIT.KYODO_NEWS_LIMIT),
      findAllCommentByCommentLoad(commentLoad),
      findCommentCount({
        type: 'news',
        typeId: id,
      }),
    ]).then((values) => {
      setYonhapLimit(LIMIT.YONHAP_NEWS_LIMIT)
      // setKyodoLimit(LIMIT.KYODO_NEWS_LIMIT)
      setNews(values[0])
      setYonhapList(values[1])
      // setKyodoList(values[2])
      setCommentList(values[2].result)
      setCommentCount(values[2].commentCount)
      setTotalCount(values[3])
    })
    await setRefreshing(false)
  }, [id, auth.user])

  const onMoreYonhap = React.useCallback(async () => {
    const newLimit = yonhapLimit + LIMIT.YONHAP_NEWS_LIMIT
    await findAllYonhapNews(newLimit).then((data) => {
      setYonhapList(data)
      setYonhapLimit(newLimit)
    })
  }, [yonhapLimit])

  // const onMoreKyodo = React.useCallback(async () => {
  //   const newLimit = kyodoLimit + LIMIT.KYODO_NEWS_LIMIT
  //   await findAllKyodoNews(newLimit).then((data) => {
  //     setKyodoList(data)
  //     setKyodoLimit(newLimit)
  //   })
  // }, [kyodoLimit])

  const saveArticleElement = async (elementType: string, value: any) => {
    if (!auth.logged) {
      props.navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    let newGood = good
    let newHate = hate
    let newScrap = scrap

    if (elementType === 'GOOD') {
      newGood = value
      setGood(newGood)

      // if (newGood) setGoodCount(goodCount + 1)
      // else setGoodCount(goodCount - 1)

      if (hate) {
        newHate = false
        setHate(newHate)
        // setHateCount(hateCount - 1)
      }
    } else if (elementType === 'HATE') {
      newHate = value
      setHate(newHate)

      // if (newHate) setHateCount(hateCount + 1)
      // else setHateCount(hateCount - 1)

      if (good) {
        newGood = false
        setGood(newGood)
        // setGoodCount(goodCount - 1)
      }
    } else if (elementType === 'SCRAP') {
      newScrap = value
      setScrap(newScrap)
    }

    const form: ICreateArticleElementsForm = {
      good: newGood,
      hate: newHate,
      scrap: newScrap,
      type: 'news',
      type_id: id,
      writer: auth.user ? auth.user.id : 0,
      jwt: auth.jwt,
    }
    if (articleElement) {
      await updateArticleElements(form, articleElement.id)
    } else {
      await createArticleElements(form)
      await findOne({
        type: 'news',
        type_id: id,
        writer: auth.user ? auth.user.id : 0,
      }).then((data) => {
        setArticleElement(data)
      })
    }
    await findByIdNews(id).then((data) => {
      setNews(data)
      setGoodCount(data.good_count)
      setHateCount(data.hate_count)
    })
  }

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const commentLoad: ICommentLoad = {
        userId: auth.user ? auth.user.id : 0,
        start: 0,
        limit: LIMIT.COMMENT_LIST_NEWS_LIMIT,
        type: 'news',
        typeId: id,
      }
      await updateNewsViewCount(id)
      await Promise.all([
        findByIdNews(id),
        findAllCommentByCommentLoad(commentLoad),
        findCommentCount({
          type: 'news',
          typeId: id,
        }),
        findOne({
          type: 'news',
          type_id: id,
          writer: auth.user ? auth.user.id : 0,
        }),
      ]).then((values) => {
        setNews(values[0])
        if (values[0]) {
          setGoodCount(values[0].good_count)
          setHateCount(values[0].hate_count)
        }
        setCommentList(values[1].result)
        setCommentCount(values[1].commentCount)
        setTotalCount(values[2])
        setArticleElement(values[3])
        if (values[3]) {
          setGood(values[3].good)
          setHate(values[3].hate)
          setScrap(values[3].scrap)
        }
        setYonhapList(initYonhapList)
        // setKyodoList(initKyodoList)
      })
      await setLoading(false)
    }
    void fetchData()
  }, [auth.user, id, initYonhapList])

  React.useLayoutEffect(() => {
    if (goComment) {
      scrollToHandler(dataSourceCords)
    }
  }, [dataSourceCords, goComment])

  if (isLoading) {
    return (
      <Box flex={1} justifyContent='center'>
        <Spinner color='#ff6f1e' />
      </Box>
    )
  }

  return news ? (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flexGrow: 1 }}
      // extraScrollHeight={80}
      // enableOnAndroid={true}
      // enableAutomaticScroll={Platform.OS === 'ios'}
      scrollEventThrottle={16}
      onScroll={(event) => {
        const scrolling = event.nativeEvent.contentOffset.y
        setCommentPageY(scrolling)
      }}
      keyboardShouldPersistTaps='handled'
    >
      {/*<TouchableWithoutFeedback onPress={Keyboard.dismiss}>*/}
      <Box>
        <NewsDetailHeader
          news={news}
          goodCount={goodCount}
          hateCount={hateCount}
          commentCount={commentCount}
          scrollY={dataSourceCords}
          scrollToHandler={scrollToHandler}
        />
        <NewsDetailBody
          news={news}
          saveArticleElement={saveArticleElement}
          goodCount={goodCount}
          hateCount={hateCount}
          good={good}
          hate={hate}
          scrap={scrap}
          complain={null}
        />
        <Box
          onLayout={(e) => {
            const layout = e.nativeEvent.layout
            setDataSourceCords(layout.y)
          }}
        >
          <Comment
            commentType='NEWS'
            typeId={news.id}
            articleWriter={news.reporter ? news.reporter : 0}
            commentList={commentList}
            commentCount={commentCount}
            totalCount={totalCount}
            reLoadCommentList={reLoadCommentList}
            onMoreComment={onMoreComment}
            scrollToHandler={scrollToHandler}
            dataSourceCords={dataSourceCords}
            commentScrollToHandler={commentScrollToHandler}
          />
        </Box>
        <NewsDetailYonhapNewsList newsList={yonhapList} onMore={onMoreYonhap} />
        {/* <NewsDetailKyodoNewsList
          newsList={kyodoList}
          onMore={onMoreKyodo}
          handleDataSourceCords={handleDataSourceCords}
        /> */}
        <Footer />
      </Box>
      {/*</TouchableWithoutFeedback>*/}
    </Animated.ScrollView>
  ) : null
}
