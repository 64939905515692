import React, { useState } from 'react'
import CButton from './CButton'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import LanguageModal from './LanguageModal'
import Cookies from 'universal-cookie'

export default function LanguageHeaderItem() {
  const cookies = new Cookies()
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const closeMenuModal = () => {
    setIsOpenModal(false)
  }
  const googtrans = cookies.get('googtrans')
  const [isLanguageCode, setLanguageCode] = useState('ko')
  const [isFlagSvg, setFlagSvg] = useState(require('@/assets/svg/icon-korea.svg'))

  React.useEffect(() => {
    if (googtrans) {
      const languageCode = googtrans.split('/').pop()
      if (languageCode) {
        switch (languageCode) {
          case 'ko':
            setLanguageCode('ko')
            return setFlagSvg(require('@/assets/svg/icon-korea.svg'))
          case 'ja':
            setLanguageCode('ja')
            return setFlagSvg(require('@/assets/svg/icon-japan.svg'))
          case 'en':
            setLanguageCode('en')
            return setFlagSvg(require('@/assets/svg/icon-united-states.svg'))
          case 'zh-CN':
            setLanguageCode('zh-CN')
            return setFlagSvg(require('@/assets/svg/icon-china.svg'))
          case 'zh-TW':
            setLanguageCode('zh-TW')
            return setFlagSvg(require('@/assets/svg/icon-taiwan.svg'))
          case 'vi':
            setLanguageCode('vi')
            return setFlagSvg(require('@/assets/svg/icon-vietnam.svg'))
          case 'tl':
            setLanguageCode('tl')
            return setFlagSvg(require('@/assets/svg/icon-philippines.svg'))
          case 'pt':
            setLanguageCode('pt')
            return setFlagSvg(require('@/assets/svg/icon-portugal.svg'))
          case 'ne':
            setLanguageCode('ne')
            return setFlagSvg(require('@/assets/svg/icon-nepal.svg'))
          case 'id':
            setLanguageCode('id')
            return setFlagSvg(require('@/assets/svg/icon-indonesia.svg'))
        }
      }
    }
  }, [googtrans])

  // 언어 변경 핸들러
  const changeLanguage = (languageCode: string, svg: string) => {
    setFlagSvg(svg)
    setIsOpenModal(false)

    setTimeout(() => {
      const selectField = document.querySelector('.goog-te-combo') as HTMLSelectElement
      if (selectField) {
        selectField.value = languageCode
        selectField.dispatchEvent(new Event('change'))
      }
    }, 2000) // 번역기 UI가 로드되기를 기다리기 위해 1초 지연
  }

  return (
    <React.Fragment>
      <CButton
        position='relative'
        my='auto'
        onPress={() => {
          setIsOpenModal(true)
        }}
      >
        <DynamicSvgIcon width='30' height='30' src={isFlagSvg} />
      </CButton>
      {isOpenModal && (
        <LanguageModal
          handleCloseModal={closeMenuModal}
          handleChangeLang={changeLanguage}
          lang={isLanguageCode}
        />
      )}
    </React.Fragment>
  )
}
