import React from 'react'
import { Box, Center, HStack, Text, TextArea } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { updateReCommentByCommentForm } from '@/services/comment'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import CreateModal from '@/components/CreateModal'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { IReComment, IReCommentForm } from '@/interfaces/comment'
import { RootStackParamList } from '@/interfaces/navigation'
import { Keyboard } from 'react-native'

interface Props {
  reComment: IReComment
  openCreateReCommentHandle: (val: boolean) => void
  reLoadCommentList: () => void
  hashUser: number
  hashUserNickName: string
  handleHashUser: (hashUser: number, hashUserNickName: string) => void
}

export default function UpdateReCommentBox(props: Props) {
  const {
    reComment,
    openCreateReCommentHandle,
    reLoadCommentList,
    handleHashUser,
    hashUser,
    hashUserNickName,
  } = props
  const [auth] = useAtom(authAtom)
  const [content, setContent] = React.useState(reComment.contents)
  const [contentLength, setContentLength] = React.useState(reComment.contents.length)
  const [initHashUser, setInitHashUser] = React.useState(false)
  const [isOpenAlertNoContent, setIsOpenAlertNoContent] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const { handleSubmit, control } = useForm<IReCommentForm>({
    defaultValues: {
      id: reComment.id,
      writer: auth.user ? auth.user.id : 0,
      jwt: auth.jwt ? auth.jwt : '',
      type: reComment.type,
      type_id: reComment.type_id,
      contents: content,
      comment: reComment.comment,
      hash_user: hashUser,
    },
  })

  const handleContentLength = (length: number) => {
    setContentLength(length)
  }

  const handleContent = (value: string) => {
    if (value.length > 300) {
      value = value.substring(0, 300)
    }
    setContent(value)
    handleContentLength(value.length)
  }

  const handleCancelButton = () => {
    handleContent('')
    handleHashUser(0, '')
    openCreateReCommentHandle(false)
  }

  const onSubmit = async (reCommentForm: IReCommentForm) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (contentLength === 0) {
      setIsOpenAlertNoContent(true)
      return
    }

    reCommentForm.hash_user = hashUser
    await updateReCommentByCommentForm(reCommentForm)
    setContent('')
    setContentLength(0)
    reLoadCommentList()
    openCreateReCommentHandle(false)
    Keyboard.dismiss()
  }

  React.useEffect(() => {
    if (!initHashUser) {
      handleHashUser(reComment.hash_user, reComment.hash_user_nick_name)
      setInitHashUser(true)
    }
  }, [handleHashUser, initHashUser, reComment.hash_user, reComment.hash_user_nick_name])

  return (
    <HStack py='18px' pr='17px' flex={1} bgColor='#F8F8F8'>
      {isOpenAlertNoContent && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_NO_CONTENT}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => setIsOpenAlertNoContent(false)}
        />
      )}
      <Box w='23px'>
        <FastImage
          source={require('@/assets/images/re-comment-arrow.png')}
          style={{
            width: 15,
            height: 13,
          }}
          resizeMode='contain'
        />
      </Box>
      <Box borderWidth='1px' borderColor='#dedede' w='full' bgColor='white'>
        <Box w='full' flexDirection='row' ml='12px' mt='10px'>
          {hashUserNickName && (
            <CButton mr='17px' onPress={() => handleHashUser(0, '')}>
              <Text>{hashUserNickName}</Text>
            </CButton>
          )}
          <Text>{contentLength}/300</Text>
        </Box>
        <Controller
          control={control}
          name='contents'
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <TextArea
              autoCompleteType={undefined}
              placeholder='내용을 입력해주세요.'
              w='full'
              fontSize={16}
              variant='unstyled'
              maxLength={300}
              value={content}
              onChangeText={(value) => {
                onChange(value)
                handleContent(value)
              }}
            />
          )}
        />
        <HStack borderTopWidth='1px' borderTopColor='#f4f4f4' justifyContent='flex-end' h='40px'>
          {/*<Text fontSize='11px' color='#ff6f1e' px='12px' py='14px' my='auto'>*/}
          {/*  이 댓글에 대한 법적 책임은 작성자에게 귀속됩니다.*/}
          {/*</Text>*/}
          <Box flexDirection='row'>
            <CButton my='auto' mr='7px' onPress={handleSubmit(onSubmit)}>
              <Center w='39px' h='25px' bgColor='#f4f4f4'>
                <Text fontSize='12px'>등록</Text>
              </Center>
            </CButton>
            <CButton my='auto' mr='7px' onPress={() => handleCancelButton()}>
              <Center w='39px' h='25px' bgColor='#f4f4f4'>
                <Text fontSize='12px'>취소</Text>
              </Center>
            </CButton>
          </Box>
        </HStack>
      </Box>
    </HStack>
  )
}
