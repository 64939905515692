import { HStack, Text, VStack, View } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ROOT_STACK } from '@/constants/label'
import { IAddressBook } from '@/interfaces/address'
import { RootStackParamList } from '@/interfaces/navigation'
import { TouchableHighlight } from 'react-native'
import FastImage from '@/components/FastImage'

interface Props {
  currentCategory: number
  addressBook: IAddressBook
}

export default function AddressItem(props: Props) {
  const { addressBook } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <View>
      <TouchableHighlight
        onPress={() =>
          navigation.push(ROOT_STACK.ADDRESS_DETAIL, { id: addressBook.id, item: addressBook })
        }
        underlayColor='#e9e9e9'
      >
        <VStack
          borderWidth='1px'
          borderColor='#E8E8E8'
          // h='75px'
          py='16px'
          px='30px'
          // w='full'
          bg='#ffffff'
          rounded='35px'
          mx='16px'
          mb='8px'
        >
          <HStack justifyContent='space-between'>
            <Text fontSize='18px' fontWeight={700} mr='2px'>
              {addressBook.title}
            </Text>
            <FastImage
              source={require('@/assets/images/arrow-left-bold.png')}
              style={{
                width: 18,
                height: 18,
                marginTop: 5,
              }}
              resizeMode='cover'
            />
          </HStack>
          {addressBook.description == null || addressBook.description == '' ? null : (
            <Text
              fontSize='13px'
              fontWeight={700}
              color='#A0A0A0'
              flex={1}
              lineBreakMode='tail'
              numberOfLines={2}
              mt='5px'
              mr='10%'
            >
              {addressBook.description}
            </Text>
          )}
        </VStack>
      </TouchableHighlight>
    </View>
  )
}
