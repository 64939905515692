import React from 'react'
import { RefreshControl } from 'react-native'
import { VStack } from 'native-base'
import { useNavigation, useScrollToTop } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { myPageCommentListAtom, myPageCommentTotalCountAtom } from '@/atom/initAtom'
import { findAllCommentByMeAuth } from '@/services/userContents'
import MoreTab from '@/components/MoreTab'
import { LIMIT } from '@/constants/limit'
import { MY_PAGE_TAB, ROOT_STACK } from '@/constants/label'
import { MyPageTabParamList, RootStackParamList } from '@/interfaces/navigation'
import MyPageBoardHeader from './components/MyPageBoardHeader'
import MyPageBoardTitle from './components/MyPageBoardTitle'
import MyPageBoardContents from './components/MyPageBoardContents'
import { TouchableHighlight } from 'react-native'
import { CATEGORY_LABEL } from '@/constants/site'
import { findById } from '@/services/banner'
import MyPageBoardBlank from './components/MyPageBoardBlank'

type Props = NativeStackScreenProps<MyPageTabParamList, MY_PAGE_TAB.MY_PAGE_COMMENT>

export default function MyPageCommentScreen({ navigation }: Props) {
  const [auth] = useAtom(authAtom)
  const [list, setList] = useAtom(myPageCommentListAtom)
  const [totalCount, setTotalCount] = useAtom(myPageCommentTotalCountAtom)
  const [limit, setLimit] = React.useState(LIMIT.MY_PAGE_LIST)
  const [refreshing, setRefreshing] = React.useState(false)
  const navigation_main = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  // const { scrollHandler } = useScroll()

  const onPageChange = async (board: any) => {
    const board_category = CATEGORY_LABEL.find((item) => item.id === board.category)
    if (board.type == 'board' && board_category) {
      navigation_main.push(ROOT_STACK.BOARD_DETAIL, {
        id: board.id,
        category: board.category,
      })
    } else if (board.type == 'news') {
      navigation_main.push(ROOT_STACK.NEWS_DETAIL, { id: board.id })
    } else {
      const banner = await findById(board.category)
      navigation_main.push(ROOT_STACK.BANNER_BOARD_DETAIL, {
        id: board.id,
        category: board.category,
        banner: banner,
      })
    }
  }

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    await findAllCommentByMeAuth({ jwt: auth.jwt, limit: LIMIT.MY_PAGE_LIST }).then((data) => {
      setList(data.contents)
      setTotalCount(data.totalCount)
    })
    await setRefreshing(false)
  }, [auth, setList, setTotalCount])

  const onMore = React.useCallback(async () => {
    const newLimit = limit + LIMIT.MY_PAGE_LIST
    await findAllCommentByMeAuth({ jwt: auth.jwt, limit: newLimit }).then((data) => {
      setList(data.contents)
      setTotalCount(data.totalCount)
      setLimit(newLimit)
    })
  }, [auth, limit, setList, setTotalCount])

  return (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      contentContainerStyle={{ flexGrow: 1, backgroundColor: '#ffffff' }}
      showsVerticalScrollIndicator={false}
      // scrollEventThrottle={16}
      // onScroll={scrollHandler}
    >
      {list.length ? (
        list.map((item) => {
          return (
            <TouchableHighlight
              key={item.type + item.id + item.contents}
              onPress={() => onPageChange(item)}
              underlayColor='#e9e9e9'
            >
              <VStack
                key={
                  item.type == 'board'
                    ? 'board' + item.id
                    : item.type == 'advertisement'
                    ? 'advertisement' + item.id
                    : 'new' + item.id
                }
                py='16px'
                px='16px'
                borderColor='#F2F2F2'
                borderBottomWidth='8px'
              >
                <MyPageBoardHeader board={item} />
                <MyPageBoardTitle board={item} screen={'MyPageCommentScreen'} />
                <MyPageBoardContents board={item} screen={'MyPageCommentScreen'} />
              </VStack>
            </TouchableHighlight>
          )
        })
      ) : (
        <MyPageBoardBlank title='내가 쓴 댓글이 없습니다.' screen={'MyPageCommentScreen'} />
      )}
      {totalCount > list.length ? (
        <MoreTab moreButtonText='더보기' moreButtonOnPress={onMore} useSideButton={false} />
      ) : null}
    </Animated.ScrollView>
  )
}
