import React, { useState } from 'react'
import { Platform, Pressable, TextInput } from 'react-native'
import { Box, HStack, Icon, Input, Text, VStack, View } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import * as WebBrowser from 'expo-web-browser'
import * as Google from 'expo-auth-session/providers/google'
import { useAtom } from 'jotai'
import { authStatusAtom, handleLogin, handleSocialLogin } from '@/atom/authAtom'
import CButton from '@/components/CButton'
import { AuthHeader, AuthSubHeader, LargeButton, SocialButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK, SOCIAL } from '@/constants/label'
import { AUTH_STATUS } from '@/constants/auth'
import { Env } from '@/constants/env'
import { AuthStackParamList } from '@/interfaces/navigation'
import { ILogin } from '@/interfaces/auth'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import FastImage from '@/components/FastImage'
import * as AuthService from '@/services/auth'
import axios from 'axios'
import queryString from 'query-string'
// import { MaterialIcons } from '@expo/vector-icons'
import ErrorMessageBox from './components/ErrorMessageBox'
import AuthSubTitle from './components/AuthSubTitle'
import TooltipButtonStyles from './style/TooltipButtonStyles'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_SIGN_IN>

WebBrowser.maybeCompleteAuthSession()

export default function SignInScreen({ navigation }: Props) {
  const [authStatus, setAuthStatus] = useAtom(authStatusAtom)
  const [, login] = useAtom(handleLogin)
  const [, socialLogin] = useAtom(handleSocialLogin)
  const [isLoading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isFBLoading, setIsFBLoading] = React.useState(false)
  const [dataSourceCords, setDataSourceCords] = React.useState<{ key: number; scrollY: number }[]>(
    [],
  )
  //패스워드 보이기,숨기기
  const [showPswd, setShowPswd] = useState<boolean>(true)

  const [googleRequest, googleResponse, googlePromptAsync] = Google.useAuthRequest({
    expoClientId: Env.EXPO_CLIENT_ID,
    webClientId: Env.WEB_CLIENT_ID,
  })

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<ILogin>({
    defaultValues: {
      identifier: '',
      password: '',
    },
  })

  const idInputRef = React.useRef<TextInput>(null)
  const passwordInputRef = React.useRef<TextInput>(null)

  const onSubmit = async (data: ILogin) => {
    setLoading(true)
    await login(data)
    setLoading(false)
  }

  const onSocialLogin = React.useCallback(
    async (token: string, provider: string) => {
      await socialLogin({ token, provider })
    },
    [socialLogin],
  )

  const onFacebookLogin = async () => {
    setIsFBLoading(true)
    await WebBrowser.openBrowserAsync(`${Env.STRAPI_API_URL}/connect/facebook`)
    setIsFBLoading(false)
  }

  const onKakaoLogin = () => {
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${Env.KAKAO_REST_API_KEY}&redirect_uri=${Env.KAKAO_MOBILEWEB_REDIRECT_URI}&prompt=login`
    window.location.href = kakaoURL
  }

  const kakaoLogin = async (authorize_code: any) => {
    let AccessToken = 'null'
    axios({
      method: 'post',
      url: 'https://kauth.kakao.com/oauth/token',
      params: {
        grant_type: 'authorization_code',
        client_id: Env.KAKAO_REST_API_KEY,
        redirect_uri: Env.KAKAO_MOBILEWEB_REDIRECT_URI,
        code: authorize_code,
        client_secret: Env.KAKAO_CLIENT_SECRET,
      },
    })
      .then((response) => {
        AccessToken = response.data.access_token
        axios({
          method: 'GET',
          url: 'https://kapi.kakao.com/v2/user/me',
          headers: {
            Authorization: `Bearer ${AccessToken}`,
          },
        })
          .then(async (response) => {
            const useremail = response.data.kakao_account.email
            const password = '90Q|$<p[(.E1[=fxJ'
            const login: ILogin = {
              identifier: useremail,
              password: password,
            }
            await AuthService.getEmailInfo(useremail)
              .then((authdata) => {
                if (authdata[0].username.includes('kakao')) {
                  onSubmit(login)
                } else {
                  //카카오 회원가입 아이디가 아니면 로그인 안됨
                  setErrorMessage('이미 가입된 이메일 입니다.')
                }
              })
              .catch(() => {
                setErrorMessage('입력한 정보와 일치하는 계정이 존재하지 않습니다.')
              })
          })
          .catch(function (error) {
            setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #2')
          })
      })
      .catch(function (error) {
        setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #1')
      })
  }

  const onNaverLogin = async () => {
    // const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${Env.NAVER_CLIENT_ID}&redirect_uri=${Env.NAVER_MOBILEWEB_REDIRECT_URI}&state=${Env.NAVER_STATE}`
    const naverURL =
      'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=PhBIbICwmhYBzC2lLP1l&redirect_uri=https://hanteo2050.com/AUTH_SIGN_IN&state=HANTEO'
    window.location.href = naverURL
  }

  const naverLogin = async (authorize_code: any) => {
    setLoading(true)
    setErrorMessage('')
    let access_token = 'null'
    await axios
      .get(
        `https://hanteo2050.com/login-api/naver/login/code/${authorize_code}?redirect_uri=http://hanteo2050.com/AUTH_SIGN_IN'}`,
      )
      .then(async (response) => {
        access_token = response.data.access_token
        await axios
          .get(`https://hanteo2050.com/login-api/naver/login/token/${access_token}`)
          .then(async (response) => {
            const user_email = response.data.response.email
            const password = '90Q|$<p[(.E1[=fxJ'
            const login: ILogin = {
              identifier: user_email,
              password: password,
            }
            await AuthService.getEmailInfo(user_email)
              .then((authdata) => {
                if (authdata[0].username.includes('naver')) {
                  onSubmit(login)
                } else {
                  //네이버 회원가입 아이디가 아니면 로그인 안됨
                  setErrorMessage('이미 가입된 이메일 입니다.')
                  onNaverLogout(access_token)
                  setLoading(false)
                }
              })
              .catch(() => {
                setErrorMessage('입력한 정보와 일치하는 계정이 존재하지 않습니다.')
                onNaverLogout(access_token)
                setLoading(false)
              })
          })
          .catch((error) => {
            setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #2')
            onNaverLogout(access_token)
            setLoading(false)
          })
      })
      .catch((error) => {
        setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #1')
        setLoading(false)
      })
  }

  const onNaverLogout = async (access_token: string) => {
    await axios
      .get(`https://hanteo2050.com/login-api/naver/logout/${access_token}`)
      .then((response) => {
        console.log(response.data)
      })
      .catch((e) => console.log('error!', e))
  }

  const onAppleLogin = async () => {
    const appleURL =
      'https://appleid.apple.com/auth/authorize?response_type=code&client_id=com.hanteo.newservices&redirect_uri=https://hanteo2050.com/AUTH_SIGN_IN'
    window.location.href = appleURL
  }

  const appleLogin = async (authorize_code: any) => {
    setLoading(true)
    setErrorMessage('')
    await axios({
      method: 'get',
      url: `https://hanteo2050.com/login-api/apple/getToken/${authorize_code}`,
    })
      .then(async (response) => {
        console.log(response.data)
        await axios({
          method: 'get',
          url: 'https://hanteo2050.com/login-api/apple/idTokenDecode',
          params: {
            token: response.data.id_token,
          },
        })
          .then(async (response) => {
            const sub = response.data.sub.toString()
            const username = sub
            const useremail = `${username}@apple.com`
            const password = '8JDN#$YNF90KDH#$8'
            const login: ILogin = {
              identifier: useremail,
              password: password,
            }
            await AuthService.getTokenInfo(username).then((authdata) => {
              const len = authdata.length
              if (len == 1) {
                //이미 회원가입이 되어 있다.
                if (authdata[0].email.includes('apple')) {
                  onSubmit(login)
                }
              } else {
                setErrorMessage('입력한 정보와 일치하는 계정이 존재하지 않습니다.')
                setLoading(false)
              }
            })
          })
          .catch(function (error) {
            setErrorMessage('한터 회원가입이 일시적으로 불가능합니다. #2')
            setLoading(false)
          })
      })
      .catch(function (error) {
        setErrorMessage('한터 회원가입이 일시적으로 불가능합니다. #1')
        setLoading(false)
      })
  }

  React.useEffect(() => {
    const query = queryString.parse(window.location.search)
    if (query['code']) {
      if (query['state'] == 'HANTEO') {
        naverLogin(query['code'])
      } else {
        if (query['code'].includes('.')) {
          appleLogin(query['code'])
        } else {
          kakaoLogin(query['code'])
        }
      }
    }
  }, [window.location.search])

  React.useEffect(() => {
    if (googleResponse?.type === 'success') {
      const authentication = googleResponse.authentication
      if (authentication) {
        void onSocialLogin(authentication.accessToken, 'google')
      }
    }
  }, [onSocialLogin, googleResponse])

  useFocusEffect(() => {
    setAuthStatus(AUTH_STATUS.INIT)
  })

  React.useEffect(() => {
    if (authStatus === AUTH_STATUS.RESEND_SUCCESS) {
      navigation.push(AUTH_STACK.AUTH_AUTHENTICATION)
    } else if (authStatus === AUTH_STATUS.SERVER_ERROR) {
      setErrorMessage('서버에 문제가 발생하였습니다.')
    } else if (authStatus === AUTH_STATUS.NO_PROFILE) {
      navigation.push(AUTH_STACK.AUTH_PROFILE)
    } else if (authStatus === AUTH_STATUS.LOGIN_FAILED) {
      setErrorMessage('아이디 또는 비밀번호가 맞지 않습니다.')
    } else if (authStatus === AUTH_STATUS.PROVIDER_FAILED) {
      setErrorMessage('구글 또는 페이스북으로 가입된 계정입니다.')
    } else if (authStatus === AUTH_STATUS.SOCIAL_LOGIN_FAILED) {
      setErrorMessage('일반 이메일 계정으로 가입된 계정입니다.')
    }
  }, [authStatus, navigation])

  return (
    <React.Fragment>
      <AuthStackHeader title='로그인' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        removeClippedSubviews={true}
      >
        <Box mt='28px' mb='20px' mx='10%'>
          <AuthHeader>
            <VStack>
              <Text fontSize='15px' color='#959595'>
                한터 회원가입을 하시면
              </Text>
              <Text fontSize='15px' color='#959595'>
                각종 혜택을 받으실 수 있습니다.
              </Text>
            </VStack>
          </AuthHeader>
          {/* {Platform.OS !== 'ios' ? ( */}
          <Box>
            <Box
              key={0}
              onLayout={(e) => {
                const layout = e.nativeEvent.layout
                setDataSourceCords([...dataSourceCords, { key: 0, scrollY: layout.y }])
              }}
            >
              <Controller
                control={control}
                name='identifier'
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    ref={idInputRef}
                    placeholder='이메일을 입력해주세요'
                    w='full'
                    h='40px'
                    borderColor='#F4F4F4'
                    backgroundColor='#F4F4F4'
                    borderRadius='3px'
                    placeholderTextColor='#5B5B5B'
                    mb='10px'
                    pl='10px'
                    fontSize='15px'
                    onChangeText={(value) => {
                      onChange(value)
                      setErrorMessage('')
                      formClearErrors()
                    }}
                    onBlur={onBlur}
                    value={value}
                    autoCapitalize='none'
                    autoComplete='off'
                    autoCorrect={false}
                    onSubmitEditing={() => {
                      passwordInputRef.current?.focus()
                    }}
                  />
                )}
              />
            </Box>
            <Box
              key={1}
              mb='2px'
              onLayout={(e) => {
                const layout = e.nativeEvent.layout
                setDataSourceCords([...dataSourceCords, { key: 1, scrollY: layout.y }])
              }}
            >
              <View flexDirection='row' justifyContent='flex-end' flex={1} alignItems='center'>
                <Controller
                  control={control}
                  name='password'
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      flex={1}
                      ref={passwordInputRef}
                      placeholder='비밀번호를 입력해주세요'
                      width='full'
                      height='40px'
                      borderColor='#F4F4F4'
                      backgroundColor='#F4F4F4'
                      borderRadius='3px'
                      placeholderTextColor='#5B5B5B'
                      mb='16px'
                      pl='10px'
                      fontSize='15px'
                      secureTextEntry={showPswd}
                      maxLength={32}
                      onChangeText={(value) => {
                        onChange(value)
                        setErrorMessage('')
                        formClearErrors()
                      }}
                      onBlur={onBlur}
                      value={value}
                      autoCapitalize='none'
                      autoComplete='password'
                      autoCorrect={false}
                      onSubmitEditing={handleSubmit(onSubmit)}
                      InputRightElement={
                        <Pressable onPress={() => setShowPswd(!showPswd)}>
                          <HStack mx='8px'>
                            <FastImage
                              source={
                                showPswd
                                  ? require('@/assets/images/Eye-Open.png')
                                  : require('@/assets/images/Eye-Closed.png')
                              }
                              style={{
                                width: 24,
                                height: 24,
                              }}
                              resizeMode='contain'
                            />
                          </HStack>
                        </Pressable>
                      }
                    />
                  )}
                />
              </View>
            </Box>
            <Box mb='16px'>
              <LargeButton
                buttonTitle='로그인'
                loading={isLoading}
                onPress={handleSubmit(onSubmit)}
                fill
              />
            </Box>
            {formErrors.identifier || formErrors.password ? (
              <ErrorMessageBox
                marginTop='0px'
                errorMessage='아이디 또는 비밀번호를 입력해주세요.'
              />
            ) : errorMessage ? (
              <ErrorMessageBox marginTop='0px' errorMessage={errorMessage} />
            ) : null}
          </Box>
          <Box mt='16px' mb='24px'>
            <AuthSubTitle title='SNS 간편 로그인' />
            <HStack mt='8px' space={Platform.OS == 'ios' ? '18px' : '18px'} justifyContent='center'>
              <SocialButton
                provider={SOCIAL.FACEBOOK}
                buttonText='페이스북으로 로그인하기'
                onPress={() => onFacebookLogin()}
                disabled={isFBLoading}
              />
              <SocialButton
                provider={SOCIAL.GOOGLE}
                buttonText='구글로 로그인하기'
                onPress={() => googlePromptAsync()}
                disabled={!googleRequest}
              />
              <SocialButton
                provider={SOCIAL.KAKAO}
                buttonText='카카오톡 로그인하기'
                onPress={() => onKakaoLogin()}
                disabled={!googleRequest}
              />
              <SocialButton
                provider={SOCIAL.NAVER}
                buttonText='네이버로 로그인하기'
                onPress={() => onNaverLogin()}
                disabled={!googleRequest}
              />
              <SocialButton
                provider={SOCIAL.APPLE}
                buttonText='애플로 로그인하기'
                onPress={() => onAppleLogin()}
                disabled={!googleRequest}
              />
            </HStack>
          </Box>
          <LargeButton
            buttonTitle='회원가입'
            onPress={() => navigation.push(AUTH_STACK.AUTH_AGREE_TO_TERMS)}
          />
          <Box mb='24px'>
            <View style={TooltipButtonStyles.tooltipContainer}>
              <View style={TooltipButtonStyles.tooltipArrow} />
              <View style={TooltipButtonStyles.tooltipBubble}>
                <Text style={TooltipButtonStyles.tooltipText}>
                  한터 회원만의 혜택을 받아가세요.
                </Text>
              </View>
            </View>
          </Box>
          <CButton onPress={() => navigation.push(AUTH_STACK.AUTH_FORGOT_PW)}>
            <HStack mb='36px' justifyContent='flex-end' alignItems='center'>
              <Text mr='16px' fontSize='16px' fontWeight='bold' color='#7C7C7C' textAlign='center'>
                비밀번호 찾기
              </Text>
              <FastImage
                source={require('@/assets/images/gray_left_arrow.png')}
                style={{
                  width: 8,
                  height: 16,
                }}
                resizeMode='contain'
              />
            </HStack>
          </CButton>
        </Box>
        {/*</TouchableWithoutFeedback>*/}
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
