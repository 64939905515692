import React from 'react'
import { Pressable } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import FastImage from '@/components/FastImage'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  navigation: any
}

export default function CloseButton(props: Props) {
  const { navigation } = props
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  return (
    <Pressable
      onPress={() => {
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          rootNavigation.navigate(ROOT_STACK.TOP_TAB, {
            screen: TOP_TAB.MAIN,
          })
        }
      }}
      mr='10px'
    >
      <FastImage
        source={require('@/assets/images/black-close-icon.png')}
        style={{
          width: 16,
          height: 16,
        }}
        resizeMode='contain'
      />
    </Pressable>
  )
}
