import React from 'react'
import { RefreshControl } from 'react-native'
import { useScrollToTop } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import {
  jobBannerListAtom,
  jobLeftBoardListAtom,
  jobLeftBoardTotalCountAtom,
  jobRightBoardListAtom,
  jobRightBoardTotalCountAtom,
} from '@/atom/initAtom'
import { findAllBoardByBoardLoad, findBoardCount } from '@/services/board'
import { findAllByBoardBanner } from '@/services/banner'
import BoardList from '@/screens/boards/components/BoardList'
import { LIMIT } from '@/constants/limit'
import { TOP_TAB } from '@/constants/label'
import { BOARD_PROPS } from '@/constants/site'
import { TopTabParamList } from '@/interfaces/navigation'
import { Box } from 'native-base'
import useScroll from '@/hooks/useScroll'

type Props = NativeStackScreenProps<TopTabParamList, TOP_TAB.JOB>

export default function BoardListJobScreen({ navigation, route }: Props) {
  const { isScrollToList } = route.params || { isScrollToList: false }
  const [auth] = useAtom(authAtom)
  const [bannerList, setBannerList] = useAtom(jobBannerListAtom)
  const [leftBoardList, setLeftBoardList] = useAtom(jobLeftBoardListAtom)
  const [rightBoardList, setRightBoardList] = useAtom(jobRightBoardListAtom)
  const [leftTotalCount, setLeftTotalCount] = useAtom(jobLeftBoardTotalCountAtom)
  const [rightTotalCount, setRightTotalCount] = useAtom(jobRightBoardTotalCountAtom)
  const [currentCategory, setCurrentCategory] = React.useState<number>(
    BOARD_PROPS.job.left.category,
  )
  const [currentTabName, setCurrentTabName] = React.useState('LEFT')
  const [leftLimit, setLeftLimit] = React.useState(LIMIT.BOARD_LIST_LIMIT)
  const [rightLimit, setRightLimit] = React.useState(LIMIT.BOARD_LIST_LIMIT)
  const [refreshing, setRefreshing] = React.useState(false)
  const [dataSourceCords, setDataSourceCords] = React.useState(0)

  const { scrollHandler } = useScroll()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  const handleCurrentCategory = (pCategory: number) => {
    setCurrentCategory(pCategory)
    setCurrentTabName(pCategory === BOARD_PROPS.job.right.category ? 'RIGHT' : 'LEFT')
  }

  const handleRefresh = React.useCallback(async () => {
    setRefreshing(true)
    if (currentTabName === 'RIGHT') {
      await Promise.all([
        findAllBoardByBoardLoad({
          userId: auth.user ? auth.user.id : 0,
          start: 0,
          limit: LIMIT.BOARD_LIST_LIMIT,
          category: BOARD_PROPS.job.right.category,
        }),
        findBoardCount(BOARD_PROPS.job.right.category),
        findAllByBoardBanner(BOARD_PROPS.job.bannerCategory),
      ]).then((values) => {
        setRightBoardList(values[0])
        setRightTotalCount(values[1])
        setBannerList(values[2])
        setRightLimit(LIMIT.BOARD_LIST_LIMIT)
      })
    } else {
      await Promise.all([
        findAllBoardByBoardLoad({
          userId: auth.user ? auth.user.id : 0,
          start: 0,
          limit: LIMIT.BOARD_LIST_LIMIT,
          category: BOARD_PROPS.job.left.category,
        }),
        findBoardCount(BOARD_PROPS.job.left.category),
        findAllByBoardBanner(BOARD_PROPS.job.bannerCategory),
      ]).then((values) => {
        setLeftBoardList(values[0])
        setLeftTotalCount(values[1])
        setBannerList(values[2])
        setLeftLimit(LIMIT.BOARD_LIST_LIMIT)
      })
    }
    await setRefreshing(false)
  }, [
    auth,
    currentTabName,
    setBannerList,
    setLeftBoardList,
    setLeftTotalCount,
    setRightBoardList,
    setRightTotalCount,
  ])

  const handleMore = React.useCallback(async () => {
    if (currentTabName === 'RIGHT') {
      const newLimit = rightLimit + LIMIT.BOARD_LIST_LIMIT
      await Promise.all([
        findAllBoardByBoardLoad({
          userId: auth.user ? auth.user.id : 0,
          start: 0,
          limit: newLimit,
          category: BOARD_PROPS.job.right.category,
        }),
        findBoardCount(BOARD_PROPS.job.right.category),
      ]).then((values) => {
        setRightBoardList(values[0])
        setRightTotalCount(values[1])
        setRightLimit(newLimit)
      })
    } else {
      const newLimit = leftLimit + LIMIT.BOARD_LIST_LIMIT
      await Promise.all([
        findAllBoardByBoardLoad({
          userId: auth.user ? auth.user.id : 0,
          start: 0,
          limit: newLimit,
          category: BOARD_PROPS.job.left.category,
        }),
        findBoardCount(BOARD_PROPS.job.left.category),
      ]).then((values) => {
        setLeftBoardList(values[0])
        setLeftTotalCount(values[1])
        setLeftLimit(newLimit)
      })
    }
  }, [
    auth,
    leftLimit,
    rightLimit,
    currentTabName,
    setLeftBoardList,
    setLeftTotalCount,
    setRightBoardList,
    setRightTotalCount,
  ])

  return (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={handleRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      showsVerticalScrollIndicator={false}
      onScroll={scrollHandler}
      scrollEventThrottle={16}
    >
      <Box>
        {currentTabName === 'RIGHT' ? (
          <BoardList
            list={rightBoardList && rightBoardList.length ? rightBoardList : []}
            currentCategory={currentCategory}
            handleCurrentCategory={handleCurrentCategory}
            isMore={rightTotalCount > rightLimit}
            handleMore={handleMore}
            bannerList={bannerList}
            setDataSourceCords={setDataSourceCords}
          />
        ) : (
          <BoardList
            list={leftBoardList && leftBoardList.length ? leftBoardList : []}
            currentCategory={currentCategory}
            handleCurrentCategory={handleCurrentCategory}
            isMore={leftTotalCount > leftLimit}
            handleMore={handleMore}
            bannerList={bannerList}
            setDataSourceCords={setDataSourceCords}
          />
        )}
      </Box>
    </Animated.ScrollView>
  )
}
