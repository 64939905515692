import { TouchableOpacity } from 'react-native'
import { Box, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

export default function MainNewsPaperBanner() {
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <VStack alignItems='center' bg='#ffffff'>
      <TouchableOpacity onPress={() => navigation.push(ROOT_STACK.NEWS_PAPER)}>
        <Box my='17px'>
          <FastImage
            source={require('@/assets/images/news-paper-banner.png')}
            style={{
              width: screenWidth - 34,
              height: screenWidth / 2,
            }}
            resizeMode='contain'
          />
        </Box>
      </TouchableOpacity>
      <Box bg='#f2f2f2' h='11px' />
    </VStack>
  )
}
