import moment from 'moment'
import { BANNER_CATEGORY_LABEL, CATEGORY_LABEL } from '@/constants/site'

export default function useFormatter() {
  const numberCommaFormatter = (visitor: number) => {
    return visitor.toLocaleString('ko-KR')
  }

  const datetimeFormatter = (datetime: string, pFormat?: string) => {
    const format = pFormat ? pFormat : 'YYYY.MM.DD HH:mm'
    return datetime ? moment(datetime).format(format) : ''
  }

  const dateFormatter = (datetime: string) => {
    return moment(datetime).format('YY.MM.DD')
  }

  const categoryFormatter = (categoryId: number | string) => {
    const obj = CATEGORY_LABEL.find((item) => item.id === Number(categoryId))
    return obj?.label
  }

  const bannerCategoryFormatter = (categoryId: number) => {
    const obj = BANNER_CATEGORY_LABEL.find((item) => item.bannerCategory === categoryId)
    return obj?.label
  }

  const boardCategoryFormatter = (categoryId: number | string) => {
    const obj = CATEGORY_LABEL.find((item) => item.id === Number(categoryId))
    return obj?.categoryName
  }

  const compareIsBeforeDate = (dateString: string): boolean => {
    const inputDate = new Date(dateString) // 문자열을 Date 객체로 변환
    const today = new Date() // 오늘 날짜 생성
    const dateToCompare = new Date(inputDate.toDateString()) // 입력된 날짜에서 시간 제거
    const todayDate = new Date(today.toDateString()) // 오늘 날짜에서 시간 제거

    if (dateToCompare > todayDate) {
      //'The given date is later than today.'
      return false
    } else if (dateToCompare < todayDate) {
      //'The given date is earlier than today.'
      return true
    } else {
      return false
    }
  }

  const compareTodayDate = (dateString: string): boolean => {
    const inputDate = new Date(dateString) // 문자열을 Date 객체로 변환
    const today = new Date() // 오늘 날짜 생성
    const dateToCompare = new Date(inputDate.toDateString()) // 입력된 날짜에서 시간 제거
    const todayDate = new Date(today.toDateString()) // 오늘 날짜에서 시간 제거

    if (dateToCompare > todayDate) {
      //'The given date is later than today.'
      return false
    } else if (dateToCompare < todayDate) {
      //'The given date is earlier than today.'
      return false
    } else {
      return true
    }
  }

  const extractColorCode = (text: string | undefined) => {
    if (text == undefined) {
      return '#000000'
    }
    const regex = /\(#([0-9a-fA-F]{6})\)/
    const match = text.match(regex)
    return match ? `#${match[1]}` : '#000000'
  }

  return {
    numberCommaFormatter,
    datetimeFormatter,
    dateFormatter,
    categoryFormatter,
    bannerCategoryFormatter,
    boardCategoryFormatter,
    compareIsBeforeDate,
    compareTodayDate,
    extractColorCode,
  }
}
