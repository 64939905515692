import Constants from 'expo-constants'

export const APP_ENV =
  Constants.manifest2?.extra?.expoClient?.extra?.eas?.APP_ENV ??
  Constants.manifest?.extra?.eas?.APP_ENV ??
  Constants.manifest2?.extra?.expoClient?.extra?.APP_ENV ??
  Constants.manifest?.extra?.APP_ENV

function getAppEnv() {
  const APP_ENV =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.APP_ENV ??
    Constants.manifest?.extra?.eas?.APP_ENV ??
    Constants.manifest2?.extra?.expoClient?.extra?.APP_ENV ??
    Constants.manifest?.extra?.APP_ENV

  if (!APP_ENV) {
    throw new Error('APP_ENV is missing.')
  }

  return APP_ENV
}

function getStrapiApiUrl() {
  let STRAPI_API_URL
  if (APP_ENV === 'production' || APP_ENV === 'preview') {
    STRAPI_API_URL =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_STRAPI_API_URL ??
      Constants.manifest?.extra?.eas?.PROD_STRAPI_API_URL ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_STRAPI_API_URL ??
      Constants.manifest?.extra?.PROD_STRAPI_API_URL
  } else if (APP_ENV === 'development') {
    STRAPI_API_URL =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.DEV_STRAPI_API_URL ??
      Constants.manifest?.extra?.eas?.DEV_STRAPI_API_URL ??
      Constants.manifest2?.extra?.expoClient?.extra?.DEV_STRAPI_API_URL ??
      Constants.manifest?.extra?.DEV_STRAPI_API_URL
  } else {
    STRAPI_API_URL =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_STRAPI_API_URL ??
      Constants.manifest?.extra?.eas?.PROD_STRAPI_API_URL ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_STRAPI_API_URL ??
      Constants.manifest?.extra?.PROD_STRAPI_API_URL
  }

  if (!STRAPI_API_URL) {
    throw new Error('STRAPI_API_URL is missing.')
  }

  return STRAPI_API_URL
}

function getStrapiApiAdminIdentifier() {
  let STRAPI_API_ADMIN_IDENTIFIER
  if (APP_ENV === 'production' || APP_ENV === 'preview') {
    STRAPI_API_ADMIN_IDENTIFIER =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest?.extra?.eas?.PROD_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest?.extra?.PROD_STRAPI_API_ADMIN_IDENTIFIER
  } else if (APP_ENV === 'development') {
    STRAPI_API_ADMIN_IDENTIFIER =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.DEV_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest?.extra?.eas?.DEV_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest2?.extra?.expoClient?.extra?.DEV_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest?.extra?.DEV_STRAPI_API_ADMIN_IDENTIFIER
  } else {
    STRAPI_API_ADMIN_IDENTIFIER =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest?.extra?.eas?.PROD_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_STRAPI_API_ADMIN_IDENTIFIER ??
      Constants.manifest?.extra?.PROD_STRAPI_API_ADMIN_IDENTIFIER
  }

  if (!STRAPI_API_ADMIN_IDENTIFIER) {
    throw new Error('STRAPI_API_ADMIN_IDENTIFIER is missing.')
  }

  return STRAPI_API_ADMIN_IDENTIFIER
}

function getStrapiApiAdminPassword() {
  let STRAPI_API_ADMIN_PASSWORD
  if (APP_ENV === 'production' || APP_ENV === 'preview') {
    STRAPI_API_ADMIN_PASSWORD =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest?.extra?.eas?.PROD_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest?.extra?.PROD_STRAPI_API_ADMIN_PASSWORD
  } else if (APP_ENV === 'development') {
    STRAPI_API_ADMIN_PASSWORD =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.DEV_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest?.extra?.eas?.DEV_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest2?.extra?.expoClient?.extra?.DEV_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest?.extra?.DEV_STRAPI_API_ADMIN_PASSWORD
  } else {
    STRAPI_API_ADMIN_PASSWORD =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest?.extra?.eas?.PROD_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_STRAPI_API_ADMIN_PASSWORD ??
      Constants.manifest?.extra?.PROD_STRAPI_API_ADMIN_PASSWORD
  }

  if (!STRAPI_API_ADMIN_PASSWORD) {
    throw new Error('STRAPI_API_ADMIN_PASSWORD is missing.')
  }

  return STRAPI_API_ADMIN_PASSWORD
}

function getExpoClientId() {
  const EXPO_CLIENT_ID =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.EXPO_CLIENT_ID ??
    Constants.manifest?.extra?.eas?.EXPO_CLIENT_ID ??
    Constants.manifest2?.extra?.expoClient?.extra?.EXPO_CLIENT_ID ??
    Constants.manifest?.extra?.EXPO_CLIENT_ID

  if (!EXPO_CLIENT_ID) {
    throw new Error('EXPO_CLIENT_ID is missing.')
  }

  return EXPO_CLIENT_ID
}

function getIosClientId() {
  const IOS_CLIENT_ID =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.IOS_CLIENT_ID ??
    Constants.manifest?.extra?.eas?.IOS_CLIENT_ID ??
    Constants.manifest2?.extra?.expoClient?.extra?.IOS_CLIENT_ID ??
    Constants.manifest?.extra?.IOS_CLIENT_ID

  if (!IOS_CLIENT_ID) {
    throw new Error('IOS_CLIENT_ID is missing.')
  }

  return IOS_CLIENT_ID
}

function getAndroidClientId() {
  const ANDROID_CLIENT_ID =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.ANDROID_CLIENT_ID ??
    Constants.manifest?.extra?.eas?.ANDROID_CLIENT_ID ??
    Constants.manifest2?.extra?.expoClient?.extra?.ANDROID_CLIENT_ID ??
    Constants.manifest?.extra?.ANDROID_CLIENT_ID

  if (!ANDROID_CLIENT_ID) {
    throw new Error('ANDROID_CLIENT_ID is missing.')
  }

  return ANDROID_CLIENT_ID
}

function getWebClientId() {
  const WEB_CLIENT_ID =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.WEB_CLIENT_ID ??
    Constants.manifest?.extra?.eas?.WEB_CLIENT_ID ??
    Constants.manifest2?.extra?.expoClient?.extra?.WEB_CLIENT_ID ??
    Constants.manifest?.extra?.WEB_CLIENT_ID

  if (!WEB_CLIENT_ID) {
    throw new Error('WEB_CLIENT_ID is missing.')
  }

  return WEB_CLIENT_ID
}

function getFacebookClientId() {
  const FACEBOOK_CLIENT_ID =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.FACEBOOK_CLIENT_ID ??
    Constants.manifest?.extra?.eas?.FACEBOOK_CLIENT_ID ??
    Constants.manifest2?.extra?.expoClient?.extra?.FACEBOOK_CLIENT_ID ??
    Constants.manifest?.extra?.FACEBOOK_CLIENT_ID

  if (!FACEBOOK_CLIENT_ID) {
    throw new Error('FACEBOOK_CLIENT_ID is missing.')
  }

  return FACEBOOK_CLIENT_ID
}

function getWebUrl() {
  let WEB_URL
  if (APP_ENV === 'production' || APP_ENV === 'preview') {
    WEB_URL =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_WEB_URL ??
      Constants.manifest?.extra?.eas?.PROD_WEB_URL ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_WEB_URL ??
      Constants.manifest?.extra?.PROD_WEB_URL
  } else if (APP_ENV === 'development') {
    WEB_URL =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.DEV_WEB_URL ??
      Constants.manifest?.extra?.eas?.DEV_WEB_URL ??
      Constants.manifest2?.extra?.expoClient?.extra?.DEV_WEB_URL ??
      Constants.manifest?.extra?.DEV_WEB_URL
  } else {
    WEB_URL =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_WEB_URL ??
      Constants.manifest?.extra?.eas?.PROD_WEB_URL ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_WEB_URL ??
      Constants.manifest?.extra?.PROD_WEB_URL
  }

  if (!WEB_URL) {
    throw new Error('WEB_URL is missing.')
  }

  return WEB_URL
}

function getGaTrackingId() {
  let GA_TRACKING_ID
  if (APP_ENV === 'production' || APP_ENV === 'preview') {
    GA_TRACKING_ID =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_GA_TRACKING_ID ??
      Constants.manifest?.extra?.eas?.PROD_GA_TRACKING_ID ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_GA_TRACKING_ID ??
      Constants.manifest?.extra?.PROD_GA_TRACKING_ID
  } else if (APP_ENV === 'development') {
    GA_TRACKING_ID =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.DEV_GA_TRACKING_ID ??
      Constants.manifest?.extra?.eas?.DEV_GA_TRACKING_ID ??
      Constants.manifest2?.extra?.expoClient?.extra?.DEV_GA_TRACKING_ID ??
      Constants.manifest?.extra?.DEV_GA_TRACKING_ID
  } else {
    GA_TRACKING_ID =
      Constants.manifest2?.extra?.expoClient?.extra?.eas?.PROD_GA_TRACKING_ID ??
      Constants.manifest?.extra?.eas?.PROD_GA_TRACKING_ID ??
      Constants.manifest2?.extra?.expoClient?.extra?.PROD_GA_TRACKING_ID ??
      Constants.manifest?.extra?.PROD_GA_TRACKING_ID
  }

  if (!GA_TRACKING_ID) {
    throw new Error('GA_TRACKING_ID is missing.')
  }

  return GA_TRACKING_ID
}

function getKakaoRestApiKey() {
  const KAKAO_REST_API_KEY =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.KAKAO_REST_API_KEY ??
    Constants.manifest?.extra?.eas?.KAKAO_REST_API_KEY ??
    Constants.manifest2?.extra?.expoClient?.extra?.KAKAO_REST_API_KEY ??
    Constants.manifest?.extra?.KAKAO_REST_API_KEY

  if (!KAKAO_REST_API_KEY) {
    throw new Error('KAKAO_REST_API_KEY is missing.')
  }

  return KAKAO_REST_API_KEY
}

function getKakaoRedirectUrl() {
  const KAKAO_MOBILEWEB_REDIRECT_URI =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.KAKAO_MOBILEWEB_REDIRECT_URI ??
    Constants.manifest?.extra?.eas?.KAKAO_MOBILEWEB_REDIRECT_URI ??
    Constants.manifest2?.extra?.expoClient?.extra?.KAKAO_MOBILEWEB_REDIRECT_URI ??
    Constants.manifest?.extra?.KAKAO_MOBILEWEB_REDIRECT_URI

  if (!KAKAO_MOBILEWEB_REDIRECT_URI) {
    throw new Error('KAKAO_MOBILEWEB_REDIRECT_URI is missing.')
  }

  return KAKAO_MOBILEWEB_REDIRECT_URI
}

function getKakaoClientSecret() {
  const KAKAO_CLIENT_SECRET =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.KAKAO_CLIENT_SECRET ??
    Constants.manifest?.extra?.eas?.KAKAO_CLIENT_SECRET ??
    Constants.manifest2?.extra?.expoClient?.extra?.KAKAO_CLIENT_SECRET ??
    Constants.manifest?.extra?.KAKAO_CLIENT_SECRET

  if (!KAKAO_CLIENT_SECRET) {
    throw new Error('KAKAO_CLIENT_SECRET is missing.')
  }

  return KAKAO_CLIENT_SECRET
}

function getNaverClientId() {
  const NAVER_CLIENT_ID =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.NAVER_CLIENT_ID ??
    Constants.manifest?.extra?.eas?.NAVER_CLIENT_ID ??
    Constants.manifest2?.extra?.expoClient?.extra?.NAVER_CLIENT_ID ??
    Constants.manifest?.extra?.NAVER_CLIENT_ID

  if (!NAVER_CLIENT_ID) {
    throw new Error('NAVER_CLIENT_ID is missing.')
  }

  return NAVER_CLIENT_ID
}

function getNaverClientSecret() {
  const NAVER_CLIENT_SECRET =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.NAVER_CLIENT_SECRET ??
    Constants.manifest?.extra?.eas?.NAVER_CLIENT_SECRET ??
    Constants.manifest2?.extra?.expoClient?.extra?.NAVER_CLIENT_SECRET ??
    Constants.manifest?.extra?.NAVER_CLIENT_SECRET

  if (!NAVER_CLIENT_SECRET) {
    throw new Error('NAVER_CLIENT_SECRET is missing.')
  }

  return NAVER_CLIENT_SECRET
}

function getNaverReDirectUrl() {
  const NAVER_MOBILEWEB_REDIRECT_URI =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.NAVER_MOBILEWEB_REDIRECT_URI ??
    Constants.manifest?.extra?.eas?.NAVER_MOBILEWEB_REDIRECT_URI ??
    Constants.manifest2?.extra?.expoClient?.extra?.NAVER_MOBILEWEB_REDIRECT_URI ??
    Constants.manifest?.extra?.NAVER_MOBILEWEB_REDIRECT_URI

  if (!NAVER_MOBILEWEB_REDIRECT_URI) {
    throw new Error('NAVER_MOBILEWEB_REDIRECT_URI is missing.')
  }

  return NAVER_MOBILEWEB_REDIRECT_URI
}

function getNaverState() {
  const NAVER_STATE =
    Constants.manifest2?.extra?.expoClient?.extra?.eas?.NAVER_STATE ??
    Constants.manifest?.extra?.eas?.NAVER_STATE ??
    Constants.manifest2?.extra?.expoClient?.extra?.NAVER_STATE ??
    Constants.manifest?.extra?.NAVER_STATE

  if (!NAVER_STATE) {
    throw new Error('NAVER_STATE is missing.')
  }

  return NAVER_STATE
}

export const Env = {
  APP_ENV: getAppEnv(),
  STRAPI_API_URL: getStrapiApiUrl(),
  STRAPI_API_ADMIN_IDENTIFIER: getStrapiApiAdminIdentifier(),
  STRAPI_API_ADMIN_PASSWORD: getStrapiApiAdminPassword(),
  EXPO_CLIENT_ID: getExpoClientId(),
  IOS_CLIENT_ID: getIosClientId(),
  ANDROID_CLIENT_ID: getAndroidClientId(),
  WEB_CLIENT_ID: getWebClientId(),
  FACEBOOK_CLIENT_ID: getFacebookClientId(),
  WEB_URL: getWebUrl(),
  GA_TRACKING_ID: getGaTrackingId(),
  KAKAO_REST_API_KEY: getKakaoRestApiKey(),
  KAKAO_MOBILEWEB_REDIRECT_URI: getKakaoRedirectUrl(),
  KAKAO_CLIENT_SECRET: getKakaoClientSecret(),
  NAVER_CLIENT_ID: getNaverClientId(),
  NAVER_CLIENT_SECRET: getNaverClientSecret(),
  NAVER_MOBILEWEB_REDIRECT_URI: getNaverReDirectUrl(),
  NAVER_STATE: getNaverState(),
}
