import { TouchableHighlight } from 'react-native'
import { Center, Spinner, Text, View } from 'native-base'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'

interface Props {
  moreButtonTextColor?: string
  moreButtonText: string
  moreButtonOnPress: () => void
  mainButton?: boolean
  useSideButton: boolean
  sideButtonText?: string
  sideButtonOnPress?: () => void
  loading?: boolean
  wid?: number
}

export default function MoreTabBannerBoard(props: Props) {
  const {
    moreButtonTextColor,
    moreButtonText,
    moreButtonOnPress,
    mainButton,
    useSideButton,
    sideButtonText,
    sideButtonOnPress,
    loading,
    wid,
  } = props

  return (
    // <TouchableHighlight onPress={moreButtonOnPress} underlayColor='#e9e9e9'>
    <Center flexDirection='row' justifyItems='center' h='76px'>
      {loading ? (
        <Spinner color='#ff6f1e' />
      ) : mainButton ? (
        <TouchableHighlight onPress={moreButtonOnPress} underlayColor='#e9e9e9'>
          <Center
            style={wid ? { width: wid } : { width: 160 }}
            flexDirection='row'
            h='40px'
            borderWidth='1'
            borderRadius='34px'
            borderColor='#8F8F8F'
          >
            {moreButtonTextColor ? (
              <Text fontSize='17px' color='#FF6666' textAlign='center' mr='5px'>
                {moreButtonTextColor}
              </Text>
            ) : null}
            <Text fontSize='17px' color='#282828' textAlign='center' mr='8px'>
              {moreButtonText}
            </Text>
            <View pt='2px'>
              <FastImage
                source={require('@/assets/images/down-arrow.png')}
                style={{
                  width: 14,
                  height: 8,
                }}
                resizeMode='contain'
              />
            </View>
          </Center>
        </TouchableHighlight>
      ) : null}
      {useSideButton ? (
        <CButton
          position='absolute'
          bottom='16px'
          right='10px'
          onPress={() => {
            if (sideButtonOnPress) sideButtonOnPress()
          }}
        >
          <Center
            h='40px'
            w='88px'
            backgroundColor='#ff6f1e'
            borderRadius='20px'
            borderColor='#ff6f1e'
            marginTop='25px'
            marginLeft='5px'
          >
            <Text mt='2px' color='white' fontWeight='600' fontSize={15}>
              {sideButtonText || ''}
            </Text>
          </Center>
        </CButton>
      ) : null}
    </Center>
    // </TouchableHighlight>
  )
}
