import React from 'react'

interface Props {
  width?: string
  height?: string
  fillColor?: string
}

const NotifyIcon: React.FC<Props> = ({ width = '36', height = '24', fillColor = '#292D32' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 36 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='24' rx='8' fill={fillColor} />
      <path
        d='M10.5453 13.824C13.7453 13.824 15.7933 14.88 15.7933 16.64C15.7933 18.368 13.7453 19.424 10.5453 19.424C7.34525 19.424 5.29725 18.368 5.29725 16.64C5.29725 14.88 7.34525 13.824 10.5453 13.824ZM10.5453 15.424C8.54525 15.424 7.40925 15.84 7.40925 16.64C7.40925 17.424 8.54525 17.824 10.5453 17.824C12.5453 17.824 13.6813 17.424 13.6813 16.64C13.6813 15.84 12.5453 15.424 10.5453 15.424ZM5.45725 5.264H14.8333V6.944H5.45725V5.264ZM3.95325 11.216H17.2973V12.912H3.95325V11.216ZM8.89725 8.64H11.0093V11.648H8.89725V8.64ZM13.6013 5.264H15.7133V6.48C15.7133 7.648 15.7133 8.912 15.3133 10.448L13.2013 10.224C13.6013 8.704 13.6013 7.632 13.6013 6.48V5.264ZM22.208 6.944H23.904V8.608C23.904 12.032 22.512 15.376 19.728 16.64L18.528 14.96C21.008 13.856 22.208 11.12 22.208 8.608V6.944ZM22.688 6.944H24.368V8.608C24.368 11.008 25.552 13.568 28.032 14.592L26.88 16.272C24.064 15.056 22.688 11.904 22.688 8.608V6.944ZM19.12 6.032H27.424V7.776H19.12V6.032ZM28.8 4.608H30.944V19.424H28.8V4.608Z'
        fill='white'
      />
    </svg>
  )
}

export default NotifyIcon
