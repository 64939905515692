import React from 'react'
import { Box, Input, VStack, Text, HStack } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import Animated from 'react-native-reanimated'

import { confirmCheckByEmailAuth, forgotPwAuth } from '@/services/auth'

// import useScroll from '@/hooks/useScroll'
import { AuthHeader, LargeButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK } from '@/constants/label'
import { AuthStackParamList } from '@/interfaces/navigation'
import { IForgot } from '@/interfaces/auth'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Platform } from 'react-native'
import ErrorMessageBox from './components/ErrorMessageBox'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_FORGOT_PW>

export default function ForgotPwScreen({ navigation }: Props) {
  const [isLoading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  // const { scrollHandler } = useScroll()

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<IForgot>({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data: IForgot) => {
    const emailRegex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i

    if (!emailRegex.test(data.email)) {
      setErrorMessage('이메일 형식과 맞지 않습니다.')
    } else {
      setLoading(true)
      const user = await confirmCheckByEmailAuth(data.email)
      if (user && user.provider === 'local') {
        await forgotPwAuth(data)
          .then(() => {
            setLoading(false)
            return navigation.push(AUTH_STACK.AUTH_FORGOT_PW_COMPLETE, data)
          })
          .catch(() => {
            setErrorMessage('입력한정보와 일치하는 계정이 존재하지 않습니다.')
            return setLoading(false)
          })
      } else {
        setErrorMessage('구글 또는 페이스북으로 가입된 계정입니다.')
        return setLoading(false)
      }
    }
  }

  return (
    <React.Fragment>
      <AuthStackHeader title='로그인' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        keyboardShouldPersistTaps={'handled'}
      >
        <Box mt='28px' mb='20px' mx='10%'>
          <AuthHeader>
            <VStack>
              <Text fontSize='15px' color='#959595'>
                가입시 등록한 이메일 정보로
              </Text>
              <Text fontSize='15px' color='#959595'>
                비밀번호를 찾아주세요.
              </Text>
            </VStack>
          </AuthHeader>
          <Box>
            <Controller
              control={control}
              name='email'
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  placeholder='이메일을 입력하세요.'
                  width='full'
                  height='40px'
                  borderColor='#F4F4F4'
                  backgroundColor='#F4F4F4'
                  borderRadius='3px'
                  placeholderTextColor='#5B5B5B'
                  mb='16px'
                  pl='10px'
                  fontSize='15px'
                  onChangeText={(value) => {
                    onChange(value)
                    setErrorMessage('')
                    formClearErrors('email')
                  }}
                  onBlur={onBlur}
                  value={value}
                  autoCapitalize='none'
                  autoComplete='email'
                  autoCorrect={false}
                  keyboardType='email-address'
                />
              )}
            />
            {formErrors.email ? (
              <ErrorMessageBox
                errorMessage='이메일을 입력해주세요.'
                marginTop='12px'
                marginBottom='12px'
              />
            ) : errorMessage ? (
              <ErrorMessageBox errorMessage={errorMessage} marginTop='12px' marginBottom='12px' />
            ) : null}
          </Box>
          <Box mb='20px'>
            <LargeButton
              buttonTitle='비밀번호 찾기'
              fill
              onPress={handleSubmit(onSubmit)}
              loading={isLoading}
            />
          </Box>
          <CButton onPress={() => navigation.replace(AUTH_STACK.AUTH_SIGN_IN)}>
            <HStack mb='36px' justifyContent='flex-end' alignItems='center'>
              <Text
                mr='16px'
                mb='1px'
                fontSize='16px'
                fontWeight='bold'
                color='#7C7C7C'
                textAlign='center'
              >
                로그인 화면으로 이동
              </Text>
              <FastImage
                source={require('@/assets/images/gray_left_arrow.png')}
                style={{
                  width: 8,
                  height: 16,
                }}
                resizeMode='contain'
              />
            </HStack>
          </CButton>
        </Box>
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
