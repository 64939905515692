import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { Center, HStack } from 'native-base'
import { Text, View } from 'native-base'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { BANNER_CATEGORIES, CATEGORY_LABEL } from '@/constants/site'
import FastImage from '@/components/FastImage'
import { TouchableHighlight } from 'react-native'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'

interface Props {
  board: any
  screen?: string
  onHandleFunc?: (value: string) => void
}

export default function MyPageBoardHeader(props: Props) {
  const { board, screen, onHandleFunc } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const board_category = CATEGORY_LABEL.find((item) => item.id === board.category)
  const banner_category = BANNER_CATEGORIES.find((item) => item.id === board.banner_category)
  const onPageChange = async (item: any) => {
    if (item.type == 'board' && board_category) {
      navigation.push(ROOT_STACK.TOP_TAB, { screen: board_category.page })
    } else if (item.type == 'news') {
      navigation.push(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.NEWS })
    } else {
      navigation.push(ROOT_STACK.BANNER_DETAIL, { id: item.category, isScroll: true })
    }
  }

  return (
    <HStack mt='4px' mb='8px' w='auto' justifyContent='space-between'>
      <TouchableHighlight onPress={() => onPageChange(board)} underlayColor='#e9e9e9'>
        <View
          justifyContent='center'
          alignItems='center'
          bgColor='#EAEAEA'
          borderRadius='3px'
          px='10px'
        >
          <Text mt='2px' color='#000000' fontSize='12px' fontWeight='bold'>
            {board.type == 'board'
              ? `${board_category?.label}`
              : board.type == 'advertisement'
              ? `${banner_category?.label} / ${board.banner_name}`
              : '뉴스'}
          </Text>
        </View>
      </TouchableHighlight>
      {onHandleFunc && screen && screen == 'MyPageGoodScreen' ? (
        <TouchableHighlight
          onPress={() => {
            onHandleFunc(board.reaction_id)
          }}
          underlayColor='#e9e9e9'
        >
          <View>
            <FastImage
              source={require('@/assets/images/favorite-red.png')}
              style={{
                width: 20,
                height: 20,
              }}
              resizeMode='contain'
            />
          </View>
        </TouchableHighlight>
      ) : null}
      {onHandleFunc && screen && screen == 'MyPageBannerBoardSubItem' ? (
        <TouchableHighlight
          onPress={() => {
            onHandleFunc('A')
          }}
          underlayColor='#e9e9e9'
        >
          <Center w='20px' h='20px'>
            <FastImage
              source={require('@/assets/images/menu-horizontal.png')}
              style={{
                width: 18,
                height: 4,
              }}
              resizeMode='contain'
            />
          </Center>
        </TouchableHighlight>
      ) : null}
      {onHandleFunc && screen && screen == 'MyPageScrapScreen' ? (
        <TouchableHighlight
          onPress={() => {
            onHandleFunc(board.reaction_id)
          }}
          underlayColor='#e9e9e9'
        >
          <View>
            <DynamicSvgIcon
              width='16px'
              height='20px'
              src={require('@/assets/svg/trash_icon.svg')}
            />
          </View>
        </TouchableHighlight>
      ) : null}
    </HStack>
  )
}
