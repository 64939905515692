import { Box, Modal, Text } from 'native-base'
import SelectBoardItem from '@/components/SelectBoardItem'
import { CATEGORY_LIST } from '@/constants/site'
import CButton from '@/components/CButton'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'

interface Props {
  isOpen: boolean
  onClose: () => void
  selectCategory?: (category: number, categoryTitle: string) => void
}

export default function ModalSelectBoard(props: Props) {
  const { isOpen, onClose, selectCategory } = props
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Content w={screenWidth - 60} minH='507px'>
        <Box mx='auto' bgColor='white' h='532px' borderRadius='8px' py='16px' px='26px'>
          <Box flexDirection='row' pb='16px' borderBottomWidth='1px' borderBottomColor='#7C7C7C'>
            <Text fontSize='18px' fontWeight='bold' mr='20px'>
              전체 게시판
            </Text>
            <Text fontSize='16px' fontWeight='medium' color='#9F9F9F' mt='3px'>
              게시판을 선택해주세요.
            </Text>
          </Box>
          <Box
            w='auto'
            mt='16px'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='space-between'
          >
            {CATEGORY_LIST.map((item) => {
              return (
                <CButton
                  key={item.category}
                  onPress={() =>
                    selectCategory ? selectCategory(item.category, item.title) : null
                  }
                >
                  <SelectBoardItem title={item.title} />
                </CButton>
              )
            })}
          </Box>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
