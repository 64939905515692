import React from 'react'
import { Box, HStack, Spinner, Text, VStack } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import moment from 'moment'

import { useAtom } from 'jotai'
import {
  searchNewsListAtom,
  searchBannerListAtom,
  searchBoardListAtom,
  handleHistory,
  handleSearch,
  handleSearchResultClear,
  searchAddressListAtom,
} from '@/atom/searchAtom'

// import useScroll from '@/hooks/useScroll'
import {
  SearchResultNewsItem,
  SearchResultBannerItem,
  SearchResultBoardItem,
  SearchHeader,
} from '@/screens/search/components'
import MoreTab from '@/components/MoreTab'
import { SEARCH_STACK } from '@/constants/label'
import { SearchStackParamList } from '@/interfaces/navigation'
import SearchResultAddressList from '@/screens/search/components/SearchResultAddressList'
import { authAtom } from '@/atom/authAtom'

type Props = NativeStackScreenProps<SearchStackParamList, SEARCH_STACK.SEARCH_PREVIEW>

export default function SearchPreviewScreen({ navigation, route }: Props) {
  const { keyword } = route.params
  const [, setHistory] = useAtom(handleHistory)
  const [, search] = useAtom(handleSearch)
  const [, searchResultClear] = useAtom(handleSearchResultClear)
  const [searchNewsList] = useAtom(searchNewsListAtom)
  const [searchBannerList] = useAtom(searchBannerListAtom)
  const [searchBoardList] = useAtom(searchBoardListAtom)
  const [searchAddressList] = useAtom(searchAddressListAtom)
  const [isLoading, setLoading] = React.useState(false)
  const [auth] = useAtom(authAtom)

  // const { scrollHandler } = useScroll()

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await searchResultClear()
      await search({
        keyword,
        start: 0,
        limit: 10,
      }).then(() => {
        setHistory({
          id: Number(moment().toDate()),
          keyword,
          date: moment().format('YY.MM.DD'),
        })
      })
      await setLoading(false)
    }
    void fetchData()
  }, [keyword, search, setHistory, searchResultClear])

  return (
    <React.Fragment>
      <SearchHeader />
      {isLoading ? (
        <Box flex={1} justifyContent='center'>
          <Spinner color='#ff6f1e' />
        </Box>
      ) : (
        <Animated.ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          // scrollEventThrottle={16}
          // onScroll={scrollHandler}
        >
          {searchNewsList ? (
            <Box my='10px'>
              <Text fontSize='18px' fontWeight='bold' px='16px' pt='8px' pb='16px'>
                한터 추천뉴스
              </Text>
              <VStack>
                {searchNewsList.contents.length ? (
                  searchNewsList.contents.map((item) => {
                    return <SearchResultNewsItem item={item} key={item.id} />
                  })
                ) : (
                  <HStack justifyContent='center' my='70px' mx='auto'>
                    <Text fontWeight='bold'>'{keyword}'</Text>
                    <Text>에 대한 검색결과가 없습니다.</Text>
                  </HStack>
                )}
              </VStack>
              {searchNewsList.totalCount > searchNewsList.contents.length ? (
                <MoreTab
                  moreButtonTextColor='뉴스'
                  moreButtonText='더보기'
                  moreButtonOnPress={() => navigation.push(SEARCH_STACK.SEARCH_NEWS, { keyword })}
                  useSideButton={false}
                />
              ) : null}
              <Box bgColor={'#F4F4F4'} h='11px' />
            </Box>
          ) : null}
          {searchBannerList ? (
            <Box mb='10px'>
              <Text fontSize='18px' fontWeight='bold' px='16px' pt='8px' pb='16px'>
                스폰서
              </Text>
              {searchBannerList.contents.length ? (
                searchBannerList.contents.map((item, index) => {
                  if (index % 2 === 1) {
                    const leftItem = searchBannerList.contents[index - 1]
                    const rightItem = searchBannerList.contents[index]

                    return (
                      <HStack justifyContent='space-between' px='15px' key={index}>
                        <SearchResultBannerItem item={leftItem} />
                        <SearchResultBannerItem item={rightItem} />
                      </HStack>
                    )
                  } else {
                    const lastIndex = searchBannerList.contents.length - 1
                    if (lastIndex === index) {
                      return (
                        <HStack justifyContent='space-between' px='15px' key={index}>
                          <SearchResultBannerItem item={item} />
                        </HStack>
                      )
                    }
                  }
                })
              ) : (
                <HStack justifyContent='center' my='70px' mx='auto'>
                  <Text fontWeight='bold'>'{keyword}'</Text>
                  <Text>에 대한 검색결과가 없습니다.</Text>
                </HStack>
              )}
              {searchBannerList.totalCount > searchBannerList.contents.length ? (
                <Box mt='17px' borderTopWidth='1px' borderTopColor='#f4f4f4'>
                  <MoreTab
                    moreButtonTextColor='스폰서'
                    moreButtonText='더보기'
                    moreButtonOnPress={() =>
                      navigation.push(SEARCH_STACK.SEARCH_BANNER, { keyword })
                    }
                    useSideButton={false}
                  />
                </Box>
              ) : null}
              <Box mt='16px' bgColor={'#F4F4F4'} h='11px' />
            </Box>
          ) : null}
          {searchBoardList ? (
            <Box mb='10px'>
              <Text fontSize='18px' fontWeight='bold' px='16px' pt='8px' pb='16px'>
                게시글
              </Text>
              <VStack>
                {searchBoardList.contents.length ? (
                  searchBoardList.contents.map((item) => {
                    return <SearchResultBoardItem item={item} key={item.id} />
                  })
                ) : (
                  <HStack justifyContent='center' my='70px' mx='auto'>
                    <Text fontWeight='bold'>'{keyword}'</Text>
                    <Text>에 대한 검색결과가 없습니다.</Text>
                  </HStack>
                )}
              </VStack>
              {searchBoardList.totalCount > searchBoardList.contents.length ? (
                <MoreTab
                  moreButtonTextColor='게시글'
                  moreButtonText='더보기'
                  moreButtonOnPress={() => navigation.push(SEARCH_STACK.SEARCH_BOARD, { keyword })}
                  useSideButton={false}
                />
              ) : null}
              <Box mt='16px' bgColor={'#F4F4F4'} h='11px' />
            </Box>
          ) : null}
          {auth.logged && searchAddressList ? (
            <SearchResultAddressList searchAddressList={searchAddressList} keyword={keyword} />
          ) : null}
        </Animated.ScrollView>
      )}
    </React.Fragment>
  )
}
