import React from 'react'
import { Modal } from 'native-base'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import LanguageModalItem from './LanguageModalItem'

interface Props {
  lang: string
  handleChangeLang: (lang: string, svg: string) => void
  handleCloseModal: () => void
}

export default function LanguageModal(props: Props) {
  const { lang, handleChangeLang, handleCloseModal } = props
  const [isMenuOpen] = React.useState(true)
  const [screenWidth] = useAtom(screenWidthAtom)

  const closeUserMenu = () => {
    handleCloseModal()
  }

  return (
    <React.Fragment>
      <Modal isOpen={isMenuOpen} onClose={() => closeUserMenu()}>
        <Modal.Content w={screenWidth - 80} borderRadius='5px'>
          <Modal.Body borderBottomWidth={0} pb={2}>
            <div className='notranslate'>
              <LanguageModalItem
                title='한국어'
                lang='ko'
                svg={require('@/assets/svg/icon-korea.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='日本語'
                lang='ja'
                svg={require('@/assets/svg/icon-japan.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='English'
                lang='en'
                svg={require('@/assets/svg/icon-united-states.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='简体中文'
                lang='zh-CN'
                svg={require('@/assets/svg/icon-china.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='繁體中文'
                lang='zh-TW'
                svg={require('@/assets/svg/icon-taiwan.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='Tiếng Việt'
                lang='vi'
                svg={require('@/assets/svg/icon-vietnam.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='Filipino'
                lang='tl'
                svg={require('@/assets/svg/icon-philippines.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='Português'
                lang='pt'
                svg={require('@/assets/svg/icon-portugal.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='नेपाली'
                lang='ne'
                svg={require('@/assets/svg/icon-nepal.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
              <LanguageModalItem
                title='Indonesia'
                lang='id'
                svg={require('@/assets/svg/icon-indonesia.svg')}
                setlang={lang}
                handleChangeLang={handleChangeLang}
              />
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
