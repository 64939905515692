import React from 'react'
import { Box, Center, Pressable, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import CreateModal from '@/components/CreateModal'
import { MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { INewsPaper } from '@/interfaces/newspaper'
import { RootStackParamList } from '@/interfaces/navigation'
import { screenHeightAtom, screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'

interface Props {
  item: INewsPaper | null
}

export default function NewsPaperMain(props: Props) {
  const { item } = props
  const [isOpenShare, setIsOpenShare] = React.useState(false)
  const [screenWidth] = useAtom(screenWidthAtom)
  const [screenHeight] = useAtom(screenHeightAtom)

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return item ? (
    <Center bgColor='#ffffff'>
      {isOpenShare && (
        <CreateModal
          modalType={MODAL_TYPE.SHARE}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => {
            setIsOpenShare(false)
          }}
        />
      )}
      <Center bgColor='#E8E8E8' p='16px'>
        <Box w={screenWidth} mb='16px'>
          <Pressable
            onPress={() => {
              if (item.pdf_file.url) {
                navigation.push(ROOT_STACK.BANNER_COMPANY_DETAIL, {
                  uri: item.pdf_file.url,
                  title: item.title,
                })
              }
            }}
          >
            <FastImage
              source={{ uri: item.thumbnail.url }}
              style={{
                width: screenWidth,
                height: screenHeight * 0.6,
              }}
              resizeMode='contain'
            />
          </Pressable>
        </Box>
        <Center flexDirection='row' mb='8px'>
          <Text fontSize='18px' mr='16px' fontWeight='bold'>
            {item.title.replace('한터', '').trim()}
          </Text>
          <CButton onPress={() => setIsOpenShare(true)}>
            <Center
              w='50px'
              h='32px'
              bgColor='white'
              rounded='20px'
              borderColor='#8F8F8F'
              borderWidth={1}
            >
              <FastImage
                source={require('@/assets/images/share.png')}
                style={{
                  width: 20,
                  height: 20,
                }}
                resizeMode='contain'
              />
            </Center>
          </CButton>
        </Center>
      </Center>
    </Center>
  ) : null
}
