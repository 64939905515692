import { useAtom } from 'jotai'
import {
  addressRightBoardListAtom,
  addressRightBoardTotalCountAtom,
  clubLeftBoardListAtom,
  clubLeftBoardTotalCountAtom,
  clubRightBoardListAtom,
  clubRightBoardTotalCountAtom,
  educationLeftBoardListAtom,
  educationLeftBoardTotalCountAtom,
  educationRightBoardListAtom,
  educationRightBoardTotalCountAtom,
  jobLeftBoardListAtom,
  jobLeftBoardTotalCountAtom,
  jobRightBoardListAtom,
  jobRightBoardTotalCountAtom,
  lawLeftBoardListAtom,
  lawLeftBoardTotalCountAtom,
  lawRightBoardListAtom,
  lawRightBoardTotalCountAtom,
  medicalLeftBoardListAtom,
  medicalLeftBoardTotalCountAtom,
  medicalRightBoardListAtom,
  medicalRightBoardTotalCountAtom,
  propertyLeftBoardListAtom,
  propertyLeftBoardTotalCountAtom,
  propertyRightBoardListAtom,
  propertyRightBoardTotalCountAtom,
  serviceLeftBoardListAtom,
  serviceLeftBoardTotalCountAtom,
  serviceRightBoardListAtom,
  serviceRightBoardTotalCountAtom,
  shoppingLeftBoardListAtom,
  shoppingLeftBoardTotalCountAtom,
  shoppingRightBoardListAtom,
  shoppingRightBoardTotalCountAtom,
  tripLeftBoardListAtom,
  tripLeftBoardTotalCountAtom,
  tripRightBoardListAtom,
  tripRightBoardTotalCountAtom,
} from '@/atom/initAtom'
import { findAllBoardByBoardLoad, findBoardCount } from '@/services/board'
import { LIMIT } from '@/constants/limit'
import { authAtom } from '@/atom/authAtom'
import { IBoard, IBoardLoad } from '@/interfaces/board'

export default function useBoard() {
  const [auth] = useAtom(authAtom)

  const [, setJobLeftBoardList] = useAtom(jobLeftBoardListAtom)
  const [, setJobLeftTotalCount] = useAtom(jobLeftBoardTotalCountAtom)
  const [, setJobRightBoardList] = useAtom(jobRightBoardListAtom)
  const [, setJobRightTotalCount] = useAtom(jobRightBoardTotalCountAtom)
  const [, setPropertyLeftBoardList] = useAtom(propertyLeftBoardListAtom)
  const [, setPropertyLeftTotalCount] = useAtom(propertyLeftBoardTotalCountAtom)
  const [, setPropertyRightBoardList] = useAtom(propertyRightBoardListAtom)
  const [, setPropertyRightTotalCount] = useAtom(propertyRightBoardTotalCountAtom)
  const [, setShoppingLeftBoardList] = useAtom(shoppingLeftBoardListAtom)
  const [, setShoppingLeftTotalCount] = useAtom(shoppingLeftBoardTotalCountAtom)
  const [, setShoppingRightBoardList] = useAtom(shoppingRightBoardListAtom)
  const [, setShoppingRightTotalCount] = useAtom(shoppingRightBoardTotalCountAtom)
  const [, setServiceLeftBoardList] = useAtom(serviceLeftBoardListAtom)
  const [, setServiceLeftTotalCount] = useAtom(serviceLeftBoardTotalCountAtom)
  const [, setServiceRightBoardList] = useAtom(serviceRightBoardListAtom)
  const [, setServiceRightTotalCount] = useAtom(serviceRightBoardTotalCountAtom)
  const [, setEducationLeftBoardList] = useAtom(educationLeftBoardListAtom)
  const [, setEducationLeftTotalCount] = useAtom(educationLeftBoardTotalCountAtom)
  const [, setEducationRightBoardList] = useAtom(educationRightBoardListAtom)
  const [, setEducationRightTotalCount] = useAtom(educationRightBoardTotalCountAtom)
  const [, setTripLeftBoardList] = useAtom(tripLeftBoardListAtom)
  const [, setTripLeftTotalCount] = useAtom(tripLeftBoardTotalCountAtom)
  const [, setTripRightBoardList] = useAtom(tripRightBoardListAtom)
  const [, setTripRightTotalCount] = useAtom(tripRightBoardTotalCountAtom)
  const [, setMedicalLeftBoardList] = useAtom(medicalLeftBoardListAtom)
  const [, setMedicalLeftTotalCount] = useAtom(medicalLeftBoardTotalCountAtom)
  const [, setMedicalRightBoardList] = useAtom(medicalRightBoardListAtom)
  const [, setMedicalRightTotalCount] = useAtom(medicalRightBoardTotalCountAtom)
  const [, setLawLeftBoardList] = useAtom(lawLeftBoardListAtom)
  const [, setLawLeftTotalCount] = useAtom(lawLeftBoardTotalCountAtom)
  const [, setLawRightBoardList] = useAtom(lawRightBoardListAtom)
  const [, setLawRightTotalCount] = useAtom(lawRightBoardTotalCountAtom)
  const [, setClubLeftBoardList] = useAtom(clubLeftBoardListAtom)
  const [, setClubLeftTotalCount] = useAtom(clubLeftBoardTotalCountAtom)
  const [, setClubRightBoardList] = useAtom(clubRightBoardListAtom)
  const [, setClubRightTotalCount] = useAtom(clubRightBoardTotalCountAtom)
  const [, setAddressRightBoardList] = useAtom(addressRightBoardListAtom)
  const [, setAddressRightTotalCount] = useAtom(addressRightBoardTotalCountAtom)

  const handleBoards = (boards: any) => {
    setJobLeftBoardList(boards.job.left.contents)
    setJobLeftTotalCount(boards.job.left.totalCount)
    setJobRightBoardList(boards.job.right.contents)
    setJobRightTotalCount(boards.job.right.totlaCount)
    setPropertyLeftBoardList(boards.property.left.contents)
    setPropertyLeftTotalCount(boards.property.left.totalCount)
    setPropertyRightBoardList(boards.property.right.contents)
    setPropertyRightTotalCount(boards.property.right.totlaCount)
    setShoppingLeftBoardList(boards.shopping.left.contents)
    setShoppingLeftTotalCount(boards.shopping.left.totalCount)
    setShoppingRightBoardList(boards.shopping.right.contents)
    setShoppingRightTotalCount(boards.shopping.right.totlaCount)
    setServiceLeftBoardList(boards.service.left.contents)
    setServiceLeftTotalCount(boards.service.left.totalCount)
    setServiceRightBoardList(boards.service.right.contents)
    setServiceRightTotalCount(boards.service.right.totlaCount)
    setEducationLeftBoardList(boards.education.left.contents)
    setEducationLeftTotalCount(boards.education.left.totalCount)
    setEducationRightBoardList(boards.education.right.contents)
    setEducationRightTotalCount(boards.education.right.totlaCount)
    setTripLeftBoardList(boards.trip.left.contents)
    setTripLeftTotalCount(boards.trip.left.totalCount)
    setTripRightBoardList(boards.trip.right.contents)
    setTripRightTotalCount(boards.trip.right.totlaCount)
    setMedicalLeftBoardList(boards.medical.left.contents)
    setMedicalLeftTotalCount(boards.medical.left.totalCount)
    setMedicalRightBoardList(boards.medical.right.contents)
    setMedicalRightTotalCount(boards.medical.right.totlaCount)
    setLawLeftBoardList(boards.law.left.contents)
    setLawLeftTotalCount(boards.law.left.totalCount)
    setLawRightBoardList(boards.law.right.contents)
    setLawRightTotalCount(boards.law.right.totlaCount)
    setClubLeftBoardList(boards.club.left.contents)
    setClubLeftTotalCount(boards.club.left.totalCount)
    setClubRightBoardList(boards.club.right.contents)
    setClubRightTotalCount(boards.club.right.totlaCount)
    setAddressRightBoardList(boards.address.right.contents)
    setAddressRightTotalCount(boards.address.right.totlaCount)
  }

  const findBoardListByCategory = async (category: number) => {
    if (category < 1 || category > 19) return

    const boardLoadForm: IBoardLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.BOARD_LIST_LIMIT,
      category: category,
    }

    await findAllBoardByBoardLoad(boardLoadForm).then((data) => {
      setBoardListByCategory(category, data)
    })
    await findBoardCount(category).then((data) => {
      setTotalCountByCategory(category, data)
    })
  }

  const setBoardListByCategory = (category: number, data: IBoard[]) => {
    if (category === 1) setJobLeftBoardList(data)
    else if (category === 2) setJobRightBoardList(data)
    else if (category === 3) setPropertyLeftBoardList(data)
    else if (category === 4) setPropertyRightBoardList(data)
    else if (category === 5) setShoppingLeftBoardList(data)
    else if (category === 6) setShoppingRightBoardList(data)
    else if (category === 7) setServiceLeftBoardList(data)
    else if (category === 8) setServiceRightBoardList(data)
    else if (category === 9) setEducationLeftBoardList(data)
    else if (category === 10) setEducationRightBoardList(data)
    else if (category === 11) setTripLeftBoardList(data)
    else if (category === 12) setTripRightBoardList(data)
    else if (category === 13) setMedicalLeftBoardList(data)
    else if (category === 14) setMedicalRightBoardList(data)
    else if (category === 15) setLawLeftBoardList(data)
    else if (category === 16) setLawRightBoardList(data)
    else if (category === 17) setClubLeftBoardList(data)
    else if (category === 18) setClubRightBoardList(data)
    else if (category === 19) setAddressRightBoardList(data)
  }

  const setTotalCountByCategory = (category: number, data: number) => {
    if (category === 1) setJobLeftTotalCount(data)
    else if (category === 2) setJobRightTotalCount(data)
    else if (category === 3) setPropertyLeftTotalCount(data)
    else if (category === 4) setPropertyRightTotalCount(data)
    else if (category === 5) setShoppingLeftTotalCount(data)
    else if (category === 6) setShoppingRightTotalCount(data)
    else if (category === 7) setServiceLeftTotalCount(data)
    else if (category === 8) setServiceRightTotalCount(data)
    else if (category === 9) setEducationLeftTotalCount(data)
    else if (category === 10) setEducationRightTotalCount(data)
    else if (category === 11) setTripLeftTotalCount(data)
    else if (category === 12) setTripRightTotalCount(data)
    else if (category === 13) setMedicalLeftTotalCount(data)
    else if (category === 14) setMedicalRightTotalCount(data)
    else if (category === 15) setLawLeftTotalCount(data)
    else if (category === 16) setLawRightTotalCount(data)
    else if (category === 17) setClubLeftTotalCount(data)
    else if (category === 18) setClubRightTotalCount(data)
    else if (category === 19) setAddressRightTotalCount(data)
  }

  return {
    handleBoards,
    findBoardListByCategory,
  }
}
