import { Box, HStack, Text, VStack } from 'native-base'
import useFormatter from '@/hooks/useFormatter'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { IYonhapNews } from '@/interfaces/news'

interface Props {
  news: IYonhapNews
  goodCount: number
  hateCount: number
  commentCount: number
  scrollY: number
  scrollToHandler: (scrollY: number) => void
}

export default function NewsDetailHeader(props: Props) {
  const { news, goodCount, commentCount, scrollY, scrollToHandler } = props
  const { numberCommaFormatter, datetimeFormatter } = useFormatter()
  return (
    <Box borderBottomWidth='1px' borderBottomColor='#eeeff2' pb='14px' pt='17px' bg='#ffffff'>
      <VStack px='17px'>
        {news.source_type == '연합뉴스' ? (
          <FastImage
            source={require('@/assets/images/yonhap-logo.png')}
            style={{
              width: 96,
              height: 36,
              marginBottom: 9,
            }}
            resizeMode='contain'
          />
        ) : null}
        <Text fontSize='26px' fontWeight='600' pb='3px' lineHeight='35px'>
          {news.title}
        </Text>
        <HStack space='26px' pt='8px' pb='8px'>
          <Text fontSize='13px' color='#8C8C8C'>
            입력 {datetimeFormatter(news.created_at)}
          </Text>
          <Text fontSize='13px' color='#8C8C8C'>
            수정 {datetimeFormatter(news.updated_at)}
          </Text>
        </HStack>
        <HStack justifyContent='space-between' alignItems='center'>
          <HStack alignItems='center'>
            <Text fontSize='14px' fontWeight={400} color='#6C6C6C' mr='18px'>
              조회수 {news.view_count}
            </Text>
            <CButton mr='6px' mt='2px'>
              {goodCount > 0 ? (
                <FastImage
                  source={require('@/assets/images/favorite-red.png')}
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: 5,
                  }}
                  resizeMode='contain'
                />
              ) : (
                <FastImage
                  source={require('@/assets/images/favorite.png')}
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: 5,
                  }}
                  resizeMode='contain'
                />
              )}
            </CButton>
            <Text fontSize='14px' fontWeight={400} color='#6C6C6C' mr='14px'>
              {numberCommaFormatter(goodCount)}
            </Text>
            {/* <CButton mr='6px'>
              <FastImage
                source={require('@/assets/images/hate.png')}
                style={{
                  width: 20,
                  height: 20,
                  marginTop: 2,
                }}
                resizeMode='contain'
              />
            </CButton>
            <Text fontSize='14px' fontWeight={400} color='#6C6C6C' mr='6px'>
              {numberCommaFormatter(hateCount)}
            </Text> */}
          </HStack>
          <CButton onTouchEnd={() => scrollToHandler(scrollY)}>
            <HStack
              space='7px'
              h='32px'
              w='64px'
              borderWidth='1px'
              borderRadius='20px'
              borderColor='#d9d9d9'
              justifyContent='center'
              alignItems='center'
            >
              <Text fontSize='14px' fontWeight={400} color='#000000'>
                댓글
              </Text>
              <Text fontSize='14px' fontWeight={400} color='#6C6C6C'>
                {numberCommaFormatter(commentCount)}
              </Text>
            </HStack>
          </CButton>
        </HStack>
      </VStack>
    </Box>
  )
}
