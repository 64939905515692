import { useAtom } from 'jotai'
import {
  addressBannerListAtom,
  clubBannerListAtom,
  educationBannerListAtom,
  jobBannerListAtom,
  lawBannerListAtom,
  medicalBannerListAtom,
  propertyBannerListAtom,
  serviceBannerListAtom,
  shoppingBannerListAtom,
  tripBannerListAtom,
} from '@/atom/initAtom'

export default function useBanner() {
  const [, setJobBannerList] = useAtom(jobBannerListAtom)
  const [, setPropertyBannerList] = useAtom(propertyBannerListAtom)
  const [, setShoppingBannerList] = useAtom(shoppingBannerListAtom)
  const [, setServiceBannerList] = useAtom(serviceBannerListAtom)
  const [, setEducationBannerList] = useAtom(educationBannerListAtom)
  const [, setTripBannerList] = useAtom(tripBannerListAtom)
  const [, setMedicalBannerList] = useAtom(medicalBannerListAtom)
  const [, setLawBannerList] = useAtom(lawBannerListAtom)
  const [, setClubBannerList] = useAtom(clubBannerListAtom)
  const [, setAddressBannerList] = useAtom(addressBannerListAtom)

  const handleBanners = (banners: any) => {
    setJobBannerList(banners.job)
    setPropertyBannerList(banners.property)
    setShoppingBannerList(banners.shopping)
    setServiceBannerList(banners.service)
    setEducationBannerList(banners.education)
    setTripBannerList(banners.trip)
    setMedicalBannerList(banners.medical)
    setLawBannerList(banners.law)
    setClubBannerList(banners.club)
    setAddressBannerList(banners.address)
  }

  return {
    handleBanners,
  }
}
