import { Box } from 'native-base'
import AddressPremiumItem from '@/screens/boards/components/AddressPremiumItem'
import { IAddress } from '@/interfaces/address'

interface Props {
  addressList: IAddress[]
}

export default function AddressPremium(props: Props) {
  const { addressList } = props

  const getPremiumList = () => {
    const list: IAddress[] = []
    addressList.map((item) => {
      if (item.premium) list.push(item)
    })
    return list
  }

  return (
    <Box w='full' mt='16px'>
      {getPremiumList().map((item) => {
        return <AddressPremiumItem address={item} key={item.id} />
      })}
    </Box>
  )
}
