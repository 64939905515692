import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'
import { ISetTokenForm } from '@/interfaces/push'
import { setToken } from '@/services/push'

export default function useToken() {
  async function registerForPushNotificationsAsync() {
    let token
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        // alert('Failed to get push token for push notification!')
        return
      }
      token = (await Notifications.getExpoPushTokenAsync()).data
      // console.log(token)
    } else {
      // console.log('Must use physical device for Push Notifications')
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      })
    }

    return token
  }

  const createToken = async (userId: number) => {
    // Token
    const token = await registerForPushNotificationsAsync()
    if (token) {
      const setTokenForm: ISetTokenForm = {
        token: token ? token : '',
        device: Platform.OS.toUpperCase(),
        user_id: userId !== 0 ? userId : null,
      }

      await setToken(setTokenForm)
    }
  }

  return {
    createToken,
  }
}
