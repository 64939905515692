import React from 'react'
import { Text, Box, HStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { profileAtom } from '@/atom/authAtom'
import FastImage from '@/components/FastImage'
import { DualButton } from '@/screens/auth/components'
import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'
import { AuthStackParamList, RootStackParamList } from '@/interfaces/navigation'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_REGISTER_COMPLETE>

export default function RegisterCompleteScreen({ navigation }: Props) {
  const [profile] = useAtom(profileAtom)
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <React.Fragment>
      <AuthStackHeader title='회원가입 완료' navigation={navigation} />
      <Box mt='48px' flex={1} alignItems='center'>
        <Box
          alignItems='center'
          borderBottomWidth='1px'
          borderBottomColor='#ff6f12'
          mb='34px'
          pb='37px'
        >
          <Box mb='6px'>
            <FastImage
              source={require('@/assets/images/check.png')}
              style={{
                width: 70,
                height: 70,
              }}
              resizeMode='contain'
            />
          </Box>
          <Box>
            <HStack justifyContent='center' mb='34px' space='5px'>
              <Text fontSize='22px' color='#000000'>
                회원가입이
              </Text>
              <Text fontSize='22px' fontWeight='bold' color='#000000'>
                완료
              </Text>
              <Text fontSize='22px' color='#000000'>
                되었습니다.
              </Text>
            </HStack>
            <HStack justifyContent='center' alignItems='center' space='2px'>
              <Text fontSize='17px' fontWeight='bold'>
                {profile?.nick_name}
              </Text>
              <Text fontSize='17px'>님의</Text>
            </HStack>
            <Text fontSize='17px' textAlign='center'>
              회원가입을 축하합니다.
            </Text>
            <Text fontSize='17px' textAlign='center'>
              알차고 실속있는 서비스로 찾아뵙겠습니다.
            </Text>
          </Box>
        </Box>
        <Box>
          <DualButton
            firstButtonText='홈으로'
            firstButtonOnPress={() =>
              rootNavigation.replace(ROOT_STACK.TOP_TAB, {
                screen: TOP_TAB.MAIN,
              })
            }
            secondButtonText='로그인'
            secondButtonFill
            secondButtonOnPress={() => navigation.replace(AUTH_STACK.AUTH_SIGN_IN)}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}
