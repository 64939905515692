import React from 'react'
import { Box, Text, View } from 'native-base'
import { TouchableHighlight } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import useUrl from '@/hooks/useUrl'
import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  item: IBanner
}

export default function BoardListBannerItem(props: Props) {
  const { item } = props
  const { handleUrl } = useUrl()
  const [screenWidth] = useAtom(screenWidthAtom)
  const { category_top_banner_image, category_top_thumbnail_path } = item
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <TouchableHighlight
      onPress={() => navigation.push(ROOT_STACK.BANNER_DETAIL, { id: item.id, isScroll: false })}
      underlayColor='#e9e9e9'
    >
      <Box
        backgroundColor='white'
        borderRadius='5px'
        w={screenWidth / 2 - 22}
        h={screenWidth / 2 - 22}
        m='4px'
        px='12px'
        py='12px'
        justifyContent='space-between'
      >
        <Box alignItems='center'>
          <FastImage
            source={{
              uri: category_top_banner_image
                ? handleUrl(category_top_banner_image.url)
                : category_top_thumbnail_path
                ? handleUrl(category_top_thumbnail_path)
                : '',
            }}
            // resizeMode='stretch'
            resizeMode='contain'
            style={{
              width: 135,
              height: 26,
            }}
          />
        </Box>
        <View>
          <Text
            textAlign='left'
            lineBreakMode='tail'
            numberOfLines={3}
            fontSize='15px'
            fontWeight={500}
          >
            {item.main_banner_text}
          </Text>
        </View>
        <Text
          fontSize='16px'
          fontWeight={700}
          textAlign='center'
          ellipsizeMode='tail'
          numberOfLines={1}
        >
          {item.contact_info}
        </Text>
      </Box>
    </TouchableHighlight>
  )
}
