import { Center, Text } from 'native-base'
import CButton from '@/components/CButton'

interface Props {
  buttonText: string
  onPressHandler: () => void
}

export default function CancelButton(props: Props) {
  const { buttonText, onPressHandler } = props

  return (
    <CButton onPress={() => onPressHandler()}>
      <Center
        h='33px'
        w='88px'
        borderRadius='20px'
        borderColor='#8F8F8F'
        borderWidth={1}
        marginTop='25px'
        marginLeft='5px'
      >
        <Text color='#8F8F8F' fontWeight='bold' fontSize={15}>
          {buttonText}
        </Text>
      </Center>
    </CButton>
  )
}
