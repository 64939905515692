import { HStack, VStack } from 'native-base'

import MainContentsImage from './MainContentsImage'
import { IBanner } from '@/interfaces/banner'

interface Props {
  banners: IBanner[]
}

export default function MainContentsBannerItem(props: Props) {
  const { banners } = props

  return (
    <VStack space='11px'>
      <HStack space='11px' mx='17px'>
        {banners.slice(0, 2).map((item, index) => {
          return <MainContentsImage key={`${index}_${item.id}`} item={item} />
        })}
      </HStack>
      <HStack space='11px' mx='17px'>
        {banners.slice(2, 4).map((item, index) => {
          return <MainContentsImage key={`${index}_${item.id}`} item={item} />
        })}
      </HStack>
    </VStack>
  )
}
