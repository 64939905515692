import qs from 'qs'
import { StrapiAPI } from '@/services/axios'
import { LIMIT } from '@/constants/limit'

export async function findAllMainBanner() {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
      {
        home_top_eq: true,
      },
    ],
    _sort: 'home_top_priority:asc,updated_at:desc',
  })

  const response = await StrapiAPI.get(`/banners?${query}`)
  return response.data
}

export async function findAllMainContentsBanner() {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
      {
        home_bottom_eq: true,
      },
    ],
    _sort: 'home_bottom_priority:asc,updated_at:desc',
  })
  const response = await StrapiAPI.get(`/banners?${query}`)
  return response.data
}

export async function findAllByBoardBanner(bannerCategory: number) {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
      {
        category_top: true,
      },
      {
        banner_category_eq: bannerCategory,
      },
    ],
    _limit: LIMIT.BOARD_BANNER_LIST,
    _sort: 'category_top_priority:asc,updated_at:desc',
  })
  const response = await StrapiAPI.get(`/banners?${query}`)
  return response.data
}

export async function findById(id: number) {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
    ],
  })
  const response = await StrapiAPI.get(`/banners/${id}?${query}`)
  return response.data
}

export async function findAllBoardBanners() {
  const response = await StrapiAPI.get('/apps/board-banners')
  return response.data
}
