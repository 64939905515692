import React from 'react'
import { RefreshControl } from 'react-native'
import { useScrollToTop } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { findAllBoardByBoardLoad, findBoardCount } from '@/services/board'
import { findAllAddressBook } from '@/services/address'
import { findAllByBoardBanner } from '@/services/banner'
import {
  addressBannerListAtom,
  addressBookListAtom,
  addressRightBoardListAtom,
  addressRightBoardTotalCountAtom,
} from '@/atom/initAtom'

import BoardList from '@/screens/boards/components/BoardList'
import { LIMIT } from '@/constants/limit'
import { TOP_TAB } from '@/constants/label'
import { BOARD_PROPS } from '@/constants/site'
import { TopTabParamList } from '@/interfaces/navigation'
import useScroll from '@/hooks/useScroll'

type Props = NativeStackScreenProps<TopTabParamList, TOP_TAB.ADDRESS>

export default function LoggedAddressScreen({ navigation, route }: Props) {
  const [auth] = useAtom(authAtom)
  const [bannerList, setBannerList] = useAtom(addressBannerListAtom)
  const [leftAddressBookList, setLeftAddressBookList] = useAtom(addressBookListAtom)
  const [rightBoardList, setRightBoardList] = useAtom(addressRightBoardListAtom)
  const [rightTotalCount, setRightTotalCount] = useAtom(addressRightBoardTotalCountAtom)
  const [currentCategory, setCurrentCategory] = React.useState(BOARD_PROPS.address.left.category)
  const [currentTabName, setCurrentTabName] = React.useState('LEFT')
  const [rightLimit, setRightLimit] = React.useState(LIMIT.BOARD_LIST_LIMIT)
  const [refreshing, setRefreshing] = React.useState(false)
  const [dataSourceCords, setDataSourceCords] = React.useState(0)

  const { scrollHandler } = useScroll()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  const handleCurrentCategory = (pCategory: number) => {
    setCurrentCategory(pCategory)
    setCurrentTabName(pCategory === BOARD_PROPS.address.right.category ? 'RIGHT' : 'LEFT')
  }

  const handleRefresh = React.useCallback(async () => {
    setRefreshing(true)
    if (currentTabName === 'RIGHT') {
      await Promise.all([
        findAllBoardByBoardLoad({
          userId: auth.user ? auth.user.id : 0,
          start: 0,
          limit: LIMIT.BOARD_LIST_LIMIT,
          category: BOARD_PROPS.address.right.category,
        }),
        findBoardCount(BOARD_PROPS.address.right.category),
        findAllByBoardBanner(BOARD_PROPS.address.bannerCategory),
      ]).then((values) => {
        setRightBoardList(values[0])
        setRightTotalCount(values[1])
        setBannerList(values[2])
        setRightLimit(LIMIT.BOARD_LIST_LIMIT)
      })
    } else {
      await Promise.all([
        findAllAddressBook(),
        findAllByBoardBanner(BOARD_PROPS.address.bannerCategory),
      ]).then((values) => {
        setLeftAddressBookList(values[0])
        setBannerList(values[1])
      })
    }
    await setRefreshing(false)
  }, [
    auth,
    currentTabName,
    setBannerList,
    setLeftAddressBookList,
    setRightBoardList,
    setRightTotalCount,
  ])

  const handleMore = React.useCallback(async () => {
    if (currentTabName === 'RIGHT') {
      const newLimit = rightLimit + LIMIT.BOARD_LIST_LIMIT
      await Promise.all([
        findAllBoardByBoardLoad({
          userId: auth.user ? auth.user.id : 0,
          start: 0,
          limit: newLimit,
          category: BOARD_PROPS.address.right.category,
        }),
        findBoardCount(BOARD_PROPS.address.right.category),
      ]).then((values) => {
        setRightBoardList(values[0])
        setRightTotalCount(values[1])
        setRightLimit(newLimit)
      })
    }
  }, [auth, rightLimit, currentTabName, setRightBoardList, setRightTotalCount])

  return (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={handleRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      showsVerticalScrollIndicator={false}
      onScroll={scrollHandler}
      scrollEventThrottle={16}
    >
      {currentTabName === 'RIGHT' ? (
        <BoardList
          list={rightBoardList && rightBoardList.length ? rightBoardList : []}
          currentCategory={currentCategory}
          handleCurrentCategory={handleCurrentCategory}
          isMore={rightTotalCount > rightLimit}
          handleMore={handleMore}
          bannerList={bannerList}
          setDataSourceCords={setDataSourceCords}
        />
      ) : (
        <BoardList
          list={leftAddressBookList && leftAddressBookList.length ? leftAddressBookList : []}
          currentCategory={currentCategory}
          handleCurrentCategory={handleCurrentCategory}
          isMore={false}
          handleMore={handleMore}
          bannerList={bannerList}
          setDataSourceCords={setDataSourceCords}
        />
      )}
    </Animated.ScrollView>
  )
}
