import React from 'react'
import { Center, Pressable } from 'native-base'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import FastImage from '@/components/FastImage'

export default function BoardListBannerNoneItem() {
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Pressable>
      <Center
        backgroundColor='white'
        borderRadius='5px'
        w={screenWidth / 2 - 22}
        h={screenWidth / 2 - 22}
        m='4px'
      >
        <FastImage
          source={require('@/assets/images/default-banner.png')}
          style={{
            width: 152,
            height: 152,
          }}
          resizeMode='cover'
        />
      </Center>
    </Pressable>
  )
}
