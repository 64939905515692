import React from 'react'
import { Box, Center, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'

import CreateCommentBox from '@/components/CreateCommentBox'
import CommentBoardHeader from '@/components/CommentBoardHeader'
import CommentNewsHeader from '@/components/CommentNewsHeader'
import CommentItem from '@/components/CommentItem'
import MoreTab from '@/components/MoreTab'
import CButton from '@/components/CButton'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { IAllComment } from '@/interfaces/comment'
import { RootStackParamList } from '@/interfaces/navigation'
import { IBanner } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'
import { IYonhapNews } from '@/interfaces/news'

interface Props {
  board?: IBoard
  typeId: number
  articleWriter: number
  category?: number
  news?: IYonhapNews
  commentType: 'BOARD' | 'NEWS' | 'ADVERTISEMENT'
  commentList: IAllComment[]
  commentCount: number
  totalCount: number
  reLoadCommentList: () => Promise<void>
  onMoreComment: () => Promise<void>
  scrollToHandler: (scrollY: number) => void
  commentScrollToHandler: (pageY: number) => void
  dataSourceCords: number
  banner?: IBanner
}

export default function Comment(props: Props) {
  const {
    board,
    typeId,
    articleWriter,
    category,
    commentType,
    commentList,
    commentCount,
    totalCount,
    reLoadCommentList,
    onMoreComment,
    scrollToHandler,
    dataSourceCords,
    commentScrollToHandler,
    banner,
  } = props
  const [auth] = useAtom(authAtom)
  const [commentOpenId, setCommentOpenId] = React.useState<number | null>(null)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  return (
    <Center bgColor='#F4F4F4'>
      <Box mb='11px' bgColor='white' w='full' pt='15px'>
        <Box px='18px'>
          {commentType === 'NEWS' ? (
            <CommentNewsHeader
              commentCount={commentCount}
              reLoadHandler={() => {
                void reLoadCommentList()
              }}
            />
          ) : (
            <CommentBoardHeader
              commentCount={commentCount}
              reLoadHandler={() => {
                void reLoadCommentList()
              }}
              type={commentType}
              typeId={typeId}
            />
          )}
        </Box>
        <Box borderBottomWidth='1px' borderBottomColor='#e5e5e5' pb='15px' px='18px' w='full'>
          {auth && auth.logged ? (
            <Center>
              <CreateCommentBox
                board={board}
                commentType={commentType}
                typeId={typeId}
                reLoadCommentList={reLoadCommentList}
                category={category}
                commentOpenId={commentOpenId}
                setCommentOpenId={setCommentOpenId}
                scrollToCommentTop={() => scrollToHandler(dataSourceCords)}
                banner={banner}
              />
            </Center>
          ) : (
            <CButton
              onPress={() =>
                navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
              }
            >
              <Center borderWidth='1px' borderColor='#C1C1C1' h='36px'>
                <Text fontSize='15px' color='#cdcdcd' mr='auto' ml='10px'>
                  로그인 해주세요.
                </Text>
              </Center>
            </CButton>
          )}
        </Box>
        {commentList.map((item, index) => {
          return (
            <CommentItem
              board={board}
              key={index}
              comment={item.comment}
              reCommentList={item.reCommentList}
              reLoadCommentList={reLoadCommentList}
              articleWriter={articleWriter}
              category={category}
              commentOpenId={commentOpenId}
              setCommentOpenId={setCommentOpenId}
              commentScrollToHandler={commentScrollToHandler}
              banner={banner}
            />
          )
        })}
        {totalCount > commentList.length ? (
          <MoreTab
            moreButtonText='댓글 더보기'
            moreButtonOnPress={() => {
              void onMoreComment()
            }}
            useSideButton={false}
          />
        ) : null}
      </Box>
    </Center>
  )
}
