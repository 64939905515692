import React, { useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Linking } from 'react-native'
import Constants from 'expo-constants'
import { Env } from '@/constants/env'
import { RootStackParamList } from '@/interfaces/navigation'

interface RouteChangeTrackerProps {
  navigation?: NativeStackNavigationProp<RootStackParamList, keyof RootStackParamList>
}

type RootStackNavigationState = {
  index: number
  key: string
  routeNames: (keyof RootStackParamList)[]
  routes: Array<{
    name: keyof RootStackParamList
    key: string
    params?: RootStackParamList[keyof RootStackParamList]
    url?: string
    title?: string
  }>
}

const RouteChangeTracker = ({ navigation }: RouteChangeTrackerProps) => {
  const [initialized, setInitialized] = useState(false)
  const deviceId = Constants.deviceId
  useFocusEffect(
    React.useCallback(() => {
      const handleRouteChange = ({
        data: { state },
      }: {
        data: { state: RootStackNavigationState }
      }) => {
        if (!navigation) return null
        const { url, title } = state.routes[state.index]
        const pagePath = state.routes[state.index].url?.split('?')[0] || ''
        const pageTitle = title || pagePath || '한일 생활정보 한터'
        const dayOfWeek = new Date().getDay()
        const hour = new Date().getHours()
        const MEASUREMENT_ID = Env.GA_TRACKING_ID

        Linking.getInitialURL().then((initialURL) => {
          const userAgent = window.navigator.userAgent
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            userAgent,
          )
          const isTablet = !isMobile && /iPad/i.test(userAgent)
          const deviceType = isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop'

          if (!isMobile && !isTablet && initialURL && !initialURL.includes('localhost')) {
            const gaUrl = `https://www.google-analytics.com/collect?v=1&t=pageview&tid=${MEASUREMENT_ID}&cid=${deviceId}&dp=${pagePath}&dt=${pageTitle}&cd1=${dayOfWeek}&cd2=${hour}&cd3=${deviceType}`

            fetch(gaUrl)
              .then((response) => {
                console.log(response)
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      }

      navigation?.addListener('state', handleRouteChange)

      return () => {
        navigation?.removeListener('state', handleRouteChange)
      }
    }, [navigation]),
  )

  return null
}

export default RouteChangeTracker
