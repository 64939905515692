import { Text, HStack, VStack, Box } from 'native-base'
import { useAtom } from 'jotai'
import BackButton from '@/components/BackButton'
import FastImage from '@/components/FastImage'
import React from 'react'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { TouchableOpacity } from 'react-native'
import { IBoard } from '@/interfaces/board'
import { authAtom } from '@/atom/authAtom'
import { IBanner } from '@/interfaces/banner'
import LanguageHeaderItem from '@/components/LanguageHeaderItem'
import MypageButton from '@/screens/auth/components/MypageButton'
import ModalBoardMenu from '@/components/ModalBoardMenu'

interface Props {
  navigation: any
  title: string | undefined
  banner: IBanner
  board: IBoard
}

export default function BannerBoardStackHeader(props: Props) {
  const { navigation, title, board } = props
  const [auth] = useAtom(authAtom)

  return (
    <VStack
      bg='white'
      h='49px'
      justifyContent='center'
      borderBottomWidth='1px'
      borderBottomColor='#E3E3E3'
    >
      <HStack w='100%' justifyContent='space-between' alignItems='center' px='15px'>
        <HStack alignItems='center'>
          <BackButton navigation={navigation} color='BLACK' />
          <TouchableOpacity
            onPress={() => navigation.navigate(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.MAIN })}
          >
            <FastImage
              source={require('@/assets/images/logo.png')}
              style={{ width: 51, height: 32, marginLeft: 15 }}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </HStack>
        <Box flex={1} mx='16px' mt='2px'>
          <Text
            textAlign='start'
            ellipsizeMode='tail'
            numberOfLines={1}
            fontSize='20px'
            fontWeight={800}
            color='#222222'
          >
            {title ? title : ''}
          </Text>
        </Box>
        <HStack alignItems='center'>
          <LanguageHeaderItem />
          <Box ml='15px'>
            <MypageButton navigation={navigation} />
          </Box>
          {auth.user && auth.user.id === board.writer ? (
            <Box ml='15px'>
              <ModalBoardMenu
                userId={board.writer}
                userNickName={board.nick_name}
                type='BOARD'
                isBlock={board.isblock}
                board={board}
              />
            </Box>
          ) : null}
        </HStack>
      </HStack>
    </VStack>
  )
}
