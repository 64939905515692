import React from 'react'
import { Box } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { authStatusAtom, handleSocialLogin } from '@/atom/authAtom'
import { useFocusEffect } from '@react-navigation/native'
import { AUTH_STATUS } from '@/constants/auth'
import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.FACEBOOK_LOGIN>

export default function FacebookScreen({ navigation, route }: Props) {
  const accessToken = route.params.access_token
  const [authStatus, setAuthStatus] = useAtom(authStatusAtom)
  const [, socialLogin] = useAtom(handleSocialLogin)

  const onSocialLogin = React.useCallback(
    async (token: string, provider: string) => {
      await socialLogin({ token, provider })
    },
    [socialLogin],
  )

  React.useEffect(() => {
    if (accessToken) {
      void onSocialLogin(accessToken, 'facebook')
    }
  }, [onSocialLogin, accessToken])

  useFocusEffect(() => {
    setAuthStatus(AUTH_STATUS.INIT)
  })

  React.useEffect(() => {
    if (authStatus === AUTH_STATUS.RESEND_SUCCESS) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_AUTHENTICATION })
    } else if (authStatus === AUTH_STATUS.NO_PROFILE) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_PROFILE })
    } else if (authStatus === AUTH_STATUS.LOGIN_SUCCESS) {
      navigation.push(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.MAIN })
    }
  }, [authStatus, navigation])

  return <Box></Box>
}
