import { Box, Modal, Text } from 'native-base'
import ConfirmButton from '@/components/ConfirmButton'
import CancelButton from '@/components/CancelButton'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'

interface Props {
  isOpen: boolean
  onAccept: () => void
  onClose: () => void
  titleMessage: string
  contentsMessage: string
  acceptButtonText: string
  cancelButtonText: string
}

export default function ModalConfirm(props: Props) {
  const {
    isOpen,
    onAccept,
    onClose,
    titleMessage,
    contentsMessage,
    acceptButtonText,
    cancelButtonText,
  } = props
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Content w={screenWidth - 80} borderRadius='8px'>
        <Modal.Header borderBottomWidth={0} pb={0}>
          <Text textAlign='center' fontSize='20px' fontWeight={600}>
            {titleMessage}
          </Text>
        </Modal.Header>
        <Modal.Body borderBottomWidth={0} pb={0}>
          <Text textAlign='center' fontSize='16px' color='#505050'>
            {contentsMessage}
          </Text>
        </Modal.Body>
        <Modal.Footer justifyContent='center' borderTopWidth={0}>
          <CancelButton buttonText={cancelButtonText} onPressHandler={() => onClose()} />
          <Box w={3}></Box>
          <ConfirmButton buttonText={acceptButtonText} onPressHandler={() => onAccept()} />
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
