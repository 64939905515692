import { Box, Center, HStack, Text, View } from 'native-base'
import useFormatter from '@/hooks/useFormatter'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import ModalUserMenu from '@/components/ModalUserMenu'
import { IBoard } from '@/interfaces/board'
import NotifyIcon from '@/assets/svgIcon/NotifyIcon'
import RequiredIcon from '@/assets/svgIcon/RequiredIcon'
import ExpireIcon from '@/assets/svgIcon/ExpireIcon'
import NewIcon from '@/assets/svgIcon/NewIcon'

interface Props {
  board: IBoard
  goodCount: number
  hateCount: number
  commentCount: number
  reLoad: () => void
  scrollY: number
  scrollToHandler: (scrollY: number) => void
}

export default function BoardDetailHeader(props: Props) {
  const { board, goodCount, commentCount, reLoad, scrollY, scrollToHandler } = props
  const {
    datetimeFormatter,
    numberCommaFormatter,
    compareIsBeforeDate,
    compareTodayDate,
    extractColorCode,
  } = useFormatter()

  // 이미지가 표시되어야 하는지 여부 확인
  const showNewIcon = compareTodayDate(board.created_at) && !board.isblock
  const showNoticeIcon =
    board.writing_type && board.writing_type.trim() === '공지' && !board.isblock
  const showRequiredIcon =
    board.writing_type && board.writing_type.trim() === '필독' && !board.isblock
  const showExpiredIcon = compareIsBeforeDate(board.board_expired_date) && !board.isblock
  const extractedColor = extractColorCode(board.color_type)

  return (
    <Box
      borderTopColor='#eeeff2'
      borderTopWidth='1px'
      borderBottomColor='#eeeff2'
      borderBottomWidth='1px'
      py='15px'
      px='18px'
      w='full'
      position='relative'
    >
      <Text fontSize='26px' fontWeight={700} color={board.isblock ? '#6C6C6C' : '#292929'}>
        {showExpiredIcon && (
          <Box mr='8px' top='4px'>
            <ExpireIcon width='40' height='27' fillColor='#000000' />
          </Box>
        )}
        {showNoticeIcon && (
          <Box mr='8px' top='4px'>
            <NotifyIcon width='40' height='27' fillColor={extractedColor} />
          </Box>
        )}
        {showRequiredIcon && (
          <Box mr='8px' top='4px'>
            <RequiredIcon width='40' height='27' fillColor={extractedColor} />
          </Box>
        )}
        {showNewIcon && (
          <Box mr='8px'>
            <NewIcon width='20' height='20' fillColor='#FF6F1E' />
          </Box>
        )}
        {board.title}
      </Text>
      <View mt='8px' mb='8px' flexDirection='row' justifyContent='space-between'>
        <ModalUserMenu
          userId={board.writer}
          userNickName={board.nick_name}
          isBlock={board.isblock}
          blockCallback={() => reLoad()}
          unblockCallback={() => reLoad()}
        />
      </View>
      <Box flexDirection='row' justifyContent='space-between'>
        <Center flexDirection='row'>
          <Text color='#6C6C6C' fontSize='13px' pt='3px' mr='16px' numberOfLines={1}>
            {datetimeFormatter(board.created_at)}
          </Text>
          <Text fontSize='13px' fontWeight={400} color='#6C6C6C' mr='16px'>
            조회수 {numberCommaFormatter(board.view_count)}
          </Text>
          <CButton mr='6px' mt='2px'>
            {goodCount > 0 ? (
              <FastImage
                source={require('@/assets/images/favorite-red.png')}
                style={{
                  width: 18,
                  height: 18,
                  marginBottom: 5,
                }}
                resizeMode='contain'
              />
            ) : (
              <FastImage
                source={require('@/assets/images/favorite.png')}
                style={{
                  width: 18,
                  height: 18,
                  marginBottom: 5,
                }}
                resizeMode='contain'
              />
            )}
          </CButton>
          <Text fontSize='14px' fontWeight={400} color='#6C6C6C' mr='14px'>
            {numberCommaFormatter(goodCount)}
          </Text>
        </Center>
        <CButton onTouchEnd={() => scrollToHandler(scrollY)}>
          <HStack
            space='7px'
            h='32px'
            w='64px'
            borderWidth='1px'
            borderRadius='20px'
            borderColor='#8F8F8F'
            justifyContent='center'
            alignItems='center'
          >
            <Text fontSize='14px' fontWeight={400} color='#000000'>
              댓글
            </Text>
            <Text fontSize='14px' fontWeight={400} color='#6C6C6C'>
              {numberCommaFormatter(commentCount)}
            </Text>
          </HStack>
        </CButton>
      </Box>
    </Box>
  )
}
