import { atom } from 'jotai'
import { findAllByKeyword } from '@/services/search'
import {
  ISearchBanner,
  ISearchBoard,
  ISearchHistory,
  ISearchLoad,
  ISearchNews,
  ISearchAddress,
} from '@/interfaces/search'

export const globalKeywordAtom = atom<string>('')
export const searchNewsListAtom = atom<ISearchNews | null>(null)
export const searchBannerListAtom = atom<ISearchBanner | null>(null)
export const searchBoardListAtom = atom<ISearchBoard | null>(null)
export const searchAddressListAtom = atom<ISearchAddress | null>(null)
export const searchHistoryAtom = atom<ISearchHistory[]>([])

searchHistoryAtom.onMount = (setAtom) => {
  const auth = localStorage.getItem('search')
  setAtom(JSON.parse(auth as string))
}

export const handleSearch = atom(null, async (get, set, search: ISearchLoad) => {
  const { keyword, start, limit } = search
  await findAllByKeyword({
    keyword,
    start,
    limit,
  }).then((data) => {
    set(searchNewsListAtom, data.newsList)
    set(searchBannerListAtom, data.bannerList)
    set(searchBoardListAtom, data.boardList)
    set(searchAddressListAtom, data.addressList)
  })
})

export const handleSearchResultClear = atom(null, (_, set) => {
  set(searchNewsListAtom, null)
  set(searchBannerListAtom, null)
  set(searchBoardListAtom, null)
  set(searchAddressListAtom, null)
})

export const handleHistory = atom(
  (get) => get(searchHistoryAtom),
  async (get, set, newHistory: ISearchHistory) => {
    const oldHistory = get(searchHistoryAtom)

    if (oldHistory) {
      const duplicateCheck = oldHistory.find((item) => item.keyword === newHistory.keyword)
      if (!duplicateCheck) {
        set(searchHistoryAtom, [newHistory, ...oldHistory])
        await localStorage.setItem('search', JSON.stringify([newHistory, ...oldHistory]))
      } else {
        const removeOldHistory = oldHistory.filter((item) => item.keyword !== newHistory.keyword)
        set(searchHistoryAtom, [newHistory, ...removeOldHistory])
        await localStorage.setItem('search', JSON.stringify([newHistory, ...removeOldHistory]))
      }
    } else {
      set(searchHistoryAtom, [newHistory])
      await localStorage.setItem('search', JSON.stringify([newHistory]))
    }
  },
)

export const handleRemove = atom(null, async (get, set, removeId: number) => {
  const oldHistory = get(searchHistoryAtom)
  const newHistory = oldHistory.filter((item) => item.id !== removeId)
  set(searchHistoryAtom, newHistory)
  await localStorage.setItem('search', JSON.stringify(newHistory))
})

export const handleAllRemove = atom(null, async (_, set) => {
  set(searchHistoryAtom, [])
  await localStorage.removeItem('search')
})
