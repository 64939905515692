import { Center, Modal, Text } from 'native-base'
import CButton from '@/components/CButton'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'

interface Props {
  isOpen: boolean
  onAccept: () => void
  onClose: () => void
  contentsMessage: string
  acceptButtonText: string
  cancelButtonText: string
}

export default function ModalConfirmRound(props: Props) {
  const { isOpen, onAccept, onClose, contentsMessage, acceptButtonText, cancelButtonText } = props
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Content w={screenWidth - 80} borderRadius='8px' pt='15px' pb='10px'>
        <Modal.Body borderBottomWidth={0} pb={0}>
          <Text fontSize='18px' textAlign='center'>
            {contentsMessage}
          </Text>
        </Modal.Body>
        <Modal.Footer justifyContent='center' borderTopWidth={0}>
          <CButton onPress={() => onClose()}>
            <Center
              w='95px'
              h='33px'
              px='21px'
              backgroundColor='white'
              borderRadius='17px'
              borderWidth='1px'
              borderColor='#8F8F8F'
              margin='5px'
            >
              <Text color='#8f8f8f' fontWeight='600' fontSize={15}>
                {cancelButtonText}
              </Text>
            </Center>
          </CButton>
          <CButton onPress={() => onAccept()}>
            <Center
              w='95px'
              h='33px'
              px='21px'
              backgroundColor='#ff6f1e'
              borderRadius='17px'
              borderWidth='1px'
              borderColor='#ff6f1e'
              margin='5px'
            >
              <Text color='white' fontWeight='600' fontSize={15}>
                {acceptButtonText}
              </Text>
            </Center>
          </CButton>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
