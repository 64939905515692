import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'
import {
  IComment,
  ICommentForm,
  ICommentLoad,
  IReComment,
  IReCommentForm,
} from '@/interfaces/comment'
import { sendCommentPush } from '@/services/push'
import { CATEGORY_LIST } from '@/constants/site'

export async function findAllCommentByCommentLoad(commentLoad: ICommentLoad) {
  const { type, typeId, start, limit, userId } = commentLoad
  const response = await StrapiAPI.get(
    `/comments/all/type?type=${type}&type_id=${typeId}&start=${start}&limit=${limit}&userId=${userId}`,
  )

  const commentList = response.data.commentList
  const reCommentList = response.data.reCommentList
  const commentCount = response.data.totalCount
  const result: any[] = []
  commentList.map((comment: IComment) => {
    result.push({
      comment: comment,
      reCommentList: reCommentList.filter(
        (reComment: IReComment) => reComment.comment === comment.id,
      ),
    })
  })

  return {
    result,
    commentCount,
  }
}

export async function findCommentCount(param: { type: string; typeId: number }) {
  const { type, typeId } = param
  const response = await StrapiAPI.get(
    `/comments/count?is_delete=false&type=${type}&type_id=${typeId}`,
  )
  return response.data
}

//댓글 새글 작성시
export async function createCommentByCommentForm(commentForm: ICommentForm) {
  await StrapiJwtAPI(commentForm.jwt)
    .post('/comments', commentForm)
    .then(async ({ data }) => {
      if (data && data.type === 'board') {
        const category_str = CATEGORY_LIST.find((item) => item.category === commentForm.category)
        const screenName = 'BOARD_DETAIL'
        const url = `hanteo://${screenName}?id=${data.type_id}&category=${commentForm.category}&goComment=true`
        void sendCommentPush({
          jwt: commentForm.jwt,
          type: data.type,
          typeId: data.type_id,
          title: category_str?.title ? category_str.title : '댓글 알림',
          contents: setContents(commentForm, data),
          url,
        })
      } else if (data && data.type === 'advertisement') {
        const screenName = 'BANNER_BOARD_DETAIL'
        const url = `hanteo://${screenName}?id=${data.type_id}&category=${commentForm.category}&banner=${commentForm.banner}&goComment=true`
        void sendCommentPush({
          jwt: commentForm.jwt,
          type: data.type,
          typeId: data.type_id,
          title: commentForm.banner?.company_name ? commentForm.banner?.company_name : '댓글 알림',
          contents: setContents(commentForm, data),
          url,
        })
      }
    })
}

export async function updateCommentByCommentForm(commentForm: ICommentForm) {
  await StrapiJwtAPI(commentForm.jwt).put(`/comments/${commentForm.id}`, commentForm)
}

export async function removeCommentByCommentId(param: { id: number; jwt: string }) {
  await StrapiJwtAPI(param.jwt).delete(`/comments/custom/${param.id}`)
}

//대댓글 새글 작성시
export async function createReCommentByCommentForm(reCommentForm: IReCommentForm) {
  await StrapiJwtAPI(reCommentForm.jwt)
    .post('/re-comments', reCommentForm)
    .then(async ({ data }) => {
      if (data && data.type === 'board') {
        const category_str = CATEGORY_LIST.find((item) => item.category === reCommentForm.category)
        const screenName = 'BOARD_DETAIL'
        const url = `hanteo://${screenName}?id=${data.type_id}&category=${reCommentForm.category}&goComment=true`
        void sendCommentPush({
          jwt: reCommentForm.jwt,
          type: data.type,
          typeId: data.type_id,
          title: category_str?.title ? category_str.title : '댓글 알림',
          contents: setContents(reCommentForm, data),
          url,
        })
      } else if (data && data.type === 'advertisement') {
        const screenName = 'BANNER_BOARD_DETAIL'
        const url = `hanteo://${screenName}?id=${data.type_id}&category=${reCommentForm.category}&banner=${reCommentForm.banner}&goComment=true`
        void sendCommentPush({
          jwt: reCommentForm.jwt,
          type: data.type,
          typeId: data.type_id,
          title: reCommentForm.banner?.company_name
            ? reCommentForm.banner?.company_name
            : '댓글 알림',
          contents: setContents(reCommentForm, data),
          url,
        })
      }
    })
}

export async function updateReCommentByCommentForm(reCommentForm: IReCommentForm) {
  await StrapiJwtAPI(reCommentForm.jwt).put(`/re-comments/${reCommentForm.id}`, reCommentForm)
}

export async function removeReCommentByReCommentId(param: { id: number; jwt: string }) {
  await StrapiJwtAPI(param.jwt).delete(`/re-comments/custom/${param.id}`)
}

function setContents(reCommentForm: any, data: any) {
  const title_list = reCommentForm.board.title.split(' ')
  let title = ''
  if (title_list[0]) {
    title += title_list[0]
  }
  if (title_list[1]) {
    title += ' ' + title_list[1]
  }
  if (title_list[2]) {
    title += ' ' + title_list[2]
  }
  if (title_list.length > 3) {
    return '[' + title + '..]글의 댓글:' + data.contents
  } else {
    return '[' + title + ']글의 댓글:' + data.contents
  }
}
