import React from 'react'
import { Center, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import CreateModal from '@/components/CreateModal'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  type: string
  typeId: number
  typeCategory?: number
  useComplainButton?: boolean
  saveComplain?: (value: number) => void
  saveArticleElement: (elementType: string, value: any) => void
  articleWriter: number
  goodCount: number
  hateCount: number
  good: boolean
  hate: boolean
  scrap: boolean
  complain: number | null
}

export default function ArticleElementsBox(props: Props) {
  const {
    type,
    typeId,
    typeCategory,
    useComplainButton,
    saveComplain,
    saveArticleElement,
    articleWriter,
    goodCount,
    good,
    scrap,
    complain,
  } = props
  const [auth] = useAtom(authAtom)

  const [isOpenComplain, setIsOpenComplain] = React.useState(false)
  const [isOpenShare, setIsOpenShare] = React.useState(false)
  const [isOpenAlertSelfComplain, setIsOpenAlertSelfComplain] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const handleOpenComplain = (value: boolean) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (auth.user && auth.user.id === articleWriter) {
      setIsOpenAlertSelfComplain(true)
      return
    }

    setIsOpenComplain(value)
  }

  return (
    <Center flexDirection='row' justifyContent='center' pt='16px'>
      {isOpenAlertSelfComplain && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_SELF_COMPLAIN}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => {
            setIsOpenAlertSelfComplain(false)
          }}
        />
      )}
      {isOpenShare && (
        <CreateModal
          modalType={MODAL_TYPE.SHARE}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => {
            setIsOpenShare(false)
          }}
          articleElements={{ type, typeId, typeCategory }}
        />
      )}
      {isOpenComplain && (
        <CreateModal
          modalType={MODAL_TYPE.COMPLAIN}
          onAccept={() => {
            return
          }}
          saveComplain={saveComplain}
          handleCloseModal={() => {
            setIsOpenComplain(false)
          }}
          articleElements={{ type, typeId }}
        />
      )}
      <CButton onPress={() => saveArticleElement('GOOD', !good)}>
        <HStack h='32px' px='8px' mr='22px' borderRadius='2px'>
          {good ? (
            <FastImage
              source={require('@/assets/images/favorite-red.png')}
              style={{
                width: 29,
                height: 29,
              }}
              resizeMode='contain'
            />
          ) : (
            <FastImage
              source={require('@/assets/images/favorite-large.png')}
              style={{
                width: 30,
                height: 30,
              }}
              resizeMode='contain'
            />
          )}
          <Text ml='10px' mt='2pt' fontSize='18px' fontWeight={700}>
            {goodCount}
          </Text>
        </HStack>
      </CButton>
      <CButton onPress={() => setIsOpenShare(true)}>
        <Center
          borderWidth='1px'
          borderColor='#8F8F8F'
          borderRadius='20px'
          w='48px'
          h='40px'
          px='8px'
          mr='10px'
        >
          <FastImage
            source={require('@/assets/images/share.png')}
            style={{
              width: 16,
              height: 16,
            }}
            resizeMode='contain'
          />
        </Center>
      </CButton>
      <CButton onPress={() => saveArticleElement('SCRAP', !scrap)}>
        <Center
          borderWidth='1px'
          borderColor={scrap ? '#FF6F1E' : '#8F8F8F'}
          backgroundColor={scrap ? '#FFF1EA' : '#FFFFFF'}
          borderRadius='20px'
          w='69px'
          h='40px'
          px='8px'
          mr='10px'
        >
          <Text mt='5px' fontSize='16px' color={scrap ? '#FF6F1E' : '#8F8F8F'}>
            스크랩
          </Text>
        </Center>
      </CButton>
      {useComplainButton && (
        <CButton onPress={() => handleOpenComplain(true)}>
          <Center
            borderWidth='1px'
            borderColor={complain ? '#FF6F1E' : '#8F8F8F'}
            backgroundColor={complain ? '#FFF1EA' : '#FFFFFF'}
            borderRadius='20px'
            w='54px'
            h='40px'
            px='8px'
          >
            <Text mt='5px' fontSize='16px' color={complain ? '#FF6F1E' : '#8F8F8F'}>
              신고
            </Text>
          </Center>
        </CButton>
      )}
    </Center>
  )
}
