import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'
import { IBoardLoad, ICreateBoardForm } from '@/interfaces/board'

export async function findAllBoardByBoardLoad(boardLoad: IBoardLoad) {
  const { category, userId, start, limit } = boardLoad

  const response = await StrapiAPI.get(
    `/boards?category=${category}&userId=${userId}&start=${start}&limit=${limit}`,
  )
  return response.data
}

export async function findOneBoardById(param: { boardId: number; userId: number }) {
  const { boardId, userId } = param
  const response = await StrapiAPI.get(`/boards/${boardId}?userId=${userId}`)
  return response.data
}

export async function findAllBoard(userId: number, start: number, limit: number) {
  const response = await StrapiAPI.get(
    `/apps/boards?userId=${userId}&start=${start}&limit=${limit}`,
  )
  return response.data
}

export async function findBoardCount(category: number) {
  const query = { category: category }
  const response = await StrapiAPI.post('/boards/findCount', query)
  return response.data.totalCount
}

export async function createBoard(createBoardForm: ICreateBoardForm) {
  const response = await StrapiJwtAPI(createBoardForm.jwt).post('/boards', createBoardForm.board)
  return response.data
}

export async function updateBoard(createBoardForm: ICreateBoardForm) {
  const response = await StrapiJwtAPI(createBoardForm.jwt).put(
    `/boards/${createBoardForm.board.id}`,
    createBoardForm.board,
  )
  return response.data
}

export async function updateBoardViewCount(id: number) {
  await StrapiAPI.put(`/boards/view/${id}`)
}

export async function removeBoard(props: { jwt: string; id: number }) {
  const { jwt, id } = props
  await StrapiJwtAPI(jwt).delete(`/boards/custom/${id}`)
}
