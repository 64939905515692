import { Linking } from 'react-native'
import { Box, Center, HStack, Text } from 'native-base'
import CButton from '@/components/CButton'
import { IAddress } from '@/interfaces/address'
import React from 'react'
import FastImage from '@/components/FastImage'

interface Props {
  address: IAddress
}

export default function AddressNormalItem(props: Props) {
  const { address } = props
  const [isPressed, setPressed] = React.useState(false)

  return (
    <Box borderBottomWidth='1px' borderBottomColor='#e8e8e8' pt='10px'>
      <Box my='auto'>
        <Text fontSize='18px' fontWeight={700} lineBreakMode='tail' numberOfLines={1} minH='19px'>
          {address.company}
        </Text>
        {address.address == null || address.address == '' ? null : (
          <Text fontSize='16px' color='#7C7C7C' lineBreakMode='tail' numberOfLines={2} minH='20px'>
            {address.address}
          </Text>
        )}
        <Box flexDirection='row' justifyContent='space-between' mt='6px' mb='12px' minH='20px'>
          {address.contact_info ? (
            <CButton onPress={() => Linking.openURL(`tel:${address.contact_info}`)}>
              <HStack alignContent='center'>
                <FastImage
                  source={require('@/assets/images/address-phone.png')}
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 8,
                    marginTop: 6,
                  }}
                  resizeMode='contain'
                />
                <Text mt='3px' fontSize='16px' fontWeight={400} my='auto'>
                  {address.contact_info}
                </Text>
              </HStack>
            </CButton>
          ) : (
            <Box></Box>
          )}
          {address.site_url && address.site_url != '' ? (
            <CButton
              // bgColor={`${isPressed ? '#F4F4F4' : 'white'}`}
              pr='6px'
              mr='6px'
              onPressOut={() => setPressed(false)}
              onPressIn={() => setPressed(true)}
              onPress={() => {
                if (address.site_url) {
                  void Linking.openURL(address.site_url)
                }
              }}
            >
              <Center w='100px' h='24px' mt='2px' bgColor='#989898' borderRadius='5px'>
                <Text mt='3px' color='white' fontSize='13px' fontWeight={600}>
                  사이트 바로가기
                </Text>
              </Center>
            </CButton>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
