import React from 'react'
import { Box, HStack, Modal, Text } from 'native-base'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK, USER_PAGE_TAB } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { anotherUserIdAtom } from '@/atom/userPageAtom'
import { screenWidthAtom } from '@/atom/navigationAtom'
import CButton from '../CButton'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'

interface Props {
  userId: number
  userNickName: string
  isBlock: boolean
  isMember: boolean
  handleCloseModal: () => void
  openModal: (modalType: MODAL_TYPE) => void
  memberAgree: () => void
  memberDisagree: () => void
}

export default function UserMenuModal(props: Props) {
  const {
    userId,
    userNickName,
    isBlock,
    isMember,
    handleCloseModal,
    openModal,
    memberAgree,
    memberDisagree,
  } = props
  const [isMenuOpen, setIsMenuOpen] = React.useState(true)
  const [, handleAnotherUserId] = useAtom(anotherUserIdAtom)
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)

  //차단하기
  const [Member, setMember] = React.useState(isMember)

  const closeUserMenu = () => {
    handleCloseModal()
  }

  const sendMessage = () => {
    closeUserMenu()
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    navigation.push(ROOT_STACK.SEND_MESSAGE, { userId, userNickName })
  }

  const goToUserPageBoard = () => {
    closeUserMenu()
    handleAnotherUserId(userId)
    navigation.push(ROOT_STACK.USER_PAGE_TAB, {
      screen: USER_PAGE_TAB.USER_PAGE_BOARD,
    })
  }

  const goToUserPageComment = () => {
    closeUserMenu()
    handleAnotherUserId(userId)
    navigation.push(ROOT_STACK.USER_PAGE_TAB, {
      screen: USER_PAGE_TAB.USER_PAGE_COMMENT,
    })
  }

  const setUserBlock = () => {
    closeUserMenu()
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    openModal(MODAL_TYPE.BLOCK_USER)
  }

  const setUserUnBlock = () => {
    closeUserMenu()
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    openModal(MODAL_TYPE.UNBLOCK_USER)
  }

  const styleObj = {
    borderRadius: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 14,
  }

  return (
    <React.Fragment>
      <Modal isOpen={isMenuOpen} onClose={() => closeUserMenu()}>
        <Modal.Content w={screenWidth - 80} borderRadius='5px'>
          <Modal.Body borderBottomWidth={0} pb={2}>
            {!isBlock ? (
              <Box>
                {!Member ? (
                  <CButton
                    bgColor='#FF6F1E33'
                    style={styleObj}
                    onPress={() => {
                      setMember(true)
                      memberAgree()
                    }}
                  >
                    <HStack alignSelf='center'>
                      <Box mt='2px'>
                        <DynamicSvgIcon
                          width='16px'
                          height='16px'
                          src={require('@/assets/svg/bell-on.svg')}
                        />
                      </Box>
                      <Box ml='8px'>
                        <Text
                          textAlign='center'
                          fontSize='16px'
                          fontWeight={700}
                          numberOfLines={1}
                          ellipsizeMode='tail'
                          color='#E85300'
                        >
                          회원 구독
                        </Text>
                      </Box>
                    </HStack>
                  </CButton>
                ) : (
                  <CButton
                    bgColor='#ECECEC'
                    style={styleObj}
                    onPress={() => {
                      setMember(false)
                      memberDisagree()
                    }}
                  >
                    <HStack alignSelf='center'>
                      <Box mt='2px'>
                        <DynamicSvgIcon
                          width='16px'
                          height='16px'
                          src={require('@/assets/svg/bell-off.svg')}
                        />
                      </Box>
                      <Box ml='8px'>
                        <Text
                          textAlign='center'
                          fontSize='16px'
                          fontWeight={700}
                          numberOfLines={1}
                          ellipsizeMode='tail'
                        >
                          구독 취소
                        </Text>
                      </Box>
                    </HStack>
                  </CButton>
                )}
                <CButton bgColor='#ECECEC' style={styleObj} onPress={sendMessage}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    쪽지 보내기
                  </Text>
                </CButton>
                <CButton bgColor='#ECECEC' style={styleObj} onPress={goToUserPageBoard}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    작성한 글
                  </Text>
                </CButton>
                <CButton bgColor='#ECECEC' style={styleObj} onPress={goToUserPageComment}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    작성한 댓글
                  </Text>
                </CButton>
                <CButton bgColor='#ECECEC' style={styleObj} onPress={setUserBlock}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    차단하기
                  </Text>
                </CButton>
              </Box>
            ) : (
              <Box>
                <CButton bgColor='#ECECEC' style={styleObj} onPress={setUserUnBlock}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    차단 취소하기
                  </Text>
                </CButton>
              </Box>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
