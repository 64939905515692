import { Box } from 'native-base'
import BoardDetailContents from '@/screens/detail/components/BoardDetailContents'
import ArticleElementsBox from '@/components/ArticleElementsBox'
import { IBoard } from '@/interfaces/board'

interface Props {
  board: IBoard
  saveComplain: (value: number) => void
  saveArticleElement: (elementType: string, value: any) => void
  goodCount: number
  hateCount: number
  good: boolean
  hate: boolean
  scrap: boolean
  complain: number | null
}

export default function BoardDetailBody(props: Props) {
  const {
    board,
    saveComplain,
    saveArticleElement,
    goodCount,
    hateCount,
    good,
    hate,
    scrap,
    complain,
  } = props
  return (
    <Box py='16px' px='18px'>
      <BoardDetailContents board={board} />
      <ArticleElementsBox
        type='board'
        typeId={board.id}
        typeCategory={board.category}
        useComplainButton={true}
        saveComplain={saveComplain}
        saveArticleElement={saveArticleElement}
        articleWriter={board.writer ? board.writer : 0}
        goodCount={goodCount}
        hateCount={hateCount}
        good={good}
        hate={hate}
        scrap={scrap}
        complain={complain}
      />
    </Box>
  )
}
