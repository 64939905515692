import { Box, HStack, Pressable } from 'native-base'
import { useAtom } from 'jotai'
import { popupStateAtom } from '@/atom/initAtom'
import { Dimensions } from 'react-native'
import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import React from 'react'
import useUrl from '@/hooks/useUrl'
import { Linking } from 'react-native'

export default function PopupBanner() {
  const [popupState, setPopupState] = useAtom(popupStateAtom)
  const win = Dimensions.get('window')
  const ratio = win.width / (popupState.popup?.width || 600)
  const { handleUrl } = useUrl()

  const handleClose = async () => {
    await setPopupState({ popup: popupState.popup, isOpen: false })
    await localStorage.setItem('popup', JSON.stringify({ popup: popupState.popup, isOpen: false }))
  }

  return (
    <Box w={'full'} px='40px' bgColor={popupState.popup?.bg_color}>
      <HStack justifyContent='flex-start' alignItems='flex-end'>
        <Box w='100%'>
          <Pressable
            onPress={() => {
              if (popupState.popup?.is_link && popupState.popup.link) {
                void Linking.openURL(popupState.popup.link)
              }
            }}
            style={{ alignItems: 'center' }}
          >
            <FastImage
              source={{
                uri: handleUrl(
                  popupState.popup && popupState.popup.image ? popupState.popup.image.url : '',
                ),
              }}
              style={{
                width: win.width,
                height: (popupState.popup?.height || 68) * ratio,
              }}
              resizeMode='contain'
            />
          </Pressable>
        </Box>
        <Box pb='16px'>
          <CButton onPress={() => handleClose()}>
            <FastImage
              source={require('@/assets/images/popup_round_close.png')}
              style={{
                width: 32,
                height: 32,
              }}
              resizeMode='contain'
            />
          </CButton>
        </Box>
      </HStack>
    </Box>
  )
}
