import React from 'react'
import { Box, Center, Pressable, Text, TextArea } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { createCommentByCommentForm } from '@/services/comment'

import CButton from '@/components/CButton'
import CreateModal from '@/components/CreateModal'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { ICommentForm } from '@/interfaces/comment'
import { RootStackParamList } from '@/interfaces/navigation'
import { Keyboard } from 'react-native'
import { IBanner } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'

interface Props {
  board?: IBoard
  commentType: string
  typeId: number
  reLoadCommentList: () => void
  category?: number
  commentOpenId: number | null
  setCommentOpenId: (id: number) => void
  scrollToCommentTop: () => void
  banner?: IBanner
}

export default function CreateCommentBox(props: Props) {
  const {
    board,
    commentType,
    typeId,
    reLoadCommentList,
    category,
    commentOpenId,
    setCommentOpenId,
    scrollToCommentTop,
    banner,
  } = props
  const [auth] = useAtom(authAtom)
  const [content, setContent] = React.useState('')
  const [contentLength, setContentLength] = React.useState(0)
  const [isOpenAlertNoContent, setIsOpenAlertNoContent] = React.useState(false)

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const { handleSubmit, control } = useForm<ICommentForm>({
    defaultValues: {
      board: board,
      writer: auth.user ? auth.user.id : 0,
      jwt: auth.jwt ? auth.jwt : '',
      type: commentType.toLowerCase(),
      type_id: typeId,
      board_title: '',
      contents: '',
      category,
      banner: banner,
    },
  })

  const handleContentLength = (length: number) => {
    setContentLength(length)
  }

  const handleContent = (value: string) => {
    if (value.length > 300) {
      value = value.substring(0, 300)
    }
    setContent(value)
    handleContentLength(value.length)
  }

  const onSubmit = async (commentForm: ICommentForm) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (contentLength === 0) {
      setIsOpenAlertNoContent(true)
      return
    }

    await createCommentByCommentForm(commentForm)
    reLoadCommentList()
    handleContent('')
    Keyboard.dismiss()
  }

  return (
    <React.Fragment>
      {isOpenAlertNoContent && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_NO_CONTENT}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => setIsOpenAlertNoContent(false)}
        />
      )}
      {commentOpenId === 0 ? (
        <Box borderWidth='1px' borderColor='#DEDEDE' w='full' bgColor='#ffffff' rounded='5px'>
          <Box w='full' flexDirection='row' ml='12px' mt='10px'>
            <Text>{contentLength}/300</Text>
          </Box>
          <Controller
            control={control}
            name='contents'
            rules={{ required: false }}
            render={({ field: { onChange } }) => (
              <TextArea
                autoCompleteType={undefined}
                placeholder='내용을 입력해주세요.'
                w='full'
                fontSize={16}
                variant='unstyled'
                maxLength={300}
                value={content}
                onChangeText={(value) => {
                  onChange(value)
                  handleContent(value)
                }}
                autoCapitalize='none'
                autoCorrect={false}
                onFocus={() => {
                  scrollToCommentTop()
                }}
              />
            )}
          />
          <Box
            borderTopWidth='1px'
            borderTopColor='#DEDEDE'
            flexDirection='row'
            justifyContent='space-between'
            h='40px'
          >
            <Text fontSize='12px' color='#ff6f1e' px='12px' my='auto'>
              이 댓글에 대한 법적 책임은 작성자에게 귀속됩니다.
            </Text>
            <CButton my='auto' mr='7px' onPress={handleSubmit(onSubmit)}>
              <Center w='39px' h='25px' bgColor='#f4f4f4'>
                <Text fontSize='12px'>등록</Text>
              </Center>
            </CButton>
          </Box>
        </Box>
      ) : (
        <Pressable
          onPress={() => {
            setCommentOpenId(0)
            handleContent('')
          }}
          w='full'
        >
          <Center borderWidth='1px' borderColor='#C1C1C1' h='36px' bg='#ffffff' rounded='5px'>
            <Text fontSize='15px' color='#5B5B5B' mr='auto' ml='10px'>
              댓글 입력
            </Text>
          </Center>
        </Pressable>
      )}
    </React.Fragment>
  )
}
