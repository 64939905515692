export enum LIMIT {
  MAIN_NEWS_LIMIT = 18,
  MAIN_NEWS_ALL_LIMIT = 72,
  MAIN_NEWS_PAGE_LIMIT = 18,
  YONHAP_NEWS_LIMIT = 15,
  // KYODO_NEWS_LIMIT = 10,
  NEWS_PAPER_LIMIT = 15,
  BOARD_LIST_LIMIT = 10,
  COMMENT_LIST_BOARD_LIMIT = 20,
  COMMENT_LIST_NEWS_LIMIT = 10,
  MY_PAGE_LIST = 10,
  USER_PAGE_LIST = 20,
  BOARD_BANNER_LIST = 16,
  SEARCH_NEWS_LIST_LIMIT = 20,
  SEARCH_BANNER_LIST_LIMIT = 20,
  SEARCH_BOARD_LIST_LIMIT = 20,
  BANNER_BOARD_LIST_LIMIT = 10,
}
