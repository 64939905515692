import React from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import {
  anotherUserAtom,
  anotherUserBoardListAtom,
  anotherUserBoardTotalCountAtom,
  anotherUserCommentListAtom,
  anotherUserCommentTotalCountAtom,
  anotherUserGoodListAtom,
  anotherUserGoodTotalCountAtom,
  anotherUserIdAtom,
} from '@/atom/userPageAtom'
import { loginAdminAuth } from '@/services/auth'
import {
  findAllBoardByAnotherUser,
  findAllCommentByAnotherUser,
  findAllGoodByAnotherUser,
  findAnotherUser,
} from '@/services/userContents'
import UserPageBoardScreen from '@/screens/user-page/UserPageBoardScreen'
import UserPageCommentScreen from '@/screens/user-page/UserPageCommentScreen'
import UserPageGoodScreen from '@/screens/user-page/UserPageGoodScreen'
import { LIMIT } from '@/constants/limit'
import { USER_PAGE_TAB } from '@/constants/label'
import { UserPageTabParamList } from '@/interfaces/navigation'
import { Box, Spinner } from 'native-base'
import UserPageHeader from '@/components/UserPageHeader'

const UserPageTab = createMaterialTopTabNavigator<UserPageTabParamList>()

export default function UserPageTabNavigator() {
  const [auth] = useAtom(authAtom)
  const [anotherUserId] = useAtom(anotherUserIdAtom)
  const [, setAnotherUser] = useAtom(anotherUserAtom)
  const [, setBoardList] = useAtom(anotherUserBoardListAtom)
  const [, setBoardTotalCount] = useAtom(anotherUserBoardTotalCountAtom)
  const [, setCommentList] = useAtom(anotherUserCommentListAtom)
  const [, setCommentTotalCount] = useAtom(anotherUserCommentTotalCountAtom)
  const [, setGoodList] = useAtom(anotherUserGoodListAtom)
  const [, setGoodTotalCount] = useAtom(anotherUserGoodTotalCountAtom)
  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const fetch = async () => {
      if (anotherUserId) {
        setLoading(true)

        const loadUserPageData = {
          id: anotherUserId,
          start: 0,
          limit: LIMIT.USER_PAGE_LIST,
        }
        const { jwt } = await loginAdminAuth()
        await Promise.all([
          findAnotherUser({ jwt, id: anotherUserId }),
          findAllBoardByAnotherUser(loadUserPageData),
          findAllCommentByAnotherUser(loadUserPageData),
          findAllGoodByAnotherUser(loadUserPageData),
        ]).then((values) => {
          setAnotherUser(values[0])
          setBoardList(values[1].contents)
          setBoardTotalCount(values[1].totalCount)
          setCommentList(values[2].contents)
          setCommentTotalCount(values[2].totalCount)
          setGoodList(values[3].contents)
          setGoodTotalCount(values[3].totalCount)
        })

        setLoading(false)
      }
    }
    void fetch()
  }, [auth, anotherUserId, setAnotherUser])

  return isLoading ? (
    <Box flex={1} justifyContent='center'>
      <Spinner color='#ff6f1e' />
    </Box>
  ) : (
    <Box flex={1}>
      <UserPageHeader />
      <UserPageTab.Navigator
        initialRouteName={USER_PAGE_TAB.USER_PAGE_BOARD}
        backBehavior='none'
        screenOptions={{
          tabBarActiveTintColor: '#FF6F1E',
          tabBarInactiveTintColor: '#282828',
          tabBarStyle: {
            backgroundColor: '#FFFFFF',
          },
          tabBarLabelStyle: {
            fontSize: 16,
            fontWeight: 'bold',
          },
          tabBarIndicatorStyle: {
            backgroundColor: '#FF6F1E',
          },
        }}
      >
        <UserPageTab.Screen
          name={USER_PAGE_TAB.USER_PAGE_BOARD}
          component={UserPageBoardScreen}
          options={{ title: '작성글' }}
        />
        <UserPageTab.Screen
          name={USER_PAGE_TAB.USER_PAGE_COMMENT}
          component={UserPageCommentScreen}
          options={{ title: '작성댓글' }}
        />
        <UserPageTab.Screen
          name={USER_PAGE_TAB.USER_PAGE_GOOD}
          component={UserPageGoodScreen}
          options={{ title: '좋아요!' }}
        />
      </UserPageTab.Navigator>
    </Box>
  )
}
