import React from 'react'

interface Props {
  width?: string
  height?: string
  fillColor?: string
}

const NewIcon: React.FC<Props> = ({ width = '16', height = '16', fillColor = '#292D32' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='16' height='16' rx='8' fill={fillColor} />
      <path
        d='M11.0164 11.8848H8.71735L5.75835 5.94477H5.71435C5.72168 6.1281 5.72902 6.3151 5.73635 6.50577C5.74368 6.6891 5.75102 6.8761 5.75835 7.06677C5.77302 7.2501 5.78402 7.4371 5.79135 7.62777C5.79869 7.8111 5.80602 7.9981 5.81335 8.18877V11.8848H4.22935V4.03077H6.51735L9.46535 9.91577H9.49835C9.49835 9.73243 9.49469 9.55277 9.48735 9.37677C9.48002 9.19343 9.46902 9.01377 9.45435 8.83777C9.44702 8.65443 9.43969 8.47477 9.43235 8.29877C9.42502 8.11543 9.42135 7.9321 9.42135 7.74877V4.03077H11.0164V11.8848Z'
        fill='white'
      />
    </svg>
  )
}

export default NewIcon
