import React from 'react'
import { Box } from 'native-base'
import { Controller } from 'react-hook-form'
import { RichEditor } from 'react-native-pell-rich-editor'

interface Props {
  control: any
  initialContentHTML: string
  editorInitializedCallback: () => void
  setContents: (value: string) => void
}

const RichTextEditor = React.forwardRef<any, Props>((props, ref) => {
  const { control, initialContentHTML, editorInitializedCallback, setContents } = props

  return (
    <Box bg='#ffffff' px='15px' flex={1}>
      <Controller
        control={control}
        name='board.contents'
        rules={{ required: false }}
        render={({ field: { onChange } }) => (
          <RichEditor
            ref={ref}
            initialContentHTML={initialContentHTML}
            onChange={(value) => {
              if (value) {
                setContents(value)
                onChange(value)
              } else {
                setContents('')
              }
            }}
            placeholder='내용을 입력해주세요.'
            androidLayerType='software'
            showsVerticalScrollIndicator={false}
            useContainer={true}
            editorInitializedCallback={editorInitializedCallback}
          />
        )}
      />
    </Box>
  )
})

export default RichTextEditor
