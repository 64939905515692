import { TouchableOpacity } from 'react-native'
import { Box } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import useUrl from '@/hooks/useUrl'
import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  item: IBanner
}

export default function MainBannerItem(props: Props) {
  const { item } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const { small_banner_image, small_thumbnail_path } = item
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const { handleUrl } = useUrl()

  return (
    <TouchableOpacity
      onPress={() => navigation.push(ROOT_STACK.BANNER_DETAIL, { id: item.id, isScroll: false })}
    >
      <Box alignItems='center'>
        <FastImage
          source={{
            uri: small_banner_image
              ? handleUrl(small_banner_image.url)
              : small_thumbnail_path
              ? handleUrl(small_thumbnail_path)
              : '',
          }}
          style={{
            width: screenWidth,
            height: screenWidth / 4,
          }}
          resizeMode='cover'
        />
      </Box>
    </TouchableOpacity>
  )
}
