import { Box, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'
import React, { useRef } from 'react'
import { authAtom } from '@/atom/authAtom'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { IFindCommentPushAgreeForm, ISendSubscriptPushForm } from '@/interfaces/push'
import { sendBoardAgree, sendBoardDisagree } from '@/services/pushAgreeService'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'

interface Props {
  banner: IBanner
  subscript: IFindCommentPushAgreeForm | null
}

export default function BannerDetailCenter(props: Props) {
  const { banner, subscript } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)
  const targetTextRef = useRef<any>() // 딱 하나의 텍스트를 가리킬 Ref
  const [sub, setSub] = React.useState(subscript?.id)

  const advertisementAgree = async () => {
    if (auth.logged) {
      const ISendSubscriptPushForm: ISendSubscriptPushForm = {
        jwt: auth.jwt,
        user_id: auth.user ? auth.user.id : 0,
        type: 'advertisement',
        type_id: banner.id,
      }
      const result = await sendBoardAgree(ISendSubscriptPushForm)
      setSub(result.id)
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  const advertisementDisagree = async () => {
    if (auth.logged && sub) {
      const result = await sendBoardDisagree(auth.jwt, sub)
      if (result.id == sub) {
        setSub(undefined)
      }
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  return (
    <Box backgroundColor='#F9F9F9' pt='16px' pb='16px' px='16px'>
      <Text fontSize={16} color='#FF6F1E' fontWeight='bold' ref={targetTextRef}>
        {banner.banner_category?.category_name}
      </Text>
      <Text fontSize={32} fontWeight='bold'>
        {banner.company_name}
      </Text>
      {sub ? (
        <CButton
          mt='8px'
          bg='#E8E8E8'
          pl='13px'
          pr='13px'
          py='4px'
          rounded='20px'
          alignSelf='flex-start'
          onPress={advertisementDisagree}
        >
          <HStack>
            <FastImage
              source={require('@/assets/images/bell-off.png')}
              style={{
                width: 18,
                height: 16,
                marginRight: 4,
                marginTop: 3,
              }}
              resizeMode='contain'
            />
            <Text fontSize={14} color='#222222' fontWeight='semibold'>
              {'구독 취소'}
            </Text>
          </HStack>
        </CButton>
      ) : (
        <Box>
          <CButton
            mt='8px'
            bg='#FF6F1E33'
            pl='13px'
            pr='16px'
            py='4px'
            rounded='20px'
            alignSelf='flex-start'
            onPress={advertisementAgree}
          >
            <HStack>
              <FastImage
                source={require('@/assets/images/bell-on.png')}
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 4,
                  marginTop: 3,
                }}
                resizeMode='contain'
              />
              <Text fontSize={14} color='#E85300' fontWeight='semibold'>
                {banner.company_name + ' 새글 구독'}
              </Text>
            </HStack>
          </CButton>
          <Box mt='8px' ml='16px'>
            <Text fontSize={14} fontWeight='medium'>
              업체의 새글을 구독하실 수 있으며,
            </Text>
            <Box flexDirection='row' alignItems='center'>
              <FastImage
                source={require('@/assets/images/my-page-parson.png')}
                style={{
                  width: 16,
                  height: 16,
                }}
                resizeMode='contain'
              />
              <Text fontSize={14} fontWeight='bold'>
                마이페이지
              </Text>
              <Text fontSize={14} fontWeight='medium'>
                에서도 확인하실 수 있습니다.
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
