import { Box, Center, Text } from 'native-base'
import CButton from '@/components/CButton'
import { IUserContentsMessageBoard } from '@/interfaces/userContents'
import { Fragment } from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { ROOT_STACK } from '@/constants/label'

interface Props {
  messageBoard: IUserContentsMessageBoard
  selectedTabId: string
  callRemoveMessage: (id: number) => void
  callKeepMessage: (id: number) => void
}

export default function MyPageMessageItemFooter(props: Props) {
  const { messageBoard, selectedTabId, callRemoveMessage, callKeepMessage } = props
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <Box pl='8px'>
      <Box flexDirection='row' justifyContent='flex-end'>
        {selectedTabId === 'TO' ? (
          <Fragment>
            <CButton
              mr='6px'
              onPress={() =>
                rootNavigation.navigate(ROOT_STACK.SEND_MESSAGE, {
                  userId: messageBoard.from_user,
                  userNickName: messageBoard.nick_name,
                })
              }
            >
              <Center
                w='40px'
                h='26px'
                bgColor='white'
                borderColor='#8F8F8F'
                borderWidth='1px'
                borderRadius='12px'
              >
                <Text color='#222222' fontSize='14px'>
                  답장
                </Text>
              </Center>
            </CButton>
            <CButton mr='6px' onPress={() => callKeepMessage(messageBoard.id)}>
              <Center
                w='40px'
                h='26px'
                bgColor='white'
                borderColor='#8F8F8F'
                borderWidth='1px'
                borderRadius='12px'
              >
                <Text color='#222222' fontSize='14px'>
                  보관
                </Text>
              </Center>
            </CButton>
          </Fragment>
        ) : null}
        <CButton onPress={() => callRemoveMessage(messageBoard.id)}>
          <Center
            w='40px'
            h='26px'
            bgColor='white'
            borderColor='#8F8F8F'
            borderWidth='1px'
            borderRadius='12px'
          >
            <Text color='#222222' fontSize='14px'>
              삭제
            </Text>
          </Center>
        </CButton>
      </Box>
    </Box>
  )
}
