import { StrapiAPI } from '@/services/axios'
import qs from 'qs'

export async function findAllPopup() {
  const now = new Date()
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
        platform_eq: 'mobile',
        popup_expected_date_lte: now,
        popup_expired_date_gte: now,
      },
    ],
    _limit: 1,
    _sort: 'updated_at:desc',
  })
  const response = await StrapiAPI.get(`/popups?${query}`)
  return response.data && response.data[0]
}
