import React from 'react'
import { Box, HStack, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import BoardListBanner from './BoardListBanner'
import AddressItem from '@/screens/boards/components/AddressItem'
import MoreTab from '@/components/MoreTab'
import CategoryTab from '@/components/CategoryTab'
import Footer from '@/components/Footer'
import { RootStackParamList } from '@/interfaces/navigation'
import { IBanner } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'
import { TouchableHighlight } from 'react-native-gesture-handler'
import { ROOT_STACK } from '@/constants/label'
import MyPageBoardTitle from '@/screens/my-page/components/MyPageBoardTitle'
import MyPageBoardContents from '@/screens/my-page/components/MyPageBoardContents'

interface Props {
  list: any[] | IBoard[]
  currentCategory: number
  handleCurrentCategory: (pCategory: number) => void
  isMore: boolean
  handleMore: () => Promise<void>
  bannerList: IBanner[]
  setDataSourceCords: (value: number) => void
}

export default function BoardList(props: Props) {
  const {
    list,
    currentCategory,
    handleCurrentCategory,
    isMore,
    handleMore,
    bannerList,
    setDataSourceCords,
  } = props
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <Box>
      <BoardListBanner bannerList={bannerList} />
      <Box
        onLayout={(e) => {
          const layout = e.nativeEvent.layout
          setDataSourceCords(layout.y)
        }}
      />
      {currentCategory === 19 || currentCategory === 20 ? (
        <CategoryTab
          currentCategory={currentCategory}
          handleCurrentCategory={handleCurrentCategory}
        />
      ) : null}
      {currentCategory === 20
        ? list.map((item) => {
            return (
              <Box key={item.id}>
                <AddressItem addressBook={item} currentCategory={currentCategory} />
              </Box>
            )
          })
        : list.map((item) => {
            return (
              <TouchableHighlight
                key={item.id}
                onPress={() =>
                  navigation.push(ROOT_STACK.BOARD_DETAIL, { id: item.id, category: item.category })
                }
                underlayColor='#e9e9e9'
              >
                <VStack
                  py='16px'
                  px='16px'
                  borderColor='#F2F2F2'
                  borderBottomWidth='8px'
                  bg='white'
                >
                  <HStack pt='5px' space='12px'>
                    <VStack flex={1} alignContent='center' mr='4px'>
                      <MyPageBoardTitle board={item} />
                      <MyPageBoardContents board={item} screen='ArticleItem' />
                    </VStack>
                  </HStack>
                </VStack>
              </TouchableHighlight>
            )
          })}
      {isMore && (
        <Box bg='#ffffff'>
          <MoreTab moreButtonText='더보기' moreButtonOnPress={handleMore} useSideButton={false} />
          <Box bg='#ffffff' h='11px' />
        </Box>
      )}
      <Footer />
    </Box>
  )
}
