import React from 'react'
import { Box } from 'native-base'
import { RefreshControl } from 'react-native'
import { useFocusEffect, useNavigation, useScrollToTop } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { authAtom } from '@/atom/authAtom'
import { useAtom } from 'jotai'
import { reloadNoReadMessageCountAtom } from '@/atom/navigationAtom'
import {
  myPageMessageFromListAtom,
  myPageMessageFromTotalCountAtom,
  myPageMessageKeepListAtom,
  myPageMessageKeepTotalCountAtom,
  myPageMessageToListAtom,
  myPageMessageToTotalCountAtom,
} from '@/atom/initAtom'
import {
  findAllMessageBoardByMe,
  keepMessage,
  readMessage,
  removeMessage,
} from '@/services/userContents'
import MoreTab from '@/components/MoreTab'
import MyPageMessageTab from '@/components/MyPageMessageTab'
import MyPageMessageBoxHeader from '@/components/MyPageMessageBoxHeader'
import MyPageMessageItem from '@/components/MyPageMessageItem'
import { LIMIT } from '@/constants/limit'
import { AUTH_STACK, MY_PAGE_TAB, ROOT_STACK } from '@/constants/label'
import { MyPageTabParamList, RootStackParamList } from '@/interfaces/navigation'
import { ICheckedList } from '@/interfaces/userContents'
import MyPageBoardBlank from './components/MyPageBoardBlank'

type Props = NativeStackScreenProps<MyPageTabParamList, MY_PAGE_TAB.MY_PAGE_MESSAGE>

export default function MyPageMessageScreen({ navigation }: Props) {
  const [auth] = useAtom(authAtom)
  const [, reloadNoReadMessageCount] = useAtom(reloadNoReadMessageCountAtom)
  const [fromList, setFromList] = useAtom(myPageMessageFromListAtom)
  const [fromTotalCount, setFromTotalCount] = useAtom(myPageMessageFromTotalCountAtom)
  const [toList, setToList] = useAtom(myPageMessageToListAtom)
  const [toTotalCount, setToTotalCount] = useAtom(myPageMessageToTotalCountAtom)
  const [keepList, setKeepList] = useAtom(myPageMessageKeepListAtom)
  const [keepTotalCount, setKeepTotalCount] = useAtom(myPageMessageKeepTotalCountAtom)
  const [limit, setLimit] = React.useState(LIMIT.MY_PAGE_LIST)
  const [refreshing, setRefreshing] = React.useState(false)
  const [selectedTabId, setSelectedTabId] = React.useState('')
  const [checkedAll, setCheckedAll] = React.useState(false)
  const [toCheckedList, setToCheckedList] = React.useState<ICheckedList[]>([])
  const [fromCheckedList, setFromCheckedList] = React.useState<ICheckedList[]>([])
  const [keepCheckedList, setKeepCheckedList] = React.useState<ICheckedList[]>([])

  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  // const { scrollHandler } = useScroll()

  const isMore = () => {
    const totalCount =
      selectedTabId === 'TO'
        ? toTotalCount
        : selectedTabId === 'FROM'
        ? fromTotalCount
        : keepTotalCount

    const contentsLength =
      selectedTabId === 'TO'
        ? toList.length
        : selectedTabId === 'FROM'
        ? fromList.length
        : keepList.length

    return (totalCount ? totalCount : 0) > contentsLength
  }

  const getCurrentCheckList = () => {
    return selectedTabId === 'TO'
      ? toCheckedList
      : selectedTabId === 'FROM'
      ? fromCheckedList
      : keepCheckedList
  }

  const setCurrentCheckList = (checkedList: ICheckedList[]) => {
    if (selectedTabId === 'TO') {
      setToCheckedList(checkedList)
    } else if (selectedTabId === 'FROM') {
      setFromCheckedList(checkedList)
    } else {
      setKeepCheckedList(checkedList)
    }
  }

  const getCheckedLength = () => {
    let count = 0
    getCurrentCheckList().map((item) => {
      if (item.checked) count++
    })
    return count
  }

  const handleCheckedAll = () => {
    setCheckedAll(getCheckedLength() !== 0 && getCheckedLength() === getCurrentCheckList().length)
  }

  const allCheck = (value: boolean) => {
    const newList: ICheckedList[] = [...getCurrentCheckList()]

    if (newList.length > 0) {
      newList.map((item, index) => {
        newList[index].checked = value
      })
      setCheckedAll(value)
    }

    setCurrentCheckList(newList)
  }

  const handleCheckedList = (id: number) => {
    const newList: ICheckedList[] = [...getCurrentCheckList()]
    newList.map((item, index) => {
      if (item.messageId === id) newList[index].checked = !newList[index].checked
    })
    setCurrentCheckList(newList)
    handleCheckedAll()
  }

  const handleSelectedTabId = (value: string) => {
    setCheckList(value)
    // setCheckedAll(false)
    allCheck(false)
    setSelectedTabId(value)
  }

  const setCheckList = (value: string) => {
    if (value === 'TO') {
      const newList = [...toCheckedList]
      newList.map((item) => (item.checked = false))
      setToCheckedList(newList)
    } else if (value === 'FROM') {
      const newList = [...fromCheckedList]
      newList.map((item) => (item.checked = false))
      setFromCheckedList(newList)
    } else {
      const newList = [...keepCheckedList]
      newList.map((item) => (item.checked = false))
      setKeepCheckedList(newList)
    }
  }

  const realInitCheckedList = (data: any) => {
    setCheckedAll(false)
    const toList: ICheckedList[] = []
    const fromList: ICheckedList[] = []
    const keepList: ICheckedList[] = []

    data.to.contents.map((item: any, index: number) => {
      toList.push({ id: index, messageId: item.id, checked: false })
    })
    data.from.contents.map((item: any, index: number) => {
      fromList.push({ id: index, messageId: item.id, checked: false })
    })
    data.keep.contents.map((item: any, index: number) => {
      keepList.push({ id: index, messageId: item.id, checked: false })
    })

    setToCheckedList([...toList])
    setFromCheckedList([...fromList])
    setKeepCheckedList([...keepList])
  }

  const initCheckedList = (data: any) => {
    setFromList([...data.from.contents])
    setFromTotalCount(data.from.totalCount)
    setToList([...data.to.contents])
    setToTotalCount(data.to.totalCount)
    setKeepList([...data.keep.contents])
    setKeepTotalCount(data.keep.totalCount)

    setCheckedAll(false)
    const toList: ICheckedList[] = []
    const fromList: ICheckedList[] = []
    const keepList: ICheckedList[] = []

    data.to.contents.map((item: any, index: number) => {
      toList.push({ id: index, messageId: item.id, checked: false })
    })
    data.from.contents.map((item: any, index: number) => {
      fromList.push({ id: index, messageId: item.id, checked: false })
    })
    data.keep.contents.map((item: any, index: number) => {
      keepList.push({ id: index, messageId: item.id, checked: false })
    })

    setToCheckedList([...toList])
    setFromCheckedList([...fromList])
    setKeepCheckedList([...keepList])
  }

  const handleRead = async (readId: number) => {
    if (!auth.user) return

    const data = {
      userId: auth.user ? auth.user.id : 0,
      jwt: auth.jwt,
      readId,
    }

    await readMessage(data)
    await reloadMessageBoard()
    await reloadNoReadMessageCount()
  }

  const reloadMessageBoard = async () => {
    await findAllMessageBoardByMe({
      jwt: auth.jwt,
      limit: limit,
    }).then((data) => {
      // setMessageBoard(data)
      initCheckedList(data)
    })
  }

  const callRemoveMessage = async (id: number | number[]) => {
    if (!id || !auth.logged) {
      rootNavigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    await removeMessage({
      jwt: auth.jwt,
      removeId: id,
      removeTab: selectedTabId,
    }).then(() => {
      reloadMessageBoard()
    })
  }

  const callKeepMessage = async (id: number | number[]) => {
    if (!id || !auth.logged) {
      rootNavigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    await keepMessage({
      jwt: auth.jwt,
      keepId: id,
    }).then(() => {
      reloadMessageBoard()
    })
  }

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    await findAllMessageBoardByMe({
      jwt: auth.jwt,
      limit: LIMIT.MY_PAGE_LIST,
    }).then((data) => {
      // setMessageBoard(data)
      initCheckedList(data)
      // handleCheckedAll(false)
    })
    await setTimeout(() => {
      setRefreshing(false)
    }, 500)
  }, [auth])

  const onMore = React.useCallback(async () => {
    const newLimit = limit + LIMIT.MY_PAGE_LIST
    await findAllMessageBoardByMe({
      jwt: auth.jwt,
      limit: newLimit,
    }).then((data) => {
      // setMessageBoard(data)
      initCheckedList(data)
      setLimit(newLimit)
    })
  }, [auth, limit])

  useFocusEffect(
    React.useCallback(() => {
      const data = {
        from: {
          contents: fromList,
          totalCount: fromTotalCount,
        },
        to: {
          contents: toList,
          totalCount: toTotalCount,
        },
        keep: {
          contents: keepList,
          totalCount: keepTotalCount,
        },
      }
      handleSelectedTabId('TO')
      realInitCheckedList(data)
    }, []),
  )

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (navigation.getState().index !== 3) {
        scrollRef.current.scrollTo({ x: 0, y: 0, animated: false })
      }
    })

    return unsubscribe
  }, [navigation])

  return (
    <Box flex={1} backgroundColor='#ffffff'>
      <MyPageMessageTab selectedTabId={selectedTabId} handleSelectedTabId={handleSelectedTabId} />
      <Box px='17px'>
        {selectedTabId === 'TO' && (
          <MyPageMessageBoxHeader
            selectedTabId={'TO'}
            checkedAll={checkedAll}
            allCheck={allCheck}
            reloadMessageBoard={reloadMessageBoard}
            getCurrentCheckList={getCurrentCheckList}
          />
        )}
        {selectedTabId === 'FROM' && (
          <MyPageMessageBoxHeader
            selectedTabId={'FROM'}
            checkedAll={checkedAll}
            allCheck={allCheck}
            reloadMessageBoard={reloadMessageBoard}
            getCurrentCheckList={getCurrentCheckList}
          />
        )}
        {selectedTabId === 'KEEP' && (
          <MyPageMessageBoxHeader
            selectedTabId={'KEEP'}
            checkedAll={checkedAll}
            allCheck={allCheck}
            reloadMessageBoard={reloadMessageBoard}
            getCurrentCheckList={getCurrentCheckList}
          />
        )}
      </Box>
      <Animated.ScrollView
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#ff6f1e']}
            tintColor='#ff6f1e'
          />
        }
        contentContainerStyle={{ flexGrow: 1, backgroundColor: '#ffffff' }}
        showsVerticalScrollIndicator={false}
        // scrollEventThrottle={16}
        // onScroll={scrollHandler}
      >
        {selectedTabId === 'TO' && toList.length ? (
          toList.map((item, index) => {
            return (
              <Box key={item.id}>
                <MyPageMessageItem
                  messageBoard={item}
                  onRead={handleRead}
                  selectedTabId={selectedTabId}
                  callRemoveMessage={callRemoveMessage}
                  callKeepMessage={callKeepMessage}
                  handleCheckedList={handleCheckedList}
                  checkedList={toCheckedList}
                  itemIndex={index}
                />
              </Box>
            )
          })
        ) : selectedTabId === 'TO' && !toList.length ? (
          <MyPageBoardBlank title='받은 쪽지가 없습니다.' screen={'MyPageMessageScreen'} />
        ) : null}
        {selectedTabId === 'FROM' && fromList.length ? (
          fromList.map((item, index) => {
            return (
              <Box key={item.id}>
                <MyPageMessageItem
                  messageBoard={item}
                  onRead={handleRead}
                  selectedTabId={selectedTabId}
                  callRemoveMessage={callRemoveMessage}
                  callKeepMessage={callKeepMessage}
                  handleCheckedList={handleCheckedList}
                  checkedList={fromCheckedList}
                  itemIndex={index}
                />
              </Box>
            )
          })
        ) : selectedTabId === 'FROM' && !fromList.length ? (
          <MyPageBoardBlank title='보낸 쪽지가 없습니다.' screen={'MyPageMessageScreen'} />
        ) : null}
        {selectedTabId === 'KEEP' && keepList.length ? (
          keepList.map((item, index) => {
            return (
              <Box key={item.id}>
                <MyPageMessageItem
                  messageBoard={item}
                  onRead={handleRead}
                  selectedTabId={selectedTabId}
                  callRemoveMessage={callRemoveMessage}
                  callKeepMessage={callKeepMessage}
                  handleCheckedList={handleCheckedList}
                  checkedList={keepCheckedList}
                  itemIndex={index}
                />
              </Box>
            )
          })
        ) : selectedTabId === 'KEEP' && !keepList.length ? (
          <MyPageBoardBlank title='보관함에 쪽지가 없습니다.' screen={'MyPageMessageScreen'} />
        ) : null}
        {isMore() ? (
          <MoreTab moreButtonText='더보기' moreButtonOnPress={onMore} useSideButton={false} />
        ) : null}
      </Animated.ScrollView>
    </Box>
  )
}
