import React from 'react'
import { Button } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { RootStackParamList } from '@/interfaces/navigation'
import FastImage from './FastImage'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { IBoard } from '@/interfaces/board'
import ModalConfirmRound from './ModalConfirmRound'
import { removeBoard } from '@/services/board'
import useLocation from '@/hooks/useLocation'
import useBoard from '@/hooks/useBoard'
import BoardMenuModal from './modal/BoardMenuModal'

interface Props {
  userId: number
  userNickName: string
  type: 'BOARD' | 'COMMENT'
  isBlock: boolean
  board: IBoard
}

export default function ModalBoardMenu(props: Props) {
  const { userId, userNickName, type, isBlock, board } = props
  const [auth] = useAtom(authAtom)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)

  const { findBoardListByCategory } = useBoard()
  const { handleBoardListLocation } = useLocation()

  const closeMenuModal = () => {
    setIsMenuOpen(false)
  }

  const handleOpenConfirm = (value: boolean) => {
    setIsOpenConfirm(value)
  }

  const callRemoveBoard = async () => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    board.is_delete = true
    await removeBoard({
      jwt: auth.jwt,
      id: board.id,
    }).then(() => {
      findBoardListByCategory(board.category)
      handleBoardListLocation(board.category)
    })
  }

  return (
    <React.Fragment>
      {isOpenConfirm && (
        <ModalConfirmRound
          isOpen={isOpenConfirm}
          onAccept={callRemoveBoard}
          onClose={() => setIsOpenConfirm(false)}
          contentsMessage={'게시물을 삭제하시겠습니까?'}
          acceptButtonText='예'
          cancelButtonText='아니오'
        />
      )}
      {isMenuOpen && (
        <BoardMenuModal
          userId={userId}
          userNickName={userNickName}
          type={type}
          isBlock={isBlock}
          board={board}
          handleCloseModal={closeMenuModal}
          openModal={handleOpenConfirm}
        />
      )}
      <Button p={0} bgColor='white' onPress={() => setIsMenuOpen(true)}>
        <FastImage
          source={require('@/assets/images/menu-dots.png')}
          style={{ width: 24, height: 18, marginTop: 'auto', marginBottom: 'auto' }}
          resizeMode='contain'
        />
      </Button>
    </React.Fragment>
  )
}
