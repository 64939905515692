import { ScrollView } from 'react-native'
import { Box } from 'native-base'
import Footer from '@/components/Footer'
import HTMLView from 'react-native-htmlview'

export default function PolicyServiceScreen() {
  const contents = `
<pre style="line-height: 20px;">
<h4>제1조 (목적)</h4>

본 이용약관(이하 “약관”)은 한터(HANTEO2050.COM)(이하 “회사”)에서 운영하는 한터(HANTEO2050.COM)(이하  “서비스”)를 이용함에 있어 회사와 회원의 권리, 의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.


<h4>제2조 (용어의 정의)</h4>

본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.

1. 회원 : 회사에 개인정보를 제공하여 회원가입을 한 자로서, 회사와 서비스 이용계약을 체결하고 회원 아이디(ID)를 부여 받은 자를 말합니다.
2. 회원 아이디(이하 “회원ID”) : 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.
3. 비밀번호 : 회원이 부여 받은 ID와 일치된 회원임을 확인하고, 회원의 개인정보를 보호 하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.
4. 가입해지 : 회사 또는 회원이 이용계약을 해지하는 것을 말합니다.
5. 유료서비스 : 회사가 유료로 제공하는 각종 인터넷 컨텐츠 서비스를 말합니다.


<h4>제3조 (약관의 명시와 개정)</h4>

1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회사의 초기 서비스화면에 게시합니다.
2. 회사는 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률, 방문판매 등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
3. 회사는 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 새로운 약관을 회사의 초기 서비스화면에 공지합니다.
4. 회원은 개정된 약관에 대해 거부할 권리가 있습니다. 회원은 약관의 변경에 대하여 주의 의무를 다하여야 하며, 개정된 약관에 동의하지 않을 경우 회원가입을 해지할 수 있으며, 개정된 약관의 효력발생일 이후에도 서비스를 계속 이용할 경우 약관의 변경사항에 동의하는 것으로 간주합니다.


<h4>제4조 (약관외 준칙)</h4>

1. 본 약관은 회사가 제공하는 서비스에 관하여 별도의 정책 및 운영규칙과 함께 적용됩니다.


[제 2 장 이용계약의 체결]

<h4>제5조 (이용신청)</h4>

1. 회원으로 가입하여 서비스를 이용하고자 하는 자는 서비스 신청시 본 약관을 읽고 “위의 이용약관에 동의합니다”의 대화창에 표시를 한 후 가입하기 단추를 선택하고, 회사가 요청하는 소정의 신청양식에 요구된 항목을 기재하여 이용신청을 합니다.
2. 회원은 반드시 회원본인의 실명인증을 하여야 합니다. 실명으로 가입하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용제한을 받게 되며 관련법에 따라 처벌을 받을 수도 있습니다.
3. 온라인 이용신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용제한을 받을 수 있습니다.
4. 회사는 회원의 실명확인을 위하여 전문기관을 통하여 실명인증을 할 수 있습니다.


<h4>제6조 이용신청의 승낙과 제한</h4>

1. 회사는 제5조의 내용에 따르는 이용신청에 대하여 특별한 하자가 없는 한 접수한 순서대로 승낙함으로써 이용계약은 성립합니다.
2. 회사는 다음 각 호의 내용에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
 (1) 서비스 관련설비에 여유가 없는 경우
 (2) 기술상 지장이 있는 경우
 (3) 기타 회사의 사정상 필요하다고 인정되는 경우
3. 회사는 다음 각 호의 내용에 해당하는 경우 이용신청에 대하여는 이를 승낙하지 아니 할 수도 있습니다.
 (1) 실명이 아니거나 타인의 명의를 이용하여 신청한 경우
 (2) 이용 신청서의 내용을 허위로 기재하여 신청한 경우
 (3) 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우
 (4) 부정한 용도로 서비스를 이용하고자 하는 경우
 (5) 기타 회사가 정한 이용신청 요건이 미비 된 경우


<h4>제7조 개인정보보호 정책</h4>

회사는 관계법령이 정하는 바에 따라 회원가입정보를 포함한 회원의 개인정보를 보호하기 위하여 노력을 합니다. 회원의 개인정보보호에 관하여 관계법령 및 회사가 정하는 개인정보보호정책에 정한 바에 따릅니다.


<h4>제9조 회원ID 부여 및 변경 등</h4>

1. 회사는 회원에 대하여 본 약관에 정하는 바에 따라 회원ID를 부여합니다.
2. 회원은 서비스 내의 나의 정보 또는 기타 해당 페이지로 링크된 메뉴를 통하여 자신의 개인정보를 관리할 수 있는 페이지를 열람할 수 있으며, 해당 페이지에서 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
다만, 서비스 관리를 위하여 반드시 필요한 실명, 주민등록번호, 성별 등은 수정할 수 없습니다.
3. 회원ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당ID를 해지하신 후 새로 가입하셔야 합니다.


[제 3 장 계약 당사자의 의무]


<h4>제10조 회사의 의무</h4>

1. 회사는 관련법과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위하여 최선을 다하여야 합니다.
2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보보호를 위한 보안시스템을 구축하며, 개인정보보호정책을 준수합니다.
3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관계절차 및 내용 등에 있어 회원에게 편의를 제공하도록 노력합니다.

<h4>제11조 회원ID 및 비밀번호에 대한 관리</h4>
1. 회사가 관계법령 및 개인정보보호정책에 의하여 그 책임을 지는 경우를 제외하고, 회원ID와 비밀번호의 관리책임은 회원에게 있습니다.
2. 회원은 자신의 회원ID와 비밀번호를 도용 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내에 따라야 합니다.


<h4>제12조 회원의 의무</h4>

1. 회원은 본 약관에 규정하는 사항과 서비스 이용안내 또는 공지사항 등 회사가 공지 또는 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
</pre>
`

  return (
    <ScrollView
      contentContainerStyle={{ backgroundColor: '#f4f4f4' }}
      showsVerticalScrollIndicator={false}
    >
      <Box bgColor='#ffffff' pb='50px' px='15px'>
        <HTMLView value={contents} />
      </Box>
      <Footer />
    </ScrollView>
  )
}
