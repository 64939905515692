import qs from 'qs'
import { StrapiAPI } from '@/services/axios'
import { LIMIT } from '@/constants/limit'

export async function findAllMainNews() {
  const now = new Date()
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
        news_expected_date_lte: now,
      },
    ],
    _sort: 'priority:desc,created_at:desc',
    _limit: LIMIT.MAIN_NEWS_LIMIT,
  })
  // const response = await StrapiAPI.get(`/apps/news-contents?${query}`)
  const response = await StrapiAPI.get(`/news-contents?${query}`)
  return response.data
}

export async function findByIdNews(id: number) {
  const now = new Date()
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
        news_expected_date_lte: now,
      },
    ],
  })
  const response = await StrapiAPI.get(`/news-contents/${id}?${query}`)
  return response.data
}

export async function findAllYonhapNews(limit: number) {
  const now = new Date()
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
        news_expected_date_lte: now,
      },
    ],
    _sort: 'priority:desc,created_at:desc',
    _limit: limit,
  })
  // const response = await StrapiAPI.get(`/apps/news-contents?${query}`)
  const response = await StrapiAPI.get(`/news-contents?${query}`)
  return response.data
}

// export async function findAllKyodoNews(limit: number) {
//   const response = await StrapiAPI.get(`/nordots?limit=${limit}`)
//   return response.data.curations
// }

export async function updateNewsViewCount(id: number) {
  await StrapiAPI.put(`/news-contents/view/${id}`)
}
