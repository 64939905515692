import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function FabImageIcon() {
  return (
    <Svg width='25' height='25' viewBox='0 0 25 25' fill='none'>
      <Path
        d='M18.4524 7.73812L19.6429 8.92859M20.2381 4.76193C20.4726 4.9964 20.6587 5.27479 20.7856 5.58119C20.9126 5.88758 20.9779 6.21599 20.9779 6.54764C20.9779 6.87929 20.9126 7.20769 20.7856 7.51409C20.6587 7.82049 20.4726 8.09888 20.2381 8.33335L8.92858 19.6429L4.16667 20.8334L5.35715 16.1381L16.6714 4.76669C17.1174 4.31856 17.7155 4.0545 18.3472 4.02693C18.9788 3.99937 19.5977 4.21031 20.081 4.61788L20.2381 4.76193ZM11.3095 20.8334H20.8333H11.3095Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </Svg>
  )
}
