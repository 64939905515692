import React from 'react'
import { Box } from 'native-base'
import * as SplashScreen from 'expo-splash-screen'
import moment from 'moment/moment'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { searchHistoryAtom } from '@/atom/searchAtom'
import {
  addressBookListAtom,
  // kyodoNewsListAtom,
  mainBannerListAtom,
  mainContentsBannerListAtom,
  myPageBoardListAtom,
  myPageBoardSubListAtom,
  myPageBoardSubTotalCountAtom,
  myPageBoardTotalCountAtom,
  myPageCommentListAtom,
  myPageCommentTotalCountAtom,
  myPageGoodListAtom,
  myPageGoodTotalCountAtom,
  myPageMemberSubListAtom,
  myPageMemberSubTotalCountAtom,
  myPageMessageFromListAtom,
  myPageMessageFromTotalCountAtom,
  myPageMessageKeepListAtom,
  myPageMessageKeepTotalCountAtom,
  myPageMessageToListAtom,
  myPageMessageToTotalCountAtom,
  myPageScrapListAtom,
  myPageScrapTotalCountAtom,
  popupStateAtom,
  yonhapNewsListAtom,
} from '@/atom/initAtom'
import { updateMeAttendance, updateVisitorCount } from '@/services/auth'
import {
  findAllBoardBanners,
  findAllMainBanner,
  findAllMainContentsBanner,
} from '@/services/banner'
import { 
  // findAllKyodoNews, 
  findAllMainNews 
} from '@/services/news'
import { findAllBoard } from '@/services/board'
import { findAllAddressBook } from '@/services/address'
import {
  findAllBoardByMeAuth,
  findAllBoardSubByMeAuth,
  findAllCommentByMeAuth,
  findAllGoodByMeAuth,
  findAllMemberSubByMeAuth,
  findAllMessageBoardByMe,
  findAllScrapByMeAuth,
} from '@/services/userContents'
import useToken from '@/hooks/useToken'
import useBoard from '@/hooks/useBoard'
import useBanner from '@/hooks/useBanner'
import CustomSplashScreen from '@/screens/CustomSplashScreen'
import { LIMIT } from '@/constants/limit'
import { findAllPopup } from '@/services/popup'
import { reloadNoReadMessageCountAtom, reloadReadStateAtom } from '@/atom/navigationAtom'

export default function SplashProvider({ children }: any) {
  const [appIsReady, setAppIsReady] = React.useState(false)
  const [, setUserId] = React.useState(0)
  const [, setAuth] = useAtom(authAtom)
  const [, setSearchHistory] = useAtom(searchHistoryAtom)
  const [, setMainNewsList] = useAtom(yonhapNewsListAtom)
  // const [, setMainKyodoNewsList] = useAtom(kyodoNewsListAtom)
  const [, setMainBannerList] = useAtom(mainBannerListAtom)
  const [, setMainContentsBannerList] = useAtom(mainContentsBannerListAtom)
  const [, setAddressBookList] = useAtom(addressBookListAtom)
  const [, setMyPageBoardList] = useAtom(myPageBoardListAtom)
  const [, setMyPageBoardTotalCount] = useAtom(myPageBoardTotalCountAtom)
  const [, setMyPageCommentList] = useAtom(myPageCommentListAtom)
  const [, setMyPageCommentTotalCount] = useAtom(myPageCommentTotalCountAtom)
  const [, setMyPageGoodList] = useAtom(myPageGoodListAtom)
  const [, setMyPageGoodTotalCount] = useAtom(myPageGoodTotalCountAtom)
  const [, setMyPageScrapList] = useAtom(myPageScrapListAtom)
  const [, setMyPageScrapTotalCount] = useAtom(myPageScrapTotalCountAtom)
  const [, setMyPageMessageFromList] = useAtom(myPageMessageFromListAtom)
  const [, setMyPageMessageFromTotalCount] = useAtom(myPageMessageFromTotalCountAtom)
  const [, setMyPageMessageToList] = useAtom(myPageMessageToListAtom)
  const [, setMyPageMessageToTotalCount] = useAtom(myPageMessageToTotalCountAtom)
  const [, setMyPageMessageKeepList] = useAtom(myPageMessageKeepListAtom)
  const [, setMyPageMessageKeepTotalCount] = useAtom(myPageMessageKeepTotalCountAtom)
  const [, setMyPageMemberSubList] = useAtom(myPageMemberSubListAtom)
  const [, setMyPageMemberSubTotalCount] = useAtom(myPageMemberSubTotalCountAtom)
  const [, setMyPageBoardSubList] = useAtom(myPageBoardSubListAtom)
  const [, setMyPageBoardSubTotalCount] = useAtom(myPageBoardSubTotalCountAtom)
  const [, setPopupState] = useAtom(popupStateAtom)
  const [, reloadNoReadMessageCount] = useAtom(reloadNoReadMessageCountAtom)
  const [, reloadReadStateCount] = useAtom(reloadReadStateAtom)

  const { handleBoards } = useBoard()
  const { handleBanners } = useBanner()
  const { createToken } = useToken()

  const initFetchData = React.useCallback(async () => {
    let userId = 0
    try {
      const getAuthItem = await localStorage.getItem('auth')
      if (getAuthItem != null) {
        const getUser = JSON.parse(getAuthItem as string)
        const { jwt, user } = getUser
        if (user != undefined) {
          userId = Number(user.id)
          const today = new Date()
          const checkAttendance = moment(user.attendance).format('YYYY-MM-DD')
          const checkToday = moment(today).format('YYYY-MM-DD')
          if (checkAttendance !== checkToday) {
            const newUserData = await updateMeAttendance({ jwt: jwt, attendance: today })
            await updateVisitorCount(newUserData.id)
            await setAuth({ logged: true, jwt: jwt, user: newUserData })
            await localStorage.setItem(
              'auth',
              JSON.stringify({ logged: true, jwt: jwt, user: newUserData }),
            )
          } else {
            await setAuth({ logged: true, jwt: jwt, user: getUser.user })
            await localStorage.setItem(
              'auth',
              JSON.stringify({ logged: true, jwt: jwt, user: getUser.user }),
            )
          }

          const myPageArg = { jwt, limit: LIMIT.MY_PAGE_LIST }

          await Promise.all([
            findAllBoardByMeAuth(myPageArg),
            findAllCommentByMeAuth(myPageArg),
            findAllGoodByMeAuth(myPageArg),
            findAllScrapByMeAuth(myPageArg),
            findAllMessageBoardByMe(myPageArg),
            findAllMemberSubByMeAuth(myPageArg),
            findAllBoardSubByMeAuth(myPageArg),
            reloadNoReadMessageCount(),
            reloadReadStateCount(),
          ]).then((values) => {
            setMyPageBoardList(values[0].contents)
            setMyPageBoardTotalCount(values[0].totalCount)
            setMyPageCommentList(values[1].contents)
            setMyPageCommentTotalCount(values[1].totalCount)
            setMyPageGoodList(values[2].contents)
            setMyPageGoodTotalCount(values[2].totalCount)
            setMyPageScrapList(values[3].contents)
            setMyPageScrapTotalCount(values[3].totalCount)
            setMyPageMessageFromList(values[4].from.contents)
            setMyPageMessageFromTotalCount(values[4].from.totalCount)
            setMyPageMessageToList(values[4].to.contents)
            setMyPageMessageToTotalCount(values[4].to.totalCount)
            setMyPageMessageKeepList(values[4].keep.contents)
            setMyPageMessageKeepTotalCount(values[4].keep.totalCount)
            setMyPageMemberSubList(values[5].contents)
            setMyPageMemberSubTotalCount(values[5].totalCount)
            setMyPageBoardSubList(values[6].contents)
            setMyPageBoardSubTotalCount(values[6].totalCount)
          })
        } else {
          await setAuth({ logged: false, jwt: '', user: null })
          await localStorage.setItem('auth', JSON.stringify({ logged: false, jwt: '', user: null }))
        }
      } else {
        await setAuth({ logged: false, jwt: '', user: null })
        await localStorage.setItem('auth', JSON.stringify({ logged: false, jwt: '', user: null }))
      }
    } catch (e) {
      await setAuth({
        logged: false,
        jwt: '',
        user: null,
      })
      await localStorage.setItem('auth', JSON.stringify({ logged: false, jwt: '', user: null }))
    }
    const getSearchItem = await localStorage.getItem('search')
    if (getSearchItem !== null) {
      setSearchHistory(JSON.parse(getSearchItem as string))
    }

    await Promise.all([
      findAllMainNews(),
      // findAllKyodoNews(LIMIT.KYODO_NEWS_LIMIT),
      findAllMainBanner(),
      findAllMainContentsBanner(),
      findAllBoardBanners(),
      findAllBoard(userId, 0, LIMIT.BOARD_LIST_LIMIT),
      findAllAddressBook(),
      findAllPopup(),
    ]).then((values) => {
      setMainNewsList(values[0])
      // setMainKyodoNewsList(values[1])
      setMainBannerList(values[1])
      setMainContentsBannerList(values[2])
      handleBanners(values[3])
      handleBoards(values[4])
      setAddressBookList(values[5])
      setPopupState({ popup: values[6], isOpen: true })
    })

    await createToken(userId)
  }, [])

  // const loadFont = async () => {
  //   await Font.loadAsync({
  //     AppleSDGothicNeo_L: {
  //       uri: require('@/assets/fonts/AppleSDGothicNeoL.ttf'),
  //       display: Font.FontDisplay.FALLBACK,
  //     },
  //     AppleSDGothicNeo_M: {
  //       uri: require('@/assets/fonts/AppleSDGothicNeoM.ttf'),
  //       display: Font.FontDisplay.FALLBACK,
  //     },
  //     AppleSDGothicNeo_B: {
  //       uri: require('@/assets/fonts/AppleSDGothicNeoB.ttf'),
  //       display: Font.FontDisplay.FALLBACK,
  //     },
  //     AppleSDGothicNeo_EB: {
  //       uri: require('@/assets/fonts/AppleSDGothicNeoEB.ttf'),
  //       display: Font.FontDisplay.FALLBACK,
  //     },
  //   })
  // }

  React.useEffect(() => {
    async function prepare() {
      try {
        await SplashScreen.preventAutoHideAsync()
        await initFetchData()
      } catch (e) {
        console.warn(e)
        // void logout()
      } finally {
        setAppIsReady(true)
        await SplashScreen.hideAsync()
      }
    }

    void prepare()
  }, [initFetchData])

  if (!appIsReady) {
    return <CustomSplashScreen />
  }

  return (
    <Box flex={1} bgColor='#f4f4f4'>
      {children}
    </Box>
  )
}
