import React from 'react'
import { RefreshControl } from 'react-native'
import { Box, Spinner } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { findOneBoardById, updateBoardViewCount } from '@/services/board'
import { findAllCommentByCommentLoad, findCommentCount } from '@/services/comment'
import {
  createArticleElements,
  createComplainHistories,
  findOne,
  findOneComplainHistories,
  updateArticleElements,
  updateComplainHistories,
} from '@/services/articleElements'
import BoardDetailHeader from '@/screens/detail/components/BoardDetailHeader'
import BoardDetailBody from '@/screens/detail/components/BoardDetailBody'
import Footer from '@/components/Footer'
import Comment from '@/components/Comment'
import { LIMIT } from '@/constants/limit'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { IBoard } from '@/interfaces/board'
import { RootStackParamList } from '@/interfaces/navigation'
import { IAllComment, ICommentLoad } from '@/interfaces/comment'
import { IArticleElements, ICreateArticleElementsForm } from '@/interfaces/articleElements'
import BoardStackHeader from '@/navigation/components/BoardStackHeader'
import useFormatter from '@/hooks/useFormatter'
import FabBottomBoard from '@/navigation/components/FabBottomBoard'
import { boardCountAtom, memberCountAtom, totalCountAtom } from '@/atom/navigationAtom'
import { ReadSub } from '@/services/auth'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.BOARD_DETAIL>

export default function BoardDetailScreen(props: Props) {
  const { id, goComment } = props.route.params
  const [auth] = useAtom(authAtom)
  const [board, setBoard] = React.useState<IBoard | null>(null)
  const [commentList, setCommentList] = React.useState<IAllComment[]>([])
  const [commentCount, setCommentCount] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const [refreshing, setRefreshing] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [goodCount, setGoodCount] = React.useState(0)
  const [hateCount, setHateCount] = React.useState(0)
  const [articleElement, setArticleElement] = React.useState<IArticleElements | null>(null)
  const [good, setGood] = React.useState(false)
  const [hate, setHate] = React.useState(false)
  const [scrap, setScrap] = React.useState(false)
  const [complain, setComplain] = React.useState<number | null>(null)
  const [limit, setLimit] = React.useState(LIMIT.COMMENT_LIST_BOARD_LIMIT)
  const [dataSourceCords, setDataSourceCords] = React.useState(0)
  const [commentPageY, setCommentPageY] = React.useState(0)
  const [, setMemberCount] = useAtom(memberCountAtom)
  const [, setBoardCount] = useAtom(boardCountAtom)
  const [, setSubTotalCount] = useAtom(totalCountAtom)
  const { categoryFormatter } = useFormatter()
  const scrollRef = React.useRef<any>(null)

  const commentScrollToHandler = (pageY: number) => {
    scrollToHandler(commentPageY + pageY - 160)
  }

  const scrollToHandler = (scrollY: number) => {
    if (scrollRef.current) {
      scrollRef.current?.scrollTo({
        x: 0,
        y: scrollY, //we get the offset value from array based on key
        animated: true,
      })
    }
  }

  const onMoreComment = React.useCallback(async () => {
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.COMMENT_LIST_BOARD_LIMIT,
      type: 'board',
      typeId: id,
    }
    await Promise.all([
      findAllCommentByCommentLoad(commentLoad),
      findCommentCount({
        type: 'board',
        typeId: id,
      }),
    ]).then((values) => {
      setCommentList(values[0].result)
      setCommentCount(values[0].commentCount)
      setTotalCount(values[1])
    })
    setLimit(limit + LIMIT.COMMENT_LIST_BOARD_LIMIT)
  }, [limit, auth.user, id])

  const reLoad = async () => {
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.COMMENT_LIST_BOARD_LIMIT,
      type: 'board',
      typeId: id,
    }
    await Promise.all([
      findOneBoardById({
        boardId: id,
        userId: auth.user ? auth.user.id : 0,
      }),
      findAllCommentByCommentLoad(commentLoad),
    ]).then((values) => {
      setBoard(values[0])
      setCommentList(values[1].result)
      setCommentCount(values[1].commentCount)
    })
  }

  const reLoadCommentList = async () => {
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.COMMENT_LIST_BOARD_LIMIT,
      type: 'board',
      typeId: id,
    }
    await Promise.all([
      findAllCommentByCommentLoad(commentLoad),
      findCommentCount({
        type: 'board',
        typeId: id,
      }),
    ]).then((values) => {
      setCommentList(values[0].result)
      setCommentCount(values[0].commentCount)
      setTotalCount(values[1])
    })
  }

  const onRefresh = React.useCallback(async () => {
    const commentLoad: ICommentLoad = {
      userId: auth.user ? auth.user.id : 0,
      start: 0,
      limit: LIMIT.COMMENT_LIST_BOARD_LIMIT,
      type: 'board',
      typeId: id,
    }
    await Promise.all([
      findOneBoardById({
        boardId: id,
        userId: auth.user ? auth.user.id : 0,
      }),
      findAllCommentByCommentLoad(commentLoad),
      findCommentCount({
        type: 'board',
        typeId: id,
      }),
    ]).then((values) => {
      setBoard(values[0])
      setCommentList(values[1].result)
      setCommentCount(values[1].commentCount)
      setTotalCount(values[2])
    })
  }, [id, auth.user])

  const saveComplain = async (value: number) => {
    if (!value) return
    if (!auth.user) return

    setComplain(value)

    await findOneComplainHistories({
      type: 'board',
      type_id: id,
      reported_user: auth.user ? auth.user.id : 0,
    }).then(async (data) => {
      if (data) {
        await updateComplainHistories({
          jwt: auth.jwt,
          id: data.id,
          complain_type: value,
        })
      } else {
        await createComplainHistories({
          jwt: auth.jwt,
          type: 'board',
          type_id: id,
          complain_type: value,
          reported_user: auth.user ? auth.user.id : 0,
          writer: board && board.writer ? board.writer : 0,
          contents: board && board.title ? board.title : '',
        })
      }
    })
  }

  const saveArticleElement = async (elementType: string, value: any) => {
    if (!auth.logged) {
      props.navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    // 신고하기 변경
    if (elementType === 'COMPLAIN') {
      await saveComplain(value)
      return
    }

    let newGood = good
    let newHate = hate
    let newScrap = scrap

    if (elementType === 'GOOD') {
      newGood = value
      setGood(newGood)

      // if (newGood) setGoodCount(goodCount + 1)
      // else setGoodCount(goodCount - 1)

      if (hate) {
        newHate = false
        setHate(newHate)
        // setHateCount(hateCount - 1)
      }
    } else if (elementType === 'HATE') {
      newHate = value
      setHate(newHate)

      // if (newHate) setHateCount(hateCount + 1)
      // else setHateCount(hateCount - 1)

      if (good) {
        newGood = false
        setGood(newGood)
        // setGoodCount(goodCount - 1)
      }
    } else if (elementType === 'SCRAP') {
      newScrap = value
      setScrap(newScrap)
    }

    const form: ICreateArticleElementsForm = {
      good: newGood,
      hate: newHate,
      scrap: newScrap,
      type: 'board',
      type_id: id,
      writer: auth.user ? auth.user.id : 0,
      jwt: auth.jwt,
    }
    if (articleElement) {
      await updateArticleElements(form, articleElement.id)
    } else {
      await createArticleElements(form)
      await findOne({
        type: 'board',
        type_id: id,
        writer: auth.user ? auth.user.id : 0,
      }).then((data) => {
        setArticleElement(data)
      })
    }
    await findOneBoardById({
      boardId: id,
      userId: auth.user ? auth.user.id : 0,
    }).then((data) => {
      setBoard(data)
      setGoodCount(data.good_count)
      setHateCount(data.hate_count)
    })
  }

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const commentLoad: ICommentLoad = {
        userId: auth.user ? auth.user.id : 0,
        start: 0,
        limit: LIMIT.COMMENT_LIST_BOARD_LIMIT,
        type: 'board',
        typeId: id,
      }
      await updateBoardViewCount(id)
      await Promise.all([
        findOneBoardById({
          boardId: id,
          userId: auth.user ? auth.user.id : 0,
        }),
        findAllCommentByCommentLoad(commentLoad),
        findCommentCount({
          type: 'board',
          typeId: id,
        }),
        findOne({
          type: 'board',
          type_id: id,
          writer: auth.user ? auth.user.id : 0,
        }),
        findOneComplainHistories({
          type: 'board',
          type_id: id,
          reported_user: auth.user ? auth.user.id : 0,
        }),
      ]).then(async (values) => {
        setBoard(values[0])
        if (values[0]) {
          setGoodCount(values[0].good_count)
          setHateCount(values[0].hate_count)
          if (values[0].view_board == null && values[0].view_board !== undefined) {
            if (auth && auth.user && auth.user.id) {
              const res = await ReadSub({
                jwt: auth.jwt,
                board_type: 'board',
                user_num: auth.user.id,
                board_num: values[0].id,
              })
              setMemberCount(res.memberCount)
              setBoardCount(res.boardCount)
              setSubTotalCount(Number(res.memberCount) + Number(res.boardCount))
            }
          }
        }
        setCommentList(values[1].result)
        setCommentCount(values[1].commentCount)
        setTotalCount(values[2])
        setArticleElement(values[3])
        if (values[3]) {
          setGood(values[3].good)
          setHate(values[3].hate)
          setScrap(values[3].scrap)
        }
        if (values[4]) {
          setComplain(values[4].complain_type ? values[4].complain_type.id : null)
        }
      })
      await setLoading(false)
    }
    void fetchData()
  }, [auth, id])

  React.useLayoutEffect(() => {
    onRefresh()
    if (goComment) {
      scrollToHandler(dataSourceCords)
    }
  }, [dataSourceCords, goComment])

  return board ? (
    <Box flex={1}>
      <BoardStackHeader
        navigation={props.navigation}
        title={categoryFormatter(board.category)}
        board={board}
      />
      <Animated.ScrollView
        // innerRef={(ref) => (scrollRef.current = ref)}
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#ff6f1e']}
            tintColor='#ff6f1e'
          />
        }
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        // extraScrollHeight={90}
        // enableOnAndroid={true}
        // enableAutomaticScroll={Platform.OS === 'ios'}
        // enableResetScrollToCoords={true}
        scrollEventThrottle={16}
        onScroll={(event) => {
          const scrolling = event.nativeEvent.contentOffset.y
          setCommentPageY(scrolling)
        }}
        keyboardShouldPersistTaps='handled'
      >
        {/*<TouchableWithoutFeedback onPress={Keyboard.dismiss}>*/}
        <Box>
          <Box mb='11px' bg='#ffffff'>
            <BoardDetailHeader
              board={board}
              goodCount={goodCount}
              hateCount={hateCount}
              commentCount={commentCount}
              reLoad={reLoad}
              scrollY={dataSourceCords}
              scrollToHandler={scrollToHandler}
            />
            <BoardDetailBody
              board={board}
              saveComplain={saveComplain}
              saveArticleElement={saveArticleElement}
              goodCount={goodCount}
              hateCount={hateCount}
              good={good}
              hate={hate}
              scrap={scrap}
              complain={complain}
            />
          </Box>
          <Box
            onLayout={(e) => {
              const layout = e.nativeEvent.layout
              setDataSourceCords(layout.y)
            }}
          >
            <Comment
              board={board}
              commentType='BOARD'
              typeId={board.id}
              articleWriter={board.writer ? board.writer : 0}
              commentList={commentList}
              commentCount={commentCount}
              totalCount={totalCount}
              reLoadCommentList={reLoadCommentList}
              onMoreComment={onMoreComment}
              category={board.category}
              scrollToHandler={scrollToHandler}
              commentScrollToHandler={commentScrollToHandler}
              dataSourceCords={dataSourceCords}
            />
          </Box>
          <Footer />
          <FabBottomBoard board={board} />
        </Box>
      </Animated.ScrollView>
    </Box>
  ) : (
    <Box flex={1} justifyContent='center'>
      <Spinner color='#ff6f1e' />
    </Box>
  )
}
