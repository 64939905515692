import React from 'react'
import { Box } from 'native-base'
import ModalConfirm from '@/components/ModalConfirm'
import ModalSelectBoard from '@/components/ModalSelectBoard'
import ModalComplain from '@/components/ModalComplain'
import ModalShare from '@/components/ModalShare'
import { MODAL_TYPE } from '@/constants/label'
import ModalAlert from '@/components/ModalAlert'

interface Props {
  modalType: MODAL_TYPE
  onAccept: () => void
  handleCloseModal: () => void
  selectCategory?: (category: number, categoryTitle: string) => void
  articleElements?: {
    type: string
    typeId: number
    typeCategory?: number
  }
  saveComplain?: (value: number) => void
}

export default function CreateModal(props: Props) {
  const { modalType, onAccept, handleCloseModal, selectCategory, articleElements, saveComplain } =
    props
  const [isOpen, setIsOpen] = React.useState(true)

  const closeUserMenu = () => {
    handleCloseModal()
    setIsOpen(false)
  }

  return (
    <Box>
      {/* 알림: 내용 없음 */}
      {MODAL_TYPE.ALERT_NO_CONTENT === modalType && (
        <ModalAlert
          isOpen={isOpen}
          message='내용을 1글자 이상 입력해주세요.'
          onClose={handleCloseModal}
        />
      )}

      {/* 알림: 본인글 신고 */}
      {MODAL_TYPE.ALERT_SELF_COMPLAIN === modalType && (
        <ModalAlert
          isOpen={isOpen}
          message='본인이 작성한 글은 신고할 수 없습니다.'
          onClose={handleCloseModal}
        />
      )}

      {/* 공유하기 */}
      {MODAL_TYPE.SHARE === modalType && (
        <ModalShare
          title={''}
          isOpen={isOpen}
          onClose={handleCloseModal}
          type={articleElements ? articleElements.type : 'news-paper'}
          typeId={articleElements ? articleElements.typeId : 0}
          typeCategory={articleElements ? articleElements.typeCategory : 0}
        />
      )}

      {/* 신고하기 */}
      {MODAL_TYPE.COMPLAIN === modalType && articleElements && (
        <ModalComplain
          saveComplain={
            saveComplain
              ? saveComplain
              : () => {
                  return
                }
          }
          isOpen={isOpen}
          onClose={handleCloseModal}
          type={articleElements.type}
          typeId={articleElements.typeId}
        />
      )}

      {/* 게시판 선택 */}
      {MODAL_TYPE.SELECT_CATEGORY === modalType && (
        <ModalSelectBoard
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectCategory={selectCategory}
        />
      )}

      {/* 차단하기 */}
      {MODAL_TYPE.BLOCK_USER === modalType && isOpen && (
        <ModalConfirm
          isOpen={isOpen}
          onAccept={() => onAccept()}
          onClose={() => closeUserMenu()}
          titleMessage='차단하시겠어요?'
          contentsMessage='이 멤버의 쓴 글과 댓글이 보이지 않고, 알림도 오지 않습니다.'
          acceptButtonText='확인'
          cancelButtonText='취소'
        />
      )}

      {/* 차단해제하기 */}
      {MODAL_TYPE.UNBLOCK_USER === modalType && isOpen && (
        <ModalConfirm
          isOpen={isOpen}
          onAccept={() => onAccept()}
          onClose={() => closeUserMenu()}
          titleMessage='차단을 해제하시겠어요??'
          contentsMessage='이 멤버의 쓴 글과 댓글을 다시 보실 수 있습니다.'
          acceptButtonText='확인'
          cancelButtonText='취소'
        />
      )}
    </Box>
  )
}
