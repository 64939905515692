import { IBlockUserForm } from '@/interfaces/blockUser'
import { StrapiJwtAPI } from '@/services/axios'

export async function createBlockUser(blockUserForm: IBlockUserForm) {
  const { jwt, userId, blockUserId } = blockUserForm
  await StrapiJwtAPI(jwt).post('/block-user-lists', {
    user_id: userId,
    block_user_id: blockUserId,
  })
}

export async function removeBlockUser(blockUserForm: IBlockUserForm) {
  const { jwt, userId, blockUserId } = blockUserForm
  await StrapiJwtAPI(jwt).delete(
    `/block-user-lists/custom/delete?userId=${userId}&blockUserId=${blockUserId}`,
  )
}
