import React from 'react'
import { Box, Pressable } from 'native-base'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { IBoard } from '@/interfaces/board'
import { Linking, Modal, SafeAreaView, StyleSheet } from 'react-native'
import useUrl from '@/hooks/useUrl'
import ImageViewer from 'react-native-image-zoom-viewer'
import ImageModalHeader from '@/components/ImageModalHeader'
import HTMLView from 'react-native-htmlview'

interface Props {
  board: IBoard
}

export default function BoardDetailContents(props: Props) {
  const { board } = props
  const [isOpenImageModal, setOpenImageModal] = React.useState(false)
  const { getUrlListByContents } = useUrl()
  const [screenWidth] = useAtom(screenWidthAtom)
  const contentWidth = screenWidth - 34

  const renderNode = (node: any, index: any, siblings: any, parent: any, defaultRenderer: any) => {
    if (node.name == 'img') {
      const a = node
      return (
        <Pressable onPress={() => setOpenImageModal(true)}>
          <img style={{ width: '100%' }} src={a.attribs.src} alt='이미지' draggable='false' />
        </Pressable>
      )
    }
  }

  const styles = StyleSheet.create({
    span: {
      lineHeight: 28,
      fontSize: 17,
    },
    a: {
      lineHeight: 28,
      fontSize: 17,
      textDecorationLine: 'underline', // 추가된 부분
      color: 'blue', // 추가된 부분
    },
    p: {
      lineHeight: 28,
      fontSize: 17,
    },
  })

  const tagsStyles = {
    img: {
      marginTop: '8px',
      marginBottom: '8px',
    },
    iframe: {
      opacity: 0.99,
    },
    table: {
      opacity: 0.99,
    },
  }

  // const renderersProps = React.useMemo(
  //   () => ({
  //     enableExperimentalPercentWidth: true,
  //     iframe: {
  //       scalesPageToFit: true,
  //     },
  //     table: {
  //       cssRules,
  //       webViewProps,
  //       // computeContainerHeight() {
  //       //   return null
  //       // },
  //     },
  //     a: {
  //       onPress: async (event: any, href: any) => {
  //         await Linking.openURL(href)
  //       },
  //     },
  //   }),
  //   [cssRules, webViewProps],
  //)

  return (
    <Box w='full' minHeight={300}>
      <Modal
        visible={isOpenImageModal}
        transparent={false}
        onRequestClose={() => setOpenImageModal(false)}
      >
        <SafeAreaView style={{ flex: 1, backgroundColor: '#000000' }}>
          <ImageViewer
            imageUrls={getUrlListByContents(board.contents)}
            renderHeader={() => <ImageModalHeader onClose={() => setOpenImageModal(false)} />}
          />
        </SafeAreaView>
      </Modal>
      <HTMLView value={board.contents} renderNode={renderNode} stylesheet={styles} />
    </Box>
  )
}
