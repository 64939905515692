import { Box, Text } from 'native-base'

interface Props {
  errorMessage: string
  marginTop?: string
  marginBottom?: string
}

export default function ErrorMessageBox(props: Props) {
  const { errorMessage, marginTop, marginBottom } = props
  return (
    <Box
      alignItems='center'
      py='18px'
      backgroundColor='#FFF1EA'
      mt={marginTop == undefined ? '16px' : marginTop}
      mb={marginBottom == undefined ? '32px' : marginBottom}
      textAlign='center'
    >
      <Text fontWeight='bold' color='#FF6F1E'>
        {errorMessage}
      </Text>
    </Box>
  )
}
