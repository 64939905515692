import { TouchableHighlight } from 'react-native'
import { Box, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import useUrl from '@/hooks/useUrl'
import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IYonhapNews } from '@/interfaces/news'

interface Props {
  item: IYonhapNews
}

export default function NewsDetailYonhapNewsItem(props: Props) {
  const { item } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { handleUrl } = useUrl()

  return (
    <TouchableHighlight
      onPress={() => {
        navigation.push(ROOT_STACK.NEWS_DETAIL, { id: item.id })
      }}
      underlayColor='#e9e9e9'
    >
      <Box w={screenWidth} px='17px' py='7px' borderBottomWidth='1px' borderBottomColor='#f4f4f4'>
        <HStack alignItems='center'>
          <FastImage
            source={{
              uri: item.thumbnail
                ? handleUrl(item.thumbnail.url)
                : item.thumbnail_path
                ? handleUrl(item.thumbnail_path)
                : '',
            }}
            style={{
              width: (screenWidth - 17) / 3.8,
              height: 'auto',
              borderRadius: 3,
              aspectRatio: 3 / 2,
            }}
            resizeMode='cover'
          />
          <Box w={(screenWidth - 17) / 1.45} pl='14px'>
            <Text lineBreakMode='tail' numberOfLines={2} fontSize={16}>
              {item.title}
            </Text>
          </Box>
        </HStack>
      </Box>
    </TouchableHighlight>
  )
}
