import { LinkingOptions } from '@react-navigation/native'
import { Linking } from 'react-native'
import * as Notifications from 'expo-notifications'

import { RootStackParamList } from '@/interfaces/navigation'
import { AUTH_STACK, ROOT_STACK, TOP_TAB, USER_PAGE_TAB } from '@/constants/label'
import { Env } from '@/constants/env'

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ['hanteo://', Env.WEB_URL],
  config: {
    screens: {
      TOP_TAB: {
        screens: {
          MAIN: {
            screens: {
              MAIN: TOP_TAB.MAIN,
            },
          },
          NEWS: TOP_TAB.NEWS,
          JOB: TOP_TAB.JOB,
          PROPERTY: TOP_TAB.PROPERTY,
          SHOPPING: TOP_TAB.SHOPPING,
          SERVICE: TOP_TAB.SERVICE,
          EDUCATION: TOP_TAB.EDUCATION,
          TRIP: TOP_TAB.TRIP,
          MEDICAL: TOP_TAB.MEDICAL,
          LAW: TOP_TAB.LAW,
          CLUB: TOP_TAB.CLUB,
          ADDRESS: TOP_TAB.ADDRESS,
        },
      },
      AUTH_STACK: {
        screens: {
          AUTH_SIGN_IN: AUTH_STACK.AUTH_SIGN_IN,
          AUTH_FORGOT_ID_COMPLETE: AUTH_STACK.AUTH_FORGOT_ID_COMPLETE,
          AUTH_FORGOT_PW: AUTH_STACK.AUTH_FORGOT_PW,
          AUTH_FORGOT_PW_COMPLETE: AUTH_STACK.AUTH_FORGOT_PW_COMPLETE,
          AUTH_REGISTER: AUTH_STACK.AUTH_REGISTER,
          AUTH_AUTHENTICATION: AUTH_STACK.AUTH_AUTHENTICATION,
          AUTH_PROFILE: AUTH_STACK.AUTH_PROFILE,
          AUTH_REGISTER_COMPLETE: AUTH_STACK.AUTH_REGISTER_COMPLETE,
          AUTH_RESET_PW: AUTH_STACK.AUTH_RESET_PW,
        },
      },
      NOT_FOUND: ROOT_STACK.NOT_FOUND,
      SEND_MESSAGE: ROOT_STACK.SEND_MESSAGE,
      USER_PAGE_TAB: {
        screens: {
          USER_PAGE_BOARD: USER_PAGE_TAB.USER_PAGE_BOARD,
          USER_PAGE_COMMENT: USER_PAGE_TAB.USER_PAGE_COMMENT,
          USER_PAGE_GOOD: USER_PAGE_TAB.USER_PAGE_GOOD,
        },
      },
      NEWS_PAPER: ROOT_STACK.NEWS_PAPER,
      NEWS_PAPER_DETAIL: ROOT_STACK.NEWS_PAPER_DETAIL,
      NEWS_DETAIL: ROOT_STACK.NEWS_DETAIL,
      // KYODO_DETAIL: ROOT_STACK.KYODO_DETAIL,
      BANNER_DETAIL: ROOT_STACK.BANNER_DETAIL,
      BOARD_DETAIL: ROOT_STACK.BOARD_DETAIL,
      ADDRESS_DETAIL: ROOT_STACK.ADDRESS_DETAIL,
      COMPANY_INTRO: ROOT_STACK.COMPANY_INTRO,
      INQUIRY: ROOT_STACK.INQUIRY,
      POLICY_PRIVACY: ROOT_STACK.POLICY_PRIVACY,
      POLICY_SERVICE: ROOT_STACK.POLICY_SERVICE,
      MEMBERSHIP_WITHDRAWAL: ROOT_STACK.MEMBERSHIP_WITHDRAWAL,
      MODIFY_PROFILE: ROOT_STACK.MODIFY_PROFILE,
      'auth/facebook-login': ROOT_STACK.FACEBOOK_LOGIN,
    },
  },
  async getInitialURL() {
    const url: any = await Linking.getInitialURL()

    if (url !== null) {
      return url
    }

    const response = await Notifications.getLastNotificationResponseAsync()
    return response?.notification.request.content.data.url
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url)

    // Listen to incoming links from deep linking
    Linking.addEventListener('url', onReceiveURL)

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      const url = response.notification.request.content.data.url

      if (typeof url !== 'string') return

      listener('hanteo://')
      listener(url)
    })

    return () => {
      // Clean up the event listeners
      Linking.removeAllListeners('url')
      subscription.remove()
    }
  },
}

export default linking
