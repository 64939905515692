import React, { Fragment } from 'react'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { IYonhapNews } from '@/interfaces/news'
import { Box, Pressable, Text } from 'native-base'
import useUrl from '@/hooks/useUrl'
import ImageViewer from 'react-native-image-zoom-viewer'
import { Modal, SafeAreaView, StyleSheet } from 'react-native'
import ImageModalHeader from '@/components/ImageModalHeader'
import HTMLView from 'react-native-htmlview'

interface Props {
  news: IYonhapNews
}

export default function NewsDetailContents(props: Props) {
  const { news } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const [isOpenImageModal, setOpenImageModal] = React.useState(false)
  const { getUrlListByContents } = useUrl()

  const renderNode = (node: any, index: any, siblings: any, parent: any, defaultRenderer: any) => {
    if (node.name == 'img') {
      const a = node
      return (
        <Pressable onPress={() => setOpenImageModal(true)}>
          <img style={{ width: '100%' }} src={a.attribs.src} alt='이미지' draggable='false' />
        </Pressable>
      )
    }
  }

  const styles = StyleSheet.create({
    span: {
      lineHeight: 28,
      fontSize: 17,
    },
    a: {
      lineHeight: 28,
      fontSize: 17,
      textDecorationLine: 'underline', // 추가된 부분
      color: 'blue', // 추가된 부분
    },
    p: {
      lineHeight: 28,
      fontSize: 17,
    },
  })

  return (
    <Fragment>
      <Modal
        visible={isOpenImageModal}
        transparent={false}
        onRequestClose={() => setOpenImageModal(false)}
      >
        <SafeAreaView style={{ flex: 1, backgroundColor: '#000000' }}>
          <ImageViewer
            imageUrls={getUrlListByContents(news.contents)}
            renderHeader={() => <ImageModalHeader onClose={() => setOpenImageModal(false)} />}
          />
        </SafeAreaView>
      </Modal>
      <Box mx='16px' mt='16px' mb='8px'>
        <HTMLView value={news.contents} renderNode={renderNode} stylesheet={styles} />
      </Box>
      {news.source_type == '연합뉴스' ? (
        <Text fontSize='17px' px='16px' fontWeight='bold' mb='32px'>
          저작권자(c) 연합뉴스. 무단전재-재배포금지
        </Text>
      ) : null}
    </Fragment>
  )
}
