import React from 'react'
import { Box, HStack, Spinner, VStack } from 'native-base'
import { WebView } from 'react-native-webview'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { isOpenTopAuthMenuAtom, screenWidthAtom } from '@/atom/navigationAtom'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { RootStackParamList } from '@/interfaces/navigation'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { TouchableOpacity } from 'react-native'

interface Props {
  uri: string
  title: string
}

export default function WebBrowser(props: Props) {
  const { uri } = props

  const [screenWidth] = useAtom(screenWidthAtom)
  const [isOpen, setOpen] = useAtom(isOpenTopAuthMenuAtom)

  const webViewRef = React.useRef<any>()

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const goBackWebView = () => {
    webViewRef.current.goBack()
  }

  const goForwardWebView = () => {
    webViewRef.current.goForward()
  }

  const renderLoading = () => {
    return (
      <Box position='absolute' w='full' h='full' justifyContent='center' alignItems='center'>
        <Spinner color='#ff6f1e' />
      </Box>
    )
  }

  return (
    <React.Fragment>
      <VStack
        bg='white'
        h='45px'
        justifyContent='center'
        borderBottomWidth={3}
        borderBottomColor='#FF6F1E'
      >
        <HStack justifyContent='space-between' alignItems='center' px='15px'>
          <CButton
            onPress={() => {
              navigation.goBack()
            }}
          >
            <FastImage
              source={require('@/assets/images/black-back-icon.png')}
              style={{
                width: 24,
                height: 24,
              }}
              resizeMode='contain'
            />
          </CButton>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate(ROOT_STACK.TOP_TAB, {
                screen: TOP_TAB.MAIN,
              })
            }
          >
            <FastImage
              source={require('@/assets/images/logo.png')}
              style={{ width: 44, height: 28 }}
              resizeMode='contain'
            />
          </TouchableOpacity>
          <CButton my='auto' onPress={() => setOpen(!isOpen)}>
            <FastImage
              source={require('@/assets/images/ham_menu_black.png')}
              style={{ width: 24, height: 18, marginTop: 'auto', marginBottom: 'auto' }}
              resizeMode='contain'
            />
          </CButton>
        </HStack>
      </VStack>
      <WebView
        ref={webViewRef}
        source={{ uri }}
        startInLoadingState={true}
        renderLoading={renderLoading}
      />
      <VStack
        w='full'
        h='50px'
        position='absolute'
        bottom={0}
        left={0}
        borderTopWidth={1}
        borderTopColor='#DADADA'
        shadow='9'
        zIndex={9999}
        bg='white'
      >
        <HStack>
          <CButton onPress={() => goBackWebView()}>
            <Box top='10px' mx='5px' px='15px'>
              <FastImage
                source={require('@/assets/images/black-left-arrow.png')}
                style={{
                  width: 20,
                  height: 25,
                }}
                resizeMode='contain'
              />
            </Box>
          </CButton>
          <CButton onPress={() => goForwardWebView()}>
            <Box top='11.5px' px='15px'>
              <FastImage
                source={require('@/assets/images/black-right-arrow.png')}
                style={{
                  width: 20,
                  height: 25,
                }}
                resizeMode='contain'
              />
            </Box>
          </CButton>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate(ROOT_STACK.TOP_TAB, {
                screen: TOP_TAB.MAIN,
              })
            }
          >
            <Box top='10px' left={screenWidth / 2 - 130}>
              <FastImage
                source={require('@/assets/images/logo.png')}
                style={{ width: 44, height: 28 }}
                resizeMode='contain'
              />
            </Box>
          </TouchableOpacity>
        </HStack>
      </VStack>
    </React.Fragment>
  )
}
