import React from 'react'
import { Box, Center, Checkbox, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { keepMessage, removeMessage } from '@/services/userContents'

import CButton from '@/components/CButton'
import ModalAlert from '@/components/ModalAlert'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { ICheckedList } from '@/interfaces/userContents'

interface Props {
  selectedTabId: string
  allCheck: (value: boolean) => void
  reloadMessageBoard: () => void
  checkedAll: boolean
  getCurrentCheckList: () => ICheckedList[]
}

export default function MyPageMessageBoxHeader(props: Props) {
  const { selectedTabId, allCheck, reloadMessageBoard, checkedAll, getCurrentCheckList } = props
  const [isOpenAlertModal, setIsOpenAlertModal] = React.useState(false)
  const [auth] = useAtom(authAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const onSave = async (type: string) => {
    if (getCurrentCheckList().length === 0) {
      setIsOpenAlertModal(true)
      return
    }

    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    const saveList: number[] = []

    getCurrentCheckList().map((item) => {
      if (item.checked) saveList.push(item.messageId)
    })

    if (type === 'REMOVE') {
      await removeMessage({
        jwt: auth.jwt,
        removeId: saveList,
        removeTab: selectedTabId,
      }).then(() => {
        reloadMessageBoard()
      })
    } else if (type === 'KEEP') {
      await keepMessage({
        jwt: auth.jwt,
        keepId: saveList,
      }).then(() => {
        reloadMessageBoard()
      })
    }
  }

  return (
    <Box
      flexDirection='row'
      mt='20px'
      justifyContent='space-between'
      pb='10px'
      borderBottomWidth='1px'
    >
      {isOpenAlertModal && (
        <ModalAlert
          isOpen={isOpenAlertModal}
          message='쪽지를 선택해주세요.'
          onClose={() => setIsOpenAlertModal(false)}
        />
      )}
      <Checkbox
        // size='sm'
        value='ALL'
        colorScheme='orange'
        boxSize='19'
        _checked={{ backgroundColor: '#ff6f1e', borderColor: '#ff6f1e' }}
        onChange={(isSelected: boolean) => allCheck(isSelected)}
        isChecked={checkedAll}
      >
        <Text mt={2} fontSize={15} marginBottom={1}>
          전체선택 및 해제
        </Text>
      </Checkbox>
      <Box flexDirection='row'>
        {selectedTabId === 'TO' ? (
          <CButton mt={2} mr='6px' onPress={() => onSave('KEEP')}>
            <Center
              w='48px'
              h='24px'
              borderWidth='1px'
              borderColor='#8F8F8F'
              borderRadius='12px'
              bgColor='#ffffff'
            >
              <Text>보관</Text>
            </Center>
          </CButton>
        ) : null}
        <CButton mt={2} onPress={() => onSave('REMOVE')}>
          <Center
            w='48px'
            h='24px'
            borderWidth='1px'
            borderColor='#8F8F8F'
            borderRadius='12px'
            bgColor='#ffffff'
          >
            <Text>삭제</Text>
          </Center>
        </CButton>
      </Box>
    </Box>
  )
}
