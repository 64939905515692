import { Box, HStack, Text, VStack } from 'native-base'
import React, { Fragment } from 'react'
import AddressPremiumItem from '@/screens/boards/components/AddressPremiumItem'
import AddressNormalItem from '@/screens/boards/components/AddressNormalItem'
import { ISearchAddress } from '@/interfaces/search'

interface Props {
  searchAddressList: ISearchAddress
  keyword: string
}

export default function SearchResultAddressList(props: Props) {
  const { searchAddressList, keyword } = props

  return (
    <Box mb='20px'>
      <Text fontSize='18px' fontWeight={600} px='16px' pt='8px'>
        주소록
      </Text>
      <VStack>
        {searchAddressList.contents.length ? (
          searchAddressList.contents.map((bookItem, index) => {
            return (
              <Fragment key={`f_${index}`}>
                {bookItem.groupList.length > 0 || bookItem.premiumList.length > 0 ? (
                  <VStack key={`book_${index}`}>
                    <Box mt='25px' px='12px'>
                      <Text color='#FF6F1E' fontSize='20px' fontWeight={700}>
                        {bookItem.bookTitle}
                      </Text>
                    </Box>
                    {bookItem.premiumList.map((item, index) => {
                      return <AddressPremiumItem key={`book_pItem_${index}`} address={item} />
                    })}
                    {bookItem.groupList.map((groupItem, index) => {
                      return (
                        <Fragment key={`gf_${index}`}>
                          {groupItem.list.length > 0 ? (
                            <Box
                              mt='10px'
                              mx='17px'
                              pb='12px'
                              borderBottomWidth='1px'
                              borderBottomColor='black'
                            >
                              <Text fontSize='20px' fontWeight={700}>
                                {groupItem.groupTitle}
                              </Text>
                            </Box>
                          ) : null}
                          <Box px='17px'>
                            {groupItem.list.map((item, index) => {
                              return (
                                <AddressNormalItem key={`book_nItem_${index}`} address={item} />
                              )
                            })}
                          </Box>
                        </Fragment>
                      )
                    })}
                  </VStack>
                ) : null}
              </Fragment>
            )
          })
        ) : (
          <HStack justifyContent='center' my='100px' mx='auto'>
            <Text fontWeight='bold'>'{keyword}'</Text>
            <Text>에 대한 검색결과가 없습니다.</Text>
          </HStack>
        )}
      </VStack>
    </Box>
  )
}
