import React from 'react'
import { Box, Center, HStack, Text, TextArea, Pressable } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { createReCommentByCommentForm } from '@/services/comment'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import CreateModal from '@/components/CreateModal'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { IReCommentForm } from '@/interfaces/comment'
import { RootStackParamList } from '@/interfaces/navigation'
import { Keyboard } from 'react-native'
import { IBanner } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'

interface Props {
  board?: IBoard
  commentId: number
  type: string
  typeId: number
  reLoadCommentList: () => void
  hashUser: number
  hashUserNickName: string
  handleHashUser: (hashUser: number, hashUserNickName: string) => void
  category?: number
  commentOpenId: number | null
  setCommentOpenId: (id: number) => void
  commentScrollToHandler: (scrollY: number) => void
  banner?: IBanner
}

export default function CreateReCommentBox(props: Props) {
  const {
    board,
    commentId,
    type,
    typeId,
    reLoadCommentList,
    handleHashUser,
    hashUser,
    hashUserNickName,
    category,
    commentOpenId,
    setCommentOpenId,
    commentScrollToHandler,
    banner,
  } = props
  const [auth] = useAtom(authAtom)
  const [screenWidth] = useAtom(screenWidthAtom)
  const [content, setContent] = React.useState('')
  const [contentLength, setContentLength] = React.useState(0)
  const [isOpenAlertNoContent, setIsOpenAlertNoContent] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [commentPageY, setCommentPageY] = React.useState(0)

  const handleCommentPageY = (y: number) => {
    setCommentPageY(y)
  }

  const { handleSubmit, control } = useForm<IReCommentForm>({
    defaultValues: {
      writer: auth.user ? auth.user.id : 0,
      jwt: auth.jwt ? auth.jwt : '',
      type: type.toLowerCase(),
      type_id: typeId,
      contents: '',
      hash_user: hashUser,
      comment: commentId,
      category,
      board: board,
      banner: banner,
    },
  })

  const handleContentLength = (length: number) => {
    setContentLength(length)
  }

  const handleContent = (value: string) => {
    if (value.length > 300) {
      value = value.substring(0, 300)
    }
    setContent(value)
    handleContentLength(value.length)
  }

  const onSubmit = async (reCommentForm: IReCommentForm) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (contentLength === 0) {
      setIsOpenAlertNoContent(true)
      return
    }

    reCommentForm.hash_user = hashUser

    await createReCommentByCommentForm(reCommentForm)
    setContent('')
    setContentLength(0)
    handleHashUser(0, '')
    reLoadCommentList()
    Keyboard.dismiss()
  }

  const loginCheck = () => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
    }
  }

  return (
    <HStack py='18px' px='17px' flex={1} bgColor='#F8F8F8'>
      {isOpenAlertNoContent && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_NO_CONTENT}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => setIsOpenAlertNoContent(false)}
        />
      )}
      <Box w='23px'>
        <FastImage
          source={require('@/assets/images/re-comment-arrow.png')}
          style={{
            width: 15,
            height: 13,
            marginTop: 10,
          }}
          resizeMode='contain'
        />
      </Box>
      {auth && auth.logged && commentOpenId === commentId ? (
        <Box
          borderWidth='1px'
          borderColor='#DEDEDE'
          flex={1}
          bgColor='white'
          onTouchEnd={() => loginCheck()}
          rounded={5}
        >
          <Box w='full' flexDirection='row' ml='12px' mt='10px'>
            {hashUserNickName && (
              <CButton mr='17px' onPress={() => handleHashUser(0, '')}>
                <Text>{hashUserNickName}</Text>
              </CButton>
            )}
            <Text>{contentLength}/300</Text>
          </Box>
          <Controller
            control={control}
            name='contents'
            rules={{ required: false }}
            render={({ field: { onChange } }) => (
              <TextArea
                autoCompleteType={undefined}
                placeholder={auth && auth.logged ? '내용을 입력해주세요.' : '로그인 해주세요.'}
                w='full'
                fontSize={16}
                variant='unstyled'
                maxLength={300}
                value={content}
                onChangeText={(value) => {
                  onChange(value)
                  handleContent(value)
                }}
                isReadOnly={!(auth && auth.logged)}
                autoCapitalize='none'
                autoCorrect={false}
                onTouchStart={(e) => handleCommentPageY(e.nativeEvent.pageY)}
                onFocus={() => commentScrollToHandler(commentPageY)}
              />
            )}
          />
          <Box
            borderTopWidth='1px'
            borderTopColor='#DEDEDE'
            flexDirection='row'
            justifyContent='space-between'
            h='40px'
          >
            <Text fontSize='12px' color='#ff6f1e' px='12px' my='auto'>
              이 댓글에 대한 법적 책임은 작성자에게 귀속됩니다.
            </Text>
            <CButton my='auto' mr='7px' onPress={handleSubmit(onSubmit)}>
              <Center w='39px' h='25px' bgColor='#f4f4f4'>
                <Text fontSize='12px'>등록</Text>
              </Center>
            </CButton>
          </Box>
        </Box>
      ) : auth && auth.logged && commentOpenId !== commentId ? (
        <Pressable
          onPress={() => {
            setCommentOpenId(commentId)
            handleContent('')
          }}
          w={screenWidth - 60}
        >
          <Center borderWidth='1px' borderColor='#C1C1C1' h='36px' bg='#ffffff' rounded='5px'>
            <Text fontSize='15px' color='#5B5B5B' mr='auto' ml='10px'>
              댓글 입력
            </Text>
          </Center>
        </Pressable>
      ) : (
        <CButton
          onPress={() =>
            navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
          }
          w={screenWidth - 60}
        >
          <Center borderWidth='1px' borderColor='#C1C1C1' h='36px' bg='#ffffff'>
            <Text fontSize='15px' color='#cdcdcd' mr='auto' ml='10px'>
              로그인 해주세요.
            </Text>
          </Center>
        </CButton>
      )}
    </HStack>
  )
}
