import React from 'react'
import { RefreshControl } from 'react-native'
import { VStack } from 'native-base'
import { useNavigation, useScrollToTop } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { myPageScrapListAtom, myPageScrapTotalCountAtom } from '@/atom/initAtom'
import { findAllScrapByMeAuth } from '@/services/userContents'
import MoreTab from '@/components/MoreTab'
import { LIMIT } from '@/constants/limit'
import { MY_PAGE_TAB, ROOT_STACK } from '@/constants/label'
import { MyPageTabParamList, RootStackParamList } from '@/interfaces/navigation'
import MyPageBoardBlank from './components/MyPageBoardBlank'
import { TouchableHighlight } from 'react-native'
import MyPageBoardHeader from './components/MyPageBoardHeader'
import MyPageBoardTitle from './components/MyPageBoardTitle'
import MyPageBoardContents from './components/MyPageBoardContents'
import { CATEGORY_LABEL } from '@/constants/site'
import { findById } from '@/services/banner'
import { updateScrapElements } from '@/services/articleElements'

type Props = NativeStackScreenProps<MyPageTabParamList, MY_PAGE_TAB.MY_PAGE_SCRAP>

export default function MyPageScrapScreen({ navigation }: Props) {
  const [auth] = useAtom(authAtom)
  const [list, setList] = useAtom(myPageScrapListAtom)
  const [totalCount, setTotalCount] = useAtom(myPageScrapTotalCountAtom)
  const [limit, setLimit] = React.useState(LIMIT.MY_PAGE_LIST)
  const [refreshing, setRefreshing] = React.useState(false)
  const navigation_main = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  const onPageChange = async (board: any) => {
    if (typeof board === 'number') {
      const result = await updateScrapElements(auth.jwt, board)
      if (result.status == 200) {
        const myPageArg = { jwt: auth.jwt, limit: LIMIT.MY_PAGE_LIST }
        const res = await findAllScrapByMeAuth(myPageArg)
        if (result.status == 200) {
          setList(res.contents)
          setTotalCount(res.totalCount)
          return null
        }
        return null
      }
    } else {
      const board_category = CATEGORY_LABEL.find((item) => item.id === board.category)
      if (board.type == 'board' && board_category) {
        navigation_main.push(ROOT_STACK.BOARD_DETAIL, {
          id: board.id,
          category: board.category,
        })
      } else if (board.type == 'news') {
        navigation_main.push(ROOT_STACK.NEWS_DETAIL, { id: board.id })
      } else {
        const banner = await findById(board.category)
        navigation_main.push(ROOT_STACK.BANNER_BOARD_DETAIL, {
          id: board.id,
          category: board.category,
          banner: banner,
        })
      }
    }
  }

  const onRefresh = React.useCallback(async () => {
    await setRefreshing(true)
    await findAllScrapByMeAuth({ jwt: auth.jwt, limit: LIMIT.MY_PAGE_LIST }).then((data) => {
      setList(data.contents)
      setTotalCount(data.totalCount)
    })
    await setRefreshing(false)
  }, [auth, setList, setTotalCount])

  const onMore = React.useCallback(async () => {
    const newLimit = limit + LIMIT.MY_PAGE_LIST
    await findAllScrapByMeAuth({ jwt: auth.jwt, limit: newLimit }).then((data) => {
      setList(data.contents)
      setTotalCount(data.totalCount)
      setLimit(newLimit)
    })
  }, [auth, limit, setList, setTotalCount])

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (navigation.getState().index !== 4) {
        scrollRef.current.scrollTo({ x: 0, y: 0, animated: false })
      }
    })
    return unsubscribe
  }, [navigation])

  return (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      contentContainerStyle={{ flexGrow: 1, backgroundColor: '#ffffff' }}
      showsVerticalScrollIndicator={false}
      // scrollEventThrottle={16}
      // onScroll={scrollHandler}
    >
      {list.length ? (
        list.map((item) => {
          return (
            <TouchableHighlight
              onPress={() => onPageChange(item)}
              underlayColor='#e9e9e9'
              key={
                item.type == 'board'
                  ? 'board' + item.id + item.title
                  : item.type == 'advertisement'
                  ? 'advertisement' + item.id + item.title
                  : 'new' + item.id + item.title
              }
            >
              <VStack py='16px' px='16px' borderColor='#F2F2F2' borderBottomWidth='8px'>
                <MyPageBoardHeader
                  board={item}
                  screen={'MyPageScrapScreen'}
                  onHandleFunc={onPageChange}
                />
                <MyPageBoardTitle board={item} />
                <MyPageBoardContents board={item} screen={'MyPageScrapScreen'} />
              </VStack>
            </TouchableHighlight>
          )
        })
      ) : (
        <MyPageBoardBlank title='스크랩이 없습니다.' screen={'MyPageScrapScreen'} />
      )}
      {totalCount > list.length ? (
        <MoreTab moreButtonText='더보기' moreButtonOnPress={onMore} useSideButton={false} />
      ) : null}
    </Animated.ScrollView>
  )
}
