import React from 'react'
import { Keyboard, Platform, TextInput, TouchableWithoutFeedback } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import { Controller, useForm } from 'react-hook-form'
import { Box, HStack, Input, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import FormData from 'form-data'
import Animated from 'react-native-reanimated'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { updateBoard } from '@/services/board'
import { uploadFile } from '@/services/upload'

import useBoard from '@/hooks/useBoard'
import useLocation from '@/hooks/useLocation'
import CreateBoardHeader from '@/screens/boards/components/CreateBoardHeader'
import ModalConfirmRound from '@/components/ModalConfirmRound'
import FastImage from '@/components/FastImage'
import CreateModal from '@/components/CreateModal'
import ModalAlert from '@/components/ModalAlert'
import RichTextEditor from '@/components/RichTextEditor'
import RichTextToolbar from '@/components/RichTextToolbar'
import { CATEGORY_LIST } from '@/constants/site'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { ICreateBoardForm } from '@/interfaces/board'
import { RootStackParamList } from '@/interfaces/navigation'

export default function SimpleUpdateBoardScreen(props: any) {
  const { board } = props.route.params
  const [auth] = useAtom(authAtom)
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const [category, setCategory] = React.useState(0)
  const [categoryTitle, setCategoryTitle] = React.useState('게시판을 선택하세요')
  const [title, setTitle] = React.useState('')
  const [contents, setContents] = React.useState('')
  const [isOpenAlert, setIsOpenAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
  const [isFocusedTitle, setIsFocusedTitle] = React.useState(false)

  const richTextRef = React.useRef<any>()
  const titleRef = React.useRef<TextInput>(null)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const { findBoardListByCategory } = useBoard()
  const { handleBoardListLocation } = useLocation()

  const handleOpenConfirm = (value: boolean) => {
    Keyboard.dismiss()
    setIsOpenConfirm(value)
  }

  const handleOpenAlert = (message: string) => {
    setAlertMessage(message)
    setIsOpenAlert(!isOpenAlert)
  }

  const selectCategory = (pCategory: number, pCategoryTitle: string) => {
    setCategory(pCategory)
    setCategoryTitle(pCategoryTitle)
    setIsOpenModal(false)
  }

  const closeSelectCategoryModal = () => {
    setIsOpenModal(false)
  }

  const { handleSubmit, control } = useForm<ICreateBoardForm>({
    defaultValues: {
      board: {
        id: board.id,
        writer: auth.user ? auth.user.id : board.writer,
        writing_type: board.writing_type,
        color_type: board.color_type,
        contents: board.contents,
        title: board.title,
        view_count: board.view_count,
        is_delete: board.is_delete,
        category: board.category,
      },
      jwt: auth.jwt ? auth.jwt : board.jwt,
    },
  })

  const onSubmit = async (form: ICreateBoardForm) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (category === 0) {
      handleOpenAlert('게시판을 선택해주세요.')
      return
    }

    if (!title.trim()) {
      handleOpenAlert('제목을 1글자 이상 입력해주세요.')
      return
    }

    if (!contents) {
      handleOpenAlert('내용을 1글자 이상 입력해주세요.')
      return
    }

    form.board.category = category
    await updateBoard(form).then((item) => {
      setCategory(0)
      setCategoryTitle('게시판을 선택해주세요')
      setTitle('')
      setContents('')
      if (item) {
        findBoardListByCategory(category)
        handleBoardListLocation(category)
      }
    })
  }

  const editorInitializedCallback = () => {
    // richTextRef.current.registerToolbar(function (items: string[]) {
    //   // console.log(items)
    // })
  }

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      // base64: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
    })

    if (result.cancelled) {
      return
    }

    const localUri = result.uri
    const imageWidth = result.width
    const imageHeight = result.height
    const filename = localUri.split('/').pop()
    // const base64 = result.base64

    if (filename && auth.jwt) {
      const match = /\.(\w+)$/.exec(filename)
      const type = match ? `image/${match[1]}` : 'image'
      const formData = new FormData()

      formData.append('files', {
        uri: Platform.OS === 'ios' ? localUri.replace('file://', '') : localUri,
        name: filename,
        type,
      })

      // const str = `data:${type};base64,${base64}`
      // return richTextRef.current?.insertImage(str, `width:${imageWidth}; height:${imageHeight}`)
      // richTextRef.current?.insertImage(
      //   'https://hanteo-strapi.s3.ap-northeast-1.amazonaws.com/6ada46d8_8eda_4888_a193_b27c80b5c13a_003eaa045f.jpg',
      // )
      return await uploadFile(auth.jwt, formData).then((res) => {
        const uri = res.data[0].url
        richTextRef.current?.insertHTML('<br />')
        richTextRef.current?.insertImage(uri, `width:${imageWidth}; height:${imageHeight}`)
      })
    }
  }

  React.useEffect(() => {
    if (board) {
      const obj = CATEGORY_LIST.find((item) => item.category === board.category)
      if (obj) {
        setCategory(obj.category)
        setCategoryTitle(obj.title)
        setTitle(board.title)
        setContents(board.contents)
      }
    }
  }, [board])

  React.useLayoutEffect(() => {
    titleRef.current?.focus()
  }, [])

  return (
    <Box flex={1} bg='#ffffff'>
      {isOpenConfirm && (
        <ModalConfirmRound
          isOpen={isOpenConfirm}
          onAccept={() => navigation.goBack()}
          onClose={() => setIsOpenConfirm(false)}
          contentsMessage={'작성중인 글쓰기를 \n종료하시겠습니까?'}
          acceptButtonText='예'
          cancelButtonText='아니오'
        />
      )}
      {isOpenAlert && (
        <ModalAlert
          isOpen={isOpenAlert}
          message={alertMessage}
          onClose={() => {
            handleOpenAlert('')
          }}
        />
      )}
      {isOpenModal && (
        <CreateModal
          modalType={MODAL_TYPE.SELECT_CATEGORY}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => closeSelectCategoryModal()}
          selectCategory={selectCategory}
        />
      )}
      <TouchableWithoutFeedback
        onPress={() => {
          Keyboard.dismiss()
          richTextRef.current?.blurContentEditor()
        }}
      >
        <Box bg='#ffffff' px='15px' pt='5px'>
          <CreateBoardHeader
            handleOpenConfirm={handleOpenConfirm}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isModify={title !== '' || contents !== ''}
            navigation={navigation}
          />
        </Box>
      </TouchableWithoutFeedback>
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        // scrollEventThrottle={16}
        // onScroll={scrollHandler}
      >
        <Box bg='#ffffff' px='15px'>
          <HStack
            onTouchStart={() => setIsOpenModal(true)}
            borderBottomWidth='1px'
            borderColor='#7C7C7C'
            pl='11px'
            pb='8px'
            mb='15px'
            alignItems='center'
          >
            <Text fontSize='20px' fontWeight='bold' pr='7px'>
              {categoryTitle}
            </Text>
            <FastImage
              source={require('@/assets/images/below.png')}
              style={{
                width: 24,
                height: 24,
                marginBottom: 3,
              }}
              resizeMode='contain'
            />
          </HStack>
          <HStack
            alignItems='center'
            justifyContent='center'
            borderBottomWidth='1px'
            borderBottomColor='#eaeaea'
            mb='10px'
          >
            <Controller
              control={control}
              name='board.title'
              rules={{ required: false }}
              render={({ field: { onChange } }) => (
                <Input
                  ref={titleRef}
                  placeholder='제목을 입력해주세요.'
                  placeholderTextColor='#A9A9A9'
                  borderWidth={0}
                  borderRadius={0}
                  pl='11px'
                  pb='8px'
                  fontSize='18px'
                  fontWeight={600}
                  flex={1}
                  variant='unstyled'
                  value={title}
                  maxLength={100}
                  onChangeText={(value) => {
                    onChange(value)
                    setTitle(value)
                  }}
                  onFocus={() => {
                    setIsFocusedTitle(true)
                  }}
                  onBlur={() => {
                    setIsFocusedTitle(false)
                  }}
                  autoCapitalize='none'
                  autoComplete='off'
                  autoCorrect={false}
                  blurOnSubmit={false}
                  onSubmitEditing={() => {
                    richTextRef.current.focusContentEditor()
                  }}
                />
              )}
            />
          </HStack>
        </Box>
        <RichTextEditor
          ref={richTextRef}
          initialContentHTML={contents}
          control={control}
          editorInitializedCallback={editorInitializedCallback}
          setContents={setContents}
        />
      </Animated.ScrollView>
      <RichTextToolbar ref={richTextRef} pickImage={pickImage} isFocusedTitle={isFocusedTitle} />
    </Box>
  )
}
