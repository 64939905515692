import { ScrollView } from 'react-native'
import { Box, HStack, Input, Text, VStack } from 'native-base'
import Footer from '@/components/Footer'
import React from 'react'
import CButton from '@/components/CButton'
import { Controller, useForm } from 'react-hook-form'
import { IWithdrawal } from '@/interfaces/auth'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { authAtom, handleLogout } from '@/atom/authAtom'
import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'
import ModalConfirmRound from '@/components/ModalConfirmRound'
import ModalAlert from '@/components/ModalAlert'
import RadioGroup from 'react-native-radio-buttons-group'
import {
  createWithdrawalHistory,
  findAllWithdrawal,
  loginAdminAuth,
  removeUser,
} from '@/services/auth'
import ModalAlertTitle from '@/components/ModalAlertTitle'

export default function MembershipWithdrawalScreen() {
  const [isLoading, setLoading] = React.useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [isOpenAlert, setIsOpenAlert] = React.useState(false)
  const [isOpenCompleteAlert, setIsOpenCompleteAlert] = React.useState(false)
  const [withdrawalList, setWithdrawalList] = React.useState<IWithdrawal[]>([])
  const [reasonButtons, setReasonButtons] = React.useState<unknown[]>([])
  const [auth] = useAtom(authAtom)
  const [, logout] = useAtom(handleLogout)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const handleOpenCompleteAlert = (value: boolean) => {
    setIsOpenCompleteAlert(value)
  }

  const handleAlertMessage = (message: string) => {
    setAlertMessage(message)
  }

  const handleOpenAlert = (value: boolean) => {
    setIsOpenAlert(value)
  }

  const handleOpenConfirm = (value: boolean) => {
    setIsOpenConfirm(value)
  }

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<{ identifier: string; reason: string }>({
    defaultValues: {
      identifier: '',
      reason: '',
    },
  })

  const onSubmit = async (data: { identifier: string; reason: string }) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (!data.reason) {
      handleAlertMessage('탈퇴 이유를 선택해주세요.')
      handleOpenConfirm(false)
      handleOpenAlert(true)
      return
    }

    setLoading(true)

    const { jwt } = await loginAdminAuth()

    if (auth.user) {
      await removeUser({ jwt, id: auth.user.id }).then(async (result) => {
        if (result) {
          handleOpenConfirm(false)
          handleOpenCompleteAlert(true)

          const res = await createWithdrawalHistory({
            jwt,
            username: auth.user?.email ? auth.user?.email : '',
            withdrawal_type: parseInt(data.reason),
          })

          if (res.status == 200) {
            await logout()
          }
        }
      })
    }

    await setLoading(false)
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const { data } = await findAllWithdrawal()
      if (data) {
        setWithdrawalList(data)
      }
    }
    fetchData()
  }, [])

  const reasonButtonsMemo = React.useMemo(
    () =>
      withdrawalList.map((item) => ({
        id: item.id.toString(),
        label: item.contents,
        value: item.contents,
        size: 18,
      })),
    [withdrawalList],
  )

  React.useEffect(() => {
    setReasonButtons(reasonButtonsMemo)
  }, [reasonButtonsMemo])

  return (
    <ScrollView
      contentContainerStyle={{ backgroundColor: '#ffffff' }}
      showsVerticalScrollIndicator={false}
    >
      {isOpenCompleteAlert && (
        <ModalAlertTitle
          isOpen={isOpenCompleteAlert}
          titleMessage={'정상적으로 탈퇴되었습니다.'}
          contentsMessage={'그동안 한터를 이용해 주셔서 감사합니다.'}
          cancelButtonText={'닫기'}
          onClose={() => {
            handleAlertMessage('')
            handleOpenCompleteAlert(false)
            navigation.navigate(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.MAIN })
          }}
        />
      )}
      {isOpenAlert && (
        <ModalAlert
          isOpen={isOpenAlert}
          message={alertMessage}
          onClose={() => {
            handleAlertMessage('')
            handleOpenAlert(false)
          }}
        />
      )}
      {isOpenConfirm && (
        <ModalConfirmRound
          isOpen={isOpenConfirm}
          onAccept={handleSubmit(onSubmit)}
          onClose={() => handleOpenConfirm(false)}
          contentsMessage={'탈퇴하시겠습니까?'}
          acceptButtonText='예'
          cancelButtonText='아니오'
        />
      )}
      <Box>
        <VStack px='28px' py='16px'>
          <Text fontSize='17px' fontWeight='bold'>
            한터를 탈퇴하시는 이유는 무엇인가요?
          </Text>
          <Text fontWeight='bold' fontSize='15px' color='#898A8D' pt='5px'>
            회원님께서 한터를 탈퇴하는 이유를 알려주시면 보다 좋은 서비스 제공을 위해
            노력하겠습니다.
          </Text>
          <VStack bg='#f3f3f3' py='16px' px='16px' mt='16px'>
            <Controller
              control={control}
              name='reason'
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  radioButtons={reasonButtonsMemo}
                  onPress={(value) => {
                    onChange(value)
                  }}
                  selectedId={value}
                  containerStyle={{ alignItems: 'flex-start' }}
                />
              )}
            />
          </VStack>
        </VStack>
      </Box>
      <Box>
        <HStack justifyContent='center'>
          <CButton
            w={85}
            h={33}
            bgColor='#ffffff'
            alignItems='center'
            justifyContent='center'
            borderRadius='20px'
            borderWidth='1px'
            borderColor='#cdcdcd'
            mr='11px'
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack()
              }
            }}
          >
            <Text mt='2px' color='#8f8f8f' fontSize='16px' fontWeight='bold'>
              탈퇴취소
            </Text>
          </CButton>
          <CButton
            w={85}
            h={33}
            bgColor='#ff6f1e'
            alignItems='center'
            justifyContent='center'
            borderRadius='20px'
            borderWidth='1px'
            borderColor='#ff6f1e'
            onPress={() => handleOpenConfirm(true)}
          >
            <Text mt='2px' color='#ffffff' fontSize='16px' fontWeight='bold'>
              탈퇴확인
            </Text>
          </CButton>
        </HStack>
      </Box>
      <Box bg='#f4f4f4' mt='50px'>
        <Footer />
      </Box>
    </ScrollView>
  )
}
