import { Box, Center, HStack, VStack, Text } from 'native-base'
import MainNewsImage from './MainNewsImage'
import MainNewsText from './MainNewsText'
import { IYonhapNews } from '@/interfaces/news'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { TOP_TAB } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { MaterialTopTabNavigationProp } from '@react-navigation/material-top-tabs'
import { TopTabParamList } from '@/interfaces/navigation'

interface Props {
  YonhapNews: IYonhapNews[]
}

export default function MainYonhapNewsItem(props: Props) {
  const { YonhapNews } = props
  const navigation = useNavigation<MaterialTopTabNavigationProp<TopTabParamList, TOP_TAB.NEWS>>()

  return (
    <VStack bg='#ffffff'>
      <HStack alignItems='center' justifyContent='space-between' py='8px' px='16px'>
        <Text fontSize='18px' fontWeight='bold'>
          한터 추천뉴스
        </Text>
        <CButton onPress={() => navigation.navigate(TOP_TAB.NEWS, { isScrollToKyodoNews: true })}>
          <HStack>
            <Text fontSize='14px' fontWeight='700' color='#8C8C8C' textAlign='center'>
              더보기
            </Text>
            <Center pt='4px'>
              <FastImage
                source={require('@/assets/images/gray-left-arrow-small.png')}
                style={{
                  width: 13,
                  height: 13,
                  marginLeft: 5,
                  marginBottom: 2,
                  alignSelf: 'center',
                }}
                resizeMode='contain'
              />
            </Center>
          </HStack>
        </CButton>
      </HStack>
      <HStack space='11px' px='17px' pb='11px'>
        {YonhapNews.slice(0, 2).map((item) => {
          return <MainNewsImage item={item} key={item.id} />
        })}
      </HStack>
      <Box bg='#f2f2f2' h='11px' />
      <VStack>
        {YonhapNews.slice(2, 7).map((item, index) => {
          return <MainNewsText item={item} index={index} key={item.id} />
        })}
      </VStack>
      <Box bg='#f2f2f2' h='11px' />
      <HStack space='11px' px='17px' py='11px'>
        {YonhapNews.slice(7, 9).map((item) => {
          return <MainNewsImage item={item} key={item.id} />
        })}
      </HStack>
      <Box bg='#f2f2f2' h='11px' />
      <VStack>
        {YonhapNews.slice(9, 14).map((item, index) => {
          return <MainNewsText item={item} index={index} key={item.id} />
        })}
      </VStack>
      <Box bg='#f2f2f2' h='11px' />
      <HStack space='11px' px='17px' py='11px'>
        {YonhapNews.slice(14, 16).map((item) => {
          return <MainNewsImage item={item} key={item.id} />
        })}
      </HStack>
      <HStack space='11px' px='17px' pb='11px'>
        {YonhapNews.slice(16, 18).map((item) => {
          return <MainNewsImage item={item} key={item.id} />
        })}
      </HStack>
      <Box bg='#f2f2f2' h='11px' />
    </VStack>
  )
}
