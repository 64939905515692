import React from 'react'
import { Box, HStack, Modal, Text } from 'native-base'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { screenWidthAtom } from '@/atom/navigationAtom'
import CButton from '../CButton'
import { ISendMemberPushForm } from '@/interfaces/push'
import { getMemberAgree } from '@/services/pushAgreeService'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'

interface Props {
  userId: number
  isBlock: boolean
  isMember: boolean
  handleCloseModal: () => void
  openModal: (modalType: MODAL_TYPE) => void
  memberAgree: () => void
  memberDisagree: () => void
}

export default function UserPageMenuModal(props: Props) {
  const { userId, isBlock, isMember, handleCloseModal, openModal, memberAgree, memberDisagree } =
    props
  const [isMenuOpen, setIsMenuOpen] = React.useState(true)
  const [screenWidth] = useAtom(screenWidthAtom)
  const [memberSub, setMemberSub] = React.useState<ISendMemberPushForm[] | null>(null)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)

  const closeUserMenu = () => {
    handleCloseModal()
  }

  const setUserBlock = () => {
    handleCloseModal()
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    openModal(MODAL_TYPE.BLOCK_USER)
  }

  const setUserUnBlock = () => {
    handleCloseModal()
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    openModal(MODAL_TYPE.UNBLOCK_USER)
  }

  const styleObj = {
    borderRadius: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 14,
  }

  React.useEffect(() => {
    const fetch = async () => {
      if (auth) {
        if (userId) {
          await Promise.all([
            await getMemberAgree(auth.jwt, auth.user ? auth.user.id : 0, userId),
          ]).then((values) => {
            setMemberSub(values[0])
          })
        }
      }
    }
    void fetch()
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={isMenuOpen} onClose={() => closeUserMenu()}>
        <Modal.Content w={screenWidth - 80} borderRadius='5px'>
          <Modal.Body borderBottomWidth={0} pb={2}>
            <Box>
              {/* auth.user.id == userId ? null : memberSub */}
              {isMember ? (
                <CButton bgColor='#ECECEC' style={styleObj} onPress={memberDisagree}>
                  <HStack alignSelf='center'>
                    <Box mt='2px'>
                      <DynamicSvgIcon
                        width='16px'
                        height='16px'
                        src={require('@/assets/svg/bell-off.svg')}
                      />
                    </Box>
                    <Box ml='8px'>
                      <Text
                        textAlign='center'
                        fontSize='16px'
                        fontWeight={700}
                        numberOfLines={1}
                        ellipsizeMode='tail'
                      >
                        구독 취소
                      </Text>
                    </Box>
                  </HStack>
                </CButton>
              ) : (
                <CButton bgColor='#FF6F1E33' style={styleObj} onPress={memberAgree}>
                  <HStack alignSelf='center'>
                    <Box mt='2px'>
                      <DynamicSvgIcon
                        width='16px'
                        height='16px'
                        src={require('@/assets/svg/bell-on.svg')}
                      />
                    </Box>
                    <Box ml='8px'>
                      <Text
                        textAlign='center'
                        fontSize='16px'
                        fontWeight={700}
                        numberOfLines={1}
                        ellipsizeMode='tail'
                        color='#E85300'
                      >
                        회원 구독
                      </Text>
                    </Box>
                  </HStack>
                </CButton>
              )}
              {!isBlock ? (
                <CButton bgColor='#ECECEC' style={styleObj} onPress={setUserBlock}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    차단하기
                  </Text>
                </CButton>
              ) : (
                <CButton bgColor='#ECECEC' style={styleObj} onPress={setUserUnBlock}>
                  <Text
                    textAlign='center'
                    fontSize='16px'
                    fontWeight={700}
                    numberOfLines={1}
                    ellipsizeMode='tail'
                  >
                    차단 취소하기
                  </Text>
                </CButton>
              )}
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
