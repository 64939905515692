import { IBanner, IBannerGroup } from '@/interfaces/banner'
import { ICuration, IYonhapNews } from '@/interfaces/news'

export default function useDivision() {
  const handleDivision = (arr: any[], n: number) => {
    const len = arr.length
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
    const temp = []
    for (let i = 0; i < cnt; i++) {
      temp.push(arr.splice(0, n))
    }
    return temp
  }

  const handleNewsDivision = (yonhapNews: IYonhapNews[], n: number) => {
    const len = yonhapNews.length
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
    const temp = []
    for (let i = 0; i < cnt; i++) {
      temp.push(yonhapNews.splice(0, n))
    }
    return temp
  }

  const handleBannerGroupDivision = (data: any) => {
    const BANNER_LIMIT = 16
    const job: IBannerGroup = {
      title: '구인구직',
      contents: [],
    }
    const property: IBannerGroup = {
      title: '부동산',
      contents: [],
    }
    const shopping: IBannerGroup = {
      title: '쇼핑',
      contents: [],
    }
    const service: IBannerGroup = {
      title: '서비스',
      contents: [],
    }
    const educationAndTrip: IBannerGroup = {
      title: '교육 | 여행',
      contents: [],
    }
    const medicalAndLaw: IBannerGroup = {
      title: '의료 | 법률',
      contents: [],
    }

    try {
      data.map((item: IBanner) => {
        if (item.banner_category) {
          switch (item.banner_category.id) {
            case 1:
              if (job.contents.length < BANNER_LIMIT) {
                job.contents.push(item)
              }
              break
            case 2:
              if (property.contents.length < BANNER_LIMIT) {
                property.contents.push(item)
              }
              break
            case 3:
              if (shopping.contents.length < BANNER_LIMIT) {
                shopping.contents.push(item)
              }
              break
            case 4:
              if (service.contents.length < BANNER_LIMIT) {
                service.contents.push(item)
              }
              break
            case 5:
              if (medicalAndLaw.contents.length < BANNER_LIMIT) {
                medicalAndLaw.contents.push(item)
              }
              break
            case 6:
              if (educationAndTrip.contents.length < BANNER_LIMIT) {
                educationAndTrip.contents.push(item)
              }
              break
            case 7:
              if (medicalAndLaw.contents.length < BANNER_LIMIT) {
                medicalAndLaw.contents.push(item)
              }
              break
            case 8:
              if (educationAndTrip.contents.length < BANNER_LIMIT) {
                educationAndTrip.contents.push(item)
              }
              break
            default:
              break
          }
        }
      })
    } finally {
      const jobLength = (BANNER_LIMIT - job.contents.length) % 4
      const propertyLength = (BANNER_LIMIT - property.contents.length) % 4
      const shoppingLength = (BANNER_LIMIT - shopping.contents.length) % 4
      const serviceLength = (BANNER_LIMIT - service.contents.length) % 4
      const educationAndTripLength = (BANNER_LIMIT - educationAndTrip.contents.length) % 4
      const medicalAndLawLength = (BANNER_LIMIT - medicalAndLaw.contents.length) % 4
      for (let i = 0; i < jobLength; i++) {
        job.contents.push({
          id: Math.floor(Math.random() * 100000),
          company_name: '기본',
          contents: '',
        })
      }
      for (let i = 0; i < propertyLength; i++) {
        property.contents.push({
          id: Math.floor(Math.random() * 100000),
          company_name: '기본',
          contents: '',
        })
      }
      for (let i = 0; i < shoppingLength; i++) {
        shopping.contents.push({
          id: Math.floor(Math.random() * 100000),
          company_name: '기본',
          contents: '',
        })
      }
      for (let i = 0; i < serviceLength; i++) {
        service.contents.push({
          id: Math.floor(Math.random() * 100000),
          company_name: '기본',
          contents: '',
        })
      }
      for (let i = 0; i < educationAndTripLength; i++) {
        educationAndTrip.contents.push({
          id: Math.floor(Math.random() * 100000),
          company_name: '기본',
          contents: '',
        })
      }
      for (let i = 0; i < medicalAndLawLength; i++) {
        medicalAndLaw.contents.push({
          id: Math.floor(Math.random() * 100000),
          company_name: '기본',
          contents: '',
        })
      }
    }
    return [job, property, shopping, service, educationAndTrip, medicalAndLaw]
  }

  return {
    handleDivision,
    handleNewsDivision,
    handleBannerGroupDivision,
  }
}
