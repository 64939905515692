import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import { SOCIAL } from '@/constants/label'

interface Props {
  provider: SOCIAL
  buttonText: string
  onPress?: () => void
  disabled?: boolean
}

export default function SocialButton(props: Props) {
  const { provider, buttonText, onPress, disabled } = props

  return (
    <CButton onPress={onPress} disabled={disabled}>
      <FastImage
        source={
          provider === SOCIAL.FACEBOOK
            ? require('@/assets/images/facebook_round.png')
            : provider === SOCIAL.GOOGLE
            ? require('@/assets/images/google_round.png')
            : provider === SOCIAL.KAKAO
            ? require('@/assets/images/kakao_round.png')
            : provider === SOCIAL.NAVER
            ? require('@/assets/images/naver_round.png')
            : require('@/assets/images/apple_round.png')
        }
        style={{
          width: 50,
          height: 50,
        }}
        resizeMode='contain'
      />
    </CButton>
  )
}
