import { ScrollView } from 'react-native'
import { Box } from 'native-base'
import Footer from '@/components/Footer'
import HTMLView from 'react-native-htmlview'

export default function PolicyPrivacyScreen() {
  const contents = `
<pre style="line-height: 20px">
한터(HANTEO2050.COM)에서는 모든 회원 여러분께서 안심하고 한터(HANTEO2050.COM)를 이용할 수 있도록, 상업 및 정치적 목적을 위한 개인정보 악용과 개인 사생활의 침해를 막기 위해 최선의 노력을 다하고 있습니다. 또한 무엇보다도 소중한 회원 여러분의 개인정보 지킴이로서의 역할을 성실히 수행하고 있습니다.

한터(HANTEO2050.COM)의 개인정보취급방침은 정부의 관련 법률 및 지침의 변경이나 한터(HANTEO2050.COM)의 내부방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보취급방침의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 아울러 개인정보취급방침을 개정하는 경우 그 변경사항에 대하여 즉시 홈페이지를 통하여 게시하여 개정된 사항을 이용자들이 쉽게 알아볼 수 있도록 하고 있습니다. 회원분께서는 사이트 방문시 수시로 확인하여 주시기 바랍니다.

한터(HANTEO2050.COM)는 회원 여러분의 개인정보를 보호하기 위해 최선을 다하고 있습니다.
한터(HANTEO2050.COM)는 회원님의 허락 없이 개인정보를 제3자에게 제공하지 않습니다.

보다 자세한 사항은 다음과 같습니다.

1. 개인정보 수집에 대한 동의
2. 개인정보의 수집항목
3. 개인정보의 수집 및 이용목적
4. 개인정보 공유 및 제공
5. 개인정보의 보유 및 이용기간
6. 개인정보의 파기절차 및 방법
7. 수집한 개인정보의 위탁, 목적 외 사용 및 제3자에 대한 정보 공유
8. 이용자 및 법정대리인의 권리와 그 행사방법
9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
10. 개인정보의 기술적/관리적 보호 대책
11. 이용자 관리에 대한 조치 및 준수사항
12. 광고성 정보 전송
13. 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처
14. 고지의 의무



<h4>1. 개인정보 수집에 대한 동의</h4>

한터(HANTEO2050.COM)는 회원가입 절차 중 이용약관에 대해「동의」란에 체크하시면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다.


<h4>2. 개인정보의 수집항목</h4>
가. 수집하는 개인정보의 항목

회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다.
희망ID, 비밀번호, 성별, 국적, 우편번호, 생년월일, 이메일

또한 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.

- 이용자의 브라우저 종류 및 OS, 검색어, 서비스 이용 기록 
- IP Address, 방문 일시, 서비스 이용기록, 불량 이용 기록 
- 주소, 결제기록, 쿠키 

나. 개인정보 수집방법

- 홈페이지 (회원가입)
- 전화/팩스를 통한 회원가입
- 제휴사로부터의 제공


<h4>3. 개인정보의 수집 및 이용목적</h4>

한터(HANTEO2050.COM) 가입시 기입한 개인정보는 가로수닷컴 서비스 제공시 기초 자료가 되며, 회원 개개인에게 알맞는 유용한 서비스 제공을 위해 사용하게 됩니다. 

가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
컨텐츠 제공, 본인인증, 구매 및 요금 결제

나. 회원관리
회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달 

다. 신규 서비스 개발 및 마케팅
신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계


<h4>4. 개인정보 공유 및 제공</h4>

한터(HANTEO2050.COM)는 회원님으로부터 수집한 개인정보를 회원님의 사전 동의 없이는 절대로 제3자와 함께 공유하거나 공개하지 않습니다. 
다만, 한터(HANTEO2050.COM) 서비스 내용 중 회원님께서 희망하는 서비스가 제휴, 협력 혹은 기타 계약(이하 협력)에 의해, 추가 가입이 필요한 경우에는 이를 미리 회원 여러분께 알려드리며, 이에 대한 회원님의 동의가 있거나 법에 의해 요구되는 경우에 한해 예외로 합니다. 
이 경우, 회원님의 개인정보 중 일부분, 혹은 전체가 제3자에게 제공됨을 미리 알려드리며, 추가 가입 약관, 혹은 기타 형태의 내용 화면에서 '동의' 혹은 '추가가입' 등의 버튼을 눌러 회원님의 동의가 확인될 경우에만 회원님의 정보가 보다 편리한 서비스 활용을 위하여 협력사에 제공되며, 아래의 경우에는 예외로 합니다.

- 회원님께서 사전에 공개에 동의한 경우
- 서비스 제공에 따른 요금정산을 위하여 필요한 경우
- 한터(HANTEO2050.COM) 서비스 이용약관이나 기타 회원 서비스 등 이용약관 또는 정책을 위반한 경우
- 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우
- 서비스 이용에 따른 문의사항(민원)의 처리를 위하여 해당 문의사항의 처리에 필요한 개인정보를 제공하는 경우
- 이벤트 당첨으로 인한 경품 배송의 경우

어떠한 경우에도 회원님의 동의가 없는 경우에는 추가적인 정보를 임의로 수집하거나 공유하지 않습니다.

다만, 회원님들이 직접 등록하신 매물정보가 제휴사에 제공되는 경우가 있습니다. 이는 회원님들의 매물광고효과를 높이기 위해 매물정보를 제공할 뿐이며, 제휴사에게는 매물홍보목적외에는 사용되지 않습니다.


<h4>5. 개인정보의 보유 및 이용기간</h4>

고객님의 개인정보는 고객님께 서비스를 제공하는 기간동안에 한하여 보유 및 이용됩니다.
다만, 불량 회원의 부정한 이용의 재발을 방지하고, 소비자의 불만 및 분쟁해결 등을 목적으로 이용계약 해지일로부터 1개월간 해당 회원의 ID만을 보유할 수 있습니다. 또한, 관계법령의 규정에 의하여 보존할 필요성이 있는 경우에는 관계법령에 따라 보존합니다. 

단, 다음과 같은 경우 일정기간 회원정보를 보관하며 보관된 개인정보는 보관 목적으로만 이용합니다.
- 개별적으로 회원의 동의 또는 요청에 의한 경우 : 1년 (전자상거래 등에서의 소비자보호에 관한 법률) 
- 보유기간을 회원에게 미리 고지하거나 명시한 경우(단, 지정한 보유기간동안에만 보관)
- 이용약관 및 관계법령을 위반한 불법 사용자에 대한 사법기관 수사협조 등을 위해 회원탈퇴 후 개인정보 : 1년
- 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률) 
- 대금결제 및 재화등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률) 
- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률) 
</pre>
`

  return (
    <ScrollView
      contentContainerStyle={{ backgroundColor: '#f4f4f4' }}
      showsVerticalScrollIndicator={false}
    >
      <Box bgColor='#ffffff' pb='50px' px='15px'>
        <HTMLView value={contents} />
      </Box>
      <Footer />
    </ScrollView>
  )
}
