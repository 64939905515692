import React from 'react'
import { Center, HStack, Text } from 'native-base'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import CButton from './CButton'

interface Props {
  title: string
  lang: string
  svg: string
  setlang: string
  handleChangeLang: (lang: string, svg: string) => void
}

export default function LanguageModalItem(props: Props) {
  const { title, lang, svg, handleChangeLang } = props

  const styleObj = {
    paddingTop: 4,
    paddingBottom: 8,
    marginBottom: 14,
    borderBottomWidth: '0.5px',
    borderColor: '#A0A0A0',
    borderStyle: 'dashed',
  }

  return (
    <CButton
      style={styleObj}
      onPress={() => {
        handleChangeLang(lang, svg)
      }}
    >
      <Center>
        <HStack alignItems='center'>
          <DynamicSvgIcon width='30' height='30' src={svg} />
          <Text ml='8px' textAlign='center' fontSize='14px' fontWeight={700}>
            {title}
          </Text>
        </HStack>
      </Center>
    </CButton>
  )
}
