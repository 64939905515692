import React from 'react'
import { Box, HStack, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'

import { DualButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'
import { AuthStackParamList, RootStackParamList } from '@/interfaces/navigation'
import FastImage from '@/components/FastImage'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_FORGOT_PW_COMPLETE>

export default function ForgotPwCompleteScreen({ navigation, route }: Props) {
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <React.Fragment>
      <AuthStackHeader title='비밀번호 찾기' navigation={navigation} />
      <Box flex={1} mt='40px' alignItems='center'>
        <Box
          alignItems='center'
          borderBottomWidth='1px'
          borderBottomColor='#ff6f12'
          mb='34px'
          pb='37px'
        >
          <Box mb='6px'>
            <FastImage
              source={require('@/assets/images/check.png')}
              style={{
                width: 70,
                height: 70,
              }}
              resizeMode='contain'
            />
          </Box>
          <HStack justifyContent='center' mb='34px' space='5px'>
            <Text fontSize='22px' color='#000000'>
              메일이
            </Text>
            <Text fontSize='22px' fontWeight='bold' color='#000000'>
              전송
            </Text>
            <Text fontSize='22px' color='#000000'>
              되었습니다.
            </Text>
          </HStack>
          <VStack pb='8px' alignItems='center'>
            <Text fontSize='18px'>
              입력하신 ‘
              <Text fontSize='18px' color='#ff6f1e'>
                {route.params.email}
              </Text>
              ’ 로
            </Text>
            <Text fontSize='18px'>비밀번호 재설정 메일이 전송되었습니다.</Text>
            <Text fontSize='18px'>이메일을 확인해주세요.</Text>
          </VStack>
        </Box>
        <Box mt='8px'>
          <DualButton
            firstButtonText='홈으로'
            firstButtonOnPress={() =>
              rootNavigation.replace(ROOT_STACK.TOP_TAB, {
                screen: TOP_TAB.MAIN,
              })
            }
            secondButtonText='로그인'
            secondButtonFill
            secondButtonOnPress={() => navigation.replace(AUTH_STACK.AUTH_SIGN_IN)}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}
