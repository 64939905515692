import React from 'react'
import { Center, HStack, Text } from 'native-base'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { handleAllRemove, searchHistoryAtom } from '@/atom/searchAtom'
import { SearchHeader, SearchHistoryItem } from '@/screens/search/components'
import CButton from '@/components/CButton'

export default function SearchHistoryScreen() {
  const [searchHistory] = useAtom(searchHistoryAtom)
  const [, allRemove] = useAtom(handleAllRemove)

  const getSearchHistoryData = () => {
    if (searchHistory) {
      return searchHistory.sort((a, b) => b.id - a.id)
    } else {
      void localStorage.removeItem('search')
      return []
    }
  }

  return (
    <React.Fragment>
      <SearchHeader />
      <Animated.ScrollView
        contentContainerStyle={{ flexGrow: 1, paddingBottom: 100 }}
        showsVerticalScrollIndicator={false}
        // scrollEventThrottle={16}
        // onScroll={scrollHandler}
      >
        <HStack
          pt='20px'
          pb='20px'
          px='5px'
          mx='10px'
          borderBottomWidth={0.5}
          borderBottomColor='#7C7C7C'
          justifyContent='space-between'
        >
          <Text fontSize='18px' fontWeight='bold'>
            최근 검색어
          </Text>
          <CButton onPress={allRemove}>
            <Center ml='auto' w='75px' h='24px' bgColor='#F4F4F4' borderRadius='13px'>
              <Text fontSize='14px' color='#8F8F8F'>
                전체삭제
              </Text>
            </Center>
          </CButton>
        </HStack>
        {getSearchHistoryData().map((item) => {
          return <SearchHistoryItem item={item} key={item.id} />
        })}
      </Animated.ScrollView>
    </React.Fragment>
  )
}
