import React from 'react'
import { Box, Button, Center, HStack, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import useFormatter from '@/hooks/useFormatter'
import BackButton from '@/components/BackButton'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { anotherUserAtom } from '@/atom/userPageAtom'
import CButton from './CButton'
import { ISendMemberPushForm } from '@/interfaces/push'
import {
  getBlockUser,
  getMemberAgree,
  sendMemberAgree,
  sendMemberDisagree,
} from '@/services/pushAgreeService'
import LanguageHeaderItem from './LanguageHeaderItem'
import FastImage from './FastImage'
import UserPageMenuModal from './modal/UserPageMenuModal'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import CreateModal from './CreateModal'
import { createBlockUser, removeBlockUser } from '@/services/blockUser'

//모바일하고 조금 다름
export default function UserPageHeader() {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { numberCommaFormatter } = useFormatter()
  const [anotherUser] = useAtom(anotherUserAtom)
  const [auth] = useAtom(authAtom)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const [modalType, setModalType] = React.useState(MODAL_TYPE.NONE)
  //나의 아이디인지 아닌지
  const [isMine, setMine] = React.useState(auth?.user?.id == anotherUser?.id ? true : false)
  //구독
  const [isMember, setMember] = React.useState(false)
  const [memberSub, setMemberSub] = React.useState<ISendMemberPushForm[] | null>(null)
  //차단
  const [isBlock, setBlock] = React.useState(false)
  const [blockSub, setBlockSub] = React.useState([])

  const openModal = (modalType: MODAL_TYPE) => {
    setIsOpenModal(true)

    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
    setModalType(modalType)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setIsOpenModal(false)
    setModalType(MODAL_TYPE.NONE)
  }

  const closeMenuModal = () => {
    setIsOpenModal(false)
  }

  const doBlockUser = async () => {
    if (anotherUser && !isBlock) {
      closeModal()
      await createBlockUser({
        jwt: auth.jwt,
        userId: auth.user ? auth.user.id : 0,
        blockUserId: anotherUser.id,
      })
      if (navigation.canGoBack()) {
        navigation.goBack()
      }
    }
  }

  const doUnblockUser = async () => {
    if (anotherUser && isBlock) {
      closeModal()
      await removeBlockUser({
        jwt: auth.jwt,
        userId: auth.user ? auth.user.id : 0,
        blockUserId: anotherUser.id,
      })
      if (navigation.canGoBack()) {
        navigation.goBack()
      }
    }
  }

  const memberAgree = async () => {
    if (auth.logged && anotherUser) {
      const ISendMemberPushForm: ISendMemberPushForm = {
        jwt: auth.jwt,
        user_id: auth.user ? auth.user.id : 0,
        member_id: anotherUser.id,
      }
      const result = await sendMemberAgree(ISendMemberPushForm)
      setMemberSub([result])
      setMember(true)
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  const memberDisagree = async () => {
    if (auth.logged && memberSub) {
      if (memberSub[0].id) {
        const result = await sendMemberDisagree(auth.jwt, memberSub[0].id)
        if (result.id == memberSub[0].id) {
          setMemberSub([])
          setMember(false)
        }
      }
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  const sendMessage = () => {
    if (anotherUser) {
      if (!auth.logged) {
        navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
        return
      }
      const anotherUserId = anotherUser.id
      const anotherUserNickName = anotherUser.nick_name
      if (anotherUserNickName) {
        navigation.push(ROOT_STACK.SEND_MESSAGE, {
          userId: anotherUserId,
          userNickName: anotherUserNickName,
        })
      }
    }
  }

  React.useEffect(() => {
    const fetch = async () => {
      if (auth) {
        if (anotherUser) {
          await Promise.all([
            await getMemberAgree(auth.jwt, auth.user ? auth.user.id : 0, anotherUser.id),
            await getBlockUser(auth.jwt, auth.user ? auth.user.id : 0, anotherUser.id),
          ]).then((values) => {
            setMemberSub(values[0]),
              setBlockSub(values[1]),
              setMember(values[0].length > 0 ? true : false)
            setBlock(values[1].length > 0 ? true : false)
          })
        }
      }
    }
    void fetch()
  }, [])

  return (
    <React.Fragment>
      {isOpen && modalType === MODAL_TYPE.BLOCK_USER ? (
        <CreateModal
          modalType={MODAL_TYPE.BLOCK_USER}
          handleCloseModal={closeModal}
          onAccept={doBlockUser}
        />
      ) : null}
      {isOpen && modalType === MODAL_TYPE.UNBLOCK_USER ? (
        <CreateModal
          modalType={MODAL_TYPE.UNBLOCK_USER}
          handleCloseModal={closeModal}
          onAccept={doUnblockUser}
        />
      ) : null}
      {isOpenModal && anotherUser && memberSub && (
        <UserPageMenuModal
          userId={anotherUser.id}
          isBlock={blockSub.length > 0 ? true : false}
          isMember={memberSub?.length > 0 ? true : false}
          handleCloseModal={closeMenuModal}
          openModal={openModal}
          memberAgree={memberAgree}
          memberDisagree={memberDisagree}
        />
      )}
      <VStack
        w='100%'
        py='20px'
        px='15px'
        borderBottomColor='#F4F4F4'
        borderBottomWidth='8px'
        bg='#ffffff'
      >
        <HStack justifyContent='space-between' alignItems='center'>
          <HStack flex={1}>
            <Box>
              <BackButton navigation={navigation} color='BLACK' />
            </Box>
            <Text
              ellipsizeMode='tail'
              numberOfLines={1}
              px='16px'
              fontSize='20px'
              fontWeight='bold'
            >
              {anotherUser?.nick_name}
            </Text>
          </HStack>
          <HStack>
            <LanguageHeaderItem />
            <Button bgColor='white' onPress={() => setIsOpenModal(true)}>
              <FastImage
                source={require('@/assets/images/menu-dots.png')}
                style={{ width: 24, height: 18, marginTop: 'auto', marginBottom: 'auto' }}
                resizeMode='contain'
              />
            </Button>
          </HStack>
        </HStack>
        <HStack alignItems='center' mt='10px'>
          {!isMine && (
            <HStack>
              {!isMember ? (
                <CButton ml='36px' onPress={memberAgree}>
                  <HStack
                    px='16px'
                    h='28px'
                    alignItems='center'
                    bgColor='#FF6F1E33'
                    borderRadius='23px'
                  >
                    <DynamicSvgIcon
                      width='13px'
                      height='13px'
                      src={require('@/assets/svg/bell-on.svg')}
                    />
                    <Text ml='4px' mt='2px' fontWeight='semibold' fontSize='14' color='#E85300'>
                      회원 구독
                    </Text>
                  </HStack>
                </CButton>
              ) : (
                <CButton ml='36px' onPress={memberDisagree}>
                  <HStack
                    px='16px'
                    h='28px'
                    alignItems='center'
                    bgColor='#F1F1F1'
                    borderRadius='23px'
                  >
                    <DynamicSvgIcon
                      width='13px'
                      height='13px'
                      src={require('@/assets/svg/bell-off.svg')}
                    />
                    <Text ml='4px' mt='2px' fontWeight='semibold' fontSize='14' color='#000000'>
                      구독 취소
                    </Text>
                  </HStack>
                </CButton>
              )}
              <CButton ml='16px' onPress={sendMessage}>
                <Center
                  px='16px'
                  h='28px'
                  borderColor='#8F8F8F'
                  borderWidth='1'
                  borderRadius='23px'
                >
                  <Text mt='2px' fontWeight='semibold' fontSize='14'>
                    쪽지보내기
                  </Text>
                </Center>
              </CButton>
            </HStack>
          )}
          <Text fontSize='13px' color='#7C7C7C' ml='12px'>
            방문
          </Text>
          <Text fontSize='13px' color='#7C7C7C' ml='4px'>
            {numberCommaFormatter(anotherUser?.visitor_count ? anotherUser.visitor_count : 0)}
          </Text>
        </HStack>
      </VStack>
    </React.Fragment>
  )
}
