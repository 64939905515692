import React from 'react'
import { Box, HStack, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'

import { DualButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK, ROOT_STACK, TOP_TAB } from '@/constants/label'
import { AuthStackParamList, RootStackParamList } from '@/interfaces/navigation'
import FastImage from '@/components/FastImage'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_RESET_PW_COMPLETE>

export default function ResetPwCompleteScreen({ navigation }: Props) {
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <React.Fragment>
      <AuthStackHeader title='비밀번호 재설정' navigation={navigation} />
      <Box mt='48px' flex={1} alignItems='center'>
        <Box
          alignItems='center'
          borderBottomWidth='1px'
          borderBottomColor='#ff6f12'
          mb='34px'
          pb='37px'
        >
          <Box mb='6px'>
            <FastImage
              source={require('@/assets/images/check.png')}
              style={{
                width: 70,
                height: 70,
              }}
              resizeMode='contain'
            />
          </Box>
          <HStack justifyContent='center' mb='3px' space='5px'>
            <Text fontSize='22px' color='#000000'>
              비밀번호 재설정이
            </Text>
          </HStack>
          <HStack justifyContent='center' mb='34px'>
            <Text fontSize='22px' fontWeight='bold' color='#000000'>
              완료
            </Text>
            <Text fontSize='22px' color='#000000'>
              되었습니다.
            </Text>
          </HStack>
          <VStack alignItems='center'>
            <Text fontSize='18px'>입력하신 새로운 비밀번호로</Text>
            <Text fontSize='18px' mb='16px'>
              입력하신 새로운 재설정이 완료되었습니다.
            </Text>
            <Text fontSize='18px'>새롭게 설정한 비밀번호로 로그인해주세요.</Text>
          </VStack>
        </Box>
        <Box>
          <DualButton
            firstButtonText='홈으로'
            firstButtonOnPress={() =>
              rootNavigation.replace(ROOT_STACK.TOP_TAB, {
                screen: TOP_TAB.MAIN,
              })
            }
            secondButtonText='로그인'
            secondButtonFill
            secondButtonOnPress={() => navigation.replace(AUTH_STACK.AUTH_SIGN_IN)}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}
