import { Center, Modal, Text } from 'native-base'
import CancelButton from '@/components/CancelButton'

interface Props {
  isOpen: boolean
  message: string
  onClose: () => void
}

export default function ModalAlert(props: Props) {
  const { isOpen, message, onClose } = props

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} size='sm'>
      <Modal.Content borderRadius='5px'>
        <Modal.Body pb={0}>
          <Center minH='50px' my='auto'>
            <Text fontSize={16}>{message}</Text>
          </Center>
        </Modal.Body>
        <Modal.Footer borderTopWidth={0} pb='10px' pt='10px' justifyContent='center'>
          <CancelButton buttonText='닫기' onPressHandler={() => onClose()} />
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
