import React from 'react'
import { Box, VStack } from 'native-base'
import Carousel from './Carousel'

interface Props {
  title: string
  bannerGroup: any[][]
  delay: number
}

export default function MainContBannerGrpListWeb(props: Props) {
  const { title, bannerGroup, delay } = props

  return (
    <VStack space='11px' pb='13px'>
      <Carousel bannerGroup={bannerGroup} autoplayInterval={delay} title={title} />
      <Box bg='#f2f2f2' h='11px' mt='13px' />
    </VStack>
  )
}
