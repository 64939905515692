import React from 'react'
import { RefreshControl } from 'react-native'
import { Box, Spinner } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'

import { findByIdAddressBook, findByIdAddressGroup } from '@/services/address'

// import useScroll from '@/hooks/useScroll'
import AddressDetail from '@/screens/boards/components/AddressDetail'
// import CategoryTab from '@/components/CategoryTab'
import Footer from '@/components/Footer'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IAddressBook } from '@/interfaces/address'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.ADDRESS_DETAIL>

export default function AddressDetailScreen(props: Props) {
  const { id } = props.route.params
  const [addressBook, setAddressBook] = React.useState<IAddressBook | null>(null)
  const [addressGroupList, setAddressGroupList] = React.useState([])
  const [refreshing, setRefreshing] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  // const { scrollHandler } = useScroll()

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    await Promise.all([findByIdAddressBook(id), findByIdAddressGroup(id)]).then((values) => {
      setAddressBook(values[0])
      setAddressGroupList(values[1])
    })
    await setRefreshing(false)
  }, [id])

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await Promise.all([findByIdAddressBook(id), findByIdAddressGroup(id)]).then((values) => {
        setAddressBook(values[0])
        setAddressGroupList(values[1])
      })
      await setLoading(false)
    }
    void fetchData()
  }, [id])

  if (isLoading) {
    return (
      <Box flex={1} justifyContent='center'>
        <Spinner color='#ff6f1e' />
      </Box>
    )
  }

  return addressBook ? (
    <Animated.ScrollView
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      showsVerticalScrollIndicator={false}
      // onScroll={scrollHandler}
      // scrollEventThrottle={16}
    >
      {/*<CategoryTab*/}
      {/*  currentCategory={20}*/}
      {/*  handleCurrentCategory={() => {*/}
      {/*    return*/}
      {/*  }}*/}
      {/*/>*/}
      <AddressDetail addressBook={addressBook} addressGroupList={addressGroupList} />
      <Footer />
    </Animated.ScrollView>
  ) : null
}
