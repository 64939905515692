import React from 'react'
import { Box } from 'native-base'
import Carousel from 'react-native-snap-carousel'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import MainBannerItem from './MainBannerItem'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { IBanner } from '@/interfaces/banner'

interface Props {
  mainBannerList: IBanner[]
}

export default function MainBannerListNative(props: Props) {
  const { mainBannerList } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const carouselRef = React.useRef<any>(null)

  const renderItem = ({ item }: any) => {
    return <MainBannerItem item={item} />
  }

  return (
    <Box bg='#ffffff'>
      <Carousel
        ref={carouselRef}
        data={mainBannerList}
        renderItem={renderItem}
        inactiveSlideScale={1}
        sliderWidth={screenWidth}
        itemWidth={screenWidth}
        useScrollView
        vertical={false}
        autoplay={true}
        loop
        autoplayInterval={5000}
        firstItem={0}
      />
      <Box position='absolute' top='40%' left='15px'>
        <CButton onPress={() => carouselRef.current?.snapToPrev()}>
          <FastImage
            source={require('@/assets/images/main-left-icon.png')}
            style={{
              width: 25,
              height: 25,
            }}
            resizeMode='contain'
          />
        </CButton>
      </Box>
      <Box position='absolute' top='40%' right='15px'>
        <CButton onPress={() => carouselRef.current?.snapToNext()}>
          <FastImage
            source={require('@/assets/images/main-right-icon.png')}
            style={{
              width: 25,
              height: 25,
            }}
            resizeMode='contain'
          />
        </CButton>
      </Box>
    </Box>
  )
}
