import React, { useState } from 'react'
import { Platform, Pressable, TextInput } from 'react-native'
import { Box, HStack, Input, Text, VStack, View } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { useFocusEffect } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import * as WebBrowser from 'expo-web-browser'
import * as Google from 'expo-auth-session/providers/google'
import { useAtom } from 'jotai'
import { authStatusAtom, handleLogin, handleRegisterAtom, handleSocialLogin } from '@/atom/authAtom'
import { AuthStatusBar, AuthSubHeader, LargeButton, SocialButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AUTH_STACK, REGISTER_STATUS, SOCIAL } from '@/constants/label'
import { AUTH_STATUS } from '@/constants/auth'
import { Env } from '@/constants/env'
import { AuthStackParamList } from '@/interfaces/navigation'
import { ILogin, IRegister } from '@/interfaces/auth'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import axios from 'axios'
import * as AuthService from '@/services/auth'
import queryString from 'query-string'
import ErrorMessageBox from './components/ErrorMessageBox'
import AuthSubTitle from './components/AuthSubTitle'
import FastImage from '@/components/FastImage'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_REGISTER>

WebBrowser.maybeCompleteAuthSession()

export default function RegisterScreen(props: Props) {
  const { navigation } = props
  const { terms_of_service_agreement, privacy_policy_agreement, marketing_consent, email_consent } =
    props.route.params
  const [authStatus, setAuthStatus] = useAtom(authStatusAtom)
  const [, login] = useAtom(handleLogin)
  const [, register] = useAtom(handleRegisterAtom)
  const [, socialRegister] = useAtom(handleSocialLogin)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isLoading, setLoading] = React.useState(false)
  const [dataSourceCords, setDataSourceCords] = React.useState<{ key: number; scrollY: number }[]>(
    [],
  )
  const [isFBLoading, setIsFBLoading] = React.useState(false)

  const emailInputRef = React.useRef<TextInput>(null)
  const passwordInputRef = React.useRef<TextInput>(null)
  const password2InputRef = React.useRef<TextInput>(null)

  //패스워드 보이기,숨기기
  const [showPswd, setShowPswd] = useState<boolean>(true)

  React.useEffect(() => {
    const query = queryString.parse(window.location.search)
    if (query['code']) {
      if (query['state'] == 'HANTEO') {
        naverRegister(query['code'])
      } else {
        if (query['code'].includes('.')) {
          appleRegister(query['code'])
        } else {
          kakaoRegister(query['code'])
        }
      }
    }
  }, [window.location.search])

  const onKakaoRegister = () => {
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${Env.KAKAO_REST_API_KEY}&redirect_uri=https://hanteo2050.com/AUTH_REGISTER&prompt=login`
    window.location.href = kakaoURL
  }

  const kakaoRegister = async (authorize_code: any) => {
    let AccessToken = 'null'
    axios({
      method: 'post',
      url: 'https://kauth.kakao.com/oauth/token',
      params: {
        grant_type: 'authorization_code',
        client_id: Env.KAKAO_REST_API_KEY,
        redirect_uri: 'https://hanteo2050.com/AUTH_REGISTER',
        code: authorize_code,
        client_secret: Env.KAKAO_CLIENT_SECRET,
      },
    })
      .then((response) => {
        AccessToken = response.data.access_token
        axios({
          method: 'GET',
          url: 'https://kapi.kakao.com/v2/user/me',
          headers: {
            Authorization: `Bearer ${AccessToken}`,
          },
        })
          .then(async (response) => {
            const useremail = response.data.kakao_account.email
            const password = '90Q|$<p[(.E1[=fxJ'
            const username = ('kakao' +
              (Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000)) as string
            await AuthService.getEmailInfo(useremail)
              .then((authdata) => {
                if (authdata[0].username.includes('kakao')) {
                  //카카오 아이디 있으면 카카오 로그인
                  const login: ILogin = {
                    identifier: useremail,
                    password: password,
                  }
                  onLogin(login)
                } else {
                  //카카오 아이디 아니면 오류
                  setErrorMessage('이미 가입된 이메일 입니다.')
                }
              })
              .catch(() => {
                //유저 계정이 없으므로 회원가입 진행
                const register: IRegister = {
                  username: username,
                  email: useremail,
                  password: password,
                  passwordConfirmation: password,
                  blocked: false,
                  confirmed: true,
                  provider: 'kakao',
                  terms_of_service_agreement: true,
                  privacy_policy_agreement: true,
                  marketing_consent: true,
                  email_consent: true,
                }
                //인증까지 완료 되어야함.
                onSubmit(register)
              })
          })
          .catch(function (error) {
            setErrorMessage('한터 회원가입이 일시적으로 불가능합니다. #2')
          })
      })
      .catch(function (error) {
        setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #1')
      })
  }

  const onNaverRegister = async () => {
    const naverURL =
      'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=PhBIbICwmhYBzC2lLP1l&redirect_uri=https://hanteo2050.com/AUTH_REGISTER&state=HANTEO'
    window.location.href = naverURL
  }

  const naverRegister = async (authorize_code: any) => {
    let access_token = 'null'
    await axios
      .get(
        `https://hanteo2050.com/login-api/naver/login/code/${authorize_code}?redirect_uri=http://hanteo2050.com/AUTH_REGISTER'}`,
      )
      .then(async (response) => {
        access_token = response.data.access_token
        await axios
          .get(`https://hanteo2050.com/login-api/naver/login/token/${access_token}`)
          .then(async (response) => {
            const useremail = response.data.response.email
            const password = '90Q|$<p[(.E1[=fxJ'
            const username = ('naver' +
              (Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000)) as string
            await AuthService.getEmailInfo(useremail)
              .then((authdata) => {
                if (authdata[0].username.includes('naver')) {
                  //네이버 아이디 있으면 네이버 로그인
                  const login: ILogin = {
                    identifier: useremail,
                    password: password,
                  }
                  onLogin(login)
                } else {
                  //네이버 아이디 아니면 오류
                  setErrorMessage('이미 가입된 이메일 입니다.')
                  onNaverLogout(access_token)
                }
              })
              .catch(() => {
                //유저 계정이 없으므로 회원가입 진행
                const register: IRegister = {
                  username: username,
                  email: useremail,
                  password: password,
                  passwordConfirmation: password,
                  blocked: false,
                  confirmed: true,
                  provider: 'naver',
                  terms_of_service_agreement: true,
                  privacy_policy_agreement: true,
                  marketing_consent: true,
                  email_consent: true,
                }
                //인증까지 완료 되어야함.
                onSubmit(register)
              })
          })
          .catch((error) => {
            setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #2')
            onNaverLogout(access_token)
          })
      })
      .catch((error) => {
        setErrorMessage('한터 로그인이 일시적으로 불가능합니다. #1')
      })
  }

  const onNaverLogout = async (access_token: string) => {
    await axios
      .get(`https://hanteo2050.com/api/naver/logout/${access_token}`)
      .then((response) => {
        console.log(response.data)
      })
      .catch((e) => console.log('error!', e))
  }

  const onAppleRegister = async () => {
    const appleURL =
      'https://appleid.apple.com/auth/authorize?response_type=code&client_id=com.hanteo.newservices&redirect_uri=https://hanteo2050.com/AUTH_REGISTER'
    window.location.href = appleURL
  }

  const appleRegister = async (authorize_code: any) => {
    setLoading(true)
    setErrorMessage('')
    await axios({
      method: 'get',
      url: `https://hanteo2050.com/login-api/apple/getToken/${authorize_code}`,
    })
      .then(async (response) => {
        console.log(response.data)
        await axios({
          method: 'get',
          url: 'https://hanteo2050.com/login-api/apple/idTokenDecode',
          params: {
            token: response.data.id_token,
          },
        })
          .then(async (response) => {
            const sub = response.data.sub.toString()
            const username = sub
            const useremail = `${username}@apple.com`
            const password = '8JDN#$YNF90KDH#$8'
            const login: ILogin = {
              identifier: useremail,
              password: password,
            }
            await AuthService.getTokenInfo(username).then((authdata) => {
              const len = authdata.length
              if (len == 1) {
                //이미 회원가입이 되어 있다.
                if (authdata[0].email.includes('apple')) {
                  onLogin(login)
                }
              } else {
                const register: IRegister = {
                  username: username,
                  email: useremail,
                  password: password,
                  passwordConfirmation: password,
                  blocked: false,
                  confirmed: true,
                  provider: 'apple',
                  terms_of_service_agreement: true,
                  privacy_policy_agreement: true,
                  marketing_consent: true,
                  email_consent: true,
                }
                //인증까지 완료 되어야함.
                onSubmit(register)
              }
            })
          })
          .catch(function (error) {
            setErrorMessage('한터 회원가입이 일시적으로 불가능합니다. #2')
            setLoading(false)
          })
      })
      .catch(function (error) {
        setErrorMessage('한터 회원가입이 일시적으로 불가능합니다. #1')
        setLoading(false)
      })
  }

  const [googleRequest, googleResponse, googlePromptAsync] = Google.useAuthRequest({
    expoClientId: Env.EXPO_CLIENT_ID,
    iosClientId: Env.IOS_CLIENT_ID,
    androidClientId: Env.ANDROID_CLIENT_ID,
    webClientId: Env.WEB_CLIENT_ID,
  })

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<IRegister>({
    defaultValues: {
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      blocked: false,
      terms_of_service_agreement: terms_of_service_agreement,
      privacy_policy_agreement: privacy_policy_agreement,
      marketing_consent: marketing_consent,
      email_consent: email_consent,
    },
  })

  const onSubmit = async (data: IRegister) => {
    const emailRegex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/

    if (data.username == '') {
      data.username = data.email.split('@')[0]
    }

    if (!emailRegex.test(data.email)) {
      setErrorMessage('이메일 형식과 맞지 않습니다.')
    } else if (data.password !== data.passwordConfirmation) {
      setErrorMessage('입력하신 패스워드와 패스워드 확인이\n일치하지 않습니다.')
    } else if (!passwordRegex.test(data.password)) {
      setErrorMessage('패스워드는 영문자, 숫자, 특수문자를 포함한\n8자리 이상으로 입력해주세요.')
    } else {
      setLoading(true)
      await register(data)
        .then(() => {
          return setLoading(false)
        })
        .catch(() => {
          setErrorMessage('서버에 문제가 발생하였습니다.')
          return setLoading(false)
        })
    }
  }

  const onLogin = async (data: ILogin) => {
    setLoading(true)
    await login(data)
    await setLoading(false)
  }

  const onSocialRegister = React.useCallback(
    async (token: string, provider: string) => {
      await socialRegister({ token, provider })
    },
    [socialRegister],
  )

  const onFacebookRegister = async () => {
    setIsFBLoading(true)
    await WebBrowser.openBrowserAsync(`${Env.STRAPI_API_URL}/connect/facebook`)
    setIsFBLoading(false)
  }

  React.useEffect(() => {
    if (googleResponse?.type === 'success') {
      const authentication = googleResponse.authentication
      if (authentication) {
        void onSocialRegister(authentication.accessToken, 'google')
      }
    }
  }, [onSocialRegister, googleResponse])

  useFocusEffect(() => {
    setAuthStatus(AUTH_STATUS.INIT)
  })

  React.useEffect(() => {
    if (authStatus === AUTH_STATUS.REGISTER_SUCCESS) {
      navigation.replace(AUTH_STACK.AUTH_AUTHENTICATION)
    } else if (authStatus === AUTH_STATUS.ALREADY_LOCAL_REGISTER) {
      setErrorMessage('이미 가입된 회원입니다.')
    } else if (authStatus === AUTH_STATUS.ALREADY_SOCIAL_REGISTER) {
      setErrorMessage('구글 또는 페이스북으로 가입된 회원입니다.')
    } else if (authStatus === AUTH_STATUS.SERVER_ERROR) {
      setErrorMessage('서버에 문제가 발생하였습니다.')
    } else if (authStatus === AUTH_STATUS.NO_PROFILE) {
      navigation.push(AUTH_STACK.AUTH_PROFILE)
    }
  }, [authStatus, navigation])

  return (
    <React.Fragment>
      <AuthStackHeader title='회원가입' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        keyboardShouldPersistTaps={'handled'}
      >
        <Box mt='28px' mb='20px' mx='10%'>
          <AuthStatusBar active={REGISTER_STATUS.REGISTER} />
          <Box mt='32px'>
            <AuthSubHeader title='이메일로 가입하기' />
            <VStack
              key={1}
              mb='33px'
              onLayout={(e) => {
                const layout = e.nativeEvent.layout
                setDataSourceCords([...dataSourceCords, { key: 1, scrollY: layout.y }])
              }}
            >
              <Text fontSize='15px' fontWeight='bold' color='#ff6f1e'>
                이메일
              </Text>
              <Controller
                control={control}
                name='email'
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    ref={emailInputRef}
                    placeholder='이메일을 입력해주세요.'
                    width='full'
                    height='40px'
                    borderColor='#F4F4F4'
                    backgroundColor='#F4F4F4'
                    borderRadius='3px'
                    placeholderTextColor='#5B5B5B'
                    my='4px'
                    pl='10px'
                    fontSize='15px'
                    onChangeText={(value) => {
                      onChange(value)
                      formClearErrors()
                      setErrorMessage('')
                    }}
                    onBlur={onBlur}
                    value={value}
                    autoCapitalize='none'
                    autoComplete='email'
                    autoCorrect={false}
                    keyboardType='email-address'
                    blurOnSubmit={false}
                    onSubmitEditing={() => {
                      passwordInputRef.current?.focus()
                    }}
                  />
                )}
              />
              <Text fontSize='13px' fontWeight='bold' color='#000000'>
                일회용 이메일 주소의 사용을 금합니다.
              </Text>
            </VStack>
            <VStack
              key={2}
              onLayout={(e) => {
                const layout = e.nativeEvent.layout
                setDataSourceCords([...dataSourceCords, { key: 2, scrollY: layout.y }])
              }}
            >
              <Text fontSize='15px' fontWeight='bold' color='#ff6f1e' mb='5px'>
                패스워드 (영문, 숫자, 기호 혼합)
              </Text>
              <View flexDirection='row' justifyContent='flex-end' flex={1} alignItems='center'>
                <Controller
                  control={control}
                  name='password'
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      flex={1}
                      ref={passwordInputRef}
                      placeholder='8자리 이상 입력해주세요.'
                      width='full'
                      height='40px'
                      borderColor='#F4F4F4'
                      backgroundColor='#F4F4F4'
                      borderRadius='3px'
                      placeholderTextColor='#5B5B5B'
                      mb='10px'
                      pl='10px'
                      fontSize='15px'
                      secureTextEntry={showPswd}
                      maxLength={32}
                      onChangeText={(value) => {
                        onChange(value)
                        formClearErrors()
                        setErrorMessage('')
                      }}
                      onBlur={onBlur}
                      value={value}
                      autoCapitalize='none'
                      autoComplete='password'
                      autoCorrect={false}
                      blurOnSubmit={false}
                      onSubmitEditing={() => {
                        password2InputRef.current?.focus()
                      }}
                      InputRightElement={
                        <Pressable onPress={() => setShowPswd(!showPswd)}>
                          <HStack mx='8px'>
                            <FastImage
                              source={
                                showPswd
                                  ? require('@/assets/images/Eye-Open.png')
                                  : require('@/assets/images/Eye-Closed.png')
                              }
                              style={{
                                width: 24,
                                height: 24,
                              }}
                              resizeMode='contain'
                            />
                          </HStack>
                        </Pressable>
                      }
                    />
                  )}
                />
              </View>
            </VStack>
            <VStack
              key={3}
              onLayout={(e) => {
                const layout = e.nativeEvent.layout
                setDataSourceCords([...dataSourceCords, { key: 3, scrollY: layout.y }])
              }}
            >
              <View flexDirection='row' justifyContent='flex-end' flex={1} alignItems='center'>
                <Controller
                  control={control}
                  name='passwordConfirmation'
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      flex={1}
                      ref={password2InputRef}
                      placeholder='한번 더 입력해주세요.'
                      width='full'
                      height='40px'
                      borderColor='#F4F4F4'
                      backgroundColor='#F4F4F4'
                      borderRadius='3px'
                      placeholderTextColor='#5B5B5B'
                      mb='16px'
                      pl='10px'
                      fontSize='15px'
                      secureTextEntry={showPswd}
                      maxLength={32}
                      onChangeText={(value) => {
                        onChange(value)
                        formClearErrors()
                        setErrorMessage('')
                      }}
                      onBlur={onBlur}
                      value={value}
                      autoCapitalize='none'
                      autoComplete='password'
                      autoCorrect={false}
                      InputRightElement={
                        <Pressable onPress={() => setShowPswd(!showPswd)}>
                          <HStack mx='8px'>
                            <FastImage
                              source={
                                showPswd
                                  ? require('@/assets/images/Eye-Open.png')
                                  : require('@/assets/images/Eye-Closed.png')
                              }
                              style={{
                                width: 24,
                                height: 24,
                              }}
                              resizeMode='contain'
                            />
                          </HStack>
                        </Pressable>
                      }
                    />
                  )}
                />
              </View>
            </VStack>
          </Box>
          <LargeButton
            buttonTitle='등록하기'
            loading={isLoading}
            onPress={handleSubmit(onSubmit)}
            fill
          />
          {formErrors.email ? (
            <ErrorMessageBox
              errorMessage='이메일을 입력해주세요.'
              marginTop='12px'
              marginBottom='0px'
            />
          ) : formErrors.password ? (
            <ErrorMessageBox
              errorMessage='패스워드를 입력해주세요.'
              marginTop='12px'
              marginBottom='0px'
            />
          ) : formErrors.passwordConfirmation ? (
            <ErrorMessageBox
              errorMessage='패스워드 확인을 입력해주세요.'
              marginTop='12px'
              marginBottom='0px'
            />
          ) : errorMessage ? (
            <ErrorMessageBox errorMessage={errorMessage} marginTop='12px' marginBottom='0px' />
          ) : null}
          <Box mt='24px' mb='16px'>
            <AuthSubTitle title='SNS 간편 가입하기' />
            <HStack space={Platform.OS == 'ios' ? '18px' : '18px'} justifyContent='center'>
              <SocialButton
                provider={SOCIAL.FACEBOOK}
                buttonText='페이스북으로 회원등록'
                onPress={() => onFacebookRegister()}
                disabled={isFBLoading}
              />
              <SocialButton
                provider={SOCIAL.GOOGLE}
                buttonText='구글로 회원등록'
                onPress={() => googlePromptAsync()}
                disabled={!googleRequest}
              />
              <SocialButton
                provider={SOCIAL.KAKAO}
                buttonText='카카오톡 회원등록'
                onPress={() => onKakaoRegister()}
                disabled={!googleRequest}
              />
              <SocialButton
                provider={SOCIAL.NAVER}
                buttonText='네이버 회원등록'
                onPress={() => onNaverRegister()}
                disabled={!googleRequest}
              />
              <SocialButton
                provider={SOCIAL.APPLE}
                buttonText='애플 회원등록'
                onPress={() => onAppleRegister()}
                disabled={!googleRequest}
              />
            </HStack>
          </Box>
        </Box>
        {/*</TouchableWithoutFeedback>*/}
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
