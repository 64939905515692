import { Text, HStack, VStack, Box } from 'native-base'
import BackButton from '@/components/BackButton'
import FastImage from '@/components/FastImage'
import React from 'react'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { TouchableOpacity } from 'react-native'
import LanguageHeaderItem from '@/components/LanguageHeaderItem'
import MypageButton from '@/screens/auth/components/MypageButton'

interface Props {
  navigation: any
  title: string | undefined
}

export default function DetailStackHeader(props: Props) {
  const { navigation, title } = props

  return (
    <VStack
      bg='white'
      h='49px'
      justifyContent='center'
      borderBottomWidth='1px'
      borderBottomColor='#E3E3E3'
    >
      <HStack w='100%' justifyContent='space-between' alignItems='center' px='15px'>
        <HStack alignItems='center'>
          <BackButton navigation={navigation} color='BLACK' />
          <TouchableOpacity
            onPress={() => navigation.navigate(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.MAIN })}
          >
            <FastImage
              source={require('@/assets/images/logo.png')}
              style={{ width: 51, height: 32, marginLeft: 15 }}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </HStack>
        <Box flex={1} mx='16px' mt='2px'>
          <Text
            textAlign='start'
            ellipsizeMode='tail'
            numberOfLines={1}
            fontSize='20px'
            fontWeight={800}
            color='#222222'
          >
            {title ? title : ''}
          </Text>
        </Box>
        <HStack alignItems='center'>
          <LanguageHeaderItem />
          <Box ml='15px'>
            <MypageButton navigation={navigation} />
          </Box>
        </HStack>
      </HStack>
    </VStack>
  )
}
