import qs from 'qs'

import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'
import {
  IArticleElementsLoad,
  IComplainHistoryCreateForm,
  IComplainHistoryLoad,
  IComplainHistoryUpdateForm,
  ICreateArticleElementsForm,
} from '@/interfaces/articleElements'

export async function createArticleElements(ae: ICreateArticleElementsForm) {
  await StrapiJwtAPI(ae.jwt).post('/article-elements', ae)
}

export async function updateArticleElements(ae: ICreateArticleElementsForm, id: number) {
  await StrapiJwtAPI(ae.jwt).put(`/article-elements/${id}`, ae)
}

export async function findOne(aeLoad: IArticleElementsLoad) {
  const query = qs.stringify({
    _where: [{ type_eq: aeLoad.type }, { type_id_eq: aeLoad.type_id }, { writer: aeLoad.writer }],
  })
  const response = await StrapiAPI.get(`/article-elements?${query}`)
  return response.data[0]
}

export async function findOneCommentArticleElements(aeLoad: IArticleElementsLoad) {
  const query = qs.stringify({
    _where: [{ type_eq: aeLoad.type }, { type_id_eq: aeLoad.type_id }, { writer: aeLoad.writer }],
  })
  const response = await StrapiAPI.get(`/article-elements?${query}`)
  return response.data
}

export async function findOneComplainHistories(params: IComplainHistoryLoad) {
  const { reported_user, type_id, type } = params
  const query = qs.stringify({
    _where: [{ reported_user_eq: reported_user }, { type_id_eq: type_id }, { type_eq: type }],
  })

  const response = await StrapiAPI.get(`/complain-histories?${query}`)
  return response.data[0]
}

export async function updateComplainHistories(params: IComplainHistoryUpdateForm) {
  await StrapiJwtAPI(params.jwt).put(`/complain-histories/${params.id}`, params)
}

export async function createComplainHistories(params: IComplainHistoryCreateForm) {
  await StrapiJwtAPI(params.jwt).post('/complain-histories', params)
}

export async function updateGoodElements(jwt: string, id: number) {
  return StrapiJwtAPI(jwt).put(`/article-elements/${id}`, { good: false })
}

export async function updateScrapElements(jwt: string, id: number) {
  return StrapiJwtAPI(jwt).put(`/article-elements/${id}`, { scrap: false })
}
