import { Image } from 'native-base'
import React from 'react'

interface Props {
  src: string
  width: string
  height: string
}

const DynamicSvgIcon: React.FC<Props> = (props: Props) => {
  const { src, width, height } = props

  return <Image src={src} width={width} height={height}></Image>
}

export default DynamicSvgIcon
