import qs from 'qs'
import { StrapiAPI } from '@/services/axios'

export async function findAllNewsPaper(limit: number) {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
    ],
    _sort: 'created_at:desc',
    _limit: limit,
  })
  const response = await StrapiAPI.get(`/news-papers?${query}`)
  return response.data
}

export async function findNewsPaperCount() {
  const response = await StrapiAPI.get('/news-papers/count')

  return response.data
}
