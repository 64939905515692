import React from 'react'
import { KeyboardAvoidingView, Platform } from 'react-native'
import { Box } from 'native-base'
import { actions, RichToolbar } from 'react-native-pell-rich-editor'
import { getStatusBarHeight } from 'react-native-status-bar-height'

interface Props {
  pickImage: () => Promise<void>
  isFocusedTitle: boolean
}

const RichTextToolbar = React.forwardRef<any, Props>((props, ref) => {
  const { pickImage, isFocusedTitle } = props

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? getStatusBarHeight(true) : 0}
    >
      <Box borderTopWidth='1px' borderTopColor='#e1e1e1'>
        <RichToolbar
          editor={ref}
          style={{
            backgroundColor: '#ffffff',
            display: isFocusedTitle ? 'none' : 'flex',
          }}
          selectedIconTint='#ff6f1e'
          iconTint='#312921'
          actions={[
            actions.undo,
            actions.redo,
            actions.insertImage,
            actions.setBold,
            actions.setItalic,
            actions.setUnderline,
            actions.setStrikethrough,
            actions.insertOrderedList,
            actions.insertBulletsList,
            actions.insertLink,
          ]}
          onPressAddImage={pickImage}
        />
      </Box>
    </KeyboardAvoidingView>
  )
})

export default RichTextToolbar
