import React, { Fragment } from 'react'
import { Clipboard, Linking } from 'react-native'
// import Clipboard from '@react-native-clipboard/clipboard'
import { Box, HStack, Modal, Text } from 'native-base'
import KakaoShareLink from 'react-native-kakao-share-link'

import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import ModalAlert from '@/components/ModalAlert'
import { Env } from '@/constants/env'
import useFormatter from '@/hooks/useFormatter'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  type: string
  typeId: number
  typeCategory?: number
}

export default function ModalShare(props: Props) {
  const { isOpen, onClose, title, type, typeId, typeCategory } = props
  const [isOpenAlert, setIsOpenAlert] = React.useState(false)
  const [screenWidth] = useAtom(screenWidthAtom)

  const { boardCategoryFormatter } = useFormatter()

  const newsUrl = `${Env.WEB_URL}/${type}/${typeId}`
  const boardUrl = `${Env.WEB_URL}/${type}/${boardCategoryFormatter(
    typeCategory ? typeCategory : 0,
  )}/${typeId}`
  const newsPaperUrl = `${Env.WEB_URL}/${type}`
  const sendUrl = type === 'news' ? newsUrl : type === 'board' ? boardUrl : newsPaperUrl
  const sendText = '한일생활정보 한터 - ' + title

  const handleOpenAlert = (value: boolean) => {
    setIsOpenAlert(value)
  }

  // const share = async () => {
  //   await Share.share({
  //     url: title,
  //     message: title,
  //   })
  // }

  const shareKakao = async () => {
    try {
      const response = await KakaoShareLink.sendFeed({
        content: {
          title: '테스트 타이틀',
          imageUrl: 'https://hanteo-api.s3.ap-northeast-1.amazonaws.com/icon_7c2c7ff930.png',
          link: {
            webUrl: 'https://hanteo2050.com',
            mobileWebUrl: 'https://hanteo2050.com',
          },
          description: '테스트 설명',
        },
        buttons: [
          {
            title: '웹에서 보기',
            link: {
              webUrl: 'hanteo2050.com',
              mobileWebUrl: 'hanteo2050.com',
            },
          },
          {
            title: '앱에서 보기',
            link: {
              androidExecutionParams: [{ key: 'key1', value: 'value1' }],
              iosExecutionParams: [
                { key: 'key1', value: 'value1' },
                { key: 'key2', value: 'value2' },
              ],
            },
          },
        ],
      })
    } catch (e) {
      console.error(e)
    }
  }

  const shareLine = () => {
    void Linking.openURL(`https://www.line.me/R/msg/text/?${sendUrl}`)
  }

  const shareFacebook = () => {
    void Linking.openURL(`https://www.facebook.com/sharer/sharer.php?u=${sendUrl}`)
  }

  const shareTwitter = () => {
    void Linking.openURL(`https://twitter.com/intent/tweet?text=${sendText}&url=${sendUrl}`)
  }

  // const shareInstagram = () => {
  //   // void share()
  // }

  const shareKakaoStory = () => {
    void Linking.openURL(`https://story.kakao.com/share?text=${sendText}&url=${sendUrl}`)
  }

  const shareCopyUrl = () => {
    Clipboard.setString(sendUrl)
    handleOpenAlert(true)
  }

  return (
    <Fragment>
      {isOpenAlert && (
        <ModalAlert
          isOpen={isOpenAlert}
          message='URL이 복사되었습니다.'
          onClose={() => handleOpenAlert(false)}
        />
      )}
      <Modal isOpen={isOpen} onClose={() => onClose()}>
        <Modal.Content px='18px' w={screenWidth - 80} borderRadius='8px'>
          <Modal.Header borderBottomWidth='1px' borderBottomColor='#ff6f1e' pb='13px'>
            <Text fontSize='18px' fontWeight={600} textAlign='center'>
              공유하기
            </Text>
          </Modal.Header>
          <Modal.Body px={0}>
            <HStack justifyContent='center' space='5px' mb='15px'>
              <CButton onPress={() => shareKakao()}>
                <Box alignItems='center' w='75px'>
                  <FastImage
                    source={require('@/assets/images/kakao-logo.png')}
                    style={{
                      width: 43,
                      height: 43,
                    }}
                    resizeMode='contain'
                  />
                  <Text>카카오톡</Text>
                </Box>
              </CButton>
              <CButton onPress={() => shareLine()}>
                <Box alignItems='center' w='75px'>
                  <FastImage
                    source={require('@/assets/images/line-logo.png')}
                    style={{
                      width: 43,
                      height: 43,
                    }}
                    resizeMode='contain'
                  />
                  <Text>라인</Text>
                </Box>
              </CButton>
              <CButton onPress={() => shareFacebook()}>
                <Box alignItems='center' w='75px'>
                  <FastImage
                    source={require('@/assets/images/facebook-logo.png')}
                    style={{
                      width: 43,
                      height: 43,
                    }}
                    resizeMode='contain'
                  />
                  <Text>페이스북</Text>
                </Box>
              </CButton>
            </HStack>
            <HStack justifyContent='center' space='5px'>
              {/*<CButton onPress={() => shareInstagram()}>*/}
              {/*  <Box alignItems='center'>*/}
              {/*    <FastImage*/}
              {/*      source={require('@/assets/images/instagram-logo.png')}*/}
              {/*      style={{*/}
              {/*        width: 43,*/}
              {/*        height: 43,*/}
              {/*      }}*/}
              {/*      resizeMode='contain'*/}
              {/*    />*/}
              {/*    <Text>인스타그램</Text>*/}
              {/*  </Box>*/}
              {/*</CButton>*/}
              <CButton onPress={() => shareTwitter()}>
                <Box alignItems='center' w='75px'>
                  <FastImage
                    source={require('@/assets/images/twitter-logo.png')}
                    style={{
                      width: 43,
                      height: 43,
                    }}
                    resizeMode='contain'
                  />
                  <Text>X(트위터)</Text>
                </Box>
              </CButton>
              <CButton onPress={() => shareKakaoStory()}>
                <Box alignItems='center' w='75px'>
                  <FastImage
                    source={require('@/assets/images/kakao-story-logo.png')}
                    style={{
                      width: 43,
                      height: 43,
                    }}
                    resizeMode='contain'
                  />
                  <Text>카카오스토리</Text>
                </Box>
              </CButton>
              <CButton onPress={() => shareCopyUrl()}>
                <Box alignItems='center' w='75px'>
                  <FastImage
                    source={require('@/assets/images/share-logo.png')}
                    style={{
                      width: 43,
                      height: 43,
                    }}
                    resizeMode='contain'
                  />
                  <Text>URL복사</Text>
                </Box>
              </CButton>
            </HStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Fragment>
  )
}
