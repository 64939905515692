import { Center, HStack, Text } from 'native-base'
import { REGISTER_STATUS } from '@/constants/label'

interface Props {
  active: REGISTER_STATUS
}

export default function AuthStatusBar(props: Props) {
  const { active } = props

  return (
    <HStack alignItems='center'>
      <Center
        w='33.3%'
        pb='8px'
        borderBottomWidth={active === REGISTER_STATUS.REGISTER ? '2px' : '1px'}
        borderBottomColor={active === REGISTER_STATUS.REGISTER ? '#FF6F1E' : '#cdcdcd'}
      >
        <Text
          fontWeight='bold'
          fontSize='15px'
          textAlign='center'
          color={active === REGISTER_STATUS.REGISTER ? '#FF6F1E' : '#cdcdcd'}
        >
          회원등록
        </Text>
      </Center>
      <Center
        w='33.3%'
        pb='8px'
        borderBottomWidth={active === REGISTER_STATUS.AUTHENTICATION ? '2px' : '1px'}
        borderBottomColor={active === REGISTER_STATUS.AUTHENTICATION ? '#FF6F1E' : '#cdcdcd'}
      >
        <Text
          fontWeight='bold'
          fontSize='15px'
          textAlign='center'
          color={active === REGISTER_STATUS.AUTHENTICATION ? '#FF6F1E' : '#cdcdcd'}
        >
          본인인증
        </Text>
      </Center>
      <Center
        w='33.3%'
        pb='8px'
        borderBottomWidth={active === REGISTER_STATUS.PROFILE ? '2px' : '1px'}
        borderBottomColor={active === REGISTER_STATUS.PROFILE ? '#FF6F1E' : '#cdcdcd'}
      >
        <Text
          fontWeight='bold'
          fontSize='15px'
          textAlign='center'
          color={active === REGISTER_STATUS.PROFILE ? '#FF6F1E' : '#cdcdcd'}
        >
          본인정보입력
        </Text>
      </Center>
    </HStack>
  )
}
