import React from 'react'
import { Box, Text } from 'native-base'

import MoreTab from '@/components/MoreTab'
import NewsDetailYonhapNewsItem from './NewsDetailYonhapNewsItem'
import { IYonhapNews } from '@/interfaces/news'

interface Props {
  newsList: IYonhapNews[]
  onMore: () => void
}

export default function NewsDetailYonhapNewsList(props: Props) {
  const { newsList, onMore } = props

  return newsList ? (
    <Box bg='#ffffff'>
      <Text mt='16px' mb='8px' px='17px' fontWeight={700} fontSize='18px'>
        한터 추천뉴스
      </Text>
      {newsList.map((item) => {
        return <NewsDetailYonhapNewsItem item={item} key={item.id} />
      })}
      <MoreTab
        moreButtonTextColor='기사'
        moreButtonText='더보기'
        moreButtonOnPress={onMore}
        useSideButton={false}
      />
      {/* <Box bg='#f2f2f2' h='11px' mb='16px' /> */}
    </Box>
  ) : null
}
