import FastImage from '@/components/FastImage'
import FabImageIcon from '@/components/icon/FabImageIcon'
import FabTextIcon from '@/components/icon/FabTextIcon'
import { VStack, Center, Fab } from 'native-base'

interface Props {
  onPress: () => void
}

export default function FabButton(props: Props) {
  const { onPress } = props
  return (
    <Fab
      w='60px'
      h='60px'
      placement='bottom-right'
      backgroundColor='#FF6F1E'
      onPress={() => {
        onPress()
      }}
      icon={
        <VStack>
          <Center mb='4px'>
            <FabImageIcon />
            <FabTextIcon />
          </Center>
        </VStack>
      }
    />
  )
}
