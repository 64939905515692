import { Box, Center, Modal, Text } from 'native-base'
import ConfirmButton from '@/components/ConfirmButton'
import CancelButton from '@/components/CancelButton'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'
import CButton from './CButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  titleMessage: string
  contentsMessage: string
  cancelButtonText: string
}

export default function ModalAlertTitle(props: Props) {
  const { isOpen, onClose, titleMessage, contentsMessage, cancelButtonText } = props
  const [screenWidth] = useAtom(screenWidthAtom)

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Content w={screenWidth - 80} borderRadius='8px'>
        <Modal.Header borderBottomWidth={0} pb={0}>
          <Text textAlign='center' fontSize='20px' fontWeight={600}>
            {titleMessage}
          </Text>
        </Modal.Header>
        <Modal.Body borderBottomWidth={0} pb={0}>
          <Text textAlign='center' fontSize='16px' color='#505050'>
            {contentsMessage}
          </Text>
        </Modal.Body>
        <Modal.Footer justifyContent='center' borderTopWidth={0}>
          <CButton onPress={() => onClose()}>
            <Center
              h='33px'
              w='88px'
              borderRadius='20px'
              borderColor='#8F8F8F'
              borderWidth={1}
              marginLeft='5px'
            >
              <Text color='#8F8F8F' fontWeight='bold' fontSize={15}>
                {cancelButtonText}
              </Text>
            </Center>
          </CButton>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
