import { ISendMemberPushForm, ISendSubscriptPushForm } from '@/interfaces/push'
import { StrapiJwtAPI } from '@/services/axios'
import qs from 'qs'

export async function getBoardAgree(form: ISendSubscriptPushForm) {
  const response = await StrapiJwtAPI(form.jwt).get(
    `/advertisement-push-agrees?user_id=${form.user_id}&type=${form.type}&type_id=${form.type_id}`,
  )
  return response.data
}

export async function sendBoardAgree(form: ISendSubscriptPushForm) {
  const response = await StrapiJwtAPI(form.jwt).post('/advertisement-push-agrees', form)
  return response.data
}

export async function sendBoardDisagree(jwt: string, id: number) {
  const response = await StrapiJwtAPI(jwt).delete(`/advertisement-push-agrees/${id}`)
  return response.data
}

export async function getMemberAgree(jwt: string, user_id: number, member_id: number) {
  const query = qs.stringify({
    _where: [{ user_id_eq: user_id }, { member_id_eq: member_id }],
  })
  const response = await StrapiJwtAPI(jwt).get(`/member-push-agrees?${query}`)
  return response.data
}

export async function sendMemberAgree(form: ISendMemberPushForm) {
  if (form.jwt) {
    const response = await StrapiJwtAPI(form.jwt).post('/member-push-agrees', form)
    return response.data
  }
}

export async function sendMemberDisagree(jwt: string, id: number) {
  const response = await StrapiJwtAPI(jwt).delete(`/member-push-agrees/${id}`)
  return response.data
}

export async function getBlockUser(jwt: string, user_id: number, block_user_id: number) {
  const response = await StrapiJwtAPI(jwt).get(
    `/block-user-lists?user_id=${user_id}&block_user_id=${block_user_id}`,
  )
  return response.data
}

const PushAgree = {
  getBoardAgree,
  sendBoardAgree,
  sendBoardDisagree,
  getMemberAgree,
  sendMemberAgree,
  sendMemberDisagree,
  getBlockUser,
}

export default PushAgree
