import React from 'react'
import { Box, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authStatusAtom, handleConfirmCheck, profileAtom } from '@/atom/authAtom'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { AuthStatusBar, LargeButton } from '@/screens/auth/components'
import { AUTH_STATUS } from '@/constants/auth'
import { AUTH_STACK, REGISTER_STATUS, ROOT_STACK, TOP_TAB } from '@/constants/label'
import { AuthStackParamList, RootStackParamList } from '@/interfaces/navigation'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Platform } from 'react-native'

type Props = NativeStackScreenProps<AuthStackParamList, AUTH_STACK.AUTH_AUTHENTICATION>

export default function AuthenticationScreen({ navigation }: Props) {
  const [profile] = useAtom(profileAtom)
  const [authStatus] = useAtom(authStatusAtom)
  const [, confirmCheck] = useAtom(handleConfirmCheck)
  const [mailCheckError, setMailCheckError] = React.useState(false)
  const [serverError, setServerError] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const handleMailCheck = async () => {
    if (profile) {
      setLoading(true)
      await confirmCheck(profile.email).then(() => {
        return setLoading(false)
      })
    } else {
      setServerError(true)
      return setLoading(false)
    }
  }

  React.useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATION_FAILED) {
      setMailCheckError(true)
    } else if (authStatus === AUTH_STATUS.AUTHENTICATION_SUCCESS) {
      navigation.replace(AUTH_STACK.AUTH_PROFILE)
    }
  }, [authStatus, navigation])

  return (
    <React.Fragment>
      <AuthStackHeader title='회원가입' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        keyboardShouldPersistTaps={'handled'}
        removeClippedSubviews={true}
      >
        <Box mt='30px' mb='20px' mx='10%'>
          <AuthStatusBar active={REGISTER_STATUS.AUTHENTICATION} />
          {authStatus === AUTH_STATUS.SERVER_ERROR || serverError ? (
            <Box mt='56px'>
              <Box alignItems='center' mb='30px'>
                <Text
                  fontSize='18px'
                  textAlign='center'
                  color='#ff6f1e'
                  fontWeight='bold'
                  mb='30px'
                >
                  서버에 문제가 발생하였습니다.
                </Text>
                <Box
                  borderTopColor='#e8e8e8'
                  borderTopWidth='1px'
                  borderBottomColor='#e8e8e8'
                  borderBottomWidth='1px'
                  py='20px'
                  mb='10px'
                  w='full'
                >
                  <Text fontSize='16px' textAlign='center' color='#222222'>
                    지속적으로 문제가 발생할 시
                  </Text>
                  <Text fontSize='16px' textAlign='center' color='#222222'>
                    관리자에게 문의해주세요.
                  </Text>
                </Box>
              </Box>
              <LargeButton
                buttonTitle='돌아가기'
                onPress={() =>
                  rootNavigation.replace(ROOT_STACK.TOP_TAB, {
                    screen: TOP_TAB.MAIN,
                  })
                }
                fill
              />
            </Box>
          ) : mailCheckError ? (
            <Box mt='32px'>
              <Box alignItems='center' mb='24px'>
                <Text fontSize='16px' textAlign='center' color='#222222'>
                  아직 이메일 인증이 완료되지 않았습니다.
                </Text>
                <Text fontSize='16px' textAlign='center' color='#222222'>
                  등록하신‘<Text color='#ff6f1e'>{profile?.email}</Text>’으로
                </Text>
                <Text fontSize='16px' textAlign='center' color='#222222' mb='20px'>
                  이메일을 확인하시고 다시 시도해주세요.
                </Text>
                <Text fontSize='16px' textAlign='center' color='#222222' mb='8px'>
                  인증이 완료되셨다면 다음을 클릭해 주세요.
                </Text>
              </Box>
              <LargeButton buttonTitle='다음' onPress={handleMailCheck} loading={isLoading} fill />
            </Box>
          ) : (
            <Box mt='32px'>
              <Box alignItems='center' mb='24px'>
                <Text fontSize='16px' textAlign='center' color='#222222'>
                  등록하신‘
                  <Text fontSize='16px' color='#ff6f1e'>
                    {profile?.email}
                  </Text>
                  ’으로
                </Text>
                <Text fontSize='16px' textAlign='center' color='#222222'>
                  인증 이메일이 전송되었습니다.
                </Text>
                <Text fontSize='16px' textAlign='center' color='#222222' mb='20px'>
                  이메일을 확인해 주세요.
                </Text>
                <Text fontSize='16px' textAlign='center' color='#222222' mb='8px'>
                  인증이 완료되셨다면 다음을 클릭해 주세요.
                </Text>
              </Box>
              <LargeButton buttonTitle='다음' onPress={handleMailCheck} loading={isLoading} fill />
            </Box>
          )}
        </Box>
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
