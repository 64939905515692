import { Box } from 'native-base'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import useDivision from '@/hooks/useDivision'
import { IBannerGroup } from '@/interfaces/banner'
import MainContBannerGrpListWeb from './MainContBannerGrpListWeb'

interface Props {
  mainContentsBannerList: IBannerGroup[]
}

export default function MainContentsBannerList(props: Props) {
  const { mainContentsBannerList } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const { handleDivision, handleBannerGroupDivision } = useDivision()

  return (
    <Box w={screenWidth} bg='#ffffff' pt='13px'>
      {handleBannerGroupDivision(mainContentsBannerList).map((item, index) => {
        const delay = index % 2 ? 7000 : 8000
        const bannerGroup = handleDivision(item.contents, 4)
        return (
          <MainContBannerGrpListWeb
            title={item.title}
            bannerGroup={bannerGroup}
            delay={delay}
            key={index}
          />
        )
      })}
    </Box>
  )
}
