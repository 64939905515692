import React from 'react'
import { Box, Center, HStack, Text, TextArea } from 'native-base'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { updateCommentByCommentForm } from '@/services/comment'

import CButton from '@/components/CButton'
import CreateModal from '@/components/CreateModal'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { IComment, ICommentForm } from '@/interfaces/comment'
import { RootStackParamList } from '@/interfaces/navigation'
import { Keyboard } from 'react-native'

interface Props {
  comment: IComment
  commentType: string
  typeId: number
  openUpdateCommentHandle: (val: boolean) => void
  reLoadCommentList: () => void
}

export default function UpdateCommentBox(props: Props) {
  const { comment, openUpdateCommentHandle, commentType, typeId, reLoadCommentList } = props
  const [auth] = useAtom(authAtom)
  const [content, setContent] = React.useState(comment.contents)
  const [contentLength, setContentLength] = React.useState(comment.contents.length)
  const [isOpenAlertNoContent, setIsOpenAlertNoContent] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const { handleSubmit, control } = useForm<ICommentForm>({
    defaultValues: {
      id: comment.id,
      writer: auth.user ? auth.user.id : 0,
      jwt: auth.jwt ? auth.jwt : '',
      type: commentType.toLowerCase(),
      type_id: typeId,
      contents: '',
    },
  })

  const handleContentLength = (length: number) => {
    setContentLength(length)
  }

  const handleContent = (value: string) => {
    if (value.length > 300) {
      value = value.substring(0, 300)
    }
    setContent(value)
    handleContentLength(value.length)
  }

  const onSubmit = async (commentForm: ICommentForm) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (contentLength === 0) {
      setIsOpenAlertNoContent(true)
      return
    }

    await updateCommentByCommentForm(commentForm)
    reLoadCommentList()
    setContent('')
    setContentLength(0)
    openUpdateCommentHandle(false)
    Keyboard.dismiss()
  }

  return (
    <Box borderWidth='1px' borderColor='#dedede' w='full' bgColor='#ffffff'>
      {isOpenAlertNoContent && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_NO_CONTENT}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => setIsOpenAlertNoContent(false)}
        />
      )}
      <Box w='full' flexDirection='row' ml='12px' mt='10px'>
        <Text>{contentLength}/300</Text>
      </Box>
      <Controller
        control={control}
        name='contents'
        rules={{ required: false }}
        render={({ field: { onChange } }) => (
          <TextArea
            autoCompleteType={undefined}
            placeholder='내용을 입력해주세요.'
            w='full'
            fontSize={16}
            variant='unstyled'
            maxLength={300}
            value={content}
            onChangeText={(value) => {
              onChange(value)
              handleContent(value)
            }}
            autoCapitalize='none'
            autoCorrect={false}
          />
        )}
      />
      <HStack borderTopWidth='1px' borderTopColor='#f4f4f4' justifyContent='flex-end' h='40px'>
        {/*<Text fontSize='11px' color='#ff6f1e' px='12px' py='14px' my='auto'>*/}
        {/*  이 댓글에 대한 법적 책임은 작성자에게 귀속됩니다.*/}
        {/*</Text>*/}
        <Box flexDirection='row'>
          <CButton my='auto' mr='7px' onPress={handleSubmit(onSubmit)}>
            <Center w='39px' h='25px' bgColor='#f4f4f4'>
              <Text fontSize='12px'>등록</Text>
            </Center>
          </CButton>
          <CButton my='auto' mr='7px'>
            <Center w='39px' h='25px' bgColor='#f4f4f4'>
              <Text fontSize='12px' onPress={() => openUpdateCommentHandle(false)}>
                취소
              </Text>
            </Center>
          </CButton>
        </Box>
      </HStack>
    </Box>
  )
}
