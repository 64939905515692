import { Linking, ScrollView } from 'react-native'
import { Box, HStack, Text } from 'native-base'
import Footer from '@/components/Footer'
import React from 'react'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'

export default function InquiryScreen() {
  const handlePress = async () => {
    await Linking.openURL('mailto:hanto8102@gmail.com')
  }

  return (
    <ScrollView
      contentContainerStyle={{ backgroundColor: '#f4f4f4' }}
      showsVerticalScrollIndicator={false}
    >
      <Box bgColor='#ffffff' py='50px' w='full'>
        <Box alignItems='center' mb='50px' px='20px'>
          <CButton onPress={handlePress} w={213} h={43} bgColor='#FF6F1E' mb='30px'>
            <Text color='white' fontSize={18} fontWeight={800} my='7px' textAlign='center'>
              제휴문의
            </Text>
          </CButton>
          <Text fontSize={18} textAlign='center'>
            한터는 원활한 비즈니스를 위한 다양한 사업 제휴의 기회를 제공하고 있습니다.
          </Text>
          <Text fontSize={18} textAlign='center'>
            한터와 함께 성장을 도모하고, 시너지 효과를 창출할 많은 기업들의 소중한 연락을
            기다립니다.
          </Text>
        </Box>
        <Box alignItems='center' mb='50px' px='20px'>
          <CButton onPress={handlePress} w={213} h={43} bgColor='#FF6F1E' mb='30px'>
            <Text color='white' fontSize={18} fontWeight={800} my='7px' textAlign='center'>
              광고문의
            </Text>
          </CButton>
          <Text fontSize={18} textAlign='center'>
            배너광고 상담 신청을 하시면, 담당자가 연락을 드려 상담해 드립니다.
          </Text>
          <Text fontSize={18} textAlign='center'>
            광고금액 및 자세한 내용을 직접문의 하실분은 아래 연락처로 문의해 주시기 바랍니다.
          </Text>
        </Box>
        <Box alignItems='center' borderWidth={1} borderStyle='dashed'>
          <Box py='20px'>
            <Text fontSize={20}>TEL 03-6205-5318</Text>
            <Text fontSize={20}>E-Mail hanto8102@gmail.com</Text>
          </Box>
        </Box>
        <HStack justifyContent='center' my='18px'>
          <FastImage
            source={require('@/assets/images/line-logo.png')}
            style={{
              width: 53,
              height: 53,
              marginRight: 8,
            }}
          />
          <FastImage
            source={require('@/assets/images/kakao-logo.png')}
            style={{
              width: 53,
              height: 53,
              marginRight: 15,
            }}
          />
          <Text fontSize={24} fontWeight={700}>
            hanto8102
          </Text>
        </HStack>
      </Box>
      <Footer />
    </ScrollView>
  )
}
