import React from 'react'
import { RefreshControl } from 'react-native'
import { useFocusEffect, useScrollToTop } from '@react-navigation/native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import { useAtom } from 'jotai'
import { 
  // findAllKyodoNews, 
  findAllYonhapNews 
} from '@/services/news'
import { 
  // kyodoNewsListAtom, 
  yonhapNewsListAtom 
} from '@/atom/initAtom'
import Footer from '@/components/Footer'
import { LIMIT } from '@/constants/limit'
import { TOP_TAB } from '@/constants/label'
import { ICuration, IYonhapNews } from '@/interfaces/news'
import { TopTabParamList } from '@/interfaces/navigation'
import useScroll from '@/hooks/useScroll'
import NewsDetailYonhapNewsList from '../detail/components/NewsDetail/NewsDetailYonhapNewsList'
// import NewsDetailKyodoNewsList from '../detail/components/NewsDetail/NewsDetailKyodoNewsList'

type Props = NativeStackScreenProps<TopTabParamList, TOP_TAB.NEWS>

export default function NewsScreen(props: Props) {
  const { navigation } = props
  const isScrollToKyodoNews = props.route.params ? props.route.params.isScrollToKyodoNews : false
  const [initYonhapList] = useAtom(yonhapNewsListAtom)
  // const [initKyodoList] = useAtom(kyodoNewsListAtom)
  const [yonhapList, setYonhapList] = React.useState<IYonhapNews[]>([])
  const [kyodoList, setKyodoList] = React.useState<ICuration[]>([])
  const [yonhapLimit, setYonhapLimit] = React.useState(LIMIT.YONHAP_NEWS_LIMIT)
  // const [kyodoLimit, setKyodoLimit] = React.useState(LIMIT.KYODO_NEWS_LIMIT)
  const [refreshing, setRefreshing] = React.useState(false)
  const [dataSourceCords, setDataSourceCords] = React.useState(0)

  const { scrollHandler } = useScroll()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  const handleDataSourceCords = (scrollY: number) => {
    setDataSourceCords(scrollY)
  }

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    await Promise.all([
      findAllYonhapNews(LIMIT.YONHAP_NEWS_LIMIT),
      // findAllKyodoNews(LIMIT.KYODO_NEWS_LIMIT),
    ]).then((values) => {
      setYonhapLimit(LIMIT.YONHAP_NEWS_LIMIT)
      // setKyodoLimit(LIMIT.KYODO_NEWS_LIMIT)
      setYonhapList(values[0])
      // setKyodoList(values[1])
    })
    await setRefreshing(false)
  }, [])

  const onMoreYonhap = React.useCallback(async () => {
    const newLimit = yonhapLimit + LIMIT.YONHAP_NEWS_LIMIT
    await findAllYonhapNews(newLimit).then((data) => {
      setYonhapList(data)
      setYonhapLimit(newLimit)
    })
  }, [yonhapLimit])

  // const onMoreKyodo = React.useCallback(async () => {
  //   const newLimit = kyodoLimit + LIMIT.KYODO_NEWS_LIMIT
  //   await findAllKyodoNews(newLimit).then((data) => {
  //     setKyodoList(data)
  //     setKyodoLimit(newLimit)
  //   })
  // }, [kyodoLimit])

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (navigation.getState().index !== 1) {
        scrollRef.current.scrollTo({ x: 0, y: 0, animated: false })
      }
    })

    return unsubscribe
  }, [navigation])

  React.useEffect(() => {
    setYonhapList(initYonhapList)
    // setKyodoList(initKyodoList)
  }, [initYonhapList])

  useFocusEffect(
    React.useCallback(() => {
      if (isScrollToKyodoNews && scrollRef.current) {
        scrollRef.current.scrollTo({ x: 0, y: dataSourceCords, animated: false })
      }
    }, [isScrollToKyodoNews]),
  )

  return (
    <Animated.ScrollView
      ref={scrollRef}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      showsVerticalScrollIndicator={false}
      scrollEventThrottle={16} // 1 or 16
      onScroll={scrollHandler}
    >
      <NewsDetailYonhapNewsList newsList={yonhapList} onMore={onMoreYonhap} />
      {/* <NewsDetailKyodoNewsList
        newsList={kyodoList}
        onMore={onMoreKyodo}
        handleDataSourceCords={handleDataSourceCords}
      /> */}
      <Footer />
    </Animated.ScrollView>
  )
}
