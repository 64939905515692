import React from 'react'
import { Box, HStack, Spinner, Text } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'

import { useAtom } from 'jotai'
import { handleSearch, handleSearchResultClear, searchBannerListAtom } from '@/atom/searchAtom'

// import useScroll from '@/hooks/useScroll'
import { SearchHeader, SearchResultBannerItem } from '@/screens/search/components'
import MoreTab from '@/components/MoreTab'
import { LIMIT } from '@/constants/limit'
import { SEARCH_STACK } from '@/constants/label'
import { SearchStackParamList } from '@/interfaces/navigation'

type Props = NativeStackScreenProps<SearchStackParamList, SEARCH_STACK.SEARCH_BANNER>

export default function SearchBannerScreen({ route }: Props) {
  const { keyword } = route.params
  const [, search] = useAtom(handleSearch)
  const [, searchResultClear] = useAtom(handleSearchResultClear)
  const [searchBannerList] = useAtom(searchBannerListAtom)
  const [searchBannerLimit, setSearchBannerLimit] = React.useState(LIMIT.SEARCH_BANNER_LIST_LIMIT)
  const [isLoading, setLoading] = React.useState(false)

  // const { scrollHandler } = useScroll()

  const handleMore = React.useCallback(async () => {
    const newLimit = searchBannerLimit + LIMIT.SEARCH_BANNER_LIST_LIMIT
    await search({
      keyword,
      start: 0,
      limit: newLimit,
    })
    await setSearchBannerLimit(newLimit)
  }, [searchBannerLimit, keyword, search])

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await searchResultClear()
      await search({
        keyword,
        start: 0,
        limit: LIMIT.SEARCH_BANNER_LIST_LIMIT,
      })
      await setLoading(false)
    }
    void fetchData()
  }, [keyword, search, searchResultClear])

  return (
    <React.Fragment>
      <SearchHeader />
      {isLoading ? (
        <Box flex={1} justifyContent='center'>
          <Spinner color='#ff6f1e' />
        </Box>
      ) : !isLoading && searchBannerList ? (
        <Animated.FlatList
          data={searchBannerList.contents}
          numColumns={2}
          keyExtractor={(item) => item.id.toString()}
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 100, marginVertical: 15 }}
          columnWrapperStyle={{
            paddingTop: 17,
            paddingLeft: 15,
            paddingRight: 15,
            justifyContent: 'space-between',
          }}
          renderItem={({ item }) => {
            return <SearchResultBannerItem item={item} />
          }}
          ListHeaderComponent={() => {
            return (
              <HStack px='15px'>
                <Text fontWeight='bold'>'{keyword}'</Text>
                <Text> 에 대한 게시글 검색결과</Text>
              </HStack>
            )
          }}
          ListFooterComponent={() => {
            return searchBannerList.totalCount > searchBannerList.contents.length ? (
              <Box mt='17px' borderTopWidth='1px' borderTopColor='#f4f4f4'>
                <MoreTab
                  moreButtonTextColor='스폰서'
                  moreButtonText='더보기'
                  moreButtonOnPress={handleMore}
                  useSideButton={false}
                />
              </Box>
            ) : null
          }}
          showsVerticalScrollIndicator={false}
          // scrollEventThrottle={16}
          // onScroll={scrollHandler}
        />
      ) : null}
    </React.Fragment>
  )
}
