import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import CButton from '@/components/CButton'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import FastImage from '@/components/FastImage'

interface Props {
  navigation: any
  color: 'BLACK' | 'WHITE'
  onPressType?: string
}

export default function BackButton(props: Props) {
  const { navigation, color, onPressType } = props
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <CButton
      onPress={() => {
        if (onPressType === 'SEARCH') {
          navigation.popToTop()
          navigation.goBack()
          // navigation.goBack()
          // if (navigation.canGoBack()) navigation.goBack()
        } else if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          rootNavigation.navigate(ROOT_STACK.TOP_TAB, {
            screen: TOP_TAB.MAIN,
          })
        }
      }}
    >
      <FastImage
        source={
          color === 'WHITE'
            ? require('@/assets/images/white-back-icon.png')
            : require('@/assets/images/black-back-icon.png')
        }
        style={{
          width: 24,
          height: 24,
        }}
        resizeMode='contain'
      />
    </CButton>
  )
}
