import { Center, Spinner, Text } from 'native-base'

import CButton from '@/components/CButton'

interface Props {
  buttonTitle: string
  fill?: boolean
  onPress?: () => void
  loading?: boolean
}

export default function LargeButton(props: Props) {
  const { buttonTitle, fill, onPress, loading } = props
  return (
    <CButton onPress={onPress}>
      <Center
        h='48px'
        bgColor={fill ? '#ff6f1e' : '#ffffff'}
        borderColor='#ff6f1e'
        borderWidth='1px'
        borderRadius='24px'
        mb='10px'
      >
        {loading ? (
          <Spinner color={fill ? '#ffffff' : '#ff6f1e'} />
        ) : (
          <Text fontSize='20px' color={fill ? '#ffffff' : '#ff6f1e'} fontWeight={700}>
            {buttonTitle}
          </Text>
        )}
      </Center>
    </CButton>
  )
}
