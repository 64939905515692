import React from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import MainScreen from '@/screens/main/MainScreen'
import NewsScreen from '@/screens/news/NewsScreen'
import BoardListJobScreen from '@/screens/boards/BoardListJobScreen'
import BoardListPropertyScreen from '@/screens/boards/BoardListPropertyScreen'
import BoardListShoppingScreen from '@/screens/boards/BoardListShoppingScreen'
import BoardListServiceScreen from '@/screens/boards/BoardListServiceScreen'
import BoardListEducationScreen from '@/screens/boards/BoardListEducationScreen'
import BoardListTripScreen from '@/screens/boards/BoardListTripScreen'
import BoardListMedicalScreen from '@/screens/boards/BoardListMedicalScreen'
import BoardListLawScreen from '@/screens/boards/BoardListLawScreen'
import BoardListClubScreen from '@/screens/boards/BoardListClubScreen'
import BoardListAddressScreen from '@/screens/boards/BoardListAddressScreen'
import LoggedAddressScreen from '@/screens/boards/LoggedAddressScreen'
import TopTabHeader from '@/navigation/components/TopTabHeader'
import { TOP_TAB } from '@/constants/label'
import { TopTabParamList } from '@/interfaces/navigation'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import FabBottomMain from './components/FabBottomMain'

const TopTab = createMaterialTopTabNavigator<TopTabParamList>()

export default function TopTabNavigator() {
  const [auth] = useAtom(authAtom)

  return (
    <React.Fragment>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <TopTabHeader />
        <TopTab.Navigator
          initialRouteName={TOP_TAB.MAIN}
          backBehavior='none'
          screenOptions={{
            tabBarActiveTintColor: '#FF6F1E',
            tabBarInactiveTintColor: '#282828',
            tabBarScrollEnabled: true,
            tabBarStyle: {
              backgroundColor: '#FFFFFF',
            },
            tabBarItemStyle: {
              width: 'auto',
            },
            tabBarLabelStyle: {
              fontSize: 16,
              fontWeight: 'bold',
            },
            tabBarIndicatorStyle: {
              backgroundColor: '#FF6F1E',
              right: '30%',
            },
          }}
        >
          <TopTab.Screen name={TOP_TAB.MAIN} component={MainScreen} options={{ title: '홈' }} />
          <TopTab.Screen name={TOP_TAB.NEWS} component={NewsScreen} options={{ title: '뉴스' }} />
          <TopTab.Screen
            name={TOP_TAB.JOB}
            component={BoardListJobScreen}
            options={{ title: '구인구직' }}
          />
          <TopTab.Screen
            name={TOP_TAB.PROPERTY}
            component={BoardListPropertyScreen}
            options={{ title: '부동산' }}
          />
          <TopTab.Screen
            name={TOP_TAB.SHOPPING}
            component={BoardListShoppingScreen}
            options={{ title: '쇼핑' }}
          />
          <TopTab.Screen
            name={TOP_TAB.SERVICE}
            component={BoardListServiceScreen}
            options={{ title: '서비스' }}
          />
          <TopTab.Screen
            name={TOP_TAB.EDUCATION}
            component={BoardListEducationScreen}
            options={{ title: '교육' }}
          />
          <TopTab.Screen
            name={TOP_TAB.TRIP}
            component={BoardListTripScreen}
            options={{ title: '여행・여가' }}
          />
          <TopTab.Screen
            name={TOP_TAB.MEDICAL}
            component={BoardListMedicalScreen}
            options={{ title: '의료・미용' }}
          />
          <TopTab.Screen
            name={TOP_TAB.LAW}
            component={BoardListLawScreen}
            options={{ title: '법률・금융' }}
          />
          <TopTab.Screen
            name={TOP_TAB.CLUB}
            component={BoardListClubScreen}
            options={{ title: '단체・동호회' }}
          />
          {auth && auth.logged ? (
            <TopTab.Screen
              name={TOP_TAB.ADDRESS}
              component={LoggedAddressScreen}
              options={{ title: '주소록' }}
            />
          ) : (
            <TopTab.Screen
              name={TOP_TAB.ADDRESS}
              component={BoardListAddressScreen}
              options={{ title: '주소록' }}
            />
          )}
        </TopTab.Navigator>
        <FabBottomMain />
      </GestureHandlerRootView>
    </React.Fragment>
  )
}
