import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Box, HStack, Image, Pressable, Text, View } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { AUTH_STACK, ROOT_STACK, SEARCH_STACK, TOP_TAB } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import LanguageHeaderItem from '@/components/LanguageHeaderItem'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'
import SubscriptButton from '@/screens/auth/components/SubscriptButton'
import MypageButton from '@/screens/auth/components/MypageButton'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import CButton from '@/components/CButton'

export default function TopTabHeader() {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth, setAuth] = useAtom(authAtom)

  return (
    <HStack justifyContent='space-between' py='11px' px='17px' bg='#ffffff'>
      <TouchableOpacity
        onPress={() => navigation.navigate(ROOT_STACK.TOP_TAB, { screen: TOP_TAB.MAIN })}
      >
        <Image
          source={require('@/assets/images/logo.png')}
          style={{ width: 57, height: 34 }}
          resizeMode='contain'
          alt='hanteo'
        />
      </TouchableOpacity>
      <HStack alignItems='center'>
        <LanguageHeaderItem />
        <Pressable
          mx='20px'
          my='auto'
          borderRadius='4px'
          onPress={() =>
            navigation.navigate(ROOT_STACK.SEARCH_STACK, {
              screen: SEARCH_STACK.SEARCH_HISTORY,
            })
          }
        >
          <DynamicSvgIcon
            width='24px'
            height='24px'
            src={require('@/assets/svg/search-icon.svg')}
          />
        </Pressable>
        {auth.logged ? (
          <HStack>
            <Box mr='15px'>
              <MypageButton navigation={navigation} />
            </Box>
            <Box>
              <SubscriptButton navigation={navigation} />
            </Box>
          </HStack>
        ) : (
          <HStack>
            <CButton
              justifyContent='center'
              alignItems='center'
              bgColor='#EAEAEA'
              borderRadius='20px'
              mr='8px'
              px='10px'
              py='4px'
              onPress={() =>
                navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_AGREE_TO_TERMS })
              }
            >
              <Text
                mt='2px'
                fontSize='15px'
                color='#292929'
                fontWeight={700}
                numberOfLines={1}
                w='auto'
              >
                {'회원가입'}
              </Text>
            </CButton>
            <CButton
              justifyContent='center'
              alignItems='center'
              bgColor='#EAEAEA'
              borderRadius='20px'
              px='10px'
              py='4px'
              onPress={() =>
                navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
              }
            >
              <Text
                mt='2px'
                fontSize='15px'
                color='#292929'
                fontWeight={700}
                numberOfLines={1}
                w='auto'
              >
                {'로그인'}
              </Text>
            </CButton>
          </HStack>
        )}
      </HStack>
    </HStack>
  )
}
