import React from 'react'
import { TouchableHighlight } from 'react-native'
import { Box, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { globalKeywordAtom, handleRemove } from '@/atom/searchAtom'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { SEARCH_STACK } from '@/constants/label'
import { ISearchHistory } from '@/interfaces/search'
import { SearchStackParamList } from '@/interfaces/navigation'

interface Props {
  item: ISearchHistory
}

export default function SearchHistoryItem(props: Props) {
  const { item } = props
  const navigation = useNavigation<NativeStackNavigationProp<SearchStackParamList>>()
  const [, setGlobalKeyword] = useAtom(globalKeywordAtom)

  const [, remove] = useAtom(handleRemove)

  const handleSearch = () => {
    if (item.keyword.length > 0) {
      setGlobalKeyword(item.keyword)
      navigation.push(SEARCH_STACK.SEARCH_PREVIEW, { keyword: item.keyword })
    }
  }

  return (
    <TouchableHighlight onPress={handleSearch} underlayColor='#e9e9e9'>
      <Box px='16px'>
        <HStack
          pt='16px'
          pb='16px'
          justifyContent='space-between'
          borderBottomWidth={0.5}
          borderBottomColor='#f1f1f1'
        >
          <Text fontSize='16px' color='#000000'>
            {item.keyword}
          </Text>
          <HStack>
            <Text mt='3px' fontSize='15px' color='#7C7C7C'>
              {item.date}
            </Text>
            <CButton mt='auto' mb='2px' ml='18px' onPress={() => remove(item.id)}>
              <FastImage
                source={require('@/assets/images/delete.png')}
                style={{
                  width: 24,
                  height: 24,
                }}
                resizeMode='contain'
              />
            </CButton>
          </HStack>
        </HStack>
      </Box>
    </TouchableHighlight>
  )
}
