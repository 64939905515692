import { Linking, Modal, SafeAreaView, StyleSheet } from 'react-native'
import { Box, Pressable } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'
import React, { useRef } from 'react'
import ImageViewer from 'react-native-image-zoom-viewer'
import useUrl from '@/hooks/useUrl'
import ImageModalHeader from '@/components/ImageModalHeader'
import { IBoard } from '@/interfaces/board'
import { authAtom } from '@/atom/authAtom'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import SiteButton from '@/screens/auth/components/SiteButton'
import { IFindCommentPushAgreeForm, ISendSubscriptPushForm } from '@/interfaces/push'
import { sendBoardAgree, sendBoardDisagree } from '@/services/pushAgreeService'
import FabBottomBanner from '@/navigation/components/FabBottomBanner'
import BannerDetailCenter from '../BannerDetailCenter'
import BannerDetailBottom from '../BannerDetailBottom'
import HTMLView from 'react-native-htmlview'

interface Props {
  list: any[] | IBoard[]
  banner: IBanner
  subscript: IFindCommentPushAgreeForm | null
  onMore: () => void
  sum: number
  boarderLimit: number
  scrollRef: (scrollY: number) => void
  isScroll: boolean
}

export default function BannerDetail(props: Props) {
  const { list, banner, subscript, onMore, sum, boarderLimit, scrollRef, isScroll } = props
  const [isOpenImageModal, setOpenImageModal] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { getUrlListByContents } = useUrl()
  const [auth] = useAtom(authAtom)
  const targetTextRef = useRef<any>() // 딱 하나의 텍스트를 가리킬 Ref
  const [sub, setSub] = React.useState(subscript?.id)

  const renderNode = (node: any, index: any, siblings: any, parent: any, defaultRenderer: any) => {
    if (node.name == 'img') {
      const a = node
      return (
        <Pressable onPress={() => setOpenImageModal(true)}>
          <img style={{ width: '100%' }} src={a.attribs.src} alt='이미지' draggable='false' />
        </Pressable>
      )
    }
  }

  const styles = StyleSheet.create({
    span: {
      lineHeight: 28,
      fontSize: 17,
    },
    a: {
      lineHeight: 28,
      fontSize: 17,
      textDecorationLine: 'underline', // 추가된 부분
      color: 'blue', // 추가된 부분
    },
    p: {
      lineHeight: 28,
      fontSize: 17,
    },
  })

  // 특정 텍스트 컴포넌트의 y 좌표를 얻기 위한 함수
  const getYCoordinateOfTargetText = () => {
    targetTextRef.current?.measure(
      (x: any, y: any, width: any, height: any, pageX: any, pageY: any) => {
        scrollRef(pageY - 95)
      },
    )
  }

  React.useEffect(() => {
    // 3초 후에 실행할 코드
    const timer = setTimeout(() => {
      if (isScroll) {
        getYCoordinateOfTargetText()
      }
    }, 1500)
    return () => {
      clearTimeout(timer)
    }
  }, [banner])

  return (
    <Box>
      <Modal
        visible={isOpenImageModal}
        transparent={false}
        onRequestClose={() => setOpenImageModal(false)}
      >
        <SafeAreaView style={{ flex: 1, backgroundColor: '#000000' }}>
          <ImageViewer
            imageUrls={getUrlListByContents(banner.contents)}
            renderHeader={() => (
              <ImageModalHeader key={'imagekey'} onClose={() => setOpenImageModal(false)} />
            )}
          />
        </SafeAreaView>
      </Modal>
      <Box mx='16px' mt='16px' mb='8px'>
        <HTMLView value={banner.contents} renderNode={renderNode} stylesheet={styles} />
      </Box>
      {banner.site_url && banner.site_url != '' ? (
        <SiteButton
          buttonTitle='사이트 바로가기'
          fill
          onPress={() => (banner.site_url ? Linking.openURL(banner.site_url) : null)}
        />
      ) : null}
      <BannerDetailCenter banner={banner} subscript={subscript} />
      <BannerDetailBottom
        list={list}
        banner={banner}
        onMore={onMore}
        sum={sum}
        boarderLimit={boarderLimit}
      />
      <FabBottomBanner banner={banner} />
    </Box>
  )
}
