import { createNativeStackNavigator } from '@react-navigation/native-stack'

import SearchHistoryScreen from '@/screens/search/SearchHistoryScreen'
import SearchPreviewScreen from '@/screens/search/SearchPreviewScreen'
import SearchNewsScreen from '@/screens/search/SearchNewsScreen'
import SearchBoardScreen from '@/screens/search/SearchBoardScreen'
import SearchBannerScreen from '@/screens/search/SearchBannerScreen'
import { SEARCH_STACK } from '@/constants/label'
import { SearchStackParamList } from '@/interfaces/navigation'

const Stack = createNativeStackNavigator<SearchStackParamList>()

export default function SearchNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={SEARCH_STACK.SEARCH_HISTORY}
      screenOptions={{
        headerShown: false,
        contentStyle: {
          backgroundColor: '#ffffff',
        },
      }}
    >
      <Stack.Screen name={SEARCH_STACK.SEARCH_HISTORY} component={SearchHistoryScreen} />
      <Stack.Screen name={SEARCH_STACK.SEARCH_PREVIEW} component={SearchPreviewScreen} />
      <Stack.Screen name={SEARCH_STACK.SEARCH_NEWS} component={SearchNewsScreen} />
      <Stack.Screen name={SEARCH_STACK.SEARCH_BOARD} component={SearchBoardScreen} />
      <Stack.Screen name={SEARCH_STACK.SEARCH_BANNER} component={SearchBannerScreen} />
    </Stack.Navigator>
  )
}
