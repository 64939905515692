import { atom } from 'jotai'
import moment from 'moment/moment'
import {
  myPageBoardListAtom,
  myPageBoardTotalCountAtom,
  myPageCommentListAtom,
  myPageCommentTotalCountAtom,
  myPageGoodListAtom,
  myPageGoodTotalCountAtom,
  myPageMessageFromListAtom,
  myPageMessageFromTotalCountAtom,
  myPageMessageKeepListAtom,
  myPageMessageKeepTotalCountAtom,
  myPageMessageToListAtom,
  myPageMessageToTotalCountAtom,
  myPageScrapListAtom,
  myPageScrapTotalCountAtom,
  myPageMemberSubListAtom,
  myPageMemberSubTotalCountAtom,
  myPageBoardSubListAtom,
  myPageBoardSubTotalCountAtom,
} from '@/atom/initAtom'
import * as AuthService from '@/services/auth'
import * as UserContentsService from '@/services/userContents'
import useToken from '@/hooks/useToken'
import { AUTH_STATUS } from '@/constants/auth'
import { IAuth, ILogin, ILoginResponse, IRegister, IUser, ISocialAccess } from '@/interfaces/auth'
import { LIMIT } from '@/constants/limit'
import { reloadNoReadMessageCountAtom, reloadReadStateAtom } from '@/atom/navigationAtom'

export const authAtom = atom<IAuth>({
  logged: false,
  jwt: '',
  user: null,
})

authAtom.onMount = (setAtom) => {
  const auth = localStorage.getItem('auth')
  setAtom(JSON.parse(auth as string))
}

export const profileAtom = atom<IUser | null>(null)
export const authStatusAtom = atom<AUTH_STATUS>(AUTH_STATUS.INIT)

export const handleLogin = atom(null, async (get, set, params: ILogin) => {
  try {
    set(authStatusAtom, AUTH_STATUS.INIT)
    const { jwt, user }: ILoginResponse = await AuthService.loginAuth(params)
    set(profileAtom, user)
    if (user.confirmed) {
      if (user.nick_name) {
        set(profileAtom, user)
        const today = new Date()
        const checkAttendance = moment(user.attendance).format('YYYY-MM-DD')
        const checkToday = moment(today).format('YYYY-MM-DD')
        if (checkAttendance !== checkToday) {
          const newUserData = await AuthService.updateMeAttendance({ jwt, attendance: today })
          await AuthService.updateVisitorCount(newUserData.id)
          await set(authAtom, { logged: true, jwt: jwt, user: newUserData })
          await localStorage.setItem(
            'auth',
            JSON.stringify({ logged: true, jwt: jwt, user: newUserData }),
          )
        } else {
          await set(authAtom, { logged: true, jwt: jwt, user: user })
          await localStorage.setItem('auth', JSON.stringify({ logged: true, jwt: jwt, user: user }))
        }
        set(profileAtom, null)
        const myPageArg = { jwt, limit: LIMIT.MY_PAGE_LIST }

        await Promise.all([
          UserContentsService.findAllBoardByMeAuth(myPageArg),
          UserContentsService.findAllCommentByMeAuth(myPageArg),
          UserContentsService.findAllGoodByMeAuth(myPageArg),
          UserContentsService.findAllScrapByMeAuth(myPageArg),
          UserContentsService.findAllMessageBoardByMe(myPageArg),
          UserContentsService.findAllMemberSubByMeAuth(myPageArg),
          UserContentsService.findAllBoardSubByMeAuth(myPageArg),
          UserContentsService.findNoReadMessageCountByUserId({ id: user.id, jwt: jwt }),
          // UserContentsService.findSubscriptionCount(jwt),
        ]).then((values) => {
          set(myPageBoardListAtom, values[0].contents)
          set(myPageBoardTotalCountAtom, values[0].totalCount)
          set(myPageCommentListAtom, values[1].contents)
          set(myPageCommentTotalCountAtom, values[1].totalCount)
          set(myPageGoodListAtom, values[2].contents)
          set(myPageGoodTotalCountAtom, values[2].totalCount)
          set(myPageScrapListAtom, values[3].contents)
          set(myPageScrapTotalCountAtom, values[3].totalCount)
          set(myPageMessageFromListAtom, values[4].from.contents)
          set(myPageMessageFromTotalCountAtom, values[4].from.totalCount)
          set(myPageMessageToListAtom, values[4].to.contents)
          set(myPageMessageToTotalCountAtom, values[4].to.totalCount)
          set(myPageMessageKeepListAtom, values[4].keep.contents)
          set(myPageMessageKeepTotalCountAtom, values[4].keep.totalCount)
          set(myPageMemberSubListAtom, values[5].contents)
          set(myPageMemberSubTotalCountAtom, values[5].totalCount)
          set(myPageBoardSubListAtom, values[6].contents)
          set(myPageBoardSubTotalCountAtom, values[6].totalCount)
          set(reloadNoReadMessageCountAtom, values[7])
          // set(reloadReadStateAtom, values[8])
        })
        set(authStatusAtom, AUTH_STATUS.LOGIN_SUCCESS)
      } else {
        set(profileAtom, user)
        set(authStatusAtom, AUTH_STATUS.NO_PROFILE)
      }
    } else {
      try {
        await AuthService.reSendConfirmEmailAuth(user.email)
        set(authStatusAtom, AUTH_STATUS.RESEND_SUCCESS)
        set(profileAtom, user)
      } catch (error) {
        set(authStatusAtom, AUTH_STATUS.SERVER_ERROR)
        set(profileAtom, null)
      }
    }
  } catch (error) {
    const profile = get(profileAtom)
    if (profile?.provider === 'google' || profile?.provider === 'facebook') {
      set(authStatusAtom, AUTH_STATUS.PROVIDER_FAILED)
      set(profileAtom, null)
    } else {
      set(authStatusAtom, AUTH_STATUS.LOGIN_FAILED)
      set(profileAtom, null)
    }
  }
})

export const handleSocialLogin = atom(null, async (_, set, { token, provider }: ISocialAccess) => {
  const { createToken } = useToken()

  try {
    set(authStatusAtom, AUTH_STATUS.INIT)
    const { jwt, user }: ILoginResponse = await AuthService.socialLoginAuth({ token, provider })
    if (user.nick_name) {
      const today = new Date()
      const checkAttendance = moment(user.attendance).format('YYYY-MM-DD')
      const checkToday = moment(today).format('YYYY-MM-DD')
      if (checkAttendance !== checkToday) {
        const newUserData = await AuthService.updateMeAttendance({ jwt, attendance: today })
        await AuthService.updateVisitorCount(newUserData.id)
        set(authAtom, { logged: true, jwt: jwt, user: newUserData })
        await localStorage.setItem(
          'auth',
          JSON.stringify({ logged: true, jwt: jwt, user: newUserData }),
        )
      } else {
        set(authAtom, { logged: true, jwt: jwt, user: user })
        await localStorage.setItem('auth', JSON.stringify({ logged: true, jwt: jwt, user: user }))
      }
      const myPageArg = {
        jwt,
        limit: LIMIT.MY_PAGE_LIST,
      }

      await Promise.all([
        UserContentsService.findAllBoardByMeAuth(myPageArg),
        UserContentsService.findAllCommentByMeAuth(myPageArg),
        UserContentsService.findAllGoodByMeAuth(myPageArg),
        UserContentsService.findAllScrapByMeAuth(myPageArg),
        UserContentsService.findAllMessageBoardByMe(myPageArg),
        UserContentsService.findAllMemberSubByMeAuth(myPageArg),
        UserContentsService.findAllBoardSubByMeAuth(myPageArg),
        UserContentsService.findNoReadMessageCountByUserId({ id: user.id, jwt: jwt }),
        // UserContentsService.findSubscriptionCount(jwt),
      ]).then((values) => {
        set(myPageBoardListAtom, values[0].contents)
        set(myPageBoardTotalCountAtom, values[0].totalCount)
        set(myPageCommentListAtom, values[1].contents)
        set(myPageCommentTotalCountAtom, values[1].totalCount)
        set(myPageGoodListAtom, values[2].contents)
        set(myPageGoodTotalCountAtom, values[2].totalCount)
        set(myPageScrapListAtom, values[3].contents)
        set(myPageScrapTotalCountAtom, values[3].totalCount)
        set(myPageMessageFromListAtom, values[4].from.contents)
        set(myPageMessageFromTotalCountAtom, values[4].from.totalCount)
        set(myPageMessageToListAtom, values[4].to.contents)
        set(myPageMessageToTotalCountAtom, values[4].to.totalCount)
        set(myPageMessageKeepListAtom, values[4].keep.contents)
        set(myPageMessageKeepTotalCountAtom, values[4].keep.totalCount)
        set(myPageMemberSubListAtom, values[5].contents)
        set(myPageMemberSubTotalCountAtom, values[5].totalCount)
        set(myPageBoardSubListAtom, values[6].contents)
        set(myPageBoardSubTotalCountAtom, values[6].totalCount)
        set(reloadNoReadMessageCountAtom, values[7])
        // set(reloadReadStateAtom, values[8])
      })
      set(authStatusAtom, AUTH_STATUS.LOGIN_SUCCESS)
      await createToken(user.id)
    } else {
      set(profileAtom, user)
      set(authStatusAtom, AUTH_STATUS.NO_PROFILE)
    }
  } catch (error) {
    set(authStatusAtom, AUTH_STATUS.SOCIAL_LOGIN_FAILED)
  }
})

export const handleLogout = atom(null, async (_, set) => {
  set(authAtom, { logged: false, jwt: '', user: null })
  await localStorage.setItem('auth', JSON.stringify({ logged: false, jwt: '', user: null }))
  set(profileAtom, null)
  set(authStatusAtom, AUTH_STATUS.INIT)
})

export const handleRegisterAtom = atom(null, async (_, set, params: IRegister) => {
  try {
    set(authStatusAtom, AUTH_STATUS.INIT)
    const profile = await AuthService.confirmCheckByEmailAuth(params.email)
    //이미 등록한 정보가 있을 경우
    if (profile) {
      if (profile.provider === 'local') {
        //카카오나 네이버는 인증까지 하고
        set(authStatusAtom, AUTH_STATUS.ALREADY_LOCAL_REGISTER)
      } else {
        set(authStatusAtom, AUTH_STATUS.ALREADY_SOCIAL_REGISTER)
      }
    } else {
      // 등록한 정보가 없을 경우 [회원등록]
      try{
        if (
          params.username.includes('kakao') ||
          params.username.includes('naver') ||
          params.email.includes('apple')
        ) {
          const { user } = await AuthService.registerAuth(params)
          const { jwt } = await AuthService.loginAdminAuth()
          await AuthService.confirmedSocial(jwt, user.id)
          set(profileAtom, user)
          set(authStatusAtom, AUTH_STATUS.NO_PROFILE)
        } else {
          const { user } = await AuthService.registerAuth(params)
          set(profileAtom, user)
          set(authStatusAtom, AUTH_STATUS.REGISTER_SUCCESS)
        }
      } catch (error) {
        //인증 에러로 임시로 추가함
        const login: ILogin = {
          identifier: params.email,
          password: params.password,
        }
        const { user }: ILoginResponse = await AuthService.loginAuth(login)
        set(profileAtom, user)
        set(authStatusAtom, AUTH_STATUS.NO_PROFILE)
      }
    }
  } catch (error) {
    set(profileAtom, null)
    set(authStatusAtom, AUTH_STATUS.SERVER_ERROR)
  }
})

export const handleConfirmCheck = atom(null, async (_, set, email: string) => {
  try {
    set(authStatusAtom, AUTH_STATUS.INIT)
    const { confirmed } = await AuthService.confirmCheckByEmailAuth(email)
    if (confirmed) {
      set(authStatusAtom, AUTH_STATUS.AUTHENTICATION_SUCCESS)
    } else {
      set(authStatusAtom, AUTH_STATUS.AUTHENTICATION_FAILED)
    }
  } catch (error) {
    set(authStatusAtom, AUTH_STATUS.SERVER_ERROR)
  }
})

export const handleUpdateProfile = atom(null, async (_, set, params: IUser) => {
  try {
    set(authStatusAtom, AUTH_STATUS.INIT)
    const profile: IUser = await AuthService.updateProfileAuth(params)
    set(profileAtom, profile)
    set(authStatusAtom, AUTH_STATUS.UPDATE_SUCCESS)
  } catch (error) {
    set(profileAtom, null)
    set(authStatusAtom, AUTH_STATUS.SERVER_ERROR)
  }
})

export const handleChangeProfile = atom(null, async (_, set, params: IUser) => {
  try {
    //user info change
    const getAuthItem = await localStorage.getItem('auth')
    const getUser = JSON.parse(getAuthItem as string)
    const { jwt, user } = getUser
    user.nick_name = params.nick_name
    await localStorage.setItem('auth', JSON.stringify({ logged: true, jwt, user: user }))

    //db change
    const profile: IUser = await AuthService.updateProfileAuth(params)
    set(authAtom, { logged: true, jwt, user })
    set(profileAtom, profile)
  } catch (error) {
    set(profileAtom, null)
  }
})
