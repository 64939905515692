import { atom } from 'jotai'
import { ICuration, IYonhapNews } from '@/interfaces/news'
import { IBanner, IBannerGroup } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'
import { IAddressBook } from '@/interfaces/address'
import {
  IReadState,
  IUserContentsBoardSubscription,
  IUserContentsComment,
  IUserContentsGood,
  IUserContentsMemberSubscription,
  IUserContentsMessageBoard,
  IUserContentsScrap,
} from '@/interfaces/userContents'
import { IPopupState } from '@/interfaces/popup'

export const yonhapNewsListAtom = atom<IYonhapNews[]>([])
// export const kyodoNewsListAtom = atom<ICuration[]>([])
export const mainBannerListAtom = atom<IBanner[]>([])
export const mainContentsBannerListAtom = atom<IBannerGroup[]>([])

export const jobBannerListAtom = atom<IBanner[]>([])
export const propertyBannerListAtom = atom<IBanner[]>([])
export const shoppingBannerListAtom = atom<IBanner[]>([])
export const serviceBannerListAtom = atom<IBanner[]>([])
export const educationBannerListAtom = atom<IBanner[]>([])
export const tripBannerListAtom = atom<IBanner[]>([])
export const medicalBannerListAtom = atom<IBanner[]>([])
export const lawBannerListAtom = atom<IBanner[]>([])
export const clubBannerListAtom = atom<IBanner[]>([])
export const addressBannerListAtom = atom<IBanner[]>([])

export const jobLeftBoardListAtom = atom<IBoard[]>([])
export const jobLeftBoardTotalCountAtom = atom<number>(0)
export const jobRightBoardListAtom = atom<IBoard[]>([])
export const jobRightBoardTotalCountAtom = atom<number>(0)
export const propertyLeftBoardListAtom = atom<IBoard[]>([])
export const propertyLeftBoardTotalCountAtom = atom<number>(0)
export const propertyRightBoardListAtom = atom<IBoard[]>([])
export const propertyRightBoardTotalCountAtom = atom<number>(0)
export const shoppingLeftBoardListAtom = atom<IBoard[]>([])
export const shoppingLeftBoardTotalCountAtom = atom<number>(0)
export const shoppingRightBoardListAtom = atom<IBoard[]>([])
export const shoppingRightBoardTotalCountAtom = atom<number>(0)
export const serviceLeftBoardListAtom = atom<IBoard[]>([])
export const serviceLeftBoardTotalCountAtom = atom<number>(0)
export const serviceRightBoardListAtom = atom<IBoard[]>([])
export const serviceRightBoardTotalCountAtom = atom<number>(0)
export const educationLeftBoardListAtom = atom<IBoard[]>([])
export const educationLeftBoardTotalCountAtom = atom<number>(0)
export const educationRightBoardListAtom = atom<IBoard[]>([])
export const educationRightBoardTotalCountAtom = atom<number>(0)
export const tripLeftBoardListAtom = atom<IBoard[]>([])
export const tripLeftBoardTotalCountAtom = atom<number>(0)
export const tripRightBoardListAtom = atom<IBoard[]>([])
export const tripRightBoardTotalCountAtom = atom<number>(0)
export const medicalLeftBoardListAtom = atom<IBoard[]>([])
export const medicalLeftBoardTotalCountAtom = atom<number>(0)
export const medicalRightBoardListAtom = atom<IBoard[]>([])
export const medicalRightBoardTotalCountAtom = atom<number>(0)
export const lawLeftBoardListAtom = atom<IBoard[]>([])
export const lawLeftBoardTotalCountAtom = atom<number>(0)
export const lawRightBoardListAtom = atom<IBoard[]>([])
export const lawRightBoardTotalCountAtom = atom<number>(0)
export const clubLeftBoardListAtom = atom<IBoard[]>([])
export const clubLeftBoardTotalCountAtom = atom<number>(0)
export const clubRightBoardListAtom = atom<IBoard[]>([])
export const clubRightBoardTotalCountAtom = atom<number>(0)
export const addressRightBoardListAtom = atom<IBoard[]>([])
export const addressRightBoardTotalCountAtom = atom<number>(0)
export const addressBookListAtom = atom<IAddressBook[]>([])

export const myPageBoardListAtom = atom<IBoard[]>([])
export const myPageBoardTotalCountAtom = atom<number>(0)
export const myPageCommentListAtom = atom<IUserContentsComment[]>([])
export const myPageCommentTotalCountAtom = atom<number>(0)
export const myPageGoodListAtom = atom<IUserContentsGood[]>([])
export const myPageGoodTotalCountAtom = atom<number>(0)
export const myPageScrapListAtom = atom<IUserContentsScrap[]>([])
export const myPageScrapTotalCountAtom = atom<number>(0)

export const myPageMemberSubListAtom = atom<IUserContentsMemberSubscription[]>([])
export const myPageMemberSubTotalCountAtom = atom<number>(0)
export const myPageBoardSubListAtom = atom<IUserContentsBoardSubscription[]>([])
export const myPageBoardSubTotalCountAtom = atom<number>(0)

export const myPageMessageFromListAtom = atom<IUserContentsMessageBoard[]>([])
export const myPageMessageFromTotalCountAtom = atom<number>(0)
export const myPageMessageToListAtom = atom<IUserContentsMessageBoard[]>([])
export const myPageMessageToTotalCountAtom = atom<number>(0)
export const myPageMessageKeepListAtom = atom<IUserContentsMessageBoard[]>([])
export const myPageMessageKeepTotalCountAtom = atom<number>(0)
export const myPageReadStateAtom = atom<IReadState[]>([])

export const popupStateAtom = atom<IPopupState>({ popup: null, isOpen: true })
