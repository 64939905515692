import { StrapiAPI, StrapiJwtAPI } from '@/services/axios'
import { ICreateBoardForm } from '@/interfaces/board'

export async function createBoard(createBoardForm: ICreateBoardForm) {
  return StrapiJwtAPI(createBoardForm.jwt).post('/advertisement-boards', createBoardForm.board)
}

export async function updateAdBoard(createBoardForm: ICreateBoardForm) {
  return StrapiJwtAPI(createBoardForm.jwt).put(
    `/advertisement-boards/${createBoardForm.board.id}`,
    createBoardForm.board,
  )
}

export async function findOneadBoardById(param: { bannerID: number; userId: number }) {
  const { bannerID, userId } = param
  const response = await StrapiAPI.get(`/advertisement-boards/${bannerID}?userId=${userId}`)
  return response.data
}

export async function updateadBoardViewCount(id: number) {
  await StrapiAPI.put(`/advertisement-boards/view/${id}`)
}

const findById = (id: number, userId: number) => {
  return StrapiAPI.get(`/ad-boards/${id}?userId=${userId}`)
}

//ad-boards?banner.id=356
const findAllByCategory = (category: number, userId: number, start: number, limit: number) => {
  return StrapiAPI.get(
    `/advertisement-boards?category=${category}&userId=${userId}&start=${start}&limit=${limit}`,
  )
}

const findCount = (category: number) => {
  const json = { category: category }
  return StrapiAPI.post('/advertisement-boards/findCount', json)
}

export async function removeaAdBoard(props: { jwt: string; id: number }) {
  const { jwt, id } = props
  return StrapiJwtAPI(jwt).delete(`/advertisement-boards/custom/${id}`)
}

const adBoardService = {
  updateAdBoard,
  findById,
  findAllByCategory,
  findCount,
}

export default adBoardService
