import React from 'react'
// import { Linking } from 'react-native'
import { Box, Pressable, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { INewsPaper } from '@/interfaces/newspaper'
import { RootStackParamList } from '@/interfaces/navigation'
import { Linking, Platform } from 'react-native'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'

interface Props {
  item: INewsPaper
}

export default function NewsPaperItem(props: Props) {
  const { item } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const paperWidth = (screenWidth - 80) / 3
  return (
    <Box bg='#F4F4F4' mr='8px'>
      <Pressable
        onPress={() => {
          if (item.pdf_file.url) {
            if (Platform.OS !== 'web') {
              navigation.push(ROOT_STACK.BANNER_COMPANY_DETAIL, {
                uri: item.pdf_file.url,
                title: item.title,
              })
            } else {
              void Linking.openURL(item.pdf_file.url)
            }
          }
        }}
        mx='4px'
      >
        <FastImage
          source={{ uri: item.thumbnail.url }}
          style={{
            width: paperWidth,
            height: paperWidth * 1.5,
          }}
          resizeMode='contain'
        />
      </Pressable>
      <Text textAlign='center' fontSize='15px' my='6px' fontWeight='semibold'>
        {item.title.replace('한터', '').trim()}
      </Text>
    </Box>
  )
}
