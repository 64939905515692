import React from 'react'
import { Box, HStack, Modal, Text, VStack } from 'native-base'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK, USER_PAGE_TAB } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { screenWidthAtom } from '@/atom/navigationAtom'
import CButton from '../CButton'
import { getBoardAgree, sendBoardDisagree } from '@/services/pushAgreeService'
import { ISendSubscriptPushForm } from '@/interfaces/push'
import { LIMIT } from '@/constants/limit'
import { findAllBoardSubByMeAuth } from '@/services/userContents'
import { myPageBoardSubListAtom, myPageBoardSubTotalCountAtom } from '@/atom/initAtom'

interface Props {
  subId: number
  category: number
  userNickName: string
  handleCloseModal: () => void
}

export default function MySubBoardModal(props: Props) {
  const { subId, category, userNickName, handleCloseModal } = props
  const [isMenuOpen, setIsMenuOpen] = React.useState(true)
  const [screenWidth] = useAtom(screenWidthAtom)
  const [, setMyPageBoardSubList] = useAtom(myPageBoardSubListAtom)
  const [, setMyPageBoardSubTotalCount] = useAtom(myPageBoardSubTotalCountAtom)
  const [boardSub, setboardSub] = React.useState<ISendSubscriptPushForm[] | null>(null)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [auth] = useAtom(authAtom)

  const closeUserMenu = () => {
    handleCloseModal()
  }

  React.useEffect(() => {
    const fetch = async () => {
      if (auth) {
        if (subId) {
          const ISendSubscriptPushForm: ISendSubscriptPushForm = {
            jwt: auth.jwt,
            user_id: auth.user ? auth.user.id : 0,
            type: 'advertisement',
            type_id: category,
          }
          await Promise.all([await getBoardAgree(ISendSubscriptPushForm)]).then((values) => {
            setboardSub(values[0])
          })
        }
      }
    }
    void fetch()
  }, [subId])

  const subDisAgree = async () => {
    if (auth.logged && boardSub) {
      if (boardSub[0].id) {
        const result = await sendBoardDisagree(auth.jwt, boardSub[0].id)
        setboardSub(result)
        const myPageArg = {
          jwt: auth.jwt,
          limit: LIMIT.MY_PAGE_LIST,
        }
        await Promise.all([findAllBoardSubByMeAuth(myPageArg)]).then((values) => {
          setMyPageBoardSubList(values[0].contents)
          setMyPageBoardSubTotalCount(values[0].totalCount)
        })
      }
    } else {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={isMenuOpen} onClose={() => closeUserMenu()}>
        <Modal.Content w={screenWidth - 80} borderRadius='5px'>
          <Modal.Body borderBottomWidth={0} pb={2}>
            <CButton bgColor='#ECECEC' rounded='20px' py='8px' mb='14px' onPress={subDisAgree}>
              <HStack alignSelf='center'>
                <Text
                  textAlign='center'
                  fontSize='16px'
                  fontWeight={700}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {'['}
                </Text>
                <Text
                  maxW='110px'
                  textAlign='center'
                  fontSize='16px'
                  fontWeight={700}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {userNickName}
                </Text>
                <Text
                  textAlign='center'
                  fontSize='16px'
                  fontWeight={700}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {'] 게시판 구독 해지'}
                </Text>
              </HStack>
            </CButton>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
