import React from 'react'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import RootNavigator from '@/navigation/RootNavigator'
import LinkingConfiguration from '@/navigation/LinkingConfiguration'
import CustomSplashScreen from '@/screens/CustomSplashScreen'
import RouteChangeTracker from '@/hooks/RouteChangeTracker'

export default function Navigation() {
  const navTheme = DefaultTheme
  navTheme.colors.background = '#f4f4f4'
  return (
    <NavigationContainer
      independent={true}
      theme={navTheme}
      linking={LinkingConfiguration}
      fallback={<CustomSplashScreen />}
    >
      <RouteChangeTracker />
      <RootNavigator />
    </NavigationContainer>
  )
}
