import React from 'react'
import { Box, Input, Text } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Controller, useForm } from 'react-hook-form'
import { LargeButton } from '@/screens/auth/components'
import AuthStackHeader from '@/screens/auth/components/AuthStackHeader'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IProfile, IUser } from '@/interfaces/auth'
import FastImage from '@/components/FastImage'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Platform, Pressable } from 'react-native'
import { authAtom, handleChangeProfile } from '@/atom/authAtom'
import { useAtom } from 'jotai'
import ErrorMessageBox from './components/ErrorMessageBox'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.MODIFY_PROFILE>

export default function ModifyProfileScreen({ navigation }: Props) {
  const [isLoading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [auth] = useAtom(authAtom)
  const nickNameRef = React.useRef<any>(null)
  const [, updateProfile] = useAtom(handleChangeProfile)

  const {
    handleSubmit,
    control,
    formState: { errors: formErrors },
    clearErrors: formClearErrors,
  } = useForm<IProfile>({
    defaultValues: {
      nick_name: '',
    },
  })

  const onSubmit = async (data: IProfile) => {
    if (data.nick_name && data.nick_name.length > 13) {
      return setErrorMessage('닉네임은 12자 이내로 입력해 주세요.')
    } else {
      setLoading(true)
      const updateData: IUser = {
        id: auth.user?.id ? auth.user?.id : -1,
        email: auth.user?.email ? auth.user?.email : '',
        username: auth.user?.username,
        country: auth.user?.country,
        post: auth.user?.post,
        gender: auth.user?.gender,
        nick_name: data.nick_name,
      }
      await updateProfile(updateData)
      setLoading(false)

      if (navigation.canGoBack()) navigation.goBack()
    }
  }

  return (
    <React.Fragment>
      <AuthStackHeader title='프로필 설정' navigation={navigation} />
      <KeyboardAwareScrollView
        extraScrollHeight={Platform.OS === 'ios' ? 20 : -10}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
        scrollEnabled={true}
        keyboardShouldPersistTaps={'handled'}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Box mt='28px' mb='20px' mx='10%'>
          <Box>
            <Text fontSize='15px' color='#ff6f1e' mb='5px' fontWeight='bold'>
              이메일
            </Text>
            <Text fontSize='15px' color='#000000' mb={8}>
              {auth.user?.email}
            </Text>
            <Box>
              <Text fontSize='15px' fontWeight='bold' color='#ff6f1e' mb='6px'>
                닉네임 (12자 이내)
              </Text>
              <Box
                key={2}
                // onLayout={(e) => {
                //   const layout = e.nativeEvent.layout
                //   setDataSourceCords([...dataSourceCords, { key: 5, scrollY: layout.y }])
                // }}
              >
                <Controller
                  control={control}
                  name='nick_name'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      ref={nickNameRef}
                      placeholder={auth.user?.nick_name}
                      placeholderTextColor='#5B5B5B'
                      textAlign='left'
                      width='full'
                      h='40px'
                      backgroundColor='#F4F4F4'
                      borderWidth={0}
                      mb='16px'
                      pl='10px'
                      fontSize='15px'
                      maxLength={12}
                      onChangeText={(value) => {
                        onChange(value)
                        formClearErrors()
                        setErrorMessage('')
                      }}
                      value={value}
                      autoCapitalize='none'
                      autoCorrect={false}
                      onSubmitEditing={handleSubmit(onSubmit)}
                      InputRightElement={
                        <Pressable onPress={() => onChange('')}>
                          <FastImage
                            source={require('@/assets/images/delete.png')}
                            style={{
                              width: 23,
                              height: 23,
                              marginRight: 3,
                            }}
                            resizeMode='contain'
                          />
                        </Pressable>
                      }
                    />
                  )}
                />
              </Box>
              {formErrors.nick_name ? (
                <ErrorMessageBox errorMessage='닉네임을 입력해주세요.' />
              ) : errorMessage ? (
                <ErrorMessageBox errorMessage={errorMessage} />
              ) : null}
            </Box>
          </Box>
          <Box mb='20px'>
            <LargeButton
              buttonTitle='변경하기'
              fill
              onPress={handleSubmit(onSubmit)}
              loading={isLoading}
            />
          </Box>
        </Box>
      </KeyboardAwareScrollView>
    </React.Fragment>
  )
}
