import { TouchableHighlight } from 'react-native'
import { Box, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { IYonhapNews } from '@/interfaces/news'

interface Props {
  item: IYonhapNews
  index: number
}

export default function MainNewsText(props: Props) {
  const { item, index } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <TouchableHighlight
      onPress={() => {
        navigation.push(ROOT_STACK.NEWS_DETAIL, { id: item.id })
      }}
      underlayColor='#e9e9e9'
    >
      <Box
        w={screenWidth - 34}
        borderBottomWidth={index < 4 ? '1px' : 0}
        borderBottomColor='#eaeaea'
        py='11px'
        mx='17px'
      >
        <Text lineBreakMode='tail' numberOfLines={1} px='1px' fontSize={16}>
          {item.title}
        </Text>
      </Box>
    </TouchableHighlight>
  )
}
