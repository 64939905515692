import { atom } from 'jotai'
import { IUser } from '@/interfaces/auth'
import { IBoard } from '@/interfaces/board'
import { IUserContentsComment, IUserContentsGood } from '@/interfaces/userContents'

export const anotherUserIdAtom = atom<number>(0)
export const anotherUserAtom = atom<IUser | null>(null)
export const anotherUserBoardListAtom = atom<IBoard[]>([])
export const anotherUserBoardTotalCountAtom = atom<number>(0)
export const anotherUserCommentListAtom = atom<IUserContentsComment[]>([])
export const anotherUserCommentTotalCountAtom = atom<number>(0)
export const anotherUserGoodListAtom = atom<IUserContentsGood[]>([])
export const anotherUserGoodTotalCountAtom = atom<number>(0)
