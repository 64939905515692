import { HStack } from 'native-base'

import FastImage from '@/components/FastImage'

export default function AuthHeader(props: any) {
  return (
    <HStack mb='33px' space={3} justifyContent='center'>
      <FastImage
        source={require('@/assets/images/logo.png')}
        style={{
          width: 83,
          height: 52,
        }}
        resizeMode='contain'
      />
      {props.children}
    </HStack>
  )
}
