import React from 'react'
import Animated from 'react-native-reanimated'
import NewsPaperMain from '@/screens/news-paper/components'
import Footer from '@/components/Footer'
import { useFocusEffect } from '@react-navigation/native'
import { INewsPaper } from '@/interfaces/newspaper'
import { Box, Spinner } from 'native-base'
import { LIMIT } from '@/constants/limit'
import { findAllNewsPaper, findNewsPaperCount } from '@/services/newspaper'
import NewsPaperItem from './components/NewsPaperItem'
import MoreTab from '@/components/MoreTab'
import { RefreshControl } from 'react-native'

export default function NewsPaperScreen() {
  const [isLoading, setLoading] = React.useState(false)
  const [newsPaperMain, setNewsPaperMain] = React.useState<INewsPaper | null>(null)
  const [newsPaperList, setNewsPaperList] = React.useState<INewsPaper[]>([])

  const [, setNewsPaperTotalCount] = React.useState(0)
  const [refreshing, setRefreshing] = React.useState(false)
  const [newsPaperLimit, setNewsPaperLimit] = React.useState(LIMIT.NEWS_PAPER_LIMIT)

  const handleRefresh = React.useCallback(async () => {
    setRefreshing(true)
    const temp: INewsPaper[] = []
    await findAllNewsPaper(LIMIT.NEWS_PAPER_LIMIT + 1).then((list) => {
      list.map((item: INewsPaper, index: number) => {
        if (index === 0) {
          setNewsPaperMain(item)
        } else {
          temp.push(item)
        }
      })
    })
    await findNewsPaperCount().then((data) => {
      setNewsPaperTotalCount(data)
    })
    await setNewsPaperList(temp)
    await setNewsPaperLimit(LIMIT.NEWS_PAPER_LIMIT + 1)
    await setTimeout(() => {
      setRefreshing(false)
    }, 500)
  }, [])

  const handleMore = React.useCallback(async () => {
    const newLimit = newsPaperLimit + LIMIT.NEWS_PAPER_LIMIT
    const temp: INewsPaper[] = []
    await findAllNewsPaper(newLimit).then((list) => {
      list.map((item: INewsPaper, index: number) => {
        if (index === 0) {
          setNewsPaperMain(item)
        } else {
          temp.push(item)
        }
      })
    })
    await setNewsPaperList(temp)
    await setNewsPaperLimit(newLimit)
  }, [newsPaperLimit])

  useFocusEffect(
    React.useCallback(() => {
      const fetchData = async () => {
        if (newsPaperList.length === 0) {
          setLoading(true)
          const temp: INewsPaper[] = []
          await findAllNewsPaper(LIMIT.NEWS_PAPER_LIMIT + 1).then((list) => {
            list.map((item: INewsPaper, index: number) => {
              if (index === 0) {
                setNewsPaperMain(item)
              } else {
                temp.push(item)
              }
            })
          })
          await findNewsPaperCount().then((data) => {
            setNewsPaperTotalCount(data)
          })
          await setNewsPaperList(temp)
          await setLoading(false)
        }
      }
      void fetchData()
    }, [newsPaperList.length]),
  )

  if (isLoading) {
    return (
      <Box flex={1} justifyContent='center'>
        <Spinner color='#ff6f1e' />
      </Box>
    )
  }

  return (
    <Animated.FlatList
      data={newsPaperList}
      // listKey='newsPaperList'
      keyExtractor={(item) => item.id.toString()}
      numColumns={3}
      columnWrapperStyle={{
        paddingTop: 22,
        justifyContent: 'center',
        backgroundColor: '#ffffff',
      }}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={handleRefresh}
          colors={['#ff6f1e']}
          tintColor='#ff6f1e'
        />
      }
      showsVerticalScrollIndicator={false}
      // onScroll={scrollHandler}
      // scrollEventThrottle={16}
      renderItem={({ item }) => {
        return <NewsPaperItem item={item} />
      }}
      ListHeaderComponent={() => {
        return (
          <Box>
            <NewsPaperMain item={newsPaperMain} />
          </Box>
        )
      }}
      ListFooterComponent={() => {
        return (
          <Box borderTopWidth='1px' borderTopColor='#f4f4f4'>
            <Box bg='#ffffff'>
              <MoreTab
                moreButtonTextColor='신문'
                moreButtonText='더보기'
                moreButtonOnPress={handleMore}
                useSideButton={false}
                sideButtonText={''}
              />
            </Box>
            <Footer />
          </Box>
        )
      }}
    />
  )
}
