import React from 'react'
import { Box } from 'native-base'

import FastImage from '@/components/FastImage'

export default function CustomSplashScreen() {
  return (
    <Box flex={1} bgColor='#ffffff'>
      <FastImage
        source={require('@/assets/images/splash.png')}
        style={{
          width: '100%',
          height: '100%',
        }}
        resizeMode='contain'
      />
    </Box>
  )
}
