import React from 'react'
import { Box, Text } from 'native-base'

import AddressNormalItem from '@/screens/boards/components/AddressNormalItem'
import { IAddressGroup } from '@/interfaces/address'

interface Props {
  addressGroup: IAddressGroup
}

export default function AddressNormal(props: Props) {
  const { addressGroup } = props

  return (
    <Box mt='8px' pt='24px' px='17px' bgColor='white'>
      <Box borderBottomWidth='1px'>
        <Text fontSize='24px' fontWeight={600} mb='10px'>
          {addressGroup.group_name}
        </Text>
      </Box>
      {addressGroup.address_lists.map((item) => {
        return <AddressNormalItem address={item} key={item.id} />
      })}
    </Box>
  )
}
