import { Box } from 'native-base'
import ArticleElementsBox from '@/components/ArticleElementsBox'
import NewsDetailContents from './NewsDetailContents'
import { IYonhapNews } from '@/interfaces/news'
import React from 'react'

interface Props {
  news: IYonhapNews
  saveArticleElement: (elementType: string, value: any) => void
  goodCount: number
  hateCount: number
  good: boolean
  hate: boolean
  scrap: boolean
  complain: number | null
}

export default function NewsDetailBody(props: Props) {
  const { news, saveArticleElement, goodCount, hateCount, good, hate, scrap, complain } = props
  return (
    <Box bg='#ffffff'>
      <NewsDetailContents news={news} />
      <ArticleElementsBox
        type='news'
        typeId={news.id}
        useComplainButton={false}
        saveArticleElement={saveArticleElement}
        articleWriter={0}
        goodCount={goodCount}
        hateCount={hateCount}
        good={good}
        hate={hate}
        scrap={scrap}
        complain={complain}
      />
      <Box bg='#f2f2f2' h='11px' mt='31px' />
    </Box>
  )
}
