import React from 'react'
import { Platform } from 'react-native'
import MainBannerListNative from './MainBannerListNative'

import { IBanner } from '@/interfaces/banner'

interface Props {
  mainBannerList: IBanner[]
}

const PlatformSpecificMainBannerList = (props: Props) => {
  const CarouselComponent = Platform.select({
    // web: MainBannerListWeb,
    default: MainBannerListNative,
  })
  return <CarouselComponent {...props} />
}
export default PlatformSpecificMainBannerList
