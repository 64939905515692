import { Box } from 'native-base'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import useUrl from '@/hooks/useUrl'
import FastImage from '@/components/FastImage'
import { IBanner } from '@/interfaces/banner'
import { ROOT_STACK } from '@/constants/label'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  item: IBanner
}

export default function MainContentsImage(props: Props) {
  const { item } = props
  const [screenWidth] = useAtom(screenWidthAtom)
  const { handleUrl } = useUrl()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <Box w={(screenWidth - 45) / 2} alignItems='center'>
      {item.company_name !== '기본' ? (
        <TouchableOpacity
          key={item.id}
          onPress={() =>
            navigation.push(ROOT_STACK.BANNER_DETAIL, { id: item.id, isScroll: false })
          }
        >
          <FastImage
            source={{
              uri: item.big_banner_image
                ? handleUrl(item.big_banner_image.url)
                : item.big_thumbnail_path
                ? handleUrl(item.big_thumbnail_path)
                : '',
            }}
            style={{
              width: (screenWidth - 45) / 2,
              height: 'auto',
              borderRadius: 5,
              aspectRatio: 1,
            }}
            resizeMode='cover'
          />
        </TouchableOpacity>
      ) : (
        <Box alignItems='center' borderWidth='1px' borderColor='#EAEAEA' borderRadius={5}>
          <FastImage
            source={require('@/assets/images/default-banner.png')}
            style={{
              width: (screenWidth - 45) / 2,
              height: 'auto',
              borderRadius: 5,
              aspectRatio: 1,
            }}
            resizeMode='cover'
          />
        </Box>
      )}
    </Box>
  )
}
