import React from 'react'
import { Box, Center, HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { removeCommentByCommentId } from '@/services/comment'

import useFormatter from '@/hooks/useFormatter'
import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import ReCommentItem from '@/components/ReCommentItem'
import UpdateCommentBox from '@/components/UpdateCommentBox'
import CreateReCommentBox from '@/components/CreateReCommentBox'
import ModalUserMenu from '@/components/ModalUserMenu'
import { IComment, IReComment } from '@/interfaces/comment'
import {
  createArticleElements,
  createComplainHistories,
  findOne,
  findOneComplainHistories,
  updateArticleElements,
  updateComplainHistories,
} from '@/services/articleElements'
import CreateModal from '@/components/CreateModal'
import { AUTH_STACK, MODAL_TYPE, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { Autolink } from 'react-native-autolink'
import ModalConfirmRound from '@/components/ModalConfirmRound'
import { IBanner } from '@/interfaces/banner'
import { IBoard } from '@/interfaces/board'

interface Props {
  board?: IBoard
  comment: IComment
  reCommentList: IReComment[]
  reLoadCommentList: () => void
  articleWriter: number
  category?: number
  commentOpenId: number | null
  setCommentOpenId: (id: number) => void
  commentScrollToHandler: (pageY: number) => void
  banner?: IBanner
}

export default function CommentItem(props: Props) {
  const {
    board,
    comment,
    reCommentList,
    reLoadCommentList,
    articleWriter,
    category,
    commentOpenId,
    setCommentOpenId,
    commentScrollToHandler,
    banner,
  } = props
  const [auth] = useAtom(authAtom)
  const { datetimeFormatter, numberCommaFormatter } = useFormatter()
  const [openCreateCommentBox, setOpenCreateCommentBox] = React.useState(true)
  const [openReComment, setOpenReComment] = React.useState(false)
  const [openUpdateCommentBox, setOpenUpdateCommentBox] = React.useState(false)
  const [hashUser, setHashUser] = React.useState(0)
  const [hashUserNickName, setHashUserNickName] = React.useState('')
  const [goodCount, setGoodCount] = React.useState(comment.good_count)
  const [hateCount, setHateCount] = React.useState(comment.hate_count)
  const [isOpenComplain, setIsOpenComplain] = React.useState(false)
  const [isOpenAlertSelfComplain, setIsOpenAlertSelfComplain] = React.useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const handleOpenConfirm = (value: boolean) => {
    setIsOpenConfirm(value)
  }

  const handleOpenCreateCommentBox = (value: boolean) => {
    handleHashUser(0, '')
    setOpenCreateCommentBox(value)
  }

  const handleHashUser = (hashUser: number, hashUserNickName: string) => {
    setHashUser(hashUser)
    setHashUserNickName(hashUserNickName)
  }

  const removeComment = async () => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (!comment.id) return

    await removeCommentByCommentId({ id: comment.id, jwt: auth.jwt }).then(() => {
      openUpdateCommentBoxHandle(false)
      handleOpenConfirm(false)
      return reLoadCommentList()
    })
  }

  const reCommentHandle = (val: boolean) => {
    setOpenReComment(val)
  }

  const openUpdateCommentBoxHandle = (val: boolean) => {
    setOpenUpdateCommentBox(val)
  }

  const saveComplain = async (value: number) => {
    if (!value) return
    if (!auth.user) return

    await findOneComplainHistories({
      type: 'comment',
      type_id: comment.id,
      reported_user: auth.user.id,
    }).then(async (data) => {
      if (data) {
        await updateComplainHistories({
          jwt: auth.jwt,
          id: data.id,
          complain_type: value,
        })
      } else {
        await createComplainHistories({
          jwt: auth.jwt,
          type: 'comment',
          type_id: comment.id,
          complain_type: value,
          reported_user: auth.user ? auth.user.id : 0,
          writer: comment.writer,
          contents: comment.contents,
        })
      }
    })
  }

  const saveArticleElement = async (elementType: string) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    let newGood = false
    let newHate = false

    await findOne({
      type: 'comment',
      type_id: comment.id,
      writer: auth.user ? auth.user.id : 0,
    }).then((data) => {
      if (data) {
        // update
        newGood =
          elementType === 'GOOD'
            ? !data.good
            : elementType === 'HATE' && data.good
            ? false
            : data.good
        newHate =
          elementType === 'HATE'
            ? !data.hate
            : elementType === 'GOOD' && data.hate
            ? false
            : data.hate

        const update = async () => {
          await updateArticleElements(
            {
              jwt: auth.jwt,
              writer: auth.user ? auth.user.id : 0,
              type: data.type,
              type_id: data.type_id,
              scrap: data.scrap,
              good: newGood,
              hate: newHate,
            },
            data.id,
          )
        }
        void update()
        if (elementType === 'GOOD') {
          setGoodCount(newGood ? goodCount + 1 : goodCount - 1)
          if (data.hate) {
            setHateCount(hateCount - 1)
          }
        } else if (elementType === 'HATE') {
          setHateCount(newHate ? hateCount + 1 : hateCount - 1)
          if (data.good) {
            setGoodCount(goodCount - 1)
          }
        }
      } else {
        // create
        const create = async () => {
          newGood = elementType === 'GOOD'
          newHate = elementType === 'HATE'

          await createArticleElements({
            jwt: auth.jwt,
            writer: auth.user ? auth.user.id : 0,
            type: 'comment',
            type_id: comment.id,
            good: newGood,
            hate: newHate,
            scrap: false,
          })
        }
        void create()
        if (elementType === 'GOOD') setGoodCount(goodCount + 1)
        if (elementType === 'HATE') setHateCount(hateCount + 1)
      }
    })
  }

  const handleOpenComplain = (value: boolean) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
      return
    }

    if (auth.user && auth.user.id === comment.writer) {
      setIsOpenAlertSelfComplain(true)
      return
    }

    setIsOpenComplain(value)
  }

  return (
    <Box>
      {isOpenAlertSelfComplain && (
        <CreateModal
          modalType={MODAL_TYPE.ALERT_SELF_COMPLAIN}
          onAccept={() => {
            return
          }}
          handleCloseModal={() => {
            setIsOpenAlertSelfComplain(false)
          }}
        />
      )}
      {isOpenComplain && (
        <CreateModal
          modalType={MODAL_TYPE.COMPLAIN}
          onAccept={() => {
            return
          }}
          saveComplain={saveComplain}
          handleCloseModal={() => {
            setIsOpenComplain(false)
          }}
          articleElements={{ type: 'comment', typeId: comment.id }}
        />
      )}
      {isOpenConfirm && (
        <ModalConfirmRound
          isOpen={isOpenConfirm}
          onAccept={() => removeComment()}
          onClose={() => handleOpenConfirm(false)}
          contentsMessage={'삭제하시겠습니까?'}
          acceptButtonText='예'
          cancelButtonText='아니오'
        />
      )}
      <Box borderBottomWidth='1px' borderBottomColor='#e5e5e5' px='18px' py='13px'>
        <Box flexDirection='row' mb='6px' alignItems='center' justifyContent='space-between'>
          <Box mr='6px'>
            <ModalUserMenu
              userId={comment.writer}
              userNickName={comment.nick_name}
              type='COMMENT'
              isBlock={comment.is_block}
              blockCallback={reLoadCommentList}
              unblockCallback={reLoadCommentList}
            />
          </Box>
          <HStack>
            {articleWriter === comment.writer ? (
              <Center bgColor='#ff6f1e' w='44px' h='20px' borderRadius='5px' my='auto' mr='8px'>
                <Text color='white' fontSize='13px' fontWeight={700} textAlign='center'>
                  작성자
                </Text>
              </Center>
            ) : null}
            <Text fontSize='14px' color='#6C6C6C' numberOfLines={1} w='auto'>
              {datetimeFormatter(comment.created_at)}
            </Text>
          </HStack>
        </Box>
        <Box mb='20px'>
          {openUpdateCommentBox ? (
            <UpdateCommentBox
              comment={comment}
              commentType={comment.type}
              typeId={comment.type_id}
              openUpdateCommentHandle={openUpdateCommentBoxHandle}
              reLoadCommentList={reLoadCommentList}
            />
          ) : (
            <Text fontSize='16px' color={comment.is_block ? '#6C6C6C' : 'black'} selectable={true}>
              <Autolink text={comment.contents} email={false} />
            </Text>
          )}
        </Box>
        <Box flexDirection='row' justifyContent='space-between'>
          <Center flexDirection='row'>
            <Text
              fontSize='15px'
              mr='10px'
              numberOfLines={1}
              w='auto'
              onPress={() => {
                reCommentHandle(!openReComment)
              }}
            >
              {reCommentList.length > 0
                ? `답글 ${numberCommaFormatter(comment.re_comment_count)}`
                : '답글'}
            </Text>
            {auth.user && auth.user.id === comment.writer && (
              <Box flexDirection='row'>
                <CButton onPress={() => openUpdateCommentBoxHandle(true)}>
                  <Center
                    w='48px'
                    h='28px'
                    bgColor='white'
                    borderColor='#8F8F8F'
                    borderWidth='1px'
                    borderRadius='5px'
                    mr='6px'
                  >
                    <Text fontSize='15px' textAlign='center'>
                      수정
                    </Text>
                  </Center>
                </CButton>
                <CButton onPress={() => handleOpenConfirm(true)}>
                  <Center
                    w='48px'
                    h='28px'
                    bgColor='white'
                    borderColor='#8F8F8F'
                    borderWidth='1px'
                    borderRadius='5px'
                    mr='6px'
                  >
                    <Text fontSize='15px' textAlign='center'>
                      삭제
                    </Text>
                  </Center>
                </CButton>
              </Box>
            )}
          </Center>
          <Center flexDirection='row'>
            <CButton
              h='30px'
              mr='10px'
              justifyContent='center'
              onPress={() => saveArticleElement('GOOD')}
            >
              <HStack>
                {goodCount ? (
                  <FastImage
                    source={require('@/assets/images/favorite-red.png')}
                    style={{
                      width: 18,
                      height: 18,
                      marginTop: 1,
                      marginRight: 6,
                    }}
                    resizeMode='contain'
                  />
                ) : (
                  <FastImage
                    source={require('@/assets/images/favorite.png')}
                    style={{
                      width: 20,
                      height: 20,
                      marginTop: 3,
                      marginRight: 6,
                    }}
                    resizeMode='contain'
                  />
                )}
                <Text fontSize='15px' fontWeight={700} color='#6C6C6C'>
                  {numberCommaFormatter(goodCount)}
                </Text>
              </HStack>
            </CButton>
            <CButton ml='16px' onPress={() => handleOpenComplain(true)}>
              <Text fontSize='15px' fontWeight={400} color='#6C6C6C'>
                신고
              </Text>
            </CButton>
          </Center>
        </Box>
      </Box>
      {openReComment && openCreateCommentBox && (
        <CreateReCommentBox
          board={board}
          commentId={comment.id}
          type={comment.type}
          typeId={comment.type_id}
          reLoadCommentList={reLoadCommentList}
          handleHashUser={handleHashUser}
          hashUser={hashUser}
          hashUserNickName={hashUserNickName}
          category={category}
          commentOpenId={commentOpenId}
          setCommentOpenId={setCommentOpenId}
          commentScrollToHandler={commentScrollToHandler}
          banner={banner}
        />
      )}
      {openReComment && reCommentList.length > 0
        ? reCommentList.map((item) => {
            return (
              <ReCommentItem
                key={item.id}
                reComment={item}
                handleHashUser={handleHashUser}
                hashUser={hashUser}
                hashUserNickName={hashUserNickName}
                reLoadCommentList={reLoadCommentList}
                handleOpenCreateCommentBox={handleOpenCreateCommentBox}
                articleWriter={articleWriter}
              />
            )
          })
        : null}
    </Box>
  )
}
