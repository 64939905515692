import React, { useRef, useState, useEffect } from 'react'
import { Dimensions, Pressable } from 'react-native'
import MainContentsBannerItem from './MainContentsBannerItem'
import { FlatList } from 'react-native-gesture-handler'
import { Box, HStack, Text, VStack } from 'native-base'
import CButton from '@/components/CButton'
import { TOP_TAB } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { MaterialTopTabNavigationProp } from '@react-navigation/material-top-tabs'
import { TopTabParamList } from '@/interfaces/navigation'

const { width } = Dimensions.get('window')

interface Props {
  bannerGroup: any[][]
  autoplayInterval: number
  title: string
  tabRef?: any
}

const Carousel = (props: Props) => {
  const { bannerGroup, autoplayInterval, title } = props
  const flatListRef = useRef<FlatList>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [userScrolling, setUserScrolling] = useState(false)

  const navigation = useNavigation<MaterialTopTabNavigationProp<TopTabParamList, TOP_TAB.MAIN>>()

  const scrollToIndex = (index: number, animated = true) => {
    flatListRef.current?.scrollToIndex({ index, animated })
    setCurrentIndex(index)
  }

  useEffect(() => {
    const autoScroll = () => {
      if (!userScrolling) {
        let nextIndex = currentIndex + 1
        nextIndex = nextIndex >= bannerGroup.length ? 0 : nextIndex // 순환 처리
        scrollToIndex(nextIndex)
      }
    }
    const interval = setInterval(autoScroll, autoplayInterval) // 자동 스크롤
    return () => clearInterval(interval)
  }, [currentIndex, userScrolling, autoplayInterval, bannerGroup.length])

  const handleMomentumScrollEnd = (e: any) => {
    const contentOffset = e.nativeEvent.contentOffset.x
    const newIndex = Math.floor(contentOffset / width)

    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex)
    }
    setUserScrolling(false) // 스크롤이 끝났으므로 사용자 스크롤 상태를 false로 설정
  }

  // 사용자가 스크롤을 시작할 때 호출될 함수
  const onScrollBeginDrag = () => {
    setUserScrolling(true)
    // if (currentIndex === 0) { // 첫 번째 항목에서 뒤로 스크롤
    //   scrollToIndex(bannerGroup.length - 1, false);
    // } else if (currentIndex === bannerGroup.length - 1) { // 마지막 항목에서 앞으로 스크롤
    //   scrollToIndex(0, false);
    // }
  }

  const moveCategoryPage = () => {
    bannerGroup.map((item) => {
      if (item[0] && item[0].banner_category) {
        const categoryName = item[0].banner_category.category_name
        if (categoryName === '구인구직') {
          navigation.navigate(TOP_TAB.JOB)
        } else if (categoryName === '쇼핑') {
          navigation.navigate(TOP_TAB.SHOPPING)
        } else if (categoryName === '서비스') {
          navigation.navigate(TOP_TAB.SERVICE)
        } else if (categoryName === '교육') {
          navigation.navigate(TOP_TAB.EDUCATION)
        } else if (categoryName === '법률・금융') {
          navigation.navigate(TOP_TAB.LAW)
        }
      }
    })
  }

  return (
    <Box>
      <VStack>
        <HStack justifyContent='space-between' mx='16px' mt='8px' mb='16px'>
          <Pressable onPress={moveCategoryPage}>
            <Text fontSize='18px' fontWeight='bold'>
              {title}
            </Text>
          </Pressable>
          <HStack space='4px' alignItems='center'>
            {Array.from({ length: bannerGroup.length }, (_, index) =>
              currentIndex == index ? (
                <CButton key={title + index} w='8px' h='8px' bgColor='#FF6666' borderRadius='100' />
              ) : (
                <CButton key={title + index} w='8px' h='8px' bgColor='#D9D9D9' borderRadius='100' />
              ),
            )}
          </HStack>
        </HStack>
        {/* <PanGestureHandler
          // ref={panRef}
          // simultaneousHandlers={listRef}
          // onGestureEvent={gestureHandler}
        >
          <NativeViewGestureHandler 
            // ref={listRef} 
            // simultaneousHandlers={panRef}
            > */}
        <FlatList
          ref={flatListRef}
          data={bannerGroup}
          renderItem={({ item }) => <MainContentsBannerItem banners={item} />}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          onMomentumScrollEnd={handleMomentumScrollEnd}
          onScrollBeginDrag={onScrollBeginDrag}
          getItemLayout={(_, index) => ({
            length: width,
            offset: width * index,
            index,
          })}
        />
        {/* </NativeViewGestureHandler>
        </PanGestureHandler> */}
      </VStack>
    </Box>
  )
}

export default Carousel
