import React from 'react'
import { HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import CButton from '@/components/CButton'
import { MYPAGE_LABELS } from '@/constants/site'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  onClose: () => void
}

export default function TopAuthMenuMyPageNav({ onClose }: Props) {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const moveMyPage = (tabName: any) => {
    void onClose()
    navigation.navigate(ROOT_STACK.MY_PAGE_TAB, {
      screen: tabName,
    })
  }

  return (
    <HStack w='full' justifyContent='space-between' px='7px'>
      {MYPAGE_LABELS.map((item, index) => {
        return (
          <CButton my='auto' px='5px' onPress={() => moveMyPage(item.name)} key={index.toString()}>
            <Text fontSize='14px' fontWeight={600} textAlign='center'>
              {item.title}
            </Text>
          </CButton>
        )
      })}
    </HStack>
  )
}
