import { Box, HStack, Text } from 'native-base'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import useFormatter from '@/hooks/useFormatter'

interface Props {
  commentCount: number
  reLoadHandler: () => void
}

export default function CommentNewsHeader(props: Props) {
  const { commentCount, reLoadHandler } = props
  const { numberCommaFormatter } = useFormatter()

  return (
    <HStack justifyContent='space-between' mb='16px'>
      <HStack alignItems='center'>
        <Text fontSize='16px' mr='8px' numberOfLines={1} w='auto'>
          댓글
        </Text>
        <Text fontSize='15px' color='#555555' fontWeight={700} mr='8px'>
          {numberCommaFormatter(commentCount)}
        </Text>
        <CButton onPress={reLoadHandler}>
          <Box
            w='23px'
            h='23px'
            borderWidth='1px'
            borderColor='#E0E0E0'
            borderRadius='12px'
            justifyContent='center'
            alignItems='center'
            pt='6px'
            mb='2px'
          >
            <FastImage
              source={require('@/assets/images/reLoad.png')}
              style={{
                width: 15,
                height: 15,
                marginBottom: 6,
              }}
              resizeMode='contain'
            />
          </Box>
        </CButton>
      </HStack>
    </HStack>
  )
}
