import React, { useCallback, useState } from 'react'
import { Box, Checkbox, ChevronDownIcon, ChevronUpIcon, HStack, Pressable, Text } from 'native-base'
import MyPageMessageItemContents from '@/components/MyPageMessageItemContents'
import { ICheckedList, IUserContentsMessageBoard } from '@/interfaces/userContents'
import useFormatter from '@/hooks/useFormatter'
import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import MyPageMessageItemFooter from './MyPageMessageItemFooter'

interface Props {
  messageBoard: IUserContentsMessageBoard
  onRead: (readId: number) => void
  selectedTabId: string
  callRemoveMessage: (id: number) => void
  callKeepMessage: (id: number) => void
  handleCheckedList: (id: number) => void
  checkedList: ICheckedList[]
  itemIndex: number
}

export default function MyPageMessageItem(props: Props) {
  const {
    messageBoard,
    onRead,
    selectedTabId,
    callRemoveMessage,
    handleCheckedList,
    callKeepMessage,
    checkedList,
    itemIndex,
  } = props
  const [openContents, setOpenContents] = React.useState(false)
  const { dateFormatter, datetimeFormatter } = useFormatter()
  const [screenWidth] = useAtom(screenWidthAtom)

  const [textShown, setTextShown] = useState(false) //To show ur remaining Text
  const [lengthMore, setLengthMore] = useState(false) //to show the "Read more & Less Line"
  const toggleNumberOfLines = () => {
    //To toggle the show text or hide it
    setTextShown(!textShown)
    setOpenContents(true)
  }

  const onTextLayout = useCallback((e: { nativeEvent: { lines: string | any[] } }) => {
    setLengthMore(e.nativeEvent.lines.length >= 2) //to check the text is more than 4 lines or not
  }, [])

  const handleOpenContents = (readId: number) => {
    if (!messageBoard.read_time && selectedTabId === 'TO') {
      onRead(readId)
    }
    setOpenContents(!openContents)
  }

  const handleChecked = () => {
    handleCheckedList(messageBoard.id)
  }

  return (
    <Box pt='12px' borderBottomWidth='1px' borderBottomColor='#f1f1f1' bgColor='white'>
      <Box px='17px' pb='12px'>
        <Box flexDirection='row' alignItems='center' justifyContent='space-between' mb='8px'>
          {!openContents && (
            <Checkbox
              value='check'
              size='sm'
              colorScheme='orange'
              boxSize='19'
              _checked={{ backgroundColor: '#ff6f1e', borderColor: '#ff6f1e' }}
              onChange={() => handleChecked()}
              isChecked={checkedList[itemIndex] !== undefined && checkedList[itemIndex].checked}
            >
              <Text
                fontSize='16px'
                lineBreakMode='tail'
                numberOfLines={1}
                maxWidth={screenWidth - 100}
              >
                {messageBoard.title}
              </Text>
            </Checkbox>
          )}
          {openContents && (
            <Checkbox
              value='check'
              size='sm'
              colorScheme='orange'
              boxSize='19'
              _checked={{ backgroundColor: '#ff6f1e', borderColor: '#ff6f1e' }}
              onChange={() => handleChecked()}
              isChecked={checkedList[itemIndex] !== undefined && checkedList[itemIndex].checked}
            >
              <Text fontSize='16px' maxWidth={screenWidth - 100}>
                {messageBoard.title}
              </Text>
            </Checkbox>
          )}
          {!openContents && (
            <Pressable onPress={() => handleOpenContents(messageBoard.id)}>
              <ChevronDownIcon color='#000000' size={6} />
            </Pressable>
          )}
          {openContents && (
            <Pressable
              onPress={() => {
                setTextShown(false), setOpenContents(false)
              }}
            >
              <ChevronUpIcon color='#000000' size={6} />
            </Pressable>
          )}
        </Box>
        {!openContents && (
          <Box mb='8px'>
            <Text
              onTextLayout={onTextLayout}
              numberOfLines={textShown ? undefined : 2}
              fontSize={15}
            >
              {messageBoard.message}
            </Text>
            {lengthMore ? (
              <Text fontWeight='bold' color='blue.400' fontSize={15} onPress={toggleNumberOfLines}>
                {textShown ? null : '더보기'}
              </Text>
            ) : null}
          </Box>
        )}
        {openContents && (
          <MyPageMessageItemContents
            messageBoard={messageBoard}
            selectedTabId={selectedTabId}
            callRemoveMessage={callRemoveMessage}
            callKeepMessage={callKeepMessage}
          />
        )}
        <Box flexDirection='row' mb='4px'>
          <Text fontSize='13px' color='#6C6C6C' mr='11px'>
            {selectedTabId === 'TO'
              ? `보낸이 : ${messageBoard.nick_name}`
              : `받는이 : ${messageBoard.nick_name}`}
          </Text>
          <Text fontSize='13px' color='#6C6C6C' mr='11px'>
            |
          </Text>
          <Text fontSize='13px' color='#6C6C6C' mr='11px'>
            등록일 : {dateFormatter(messageBoard.created_at)}
          </Text>
        </Box>
        <Box flexDirection='row'>
          <HStack>
            <Text fontSize='13px' color='#6C6C6C'>
              읽은 시간 :{' '}
            </Text>
            {messageBoard.read_time ? (
              <Text fontSize='13px' color='#6C6C6C'>
                {datetimeFormatter(messageBoard.read_time, 'YY.MM.DD HH:mm')}
              </Text>
            ) : (
              <Text fontSize='13px' color='#FF6F1E' fontWeight={700}>
                {'읽지 않음'}
              </Text>
            )}
          </HStack>
        </Box>
        {openContents && (
          <MyPageMessageItemFooter
            messageBoard={messageBoard}
            selectedTabId={selectedTabId}
            callRemoveMessage={callRemoveMessage}
            callKeepMessage={callKeepMessage}
          />
        )}
      </Box>
    </Box>
  )
}
