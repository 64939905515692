import { extendTheme } from 'native-base'

export default function useTheme() {
  return extendTheme({
    fontConfig: {
      NotoSansKR: {
        100: {
          normal: 'NotoSansKRThin',
        },
        200: {
          normal: 'NotoSansKRExtraLight',
        },
        300: {
          normal: 'NotoSansKRLight',
        },
        400: {
          normal: 'NotoSansKRRegular',
        },
        500: {
          normal: 'NotoSansKRMedium',
        },
        600: {
          normal: 'NotoSansKRBold',
        },
        700: {
          normal: 'NotoSansKRBold',
        },
        800: {
          normal: 'NotoSansKRExtraBold',
        },
        900: {
          normal: 'NotoSansKRBlack',
        },
      },
    },

    fonts: {
      heading: 'NotoSansKR',
      body: 'NotoSansKR',
      mono: 'NotoSansKR',
    },
  })
}
