import React from 'react'
import { TextInput } from 'react-native'
import { Box, HStack, Input } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'
import { globalKeywordAtom } from '@/atom/searchAtom'

import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import ModalAlert from '@/components/ModalAlert'
import BackButton from '@/components/BackButton'
import { SEARCH_STACK } from '@/constants/label'
import { SearchStackParamList } from '@/interfaces/navigation'

export default function SearchHeader() {
  const [screenWidth] = useAtom(screenWidthAtom)
  const [globalKeyword, setGlobalKeyword] = useAtom(globalKeywordAtom)
  const [keyword, setKeyword] = React.useState('')
  const [isOpenAlert, setIsOpenAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const inputRef = React.useRef<TextInput>(null)

  const navigation = useNavigation<NativeStackNavigationProp<SearchStackParamList>>()

  const handleOpenAlert = (value: boolean) => {
    setIsOpenAlert(value)
  }

  const handleSearch = () => {
    const pKeyword = keyword.trim()
    if (pKeyword.length > 0) {
      navigation.push(SEARCH_STACK.SEARCH_PREVIEW, { keyword: pKeyword })
      setGlobalKeyword(pKeyword)
    } else {
      setAlertMessage('검색어를 입력해주세요.')
      handleOpenAlert(true)
      if (inputRef.current) inputRef.current.focus()
    }
  }

  React.useEffect(() => {
    if (navigation.getState().routes.length === 1) {
      setKeyword('')
      setGlobalKeyword('')
      if (inputRef.current) inputRef.current.focus()
    }
    if (globalKeyword !== '') {
      setKeyword(globalKeyword)
    }
  }, [setKeyword, setGlobalKeyword, globalKeyword, navigation])

  // React.useLayoutEffect(() => {
  //   if (inputRef.current) inputRef.current.focus()
  // }, [])

  return (
    <HStack
      borderBottomWidth={1}
      borderBottomColor='#e8e8e8'
      mx='10px'
      mt='20px'
      pb='20px'
      alignItems='center'
    >
      <BackButton navigation={navigation} color='BLACK' onPressType='SEARCH' />
      {isOpenAlert && (
        <ModalAlert
          isOpen={isOpenAlert}
          message={alertMessage}
          onClose={() => handleOpenAlert(false)}
        />
      )}
      <HStack
        justifyContent='space-between'
        w={screenWidth - 60}
        h='40px'
        bgColor='#f4f4f4'
        borderRadius='4px'
        ml='10px'
      >
        <Box flex={1} my='auto'>
          <Input
            ref={inputRef}
            borderWidth='0'
            placeholder='검색어를 입력하세요.'
            textAlignVertical='center'
            onChangeText={(value) => {
              setKeyword(value)
            }}
            value={keyword}
            autoCapitalize='none'
            autoCorrect={false}
            _focus={{
              backgroundColor: '#f4f4f4',
            }}
            height='40px'
            fontSize='18px'
            fontWeight='bold'
            returnKeyType='search'
            onSubmitEditing={handleSearch}
          />
        </Box>
        <Box my='auto' mr='13px'>
          {keyword ? (
            <CButton onPress={() => setKeyword('')}>
              <FastImage
                source={require('@/assets/images/delete.png')}
                style={{
                  width: 21,
                  height: 21,
                }}
                resizeMode='contain'
              />
            </CButton>
          ) : null}
        </Box>
        <CButton onPress={handleSearch} my='auto' mr='8px'>
          <FastImage
            source={require('@/assets/images/search-icon.png')}
            style={{
              width: 20,
              height: 20,
            }}
            resizeMode='contain'
          />
        </CButton>
      </HStack>
    </HStack>
  )
}
