import React from 'react'
import { HStack, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import CButton from '@/components/CButton'
import FastImage from '@/components/FastImage'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  onClose: () => void
}

export default function TopAuthMenuNotLogin(props: Props) {
  const { onClose } = props
  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const goToLoginPage = () => {
    onClose()
    rootNavigation.navigate(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
  }

  const goToRegisterPage = () => {
    onClose()
    rootNavigation.navigate(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_AGREE_TO_TERMS })
  }

  return (
    <React.Fragment>
      <HStack
        space={2}
        justifyContent='space-between'
        borderBottomWidth={1}
        borderBottomColor='#b1b1b1'
        pb='20px'
        mb='15px'
        mt='20px'
      >
        <Text fontSize='18px' fontWeight={600} my='auto'>
          안녕하세요. 로그인해주세요.
        </Text>
        <CButton my='auto' onPress={onClose}>
          <FastImage
            source={require('@/assets/images/x-icon.png')}
            style={{
              width: 21,
              height: 21,
            }}
            resizeMode='contain'
          />
        </CButton>
      </HStack>
      <HStack space={3}>
        <CButton mr='5px' my='auto' onPress={goToLoginPage}>
          <Text fontSize='18px' fontWeight={600}>
            로그인
          </Text>
        </CButton>
        <Text mr='5px' my='auto' fontSize='14px' color='#E8E8E8'>
          |
        </Text>
        <CButton my='auto' onPress={goToRegisterPage}>
          <Text fontSize='18px' fontWeight={600}>
            회원가입
          </Text>
        </CButton>
      </HStack>
    </React.Fragment>
  )
}
