import React from 'react'
import { Center, Text } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import CButton from '@/components/CButton'
import { BOARD_PROPS } from '@/constants/site'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { screenWidthAtom } from '@/atom/navigationAtom'

interface Props {
  currentCategory: number
  handleCurrentCategory: (pCategory: number) => void
}

export default function CategoryTab(props: Props) {
  const { currentCategory, handleCurrentCategory } = props
  const [auth] = useAtom(authAtom)
  const [boardProps, setBoardProps] = React.useState(BOARD_PROPS.job)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [location, setLocation] = React.useState(
    boardProps.left.category == 20 && auth.logged ? 'right' : 'left',
  )
  const [screenWidth] = useAtom(screenWidthAtom)

  const handleBoardProps = (pCurrentCategory: number) => {
    switch (pCurrentCategory) {
      case 1:
      case 2:
        setBoardProps(BOARD_PROPS.job)
        break
      case 3:
      case 4:
        setBoardProps(BOARD_PROPS.property)
        break
      case 5:
      case 6:
        setBoardProps(BOARD_PROPS.shopping)
        break
      case 7:
      case 8:
        setBoardProps(BOARD_PROPS.service)
        break
      case 9:
      case 10:
        setBoardProps(BOARD_PROPS.education)
        break
      case 11:
      case 12:
        setBoardProps(BOARD_PROPS.trip)
        break
      case 13:
      case 14:
        setBoardProps(BOARD_PROPS.medical)
        break
      case 15:
      case 16:
        setBoardProps(BOARD_PROPS.law)
        break
      case 17:
      case 18:
        setBoardProps(BOARD_PROPS.club)
        break
      case 19:
      case 20:
        setBoardProps(BOARD_PROPS.address)
        break
    }
  }

  React.useEffect(() => {
    handleBoardProps(currentCategory)
  }, [currentCategory])

  return (
    <Center
      flexDirection='row'
      py='15px'
      w='full'
      mx='auto'
      bg={location == 'left' && boardProps.left.category === 20 ? null : '#FFF'}
    >
      <CButton
        onPress={() => {
          setLocation('left')
          if (boardProps.left.category === 20) {
            if (auth && auth.logged) {
              handleCurrentCategory(boardProps.left.category)
            } else {
              navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
            }
          } else {
            handleCurrentCategory(boardProps.left.category)
          }
        }}
      >
        <Center
          py='5px'
          w={(screenWidth - 60) / 2}
          h='55px'
          borderBottomWidth={boardProps.left.category === currentCategory ? '3' : '1'}
          borderColor={boardProps.left.category === currentCategory ? '#FF6F1E' : '#DADADA'}
        >
          <Text
            color={boardProps.left.category === currentCategory ? '#FF6F1E' : '#000000'}
            fontSize={24}
            fontWeight={700}
            mb='1px'
            textAlign='center'
          >
            {boardProps.left.title}
          </Text>
        </Center>
      </CButton>
      <CButton
        onPress={() => {
          setLocation('right')
          handleCurrentCategory(boardProps.right.category)
        }}
      >
        <Center
          py='5px'
          w={(screenWidth - 60) / 2}
          h='55px'
          borderBottomWidth={boardProps.right.category === currentCategory ? '3' : '1'}
          borderColor={boardProps.right.category === currentCategory ? '#FF6F1E' : '#DADADA'}
        >
          <Text
            color={boardProps.right.category === currentCategory ? '#FF6F1E' : '#000000'}
            fontSize={24}
            fontWeight={700}
            mb='1px'
            textAlign='center'
          >
            {boardProps.right.title}
          </Text>
        </Center>
      </CButton>
    </Center>
  )
}
