export enum AUTH_STATUS {
  INIT = 'INIT',
  SERVER_ERROR = 'SERVER_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  SOCIAL_LOGIN_FAILED = 'SOCIAL_LOGIN_FAILED',
  NO_PROFILE = 'NO_PROFILE',
  RESEND_SUCCESS = 'RESEND_SUCCESS',
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  PROVIDER_FAILED = 'PROVIDER_FAILED',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  ALREADY_LOCAL_REGISTER = 'ALREADY_LOCAL_REGISTER',
  ALREADY_SOCIAL_REGISTER = 'ALREADY_SOCIAL_REGISTER',
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
}
