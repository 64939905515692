import React from 'react'
import { VStack } from 'native-base'
import { Text } from 'native-base'
import DynamicSvgIcon from '@/assets/svgIcon/DynamicSvgIcon'

interface Props {
  title: string
  screen: string
}

const MyPageBoardBlank: React.FC<Props> = (props: Props) => {
  const { title, screen } = props

  return (
    <VStack flex={1} justifyContent='center' alignItems='center'>
      {screen == 'MyPageBoardScreen' && (
        <DynamicSvgIcon width='30' height='30' src={require('@/assets/svg/mypage-board.svg')} />
      )}
      {screen == 'MyPageCommentScreen' && (
        <DynamicSvgIcon width='30' height='30' src={require('@/assets/svg/mypage-board.svg')} />
      )}
      {screen == 'MyPageGoodScreen' && (
        <DynamicSvgIcon width='30' height='30' src={require('@/assets/svg/mypage-favorite.svg')} />
      )}
      {screen == 'MyPageMessageScreen' && (
        <DynamicSvgIcon width='30' height='30' src={require('@/assets/svg/mypage-note.svg')} />
      )}
      {screen == 'MyPageScrapScreen' && (
        <DynamicSvgIcon width='30' height='26' src={require('@/assets/svg/mypage-scrap.svg')} />
      )}
      {screen == 'MyPageSubscriptionScreen' && (
        <DynamicSvgIcon width='30' height='30' src={require('@/assets/svg/mypage-sub-block.svg')} />
      )}
      <Text mt='8px' fontSize='15px' fontWeight='bold'>
        {title}
      </Text>
    </VStack>
  )
}

export default MyPageBoardBlank
