import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { IBoard } from '@/interfaces/board'
import { RootStackParamList } from '@/interfaces/navigation'
import { Flex, HStack } from 'native-base'
import { Box, Text, View } from 'native-base'
import useFormatter from '@/hooks/useFormatter'
import { parse } from 'node-html-parser'
import { ROOT_STACK } from '@/constants/label'
import { findById } from '@/services/banner'
import FastImage from '@/components/FastImage'

interface Props {
  board: any
  screen: string
}

const MyPageBoardContents: React.FC<Props> = (props: Props) => {
  const { board, screen } = props
  const { dateFormatter, numberCommaFormatter } = useFormatter()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const root = parse(board.contents)
  const image = root.querySelector('img')
  const imageSRC = image ? image.getAttribute('src') : null

  const extractText = (node: any): string => {
    if (node.nodeType === 3) {
      // 텍스트 노드
      return node.rawText.trim()
    }
    if (node.tagName === 'BR') {
      // <br> 태그
      return '\n'
    }
    if (node.tagName === 'P' && node.innerHTML.trim() === '&nbsp;') {
      // 단독 &nbsp; 처리
      return '\n'
    }
    if (node.tagName === 'P') {
      // <p> 태그
      return node.childNodes.map(extractText).join('') + '\n'
    }
    if (node.childNodes) {
      let text = node.childNodes.map(extractText).join('')
      if (text.endsWith('&nbsp;')) {
        text = text.slice(0, -6) + '\n'
      }
      return text
    }
    return ''
  }

  let textContent = extractText(root)
  textContent = textContent.replace(/&nbsp;/g, '')
  textContent = textContent.replace(/\n\s*\n/g, '\n')

  const onPageChange = async (item: IBoard) => {
    if (item.type == 'advertisement') {
      const banner = await findById(item.category)
      navigation.push(ROOT_STACK.BANNER_BOARD_DETAIL, {
        id: board.id,
        category: item.category,
        banner: banner,
        goComment: true,
      })
    } else if (item.type == 'news') {
      navigation.push(ROOT_STACK.NEWS_DETAIL, { id: item.id })
    } else {
      navigation.push(ROOT_STACK.BOARD_DETAIL, {
        id: item.id,
        category: item.category,
      })
    }
  }

  return (
    <HStack justifyContent='space-between'>
      <Box flex='auto'>
        <HStack mt='6px'>
          {screen !== 'MyPageBoardScreen' &&
          screen !== 'MyPageCommentScreen' &&
          board.type !== 'news' ? (
            <Text
              flexShrink={1}
              fontSize='14px'
              color='#222222'
              mr='16px'
              numberOfLines={1}
              ellipsizeMode='tail'
            >
              {board.nick_name}
            </Text>
          ) : null}
          <Flex direction='row' flexShrink={1}>
            <Text fontSize='13px' color='#7C7C7C' mr='16px'>
              {dateFormatter(board.created_at)}
            </Text>
          </Flex>
        </HStack>
        <HStack>
          <Flex mt='6px' direction='row' flexShrink={1}>
            <Text fontSize='13px' color='#7C7C7C' mr='16px'>
              {'조회 '}
              {numberCommaFormatter(board.view_count)}
            </Text>
          </Flex>
          <View
            justifyContent='center'
            alignItems='center'
            bgColor='#EAEAEA'
            borderRadius='20px'
            px='10px'
            py='2px'
            mt='4px'
            mb='10px'
            onTouchEnd={() => {
              onPageChange
            }}
          >
            <HStack>
              <Text fontSize='12px' fontWeight={600}>
                댓글
              </Text>
              {board.comment_count && board.re_comment_count ? (
                <Text ml='4px' fontSize='12px' fontWeight='bold'>
                  {numberCommaFormatter(
                    Number(board.comment_count) + Number(board.re_comment_count),
                  )}
                </Text>
              ) : board.comment_count ? (
                <Text ml='4px' fontSize='12px' fontWeight='bold'>
                  {numberCommaFormatter(Number(board.comment_count))}
                </Text>
              ) : (
                <Text ml='4px' fontSize='12px' fontWeight='bold'>
                  {numberCommaFormatter(Number(board.re_comment_count))}
                </Text>
              )}
            </HStack>
          </View>
        </HStack>
        {screen && screen != 'MyPageCommentScreen' && (
          <Text fontSize='16px' color='#292929' numberOfLines={2} ellipsizeMode='tail'>
            {textContent.trim()}
          </Text>
        )}
      </Box>
      {imageSRC && (
        <Box justifyContent='end'>
          <FastImage
            source={imageSRC}
            style={{ width: 100, height: 100, marginLeft: 10 }}
            // resizeMode='contain'
          />
        </Box>
      )}
    </HStack>
  )
}

export default MyPageBoardContents
