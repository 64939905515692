import { IBoardType } from '@/interfaces/board'
import { MY_PAGE_TAB, TOP_TAB } from '@/constants/label'

export const BOARD_PROPS: IBoardType = {
  job: {
    bannerCategory: 1,
    pageColor: '#77D6F4',
    labelColor: '#2DB8E4',
    link: '/board/job',
    left: {
      category: 1,
      title: '구인',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 2,
      title: '구직',
      contents: '이러한 일이 가능합니다',
    },
  },
  property: {
    bannerCategory: 2,
    pageColor: '#BFB6B0',
    labelColor: '#93847B',
    link: '/board/property',
    left: {
      category: 3,
      title: '부동산',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 4,
      title: '부동산',
      contents: '이러한 일이 가능합니다',
    },
  },
  shopping: {
    bannerCategory: 3,
    pageColor: '#D4C96B',
    labelColor: '#CAB926',
    link: '/board/shopping',
    left: {
      category: 5,
      title: '삽니다',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 6,
      title: '팝니다',
      contents: '이러한 일이 가능합니다',
    },
  },
  service: {
    bannerCategory: 4,
    pageColor: '#AAB566',
    labelColor: '#99A93A',
    link: '/board/service',
    left: {
      category: 7,
      title: '서비스',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 8,
      title: '서비스',
      contents: '이러한 일이 가능합니다',
    },
  },
  education: {
    bannerCategory: 6,
    pageColor: '#9FA1DA',
    labelColor: '#7174C9',
    link: '/board/education',
    left: {
      category: 9,
      title: '교육',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 10,
      title: '교육',
      contents: '이러한 일이 가능합니다',
    },
  },
  trip: {
    bannerCategory: 8,
    pageColor: '#DA9FD7',
    labelColor: '#C57AC1',
    link: '/board/trip',
    left: {
      category: 11,
      title: '여행여가',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 12,
      title: '여행여가',
      contents: '이러한 일이 가능합니다',
    },
  },
  medical: {
    bannerCategory: 7,
    pageColor: '#EA9797',
    labelColor: '#DB7171',
    link: '/board/medical',
    left: {
      category: 13,
      title: '의료미용',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 14,
      title: '의료미용',
      contents: '이러한 일이 가능합니다',
    },
  },
  law: {
    bannerCategory: 5,
    pageColor: '#E9CA26',
    labelColor: '#D1B000',
    link: '/board/law',
    left: {
      category: 15,
      title: '법률금융',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 16,
      title: '법률금융',
      contents: '이러한 일이 가능합니다',
    },
  },
  club: {
    bannerCategory: 9,
    pageColor: '#81C827',
    labelColor: '#78C318',
    link: '/board/club',
    left: {
      category: 17,
      title: '단체동호회',
      contents: '함께 일 할 분을 모집합니다',
    },
    right: {
      category: 18,
      title: '단체동호회',
      contents: '이러한 일이 가능합니다',
    },
  },
  address: {
    bannerCategory: 10,
    pageColor: '#DEF2F1',
    labelColor: '#48C0BA',
    link: '/board/address',
    left: {
      category: 20,
      title: '주소록',
      contents: '업체 주소를 찾으시나요?',
    },
    right: {
      category: 19,
      title: '게시판',
      contents: '업체주소 문의해주세요.',
    },
  },
}

export const CATEGORY_LABEL = [
  {
    id: 0,
    label: '뉴스',
    categoryName: 'news',
    page: TOP_TAB.NEWS,
  },
  {
    id: 1,
    label: '구인구직',
    categoryName: 'job',
    page: TOP_TAB.JOB,
  },
  {
    id: 2,
    label: '구직',
    categoryName: 'job',
    page: TOP_TAB.JOB,
  },
  {
    id: 3,
    label: '부동산',
    categoryName: 'property',
    page: TOP_TAB.PROPERTY,
  },
  {
    id: 4,
    label: '부동산',
    categoryName: 'property',
    page: TOP_TAB.PROPERTY,
  },
  {
    id: 5,
    label: '쇼핑',
    categoryName: 'shopping',
    page: TOP_TAB.SHOPPING,
  },
  {
    id: 6,
    label: '쇼핑',
    categoryName: 'shopping',
    page: TOP_TAB.SHOPPING,
  },
  {
    id: 7,
    label: '서비스',
    categoryName: 'service',
    page: TOP_TAB.SERVICE,
  },
  {
    id: 8,
    label: '서비스',
    categoryName: 'service',
    page: TOP_TAB.SERVICE,
  },
  {
    id: 9,
    label: '교육',
    categoryName: 'education',
    page: TOP_TAB.EDUCATION,
  },
  {
    id: 10,
    label: '교육',
    categoryName: 'education',
    page: TOP_TAB.EDUCATION,
  },
  {
    id: 11,
    label: '여행 ・ 여가',
    categoryName: 'trip',
    page: TOP_TAB.TRIP,
  },
  {
    id: 12,
    label: '여행 ・ 여가',
    categoryName: 'trip',
    page: TOP_TAB.TRIP,
  },
  {
    id: 13,
    label: '의료 ・ 미용',
    categoryName: 'medical',
    page: TOP_TAB.MEDICAL,
  },
  {
    id: 14,
    label: '의료 ・ 미용',
    categoryName: 'medical',
    page: TOP_TAB.MEDICAL,
  },
  {
    id: 15,
    label: '법률 ・ 금융',
    categoryName: 'law',
    page: TOP_TAB.LAW,
  },
  {
    id: 16,
    label: '법률 ・ 금융',
    categoryName: 'law',
    page: TOP_TAB.LAW,
  },
  {
    id: 17,
    label: '단체 ・ 동호회',
    categoryName: 'club',
    page: TOP_TAB.CLUB,
  },
  {
    id: 18,
    label: '단체 ・ 동호회',
    categoryName: 'club',
    page: TOP_TAB.CLUB,
  },
  {
    id: 19,
    label: '주소록',
    categoryName: 'address',
    page: TOP_TAB.ADDRESS,
  },
  {
    id: 20,
    label: '주소록',
    categoryName: 'address',
    page: TOP_TAB.ADDRESS,
  },
]

export const BANNER_CATEGORIES = [
  {
    id: 1,
    label: '구인구직',
    link: 'job',
    page: TOP_TAB.JOB,
  },
  {
    id: 2,
    label: '부동산',
    link: 'property',
    page: TOP_TAB.PROPERTY,
  },
  {
    id: 3,
    label: '쇼핑',
    link: 'shopping',
    page: TOP_TAB.SHOPPING,
  },
  {
    id: 4,
    label: '서비스',
    link: 'service',
    page: TOP_TAB.SERVICE,
  },
  {
    id: 5,
    label: '법률・금융',
    link: 'law',
    page: TOP_TAB.LAW,
  },
  {
    id: 6,
    label: '교육',
    link: 'education',
    page: TOP_TAB.EDUCATION,
  },
  {
    id: 7,
    label: '의료・미용',
    link: 'medical',
    page: TOP_TAB.MEDICAL,
  },
  {
    id: 8,
    label: '여행・여가',
    link: 'trip',
    page: TOP_TAB.TRIP,
  },
  {
    id: 9,
    label: '단체・동호회',
    link: 'club',
    page: TOP_TAB.CLUB,
  },
  {
    id: 10,
    label: '주소록',
    link: 'address',
    page: TOP_TAB.ADDRESS,
  },
  {
    id: 11,
    label: '기본',
    link: 'common',
    page: TOP_TAB.MAIN,
  },
]

export const MYPAGE_LABELS = [
  {
    index: 0,
    name: MY_PAGE_TAB.MY_PAGE_BOARD,
    title: '작성글',
  },
  {
    index: 1,
    name: MY_PAGE_TAB.MY_PAGE_COMMENT,
    title: '작성댓글',
  },
  {
    index: 2,
    name: MY_PAGE_TAB.MY_PAGE_GOOD,
    title: '좋아요!',
  },
  {
    index: 3,
    name: MY_PAGE_TAB.MY_PAGE_MESSAGE,
    title: '쪽지함',
  },
  {
    index: 4,
    name: MY_PAGE_TAB.MY_PAGE_SCRAP,
    title: '스크랩',
  },
  {
    index: 5,
    name: MY_PAGE_TAB.MY_PAGE_SUBSCRIPTION,
    title: '구독',
  },
]

export const CATEGORY_LIST = [
  {
    category: 1,
    title: '구인・구직',
    name: 'JOB',
  },
  // {
  //   category: 2,
  //   title: '구직',
  //   name: 'JOB',
  // },
  {
    category: 3,
    title: '부동산',
    name: 'PROPERTY',
  },
  {
    category: 5,
    title: '쇼핑',
    name: 'SHOPPING',
  },
  {
    category: 7,
    title: '서비스',
    name: 'SERVICE',
  },
  {
    category: 9,
    title: '교육',
    name: 'EDUCATION',
  },
  {
    category: 11,
    title: '여행·여가',
    name: 'TRIP',
  },
  {
    category: 13,
    title: '의료·미용',
    name: 'MEDICAL',
  },
  {
    category: 15,
    title: '법률·금융',
    name: 'LAW',
  },
  {
    category: 17,
    title: '단체·동호회',
    name: 'CLUB',
  },
  {
    category: 19,
    title: '주소록',
    name: 'ADDRESS',
  },
]

// export const CATEGORY_LIST = [
//   {
//     category: 1,
//     title: '구인',
//   },
//   {
//     category: 2,
//     title: '구직',
//   },
//   {
//     category: 3,
//     title: '부동산 1',
//   },
//   {
//     category: 4,
//     title: '부동산 2',
//   },
//   {
//     category: 5,
//     title: '쇼핑삽니다',
//   },
//   {
//     category: 6,
//     title: '쇼핑팝니다',
//   },
//   {
//     category: 7,
//     title: '서비스 1',
//   },
//   {
//     category: 8,
//     title: '서비스 2',
//   },
//   {
//     category: 9,
//     title: '교육 1',
//   },
//   {
//     category: 10,
//     title: '교육 2',
//   },
//   {
//     category: 11,
//     title: '여행',
//   },
//   {
//     category: 12,
//     title: '여가',
//   },
//   {
//     category: 13,
//     title: '의료',
//   },
//   {
//     category: 14,
//     title: '미용',
//   },
//   {
//     category: 15,
//     title: '법률',
//   },
//   {
//     category: 16,
//     title: '금융',
//   },
//   {
//     category: 17,
//     title: '단체',
//   },
//   {
//     category: 18,
//     title: '동호회',
//   },
//   {
//     category: 19,
//     title: '주소록',
//   },
// ]

// export const BOARD_LABEL = [
//   {
//     id: 1,
//     label: '구인구직',
//     categoryName: 'job',
//     screenName: TOP_TAB.JOB,
//     listName: BOARD_STACK.JOB_BOARD_LIST,
//     bannerCategory: 1,
//   },
//   {
//     id: 2,
//     label: '구인구직',
//     categoryName: 'job',
//     screenName: TOP_TAB.JOB,
//     listName: BOARD_STACK.JOB_BOARD_LIST,
//     bannerCategory: 1,
//   },
//   {
//     id: 3,
//     label: '부동산',
//     categoryName: 'property',
//     screenName: TOP_TAB.PROPERTY_STACK,
//     listName: BOARD_STACK.PROPERTY_BOARD_LIST,
//     bannerCategory: 2,
//   },
//   {
//     id: 4,
//     label: '부동산',
//     categoryName: 'property',
//     screenName: TOP_TAB.PROPERTY_STACK,
//     listName: BOARD_STACK.PROPERTY_BOARD_LIST,
//     bannerCategory: 2,
//   },
//   {
//     id: 5,
//     label: '쇼핑',
//     categoryName: 'shopping',
//     screenName: TOP_TAB.SHOPPING_STACK,
//     listName: BOARD_STACK.SHOPPING_BOARD_LIST,
//     bannerCategory: 3,
//   },
//   {
//     id: 6,
//     label: '쇼핑',
//     categoryName: 'shopping',
//     screenName: TOP_TAB.SHOPPING_STACK,
//     listName: BOARD_STACK.SHOPPING_BOARD_LIST,
//     bannerCategory: 3,
//   },
//   {
//     id: 7,
//     label: '서비스',
//     categoryName: 'service',
//     screenName: TOP_TAB.SERVICE_STACK,
//     listName: BOARD_STACK.SERVICE_BOARD_LIST,
//     bannerCategory: 4,
//   },
//   {
//     id: 8,
//     label: '서비스',
//     categoryName: 'service',
//     screenName: TOP_TAB.SERVICE_STACK,
//     listName: BOARD_STACK.SERVICE_BOARD_LIST,
//     bannerCategory: 4,
//   },
//   {
//     id: 9,
//     label: '교육',
//     categoryName: 'education',
//     screenName: TOP_TAB.EDUCATION_STACK,
//     listName: BOARD_STACK.EDUCATION_BOARD_LIST,
//     bannerCategory: 5,
//   },
//   {
//     id: 10,
//     label: '교육',
//     categoryName: 'education',
//     screenName: TOP_TAB.EDUCATION_STACK,
//     listName: BOARD_STACK.EDUCATION_BOARD_LIST,
//     bannerCategory: 5,
//   },
//   {
//     id: 11,
//     label: '여행 ・ 여가',
//     categoryName: 'trip',
//     screenName: TOP_TAB.TRIP_STACK,
//     listName: BOARD_STACK.TRIP_BOARD_LIST,
//     bannerCategory: 6,
//   },
//   {
//     id: 12,
//     label: '여행 ・ 여가',
//     categoryName: 'trip',
//     screenName: TOP_TAB.TRIP_STACK,
//     listName: BOARD_STACK.TRIP_BOARD_LIST,
//     bannerCategory: 6,
//   },
//   {
//     id: 13,
//     label: '의료 ・ 미용',
//     categoryName: 'medical',
//     screenName: TOP_TAB.MEDICAL_STACK,
//     listName: BOARD_STACK.MEDICAL_BOARD_LIST,
//     bannerCategory: 7,
//   },
//   {
//     id: 14,
//     label: '의료 ・ 미용',
//     categoryName: 'medical',
//     screenName: TOP_TAB.MEDICAL_STACK,
//     listName: BOARD_STACK.MEDICAL_BOARD_LIST,
//     bannerCategory: 7,
//   },
//   {
//     id: 15,
//     label: '법률 ・ 금융',
//     categoryName: 'law',
//     screenName: TOP_TAB.LAW_STACK,
//     listName: BOARD_STACK.LAW_BOARD_LIST,
//     bannerCategory: 8,
//   },
//   {
//     id: 16,
//     label: '법률 ・ 금융',
//     categoryName: 'law',
//     screenName: TOP_TAB.LAW_STACK,
//     listName: BOARD_STACK.LAW_BOARD_LIST,
//     bannerCategory: 8,
//   },
//   {
//     id: 17,
//     label: '단체 ・ 동호회',
//     categoryName: 'club',
//     screenName: TOP_TAB.CLUB_STACK,
//     listName: BOARD_STACK.CLUB_BOARD_LIST,
//     bannerCategory: 9,
//   },
//   {
//     id: 18,
//     label: '단체 ・ 동호회',
//     categoryName: 'club',
//     screenName: TOP_TAB.CLUB_STACK,
//     listName: BOARD_STACK.CLUB_BOARD_LIST,
//     bannerCategory: 9,
//   },
//   {
//     id: 19,
//     label: '주소록',
//     categoryName: 'address',
//     screenName: TOP_TAB.ADDRESS_STACK,
//     listName: BOARD_STACK.ADDRESS_BOARD_LIST,
//     bannerCategory: 10,
//   },
//   {
//     id: 20,
//     label: '주소록',
//     categoryName: 'address',
//     screenName: TOP_TAB.ADDRESS_STACK,
//     listName: BOARD_STACK.ADDRESS_BOARD_LIST,
//     bannerCategory: 10,
//   },
// ]

export const BANNER_CATEGORY_LABEL = [
  {
    bannerCategory: 1,
    label: '구인구직',
  },
  {
    bannerCategory: 2,
    label: '부동산',
  },
  {
    bannerCategory: 3,
    label: '상품',
  },
  {
    bannerCategory: 4,
    label: '서비스',
  },
  {
    bannerCategory: 5,
    label: '비자・법률',
  },
  {
    bannerCategory: 6,
    label: '교육',
  },
  {
    bannerCategory: 7,
    label: '의료',
  },
  {
    bannerCategory: 8,
    label: '여행',
  },
  {
    bannerCategory: 9,
    label: '단체・동호회',
  },
  {
    bannerCategory: 10,
    label: '주소록',
  },
  {
    bannerCategory: 11,
    label: '기본',
  },
]
