import React from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import WebBrowser from '@/screens/detail/components/WebBrowser'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.BANNER_COMPANY_DETAIL>

export default function BannerCompanyDetailScreen(props: Props) {
  const { title, uri } = props.route.params

  return <WebBrowser title={title} uri={uri} />
}
