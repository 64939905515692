import { Center, Modal, Text } from 'native-base'
import CButton from '@/components/CButton'
import React from 'react'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { findOneComplainHistories } from '@/services/articleElements'
import { IComplainType } from '@/interfaces/complainType'
import { findAllComplainType } from '@/services/complainType'
import ModalAlert from '@/components/ModalAlert'
import { screenWidthAtom } from '@/atom/navigationAtom'
import RadioGroup from 'react-native-radio-buttons-group'

interface Props {
  saveComplain: (value: number) => void
  isOpen: boolean
  onClose: () => void
  type: string
  typeId: number
}

export default function ModalComplain(props: Props) {
  const { isOpen, onClose, type, typeId, saveComplain } = props
  const [auth] = useAtom(authAtom)
  const [radioValue, setRadioValue] = React.useState('1')
  const [complainTypeList, setComplainTypeList] = React.useState<IComplainType[]>([])
  const [complainTypeButtons, setComplainTypeButtons] = React.useState<unknown[]>([])
  const [loading, setLoading] = React.useState(false)
  const [isOpenAlert, setIsOpenAlert] = React.useState(false)
  const [screenWidth] = useAtom(screenWidthAtom)

  if (!type || typeId < 1) {
    onClose()
  }

  const handleOpenAlert = (value: boolean) => {
    setIsOpenAlert(value)
    if (!value) onClose()
  }

  const doComplain = async () => {
    saveComplain(Number(radioValue))
    handleOpenAlert(true)
  }

  const complainTypeListMemo = React.useMemo(
    () =>
      complainTypeList.map((item) => ({
        id: item.id.toString(),
        label: item.name,
        value: item.name,
        size: 18,
      })),
    [complainTypeList],
  )

  React.useEffect(() => {
    setComplainTypeButtons(complainTypeListMemo)
  }, [complainTypeListMemo])

  React.useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (auth.user) {
        await findOneComplainHistories({
          type: type,
          type_id: typeId,
          reported_user: auth.user.id,
        }).then((data) => {
          setRadioValue(data && data.complain_type ? data.complain_type.id.toString() : '1')
        })
      }
      await findAllComplainType()
        .then((data) => {
          setComplainTypeList(data)
        })
        .then(() => setLoading(false))
    }
    void fetchData()
  }, [auth.user, type, typeId])

  return !loading ? (
    isOpenAlert ? (
      <ModalAlert
        isOpen={isOpenAlert}
        message='신고되었습니다.'
        onClose={() => handleOpenAlert(false)}
      />
    ) : (
      <Modal isOpen={isOpen} onClose={() => onClose()}>
        <Modal.Content w={screenWidth - 60} borderRadius='8px' bgColor='white' px='20px'>
          <Modal.Header bgColor='white' borderBottomColor='#ff6f1e'>
            <Text textAlign='center' fontSize='18px' fontWeight={600}>
              신고하기
            </Text>
          </Modal.Header>
          <Modal.Body bgColor='white' px={0}>
            <RadioGroup
              radioButtons={complainTypeListMemo}
              onPress={(value) => {
                setRadioValue(value)
              }}
              selectedId={radioValue}
              containerStyle={{ alignItems: 'flex-start' }}
            />
            <Text mt={4} fontSize='14px'>
              {
                '신고하신 내역은 운영정책에 따라 처리되며, \n허위 신고시 이용에 제한을 받으실 수 있습니다.'
              }
            </Text>
          </Modal.Body>
          <Modal.Footer bgColor='white' borderTopColor='#eeeff2' justifyContent='center'>
            <CButton onPress={() => onClose()}>
              <Center
                w='75px'
                h='33px'
                backgroundColor='white'
                borderRadius='20px'
                borderWidth='1px'
                borderColor='#cdcdcd'
                margin='5px'
              >
                <Text color='#8f8f8f' fontWeight='600' fontSize={15}>
                  취소
                </Text>
              </Center>
            </CButton>
            <CButton onPress={doComplain}>
              <Center
                w='75px'
                h='33px'
                backgroundColor='#8f8f8f'
                borderRadius='20px'
                borderWidth='1px'
                borderColor='#cdcdcd'
                margin='5px'
              >
                <Text color='white' fontWeight='600' fontSize={15}>
                  신고하기
                </Text>
              </Center>
            </CButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    )
  ) : null
}
