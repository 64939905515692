import React from 'react'
import { Box, Center, Text } from 'native-base'

import CButton from '@/components/CButton'

interface Props {
  selectedTabId: string
  handleSelectedTabId: (value: string) => void
}

export default function MyPageSubscriptionTab(props: Props) {
  const { selectedTabId, handleSelectedTabId } = props

  return (
    <Box flexDirection='row' alignItems='center' justifyContent='center' mt='16px'>
      <CButton width='45%' onPress={() => handleSelectedTabId('MEMBER_BOARD')}>
        <Center
          minH='40px'
          bgColor={selectedTabId === 'MEMBER_BOARD' ? '#FFFFFF' : '#EAEAEA'}
          borderColor={selectedTabId === 'MEMBER_BOARD' ? '#FF6F1E' : '#EAEAEA'}
          borderWidth='2px'
          borderRadius='20px'
          mr='8px'
        >
          <Text
            textAlign='center'
            color={selectedTabId === 'MEMBER_BOARD' ? '#FF6F1E' : '#222222'}
            fontWeight={selectedTabId === 'MEMBER_BOARD' ? 'bold' : 'regular'}
            fontSize='16px'
          >
            회원 구독
          </Text>
        </Center>
      </CButton>
      <CButton width='45%' onPress={() => handleSelectedTabId('ADVER_BOARD')}>
        <Center
          minH='40px'
          bgColor={selectedTabId === 'ADVER_BOARD' ? '#FFFFFF' : '#EAEAEA'}
          borderColor={selectedTabId === 'ADVER_BOARD' ? '#FF6F1E' : '#EAEAEA'}
          borderWidth='2px'
          borderRadius='20px'
          mr='8px'
        >
          <Text
            textAlign='center'
            color={selectedTabId === 'ADVER_BOARD' ? '#FF6F1E' : '#222222'}
            fontWeight={selectedTabId === 'ADVER_BOARD' ? 'bold' : 'regular'}
            fontSize='16px'
          >
            게시판 구독
          </Text>
        </Center>
      </CButton>
    </Box>
  )
}
