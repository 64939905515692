import React from 'react'

interface Props {
  width?: string
  height?: string
  fillColor?: string
}

const ExpireIcon: React.FC<Props> = ({ width = '36', height = '24', fillColor = '#292D32' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 36 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='24' rx='8' fill={fillColor} />
      <path
        d='M9.256 9.92H11.368V12.576H9.256V9.92ZM3.64 11.696H17.032V13.376H3.64V11.696ZM10.312 14.192C13.528 14.192 15.448 15.136 15.448 16.816C15.448 18.496 13.528 19.424 10.312 19.424C7.096 19.424 5.16 18.496 5.16 16.816C5.16 15.136 7.096 14.192 10.312 14.192ZM10.312 15.792C8.312 15.792 7.32 16.112 7.32 16.816C7.32 17.536 8.312 17.84 10.312 17.84C12.312 17.84 13.304 17.536 13.304 16.816C13.304 16.112 12.312 15.792 10.312 15.792ZM8.872 6H10.76V6.368C10.76 8.592 8.76 10.4 4.936 10.768L4.2 9.088C7.448 8.816 8.872 7.52 8.872 6.368V6ZM9.912 6H11.8V6.368C11.8 7.52 13.208 8.816 16.472 9.088L15.736 10.768C11.928 10.4 9.912 8.608 9.912 6.368V6ZM4.856 5.216H15.832V6.896H4.856V5.216ZM21.7668 13.248H23.8468V16.736H21.7668V13.248ZM26.4548 13.232H28.5348V16.736H26.4548V13.232ZM18.3748 16.064H31.7828V17.792H18.3748V16.064ZM19.8788 5.568H30.2788V10.48H22.0228V12.992H19.9108V8.816H28.1668V7.248H19.8788V5.568ZM19.9108 12.096H30.6148V13.808H19.9108V12.096Z'
        fill='white'
      />
    </svg>
  )
}

export default ExpireIcon
