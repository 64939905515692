import React from 'react'
import { TouchableHighlight } from 'react-native'
import { Box, HStack, VStack, Text, Center, View } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import useFormatter from '@/hooks/useFormatter'
import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { IBoard } from '@/interfaces/board'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  item: IBoard
}

export default function SearchResultBoardItem(props: Props) {
  const { item } = props
  const noticeColor =
    item.writing_type !== '일반 게시물' ? item.color_type.substring(3, 10) : 'white'
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { dateFormatter, numberCommaFormatter, categoryFormatter } = useFormatter()

  const handleNewIcon = (createAt: string) => {
    const createDate = new Date(createAt)
    const currentDate = new Date()
    createDate.setDate(createDate.getDate() + 1)

    return createDate > currentDate
  }

  const containImgTag = (contents: string) => {
    return contents.indexOf('<img') > 0
  }

  const getImageUri = (contents: string) => {
    const imgStartIndex = contents.indexOf('<img')
    const imgEndIndex = contents.indexOf('>', imgStartIndex)
    const imgTag = contents.substring(imgStartIndex, imgEndIndex + 1)
    const srcStartIndex = imgTag.indexOf('src="') + 5
    const srcEndIndex = imgTag.indexOf('"', srcStartIndex)
    return imgTag.substring(srcStartIndex, srcEndIndex)
  }

  return item.writing_type !== '일반 게시물' ? (
    <TouchableHighlight
      onPress={() =>
        navigation.push(ROOT_STACK.BOARD_DETAIL, { id: item.id, category: item.category })
      }
      underlayColor='#e9e9e9'
    >
      <VStack
        py='10px'
        px='18px'
        borderBottomWidth='1px'
        borderBottomColor='#f1f1f1'
        // minH='75px'
        bg='#ffffff'
      >
        <HStack pt='5px' space='12px'>
          <VStack flex={1} alignContent='center' mr='4px'>
            <Text fontSize='15px' fontWeight='bold' mb='8px'>
              {categoryFormatter(item.category)} 게시판
            </Text>
            <HStack mb='5px'>
              <Center
                borderColor={noticeColor}
                mr='8px'
                borderWidth={1}
                borderRadius='28px'
                w='36px'
                h='20px'
              >
                <Text mt='2px' fontSize='13px' fontWeight={700} color={noticeColor}>
                  {item.writing_type}
                </Text>
              </Center>
              <Text
                color={noticeColor}
                numberOfLines={1}
                ellipsizeMode='tail'
                fontSize='15px'
                fontWeight={700}
              >
                {item.title}
              </Text>
            </HStack>
            <HStack>
              <Text
                maxW={120}
                fontSize='13px'
                color='#6C6C6C'
                mr='8px'
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {item.nick_name}
              </Text>
              <Text fontSize='13px' color='#6C6C6C' mr='8px'>
                {dateFormatter(item.created_at)}
              </Text>
              <Text color='#6C6C6C' fontSize='13px' mr='8px'>
                {'조회 '}
                {numberCommaFormatter(item.view_count)}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </TouchableHighlight>
  ) : (
    <TouchableHighlight
      onPress={() =>
        navigation.push(ROOT_STACK.BOARD_DETAIL, { id: item.id, category: item.category })
      }
      underlayColor='#e9e9e9'
    >
      <HStack
        borderBottomWidth='1px'
        borderBottomColor='#f1f1f1'
        // minH='75px'
        py='16px'
        px='16px'
        bg='#ffffff'
      >
        <VStack flex={1} alignContent='center'>
          <Text fontSize='13px' fontWeight='bold'>
            {categoryFormatter(item.category)} 게시판
          </Text>
          {handleNewIcon(item.created_at) ? (
            //오늘 올린 글
            <HStack mb='5px'>
              <Text
                numberOfLines={3}
                ellipsizeMode='tail'
                fontSize='15px'
                fontWeight={400}
                pr='8px'
                lineHeight='22px'
                color={item.isblock ? '#6C6C6C' : 'black'}
              >
                <View
                  mr='8px'
                  alignItems='center'
                  bgColor='#ff6f1e'
                  borderRadius='20px'
                  h='13px'
                  w='13.5px'
                >
                  <Text color='white' fontSize='12px' fontWeight={600} my='auto' lineHeight='16px'>
                    N
                  </Text>
                </View>
                {item.title}
              </Text>
            </HStack>
          ) : (
            <Text
              numberOfLines={3}
              ellipsizeMode='tail'
              fontSize='15px'
              fontWeight={400}
              pr='0px'
              color={item.isblock ? '#6C6C6C' : 'black'}
            >
              {item.title}
            </Text>
          )}
          <HStack h='20px'>
            <Text
              maxW={120}
              fontSize='14px'
              color='#6C6C6C'
              mr='10px'
              numberOfLines={1}
              ellipsizeMode='tail'
            >
              {item.nick_name}
            </Text>
            <Text fontSize='14px' color='#6C6C6C' mr='10px'>
              {dateFormatter(item.created_at)}
            </Text>
            <Text color='#6C6C6C' fontSize='14px' mr='10px'>
              {'조회 '}
              {numberCommaFormatter(item.view_count)}
            </Text>
            <View
              justifyContent='center'
              alignItems='center'
              bgColor='#EAEAEA'
              borderRadius='20px'
              px='10px'
              py='10px'
              onTouchEnd={() =>
                navigation.push(ROOT_STACK.BOARD_DETAIL, {
                  id: item.id,
                  category: item.category,
                  goComment: true,
                })
              }
            >
              <Text mt='2px' fontSize='13px' fontWeight={600}>
                {'댓글 '}
                {numberCommaFormatter(Number(item.comment_count) + Number(item.re_comment_count))}
              </Text>
            </View>
          </HStack>
        </VStack>
        {containImgTag(item.contents) && (
          <Box mr='4px' borderRadius='4px'>
            <FastImage
              source={{ uri: getImageUri(item.contents) }}
              style={{
                width: 45,
                height: 54,
                borderRadius: 4,
              }}
              resizeMode='cover'
            />
          </Box>
        )}
      </HStack>
    </TouchableHighlight>
  )
}
