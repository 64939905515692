import { Box, Center, Text } from 'native-base'
import { IUserContentsMessageBoard } from '@/interfaces/userContents'

interface Props {
  messageBoard: IUserContentsMessageBoard
  selectedTabId: string
  callRemoveMessage: (id: number) => void
  callKeepMessage: (id: number) => void
}

export default function MyPageMessageItemContents(props: Props) {
  const { messageBoard } = props

  return (
    <Box>
      <Box mb='8px'>
        <Text fontSize='15px'>{messageBoard.message}</Text>
      </Box>
    </Box>
  )
}
