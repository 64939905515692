import React, { Fragment } from 'react'
import { Box, Pressable } from 'native-base'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'

import TopAuthMenuNotLogin from '@/navigation/components/TopAuthMenuNotLogin'
import TopAuthMenuLogin from '@/navigation/components/TopAuthMenuLogin'
import { isOpenTopAuthMenuAtom } from '@/atom/navigationAtom'

export default function ModalTopAuthMenu() {
  const [auth] = useAtom(authAtom)
  const [isOpen, setOpen] = useAtom(isOpenTopAuthMenuAtom)

  return isOpen ? (
    <Fragment>
      <Pressable
        position='absolute'
        w='full'
        h='full'
        bg='black'
        opacity='20'
        zIndex={999}
        onPress={() => setOpen(false)}
      />
      <Box
        position='absolute'
        zIndex={1000}
        px='20px'
        pt='5px'
        pb='20px'
        bgColor='white'
        w='full'
        borderBottomRadius={20}
      >
        {auth.logged ? (
          <TopAuthMenuLogin onClose={() => setOpen(false)} />
        ) : (
          <TopAuthMenuNotLogin onClose={() => setOpen(false)} />
        )}
      </Box>
    </Fragment>
  ) : null
}
