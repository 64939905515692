import React from 'react'
import { Center, Text } from 'native-base'
import { TouchableHighlight } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { screenWidthAtom } from '@/atom/navigationAtom'

import useUrl from '@/hooks/useUrl'
import FastImage from '@/components/FastImage'
import { ROOT_STACK } from '@/constants/label'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  item: IBanner
}

export default function SearchResultBannerItem(props: Props) {
  const { item } = props
  const { handleUrl } = useUrl()
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <TouchableHighlight
      onPress={() => navigation.push(ROOT_STACK.BANNER_DETAIL, { id: item.id, isScroll: false })}
      underlayColor='#e9e9e9'
    >
      <Center
        bgColor='white'
        borderRadius='5px'
        w={screenWidth / 2 - 17}
        h={screenWidth / 2 - 17}
        m='1px'
        borderWidth='1px'
        borderColor='#D5D8DA'
      >
        <FastImage
          source={{
            uri: item.category_top_banner_image
              ? handleUrl(item.category_top_banner_image.toString())
              : item.category_top_thumbnail_path
              ? handleUrl(item.category_top_thumbnail_path)
              : '',
          }}
          resizeMode='contain'
          style={{
            width: 135,
            height: 26,
            marginBottom: 12,
            marginTop: 19,
          }}
        />
        <Text
          lineBreakMode='tail'
          numberOfLines={3}
          h='68px'
          fontSize='15px'
          fontWeight={400}
          px='17px'
          mb='8px'
          color='#222222'
        >
          {item.main_banner_text}
        </Text>
        <Text
          w='145px'
          h='20px'
          fontSize='15px'
          fontWeight={700}
          mb='16px'
          textAlign='center'
          lineBreakMode='tail'
          numberOfLines={1}
          color='#000000'
        >
          {item.contact_info}
        </Text>
      </Center>
    </TouchableHighlight>
  )
}
