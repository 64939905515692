import React from 'react'

interface Props {
  width?: string
  height?: string
  fillColor?: string
}

const RequiredIcon: React.FC<Props> = ({ width = '36', height = '24', fillColor = '#292D32' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 36 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='24' rx='8' fill={fillColor} />
      <path
        d='M15.1293 4.608H17.2733V12.192H15.1293V4.608ZM7.24125 12.784H17.2733V16.784H9.36925V18.576H7.27325V15.264H15.1613V14.416H7.24125V12.784ZM7.27325 17.68H17.6733V19.328H7.27325V17.68ZM5.56125 5.504H13.5453V7.136H5.56125V5.504ZM5.38525 11.936L5.17725 10.256C7.76925 10.256 11.2253 10.208 14.0893 9.856L14.2013 11.376C11.2733 11.856 7.94525 11.92 5.38525 11.936ZM6.72925 6.24H8.76125V11.088H6.72925V6.24ZM10.3133 6.24H12.3773V11.088H10.3133V6.24ZM19.656 11.792H33.048V13.456H19.656V11.792ZM25.272 9.376H27.4V12.304H25.272V9.376ZM21.128 14.464H31.464V19.472H29.352V16.144H21.128V14.464ZM21.272 8.8H31.592V10.496H21.272V8.8ZM21.272 5.2H31.464V6.88H23.4V9.712H21.272V5.2Z'
        fill='white'
      />
    </svg>
  )
}

export default RequiredIcon
