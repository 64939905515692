import React, { useRef } from 'react'
import { Box, Spinner } from 'native-base'
import Animated from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { findById } from '@/services/banner'
import useFormatter from '@/hooks/useFormatter'
import BannerDetail from '@/screens/detail/components/BannerDetail'
import Footer from '@/components/Footer'
import { IBanner } from '@/interfaces/banner'
import { RootStackParamList } from '@/interfaces/navigation'
import BannerStackHeader from '@/navigation/components/BannerStackHeader'
import { IBoard } from '@/interfaces/board'
import adBoardService from '@/services/adboard'
import { authAtom } from '@/atom/authAtom'
import { useAtom } from 'jotai'
import { LIMIT } from '@/constants/limit'
import { IFindCommentPushAgreeForm, ISendSubscriptPushForm } from '@/interfaces/push'
import PushAgree from '@/services/pushAgreeService'

export default function BannerDetailScreen(props: any) {
  const { id, isScroll } = props.route.params
  const [item, setItem] = React.useState<IBanner | null>(null)
  const [list, setList] = React.useState<IBoard[] | any[]>()
  const [count, setCount] = React.useState(0)
  const [subscript, setSubscript] = React.useState<IFindCommentPushAgreeForm | null>(null)
  const [auth] = useAtom(authAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { bannerCategoryFormatter } = useFormatter()
  const [boarderLimit, setboarderLimit] = React.useState(LIMIT.BANNER_BOARD_LIST_LIMIT)
  const scrollRef = useRef<any>()

  React.useEffect(() => {
    const fetchData = async () => {
      await findById(id).then(async (data) => {
        setItem(data)
        const ISendSubscriptPushForm: ISendSubscriptPushForm = {
          jwt: auth.jwt,
          user_id: auth.user ? auth.user.id : 0,
          type: 'advertisement',
          type_id: data.id,
        }
        if (auth.jwt != '') {
          await Promise.all([PushAgree.getBoardAgree(ISendSubscriptPushForm)]).then(
            async (values) => {
              if (values[0].length == 1) {
                setSubscript(values[0][0])
              }
            },
          )
        }
      })
      await Promise.all([
        adBoardService.findAllByCategory(
          id,
          auth.user?.id ? auth.user.id : 0,
          0,
          LIMIT.BANNER_BOARD_LIST_LIMIT,
        ),
        adBoardService.findCount(id),
      ]).then((values) => {
        setList(values[0].data)
        setCount(values[1].data.totalCount)
      })
    }
    void fetchData()
  }, [id])

  const onMoreBoarder = React.useCallback(async () => {
    const limit = boarderLimit + LIMIT.BANNER_BOARD_LIST_LIMIT
    setboarderLimit(limit)
    await Promise.all([
      adBoardService.findAllByCategory(id, auth.user?.id ? auth.user.id : 0, 0, limit),
    ]).then((values) => {
      setList(values[0].data)
    })
  }, [boarderLimit])

  const scrollToHandler = (scrollY: number) => {
    if (scrollRef.current) {
      scrollRef.current?.scrollTo({
        x: 0,
        y: scrollY, //we get the offset value from array based on key
        animated: true,
      })
    }
  }

  return item && list ? (
    <React.Fragment>
      <BannerStackHeader
        navigation={navigation}
        title={
          item.banner_category && item.banner_category.category_name
            ? item.banner_category.category_name
            : bannerCategoryFormatter(Number(item.banner_category))
        }
      />
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 50 }}
        ref={scrollRef}
      >
        <Box w='full' bgColor='#f4f4f4'>
          <Box w='full' bgColor='white'>
            <BannerDetail
              scrollRef={scrollToHandler}
              isScroll={isScroll}
              banner={item}
              subscript={subscript}
              list={list}
              onMore={onMoreBoarder}
              sum={count}
              boarderLimit={boarderLimit}
            />
          </Box>
        </Box>
        <Footer mb='10px' />
      </Animated.ScrollView>
    </React.Fragment>
  ) : (
    <Box flex={1} justifyContent='center'>
      <Spinner color='#ff6f1e' />
    </Box>
  )
}
