import React from 'react'
import { Platform, RefreshControl } from 'react-native'
import { useNavigation, useScrollToTop } from '@react-navigation/native'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'
import useScroll from '@/hooks/useScroll'
import { useAtom } from 'jotai'
import {
  // kyodoNewsListAtom,
  mainBannerListAtom,
  mainContentsBannerListAtom,
  yonhapNewsListAtom,
} from '@/atom/initAtom'
import { findAllMainNews } from '@/services/news'
import { findAllMainBanner, findAllMainContentsBanner } from '@/services/banner'
import {
  MainContentsBannerList,
  MainNewsList,
  MainNewsPaperBanner,
} from '@/screens/main/components'
import PlatformSpecificMainBannerList from './components/MainBanner/PlatformSpecificMainBannerList'
import Footer from '@/components/Footer'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { RootStackParamList, TopTabParamList } from '@/interfaces/navigation'
import { Env } from '@/constants/env'
import { findOneBoardById } from '@/services/board'
import { IBoard } from '@/interfaces/board'


type Props = NativeStackScreenProps<TopTabParamList, TOP_TAB.MAIN>

export default function MainScreen({ navigation }: Props) {
  const [mainBannerList, setMainBannerList] = useAtom(mainBannerListAtom)
  const [mainYonhapNewsList, setMainYonhapNewsList] = useAtom(yonhapNewsListAtom)
  const [mainContentsBannerList, setMainContentsBannerList] = useAtom(mainContentsBannerListAtom)
  const [refreshing, setRefreshing] = React.useState(false)

  const rootNavigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const initialUrl = Platform.OS === 'web' ? window.location.href : ''

  const { scrollHandler } = useScroll()

  const scrollRef = React.useRef<any>()
  useScrollToTop(scrollRef)

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true)
    await Promise.all([
      findAllMainNews(),
      findAllMainBanner(),
      findAllMainContentsBanner(),
    ])
      .then((values) => {
        setMainYonhapNewsList(values[0])
        setMainBannerList(values[1])
        setMainContentsBannerList(values[2])
      })
      .finally(() => {
        setRefreshing(false)
      })
  }, [setMainYonhapNewsList, setMainBannerList, setMainContentsBannerList])

  const scrollToTop = () => {
    scrollRef.current.scrollTo({ x: 0, y: 0, animated: true })
  }

  const goToBoardDetailScreen = async (id: string) => {
    const boardId = parseInt(id)
    const categoryId = await findOneBoardById({
      boardId,
      userId: 0,
    }).then((data) => {
      const board: IBoard = data
      return board.category
    })

    rootNavigation.push(ROOT_STACK.BOARD_DETAIL, {
      id: boardId,
      category: categoryId,
    })
  }

  React.useEffect(() => {
    const deepLinkMobileWeb = async () => {
      if (Platform.OS === 'web') {
        const replaceUrl = initialUrl.replace(Env.WEB_URL, '')
        if (replaceUrl !== '/') {
          const splitUrl = replaceUrl.split('/')
          if (splitUrl[1] === 'news') {
            rootNavigation.push(ROOT_STACK.NEWS_DETAIL, { id: parseInt(splitUrl[2]) })
          } else if (splitUrl[1] === 'board') {
            await goToBoardDetailScreen(splitUrl[3])
          }
        }
      }
    }
    void deepLinkMobileWeb()
  }, [])

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (navigation.getState().index !== 0) {
        scrollRef.current.scrollTo({ x: 0, y: 0, animated: false })
      }
    })
    return unsubscribe
  }, [navigation])

  return (
    <React.Fragment>
      <Animated.ScrollView
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={['#ff6f1e']}
            tintColor='#ff6f1e'
          />
        }
        showsVerticalScrollIndicator={false}
        onScroll={scrollHandler}
        scrollEventThrottle={16} // 1 or 16
      >
        {mainBannerList && mainBannerList.length ? (
          <PlatformSpecificMainBannerList mainBannerList={mainBannerList} />
        ) : null}
        <MainNewsList
          mainYonhapNewsList={
            mainYonhapNewsList && mainYonhapNewsList.length ? mainYonhapNewsList : []
          }
        />
        {mainContentsBannerList && mainContentsBannerList.length ? (
          <MainContentsBannerList mainContentsBannerList={mainContentsBannerList} />
        ) : null}
        <MainNewsPaperBanner />
        <Footer scrollToTop={scrollToTop} />
      </Animated.ScrollView>
    </React.Fragment>
  )
}
