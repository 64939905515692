import React from 'react'
import { Box, Center, Text } from 'native-base'

import CButton from '@/components/CButton'

interface Props {
  selectedTabId: string
  handleSelectedTabId: (value: string) => void
}

export default function MyPageMessageTab(props: Props) {
  const { selectedTabId, handleSelectedTabId } = props

  return (
    <Box flexDirection='row' alignItems='center' justifyContent='center' mt='16px'>
      <CButton width='30%' onPress={() => handleSelectedTabId('TO')}>
        <Center
          minH='40px'
          bgColor={selectedTabId === 'TO' ? '#FFFFFF' : '#EAEAEA'}
          borderColor={selectedTabId === 'TO' ? '#FF6F1E' : '#EAEAEA'}
          borderWidth='2px'
          borderRadius='20px'
          mr='8px'
        >
          <Text
            textAlign='center'
            color={selectedTabId === 'TO' ? '#FF6F1E' : '#222222'}
            fontWeight={selectedTabId === 'TO' ? 'bold' : 'regular'}
            fontSize='16px'
          >
            받은 쪽지함
          </Text>
        </Center>
      </CButton>
      <CButton width='30%' onPress={() => handleSelectedTabId('FROM')}>
        <Center
          minH='40px'
          bgColor={selectedTabId === 'FROM' ? '#FFFFFF' : '#EAEAEA'}
          borderColor={selectedTabId === 'FROM' ? '#FF6F1E' : '#EAEAEA'}
          borderWidth='2px'
          borderRadius='20px'
          mr='8px'
        >
          <Text
            textAlign='center'
            color={selectedTabId === 'FROM' ? '#FF6F1E' : '#222222'}
            fontWeight={selectedTabId === 'FROM' ? 'bold' : 'regular'}
            fontSize='16px'
          >
            보낸 쪽지함
          </Text>
        </Center>
      </CButton>
      <CButton width='30%' onPress={() => handleSelectedTabId('KEEP')}>
        <Center
          minH='40px'
          bgColor={selectedTabId === 'KEEP' ? '#FFFFFF' : '#EAEAEA'}
          borderColor={selectedTabId === 'KEEP' ? '#FF6F1E' : '#EAEAEA'}
          borderWidth='2px'
          borderRadius='20px'
        >
          <Text
            textAlign='center'
            color={selectedTabId === 'KEEP' ? '#FF6F1E' : '#222222'}
            fontWeight={selectedTabId === 'KEEP' ? 'bold' : 'regular'}
            fontSize='16px'
          >
            보관함
          </Text>
        </Center>
      </CButton>
    </Box>
  )
}
