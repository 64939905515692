import FastImage from '@/components/FastImage'
import { Box, Pressable } from 'native-base'
import React from 'react'

interface Props {
  onClose: () => void
}

export default function ImageModalHeader(props: Props) {
  const { onClose } = props

  return (
    <Box position='absolute' top='38px' left='15px' zIndex={9999}>
      <Pressable onPress={onClose}>
        <FastImage
          source={require('@/assets/images/white-back-icon.png')}
          style={{
            width: 24,
            height: 24,
          }}
          resizeMode='contain'
        />
      </Pressable>
    </Box>
  )
}
