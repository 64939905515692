import { Box, Text } from 'native-base'

interface Props {
  title: string
}

export default function AuthSubHeader(props: Props) {
  const { title } = props
  return (
    <Box borderBottomWidth='1px' borderBottomColor='#e8e8e8' mb='15px'>
      <Text fontSize='22px' textAlign='center' fontWeight='bold' color='#222222' pb='11px'>
        {title}
      </Text>
    </Box>
  )
}
