import qs from 'qs'
import { StrapiAPI } from '@/services/axios'

export async function findAllAddressBook() {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
    ],
    _sort: 'id:asc,created_at:asc',
  })
  const response = await StrapiAPI.get(`/address-books?${query}`)
  return response.data
}

export async function findByIdAddressBook(id: number) {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
    ],
  })
  const response = await StrapiAPI.get(`/address-books/${id}?${query}`)
  return response.data
}

export async function findByIdAddressGroup(id: number) {
  const query = qs.stringify({
    _where: [
      {
        is_public_eq: true,
      },
      {
        address_book_eq: id,
      },
    ],
  })
  const response = await StrapiAPI.get(`/address-groups?${query}`)
  return response.data
}
