import React from 'react'
import { Box, Modal, Text } from 'native-base'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/interfaces/navigation'
import { useAtom } from 'jotai'
import { handleLogout } from '@/atom/authAtom'
import { screenWidthAtom } from '@/atom/navigationAtom'
import CButton from '../CButton'

interface Props {
  handleCloseModal: () => void
}

export default function MyPageHeaderModal(props: Props) {
  const { handleCloseModal } = props
  const [isMenuOpen, setIsMenuOpen] = React.useState(true)
  const [screenWidth] = useAtom(screenWidthAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [, logout] = useAtom(handleLogout)

  const closeUserMenu = () => {
    handleCloseModal()
  }

  const goToLogout = () => {
    closeUserMenu()
    void logout()
    navigation.navigate(ROOT_STACK.TOP_TAB, {
      screen: TOP_TAB.MAIN,
    })
  }

  const styleObj = {
    backgroundColor: '#ECECEC',
    borderRadius: 20,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 14,
  }

  return (
    <React.Fragment>
      <Modal isOpen={isMenuOpen} onClose={() => closeUserMenu()}>
        <Modal.Content w={screenWidth - 80} borderRadius='5px'>
          <Modal.Body borderBottomWidth={0} pb={2}>
            <Box>
              <CButton style={styleObj} onPress={goToLogout}>
                <Text
                  textAlign='center'
                  fontSize='16px'
                  fontWeight={700}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  로그아웃
                </Text>
              </CButton>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}
