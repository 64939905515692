import React from 'react'
import { Box } from 'native-base'
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack'

import SendMessage from '@/screens/send-message/components/SendMessage'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import AuthStackHeader from '../auth/components/AuthStackHeader'
import { useNavigation } from '@react-navigation/native'

type Props = NativeStackScreenProps<RootStackParamList, ROOT_STACK.SEND_MESSAGE>

export default function SendMessageScreen(props: Props) {
  const { userId, userNickName } = props.route.params
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <Box flex={1} bg='#ffffff'>
      <AuthStackHeader title='쪽지 보내기' navigation={navigation} />
      <SendMessage userId={userId} userNickName={userNickName} />
    </Box>
  )
}
