import React, { ReactElement } from 'react'
import { registerRootComponent } from 'expo'
import { NativeBaseProvider, StatusBar } from 'native-base'
import { SafeAreaView } from 'react-native'
import * as Notifications from 'expo-notifications'
import { Provider } from 'jotai'
import useTheme from '@/hooks/useTheme'
import Navigation from '@/navigation'
import SplashProvider from '@/components/SplashProvider'
import './style/globals.css'

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

const App: React.FC = (): ReactElement => {
  const theme = useTheme()

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#ffffff' }}>
      <NativeBaseProvider theme={theme}>
        <StatusBar barStyle='dark-content' backgroundColor='#ffffff' />
        <Provider>
          <SplashProvider>
            <Navigation />
          </SplashProvider>
        </Provider>
      </NativeBaseProvider>
    </SafeAreaView>
  )
}

registerRootComponent(App)
