import React from 'react'
import { KeyboardAvoidingView, Platform } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { Box, Center, Input, Text, TextArea } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import Animated from 'react-native-reanimated'

import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import { createMessage } from '@/services/userContents'

import CButton from '@/components/CButton'
import ModalAlert from '@/components/ModalAlert'
import { AUTH_STACK, ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import { ICreateMessageBoardForm } from '@/interfaces/userContents'

interface Props {
  userId: number
  userNickName: string
}

export default function SendMessage(props: Props) {
  const { userId, userNickName } = props
  const [auth] = useAtom(authAtom)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [title, setTitle] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [isOpenAlert, setIsOpenAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [contentLength, setContentLength] = React.useState(0)
  const [scrollHeight, setScrollHeight] = React.useState(Platform.OS === 'ios' ? 299 : 282)
  const textAreaRef = React.useRef<any>()

  const handleScrollHeight = (h: number) => {
    setScrollHeight(h)
  }

  const handleOpenAlert = (message: string) => {
    setAlertMessage(message)
    setIsOpenAlert(!isOpenAlert)
  }

  const { handleSubmit, control } = useForm<ICreateMessageBoardForm>({
    defaultValues: {
      from_user: auth.user ? auth.user.id : 0,
      to_user: userId,
      title: '',
      message: '',
    },
  })

  const onSubmit = async (form: ICreateMessageBoardForm) => {
    if (!auth.logged) {
      navigation.push(ROOT_STACK.AUTH_STACK, { screen: AUTH_STACK.AUTH_SIGN_IN })
    }

    if (!title.trim()) {
      handleOpenAlert('제목을 1글자 이상 입력해주세요.')
      return
    }

    if (!message) {
      handleOpenAlert('내용을 1글자 이상 입력해주세요.')
      return
    }

    await createMessage({ jwt: auth.jwt, createMessageBoardForm: form })

    setTitle('')
    setMessage('')
    navigation.goBack()
  }

  const handleContentLength = (length: number) => {
    setContentLength(length)
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={20}
    >
      {/*<TouchableWithoutFeedback onPress={Keyboard.dismiss}>*/}
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        // scrollEventThrottle={16}
        // onScroll={scrollHandler}
      >
        {isOpenAlert && (
          <ModalAlert
            isOpen={isOpenAlert}
            message={alertMessage}
            onClose={() => {
              handleOpenAlert('')
            }}
          />
        )}

        <Box px='17px' mb='25px'>
          <Box
            flexDirection='row'
            pt='20px'
            pb='20px'
            borderBottomWidth='1px'
            borderBottomColor='#eeeff2'
          >
            <Text fontSize='16px' color='#5B5B5B' w='71px'>
              받는이
            </Text>
            <Text fontSize='15px'>{userNickName}</Text>
          </Box>
          <Box flexDirection='row' pt='13px' pb='13px'>
            <Text fontSize='16px' color='#5B5B5B' w='71px'>
              제목
            </Text>
            <Controller
              control={control}
              name='title'
              rules={{ required: false }}
              render={({ field: { onChange } }) => (
                <Input
                  flex={1}
                  variant='unstyled'
                  borderWidth={1}
                  borderRadius='5px'
                  fontSize='16px'
                  p='3px'
                  h='32px'
                  value={title}
                  maxLength={100}
                  onChangeText={(value) => {
                    onChange(value)
                    setTitle(value)
                  }}
                />
              )}
            />
          </Box>
          <Box minH='310px'>
            <Controller
              control={control}
              name='message'
              rules={{ required: false }}
              render={({ field: { onChange } }) => (
                <TextArea
                  ref={textAreaRef}
                  autoCapitalize='none'
                  autoCorrect={false}
                  autoCompleteType={undefined}
                  scrollEnabled={false}
                  placeholder='쪽지 내용을 입력해주세요.'
                  placeholderTextColor='#6C6C6C'
                  fontSize='16px'
                  mb='8px'
                  h={scrollHeight}
                  borderColor='#D5D8DA'
                  borderRadius='5px'
                  maxLength={1000}
                  value={message}
                  onChangeText={(value) => {
                    onChange(value)
                    setMessage(value)
                    handleContentLength(value.length)
                    if (value) {
                      const length = value.split('\n').length
                      let height: number
                      if (Platform.OS === 'ios') {
                        height = length > 17 ? length * 17 + 10 : 299
                      } else {
                        height = length > 17 ? length * 16 + 10 : 282
                      }
                      handleScrollHeight(height)
                    }
                  }}
                />
              )}
            />
            <Text textAlign='right'>{contentLength}/1,000자</Text>
          </Box>
        </Box>
        <Center mb={300}>
          <CButton onPress={handleSubmit(onSubmit)}>
            <Center bgColor='#ff6f1e' w='120px' h='40px' rounded='5px'>
              <Text color='white' fontSize='16px' fontWeight={700}>
                쪽지 보내기
              </Text>
            </Center>
          </CButton>
        </Center>
      </Animated.ScrollView>
      {/*</TouchableWithoutFeedback>*/}
    </KeyboardAvoidingView>
  )
}
