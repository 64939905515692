import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'
import { showBarAtom } from '@/atom/navigationAtom'
import { useAtom } from 'jotai'
import { useRef } from 'react'

export default function useScroll() {
  // const scrollY = useRef<any>(new Animated.Value(0)).current
  const scrollY = useSharedValue(false)

  const [isShowBar, setShowBar] = useAtom(showBarAtom)

  const scrollHandler = (event: any) => {
    const offsetY = event.nativeEvent.contentOffset.y
    // 스크롤 방향 체크
    if (offsetY > scrollY.value && offsetY > 0) {
      if (isShowBar) setShowBar(false)
    } else if (offsetY < scrollY.value) {
      if (!isShowBar) setShowBar(true)
    }
    scrollY.value = offsetY
  }

  return {
    scrollHandler,
  }
}
