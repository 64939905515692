import { Box, HStack, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import { useAtom } from 'jotai'
import { authAtom, handleLogout } from '@/atom/authAtom'

import TopAuthMenuMyPageNav from '@/components/TopAuthMenuMyPageNav'
import CButton from '@/components/CButton'
import { ROOT_STACK, TOP_TAB } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'

interface Props {
  onClose: () => void
}

export default function TopAuthMenuLogin(props: Props) {
  const { onClose } = props
  const [auth] = useAtom(authAtom)
  const [, logout] = useAtom(handleLogout)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <VStack size={2} mb='5px' mt='18px'>
      <HStack
        space={2}
        justifyContent='space-between'
        borderBottomWidth={1}
        borderBottomColor='#E8E8E8'
        pb='20px'
        mb='5px'
      >
        <Text fontSize='18px' fontWeight={600} my='auto'>
          {auth.user?.nick_name}
        </Text>
        <HStack space={2} my='auto'>
          <CButton
            my='auto'
            mr='7px'
            onPress={() => {
              onClose()
              void logout()
              navigation.navigate(ROOT_STACK.TOP_TAB, {
                screen: TOP_TAB.MAIN,
              })
            }}
          >
            <Box borderWidth='1px' borderRadius='3px' borderColor='#9F9F9F' px='12px' py='5px'>
              <Text fontSize='15px' fontWeight={600}>
                로그아웃
              </Text>
            </Box>
          </CButton>
          <CButton onPress={onClose} my='auto'>
            <Box borderWidth='1px' borderRadius='3px' borderColor='#9F9F9F' px='12px' py='5px'>
              <Text fontSize='15px' fontWeight={600}>
                닫기
              </Text>
            </Box>
          </CButton>
        </HStack>
      </HStack>
      <TopAuthMenuMyPageNav onClose={onClose} />
    </VStack>
  )
}
