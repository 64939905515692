export default function useUrl() {
  const handleUrl = (url: string) => {
    const oldUrl = url.search('hanteo.s3.amazonaws.com')
    const oldUrl2 = url.search('hanteo.s3.ap-northeast-1.amazonaws.com')
    const newUrl = url.search('hanteo-strapi.s3.ap-northeast-1.amazonaws.com')

    if (newUrl === -1 && oldUrl2 === -1 && oldUrl !== -1) {
      return url.replace('hanteo.s3.amazonaws.com', 'd3339718t4rrg6.cloudfront.net')
    } else if (newUrl === -1 && oldUrl === -1 && oldUrl2 !== -1) {
      return url.replace('hanteo.s3.ap-northeast-1.amazonaws.com', 'd3339718t4rrg6.cloudfront.net')
    } else if (oldUrl === -1 && oldUrl2 === -1 && newUrl !== -1) {
      return url.replace(
        'hanteo-strapi.s3.ap-northeast-1.amazonaws.com',
        'd1kzwer80von2x.cloudfront.net',
      )
    } else {
      return url
    }
  }

  const getUrlListByContents = (contents: string) => {
    const regex = /(img[^>]*src\s*=\s*["']?([^>"']+)["']?[^>]*>)/g
    const urlList = []

    while (regex.test(contents)) {
      urlList.push({ url: RegExp.$2.trim() })
    }

    return urlList
  }

  return {
    handleUrl,
    getUrlListByContents,
  }
}
