import React from 'react'
import { Box, Button, HStack, Text, VStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import useFormatter from '@/hooks/useFormatter'
import BackButton from '@/components/BackButton'
import { ROOT_STACK } from '@/constants/label'
import { RootStackParamList } from '@/interfaces/navigation'
import FastImage from '@/components/FastImage'
import CButton from '@/components/CButton'
import MyPageHeaderModal from '@/components/modal/MyPageHeaderModal'
import LanguageHeaderItem from '@/components/LanguageHeaderItem'

//모바일하고 조금 다름
export default function MyPageHeader() {
  const [auth] = useAtom(authAtom)
  const { numberCommaFormatter } = useFormatter()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const closeMenuModal = () => {
    setIsMenuOpen(false)
  }

  return (
    <React.Fragment>
      {isMenuOpen && <MyPageHeaderModal handleCloseModal={closeMenuModal} />}
      <VStack
        w='100%'
        py='20px'
        px='15px'
        borderBottomColor='#F4F4F4'
        borderBottomWidth='8px'
        bg='#ffffff'
      >
        <HStack justifyContent='space-between' alignItems='center'>
          <HStack flex={1}>
            <Box pb='3px'>
              <BackButton navigation={navigation} color='BLACK' />
            </Box>
            <Text
              ellipsizeMode='tail'
              numberOfLines={1}
              px='16px'
              fontSize='20px'
              fontWeight='bold'
            >
              {auth.user && auth.user.nick_name ? auth.user.nick_name : ''}
            </Text>
          </HStack>
          <HStack>
            <LanguageHeaderItem />
            <Button bgColor='white' onPress={() => setIsMenuOpen(true)}>
              <FastImage
                source={require('@/assets/images/menu-dots.png')}
                style={{ width: 24, height: 18, marginTop: 'auto', marginBottom: 'auto' }}
                resizeMode='contain'
              />
            </Button>
          </HStack>
        </HStack>
        <HStack alignItems='center'>
          <CButton
            pl='30px'
            ml='8px'
            onPress={() => {
              navigation.push(ROOT_STACK.MODIFY_PROFILE)
            }}
          >
            <HStack mt={0.5}>
              <FastImage
                source={require('@/assets/images/profile_setting.png')}
                style={{
                  width: 15,
                  height: 15,
                  marginTop: 5,
                }}
                resizeMode='contain'
              />
              <Text mt={1} pl={2} fontSize='13px' color='#7C7C7C' fontWeight='bold'>
                프로필 설정
              </Text>
            </HStack>
          </CButton>
          <HStack mt={1}>
            <Text mt='3px' pl='12px' fontSize='13px' color='#7C7C7C'>
              방문
            </Text>
            <Text mt='3px' fontSize='13px' color='#7C7C7C' ml='4px'>
              {auth.user && auth.user.visitor_count
                ? numberCommaFormatter(auth.user.visitor_count)
                : 0}
            </Text>
          </HStack>
        </HStack>
      </VStack>
    </React.Fragment>
  )
}
