import React from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useAtom } from 'jotai'
import { authAtom } from '@/atom/authAtom'
import {
  myPageBoardListAtom,
  myPageBoardSubListAtom,
  myPageBoardSubTotalCountAtom,
  myPageBoardTotalCountAtom,
  myPageCommentListAtom,
  myPageCommentTotalCountAtom,
  myPageGoodListAtom,
  myPageGoodTotalCountAtom,
  myPageMemberSubListAtom,
  myPageMemberSubTotalCountAtom,
  myPageMessageFromListAtom,
  myPageMessageFromTotalCountAtom,
  myPageMessageKeepListAtom,
  myPageMessageKeepTotalCountAtom,
  myPageMessageToListAtom,
  myPageMessageToTotalCountAtom,
  myPageScrapListAtom,
  myPageScrapTotalCountAtom,
} from '@/atom/initAtom'
import {
  noReadMessageCountAtom,
  reloadNoReadMessageCountAtom,
  reloadReadStateAtom,
} from '@/atom/navigationAtom'
import {
  findAllBoardByMeAuth,
  findAllBoardSubByMeAuth,
  findAllCommentByMeAuth,
  findAllGoodByMeAuth,
  findAllMemberSubByMeAuth,
  findAllMessageBoardByMe,
  findAllScrapByMeAuth,
} from '@/services/userContents'
import { LIMIT } from '@/constants/limit'
import { MY_PAGE_TAB } from '@/constants/label'
import { MyPageTabParamList } from '@/interfaces/navigation'
import { Box } from 'native-base'
import { MyPageHeader } from '@/screens/my-page/components'
import MyPageSubscriptionScreen from '@/screens/my-page/MyPageSubscriptionScreen'
import MyPageBoardScreen from '@/screens/my-page/MyPageBoardScreen'
import MyPageCommentScreen from '@/screens/my-page/MyPageCommentScreen'
import MyPageGoodScreen from '@/screens/my-page/MyPageGoodScreen'
import MyPageMessageScreen from '@/screens/my-page/MyPageMessageScreen'
import MyPageScrapScreen from '@/screens/my-page/MyPageScrapScreen'

const MyPageTab = createMaterialTopTabNavigator<MyPageTabParamList>()

export default function MyPageTabNavigator() {
  const [auth] = useAtom(authAtom)
  const [noReadMessageCount] = useAtom(noReadMessageCountAtom)
  const [, setMyPageBoardList] = useAtom(myPageBoardListAtom)
  const [, setMyPageBoardTotalCount] = useAtom(myPageBoardTotalCountAtom)
  const [, setMyPageCommentList] = useAtom(myPageCommentListAtom)
  const [, setMyPageCommentTotalCount] = useAtom(myPageCommentTotalCountAtom)
  const [, setMyPageGoodList] = useAtom(myPageGoodListAtom)
  const [, setMyPageGoodTotalCount] = useAtom(myPageGoodTotalCountAtom)
  const [, setMyPageScrapList] = useAtom(myPageScrapListAtom)
  const [, setMyPageScrapTotalCount] = useAtom(myPageScrapTotalCountAtom)
  const [, setMyPageMessageFromList] = useAtom(myPageMessageFromListAtom)
  const [, setMyPageMessageFromTotalCount] = useAtom(myPageMessageFromTotalCountAtom)
  const [, setMyPageMessageToList] = useAtom(myPageMessageToListAtom)
  const [, setMyPageMessageToTotalCount] = useAtom(myPageMessageToTotalCountAtom)
  const [, setMyPageMessageKeepList] = useAtom(myPageMessageKeepListAtom)
  const [, setMyPageMessageKeepTotalCount] = useAtom(myPageMessageKeepTotalCountAtom)
  const [, setMyPageMemberSubList] = useAtom(myPageMemberSubListAtom)
  const [, setMyPageMemberSubTotalCount] = useAtom(myPageMemberSubTotalCountAtom)
  const [, setMyPageBoardSubList] = useAtom(myPageBoardSubListAtom)
  const [, setMyPageBoardSubTotalCount] = useAtom(myPageBoardSubTotalCountAtom)
  const [, reloadNoReadMessageCount] = useAtom(reloadNoReadMessageCountAtom)
  const [, reloadReadStateCount] = useAtom(reloadReadStateAtom)

  const initMyPageData = async () => {
    if (!auth.logged || auth.jwt === '') return

    const myPageArg = {
      jwt: auth.jwt,
      limit: LIMIT.MY_PAGE_LIST,
    }

    await Promise.all([
      findAllBoardByMeAuth(myPageArg),
      findAllCommentByMeAuth(myPageArg),
      findAllGoodByMeAuth(myPageArg),
      findAllScrapByMeAuth(myPageArg),
      findAllMessageBoardByMe(myPageArg),
      findAllMemberSubByMeAuth(myPageArg),
      findAllBoardSubByMeAuth(myPageArg),
      reloadNoReadMessageCount(),
      reloadReadStateCount(),
    ]).then((values) => {
      setMyPageBoardList(values[0].contents)
      setMyPageBoardTotalCount(values[0].totalCount)
      setMyPageCommentList(values[1].contents)
      setMyPageCommentTotalCount(values[1].totalCount)
      setMyPageGoodList(values[2].contents)
      setMyPageGoodTotalCount(values[2].totalCount)
      setMyPageScrapList(values[3].contents)
      setMyPageScrapTotalCount(values[3].totalCount)
      setMyPageMessageFromList(values[4].from.contents)
      setMyPageMessageFromTotalCount(values[4].from.totalCount)
      setMyPageMessageToList(values[4].to.contents)
      setMyPageMessageToTotalCount(values[4].to.totalCount)
      setMyPageMessageKeepList(values[4].keep.contents)
      setMyPageMessageKeepTotalCount(values[4].keep.totalCount)
      setMyPageMemberSubList(values[5].contents)
      setMyPageMemberSubTotalCount(values[5].totalCount)
      setMyPageBoardSubList(values[6].contents)
      setMyPageBoardSubTotalCount(values[6].totalCount)
    })
  }

  React.useEffect(() => {
    void initMyPageData()
  }, [])

  return (
    <Box flex={1}>
      <MyPageHeader />
      <MyPageTab.Navigator
        initialRouteName={MY_PAGE_TAB.MY_PAGE_BOARD}
        backBehavior='none'
        screenOptions={{
          tabBarScrollEnabled: true,
          tabBarActiveTintColor: '#FF6F1E',
          tabBarInactiveTintColor: '#282828',
          tabBarStyle: {
            backgroundColor: '#FFFFFF',
          },
          tabBarLabelStyle: {
            fontSize: 16,
            fontWeight: 'bold',
          },
          tabBarItemStyle: {
            // Make Item width adjust to Label width
            width: 'auto',
          },
          tabBarIndicatorStyle: {
            backgroundColor: '#FF6F1E',
            marginHorizontal: 0,
          },
        }}
      >
        <MyPageTab.Screen
          name={MY_PAGE_TAB.MY_PAGE_BOARD}
          component={MyPageBoardScreen}
          options={{ title: '작성글' }}
        />
        <MyPageTab.Screen
          name={MY_PAGE_TAB.MY_PAGE_COMMENT}
          component={MyPageCommentScreen}
          options={{ title: '작성댓글' }}
        />
        <MyPageTab.Screen
          name={MY_PAGE_TAB.MY_PAGE_GOOD}
          component={MyPageGoodScreen}
          options={{ title: '좋아요!' }}
        />
        <MyPageTab.Screen
          name={MY_PAGE_TAB.MY_PAGE_MESSAGE}
          component={MyPageMessageScreen}
          options={{ title: '쪽지함 ' + noReadMessageCount.toString() }}
        />
        <MyPageTab.Screen
          name={MY_PAGE_TAB.MY_PAGE_SCRAP}
          component={MyPageScrapScreen}
          options={{ title: '스크랩' }}
        />
        <MyPageTab.Screen
          name={MY_PAGE_TAB.MY_PAGE_SUBSCRIPTION}
          component={MyPageSubscriptionScreen}
          options={{ title: ' 구독 ' }}
        />
      </MyPageTab.Navigator>
    </Box>
  )
}
