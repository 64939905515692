import { Box, Pressable } from 'native-base'
import React from 'react'

export default function CButton(props: any) {
  return (
    <Pressable {...props}>
      {({ isPressed }) => {
        return (
          <Box
            style={{
              transform: [
                {
                  scale: isPressed ? 0.96 : 1,
                },
              ],
            }}
          >
            {props.children}
          </Box>
        )
      }}
    </Pressable>
  )
}
