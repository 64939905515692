import CButton from '@/components/CButton'
import { Center, Text } from 'native-base'

interface Props {
  buttonText: string
  onPressHandler: () => void
}

export default function ConfirmButton(props: Props) {
  const { buttonText, onPressHandler } = props

  return (
    <CButton onPress={() => onPressHandler()}>
      <Center
        h='33px'
        w='88px'
        backgroundColor='#ff6f1e'
        borderRadius='20px'
        borderColor='#ff6f1e'
        marginTop='25px'
        marginLeft='5px'
      >
        <Text mt='3px' color='white' fontWeight='600' fontSize={15}>
          {buttonText}
        </Text>
      </Center>
    </CButton>
  )
}
